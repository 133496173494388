import React, { useContext, useEffect, useRef, useState } from "react";
import {
  FaSearch,
  FaSortAmountDown,
  FaSortAmountDownAlt,
  FaUserPlus,
  FaCheck,
  FaCheckCircle,
  FaMinusCircle,
  // FaTimesCircle,
} from "react-icons/fa";
import { useParams } from "react-router-dom";
import { getAuthData, postAuthData } from "../../../../../helpers/request";
import {
  date_time_convertor,
  TOOLTIP_HOVER_TYPE,
  ApiContext,
  showHoverToolTip,
  ACCESS_LOG_TYPES,
  DEVICE_RESPONSIVENESS,
  ERROR_REGEX,
  ToolTipError,
  HoverToolTipTextContext,
  AccessLogTypeIcon,
  getDeviceResponsiveness,
  DevelopmentContext,
  CLIENT_USER_TYPE,
  getAccessLogData,
} from "../../../../..";
import {
  ClientAccessLogContext,
  ClientArchiveFileContext,
  ClientDashboardFileContext,
  ClientRouteContext,
  ClientSharingLogContext,
  CLIENT_MODAL_TYPE,
  CLIENT_ROUTES,
  ModalDispatchContext,
  NotFoundErrorContext,
} from "../../../../ClientComponents";
import "./LogsTab.css";
// import { renderShareModalContext } from "../../../../Modal/SharingModal/SharingModal";
import { SHARE_MODAL_TYPE } from "../../../../../utils/ShareModalType";

import logs_data from "../../../../../archive/test_data/deliveryV1_5.json";
import { SHARE_RESPONSE_STATUS } from "../../../../../utils/ShareResponseStatus";

// ===================================== Share card ==============================================
// ********** original user revoke-request ****************
const OriginalClientSharedUserCard = ({ user }) => {
  const modal_dispatch = useContext(ModalDispatchContext);

  const openRevokeRequestModal = (e) => {
    e.preventDefault();
    modal_dispatch({
      modal_show_type: CLIENT_MODAL_TYPE.REVOKE_REQUEST,
      payload: { shared_email: user?.shared_to_user_email },
    });
  };

  return (
    <>
      <div className="client-shared-user-card glass-container glass-container-hover">
        <div className="client-shared-user-card-info client-share-user">
          <span className="client-shared-user-card-info-head">User</span>
          <span className="client-shared-user-card-info-value">
            {user?.shared_to_user_email || "-"}
          </span>
        </div>
        <div className="client-shared-user-card-info client-share-shared-by">
          <span className="client-shared-user-card-info-head">Shared By</span>
          <span className="client-shared-user-card-info-value">
            {user?.shared_by || "-"}
          </span>
        </div>
        <div className="client-shared-user-card-info client-share-time-accessed client-shared-user-card-info-center">
          <span className="client-shared-user-card-info-head">
            Times Accessed
          </span>
          <span className="client-shared-user-card-info-value">
            {user?.accessed_number ?? "-"}
          </span>
        </div>
        <div className="client-shared-user-card-info client-share-last-download-ed-version client-shared-user-card-info-center">
          <span className="client-shared-user-card-info-head">
            Last Downloaded Version
          </span>
          <span className="client-shared-user-card-info-value">
            {user?.last_download_version || "-"}
          </span>
        </div>
        <div className="client-shared-user-card-info client-share-last-accessed-on client-shared-user-card-info-center">
          <span className="client-shared-user-card-info-head">
            Last Accessed on
          </span>
          <span className="client-shared-user-card-info-value">
            {date_time_convertor(user.last_accessed_time) || "-"}
          </span>
        </div>
        <div className="client-shared-user-card-info client-share-accept-icon">
          <span className="client-shared-user-card-info-value approved-access-icon">
            <FaCheck />
          </span>
        </div>
        <div className="client-shared-user-card-info client-share-decline-icon">
          <span
            className="client-shared-user-card-info-value revoke-icon"
            onClick={openRevokeRequestModal}
          >
            <FaMinusCircle />
          </span>
        </div>
      </div>
    </>
  );
};

// ********** shared-user ****************

const ClientSharedUserCard = ({ user }) => {
  return (
    <div className="client-share-user-card glass-container glass-container-hover">
      <div className="client-shared-user-card-info client-share-user">
        <span className="client-shared-user-card-info-head">User</span>
        <span className="client-shared-user-card-info-value">
          {user?.shared_to_user_email || "-"}
        </span>
      </div>
      <div className="client-shared-user-card-info client-share-shared-by">
        <span className="client-shared-user-card-info-head">Shared By</span>
        <span className="client-shared-user-card-info-value">
          {user?.shared_by || "-"}
        </span>
      </div>
      <div className="client-shared-user-card-info client-share-time-accessed client-shared-user-card-info-center">
        <span className="client-shared-user-card-info-head">
          Times Accessed
        </span>
        <span className="client-shared-user-card-info-value">
          {user?.accessed_number ?? "-"}
        </span>
      </div>
      <div className="client-shared-user-card-info client-share-last-download-ed-version client-shared-user-card-info-center">
        <span className="client-shared-user-card-info-head">
          Last Downloaded Version
        </span>
        <span className="client-shared-user-card-info-value">
          {user?.last_download_version || "-"}
        </span>
      </div>
      <div className="client-shared-user-card-info client-share-last-accessed-on client-shared-user-card-info-center">
        <span className="client-shared-user-card-info-head">
          Last Accessed on
        </span>
        <span className="client-shared-user-card-info-value">
          {date_time_convertor(user.last_accessed_time) || "-"}
        </span>
      </div>
    </div>
  );
};

// ********** original user approve-request ****************

const RequestClientSharedUserCard = ({ user }) => {
  const modal_dispatch = useContext(ModalDispatchContext);
  const { devEnv } = useContext(DevelopmentContext);
  const API_URL = useContext(ApiContext);
  const { setNotFoundError } = useContext(NotFoundErrorContext);
  const { setSharedLogData } = useContext(ClientSharingLogContext);
  const { setAccessLogData } = useContext(ClientAccessLogContext);
  const { clientDisplayedFile } = useContext(ClientDashboardFileContext);

  const fetch_access_log = () => {
    getAuthData(
      `${API_URL}/api-client/misc/v1/access-log/${clientDisplayedFile?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setAccessLogData(res.data);
        } else {
          setNotFoundError(true);
        }
      })
      .catch((err) => {
        setNotFoundError(true);
      });
  };

  const fetch_shared_log = () => {
    getAuthData(
      `${API_URL}/api-client/misc/v1/sharedlog/${clientDisplayedFile?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setSharedLogData(res);
        } else {
          setNotFoundError(true);
        }
      })
      .catch((err) => {
        setNotFoundError(true);
      });
  };

  const openDeclineRequestModal = (e) => {
    e.preventDefault();
    modal_dispatch({
      modal_show_type: CLIENT_MODAL_TYPE.DECLINE_REQUEST,
      payload: { shared_email: user?.shared_to_user_email },
    });
  };

  const openShareWarningModal = (e) => {
    e?.preventDefault();

    const original_req_data = {
      delivery_id: clientDisplayedFile.batch_id,
      email: user?.shared_to_user_email,
      accept_warning: false, //to check for warning
    };

    if (devEnv) {
      modal_dispatch({ modal_show_type: CLIENT_MODAL_TYPE.SHARE_WARNING });
    } else {
      postAuthData(
        `${API_URL}/api-client/misc/v1/accept-request/`,
        getAccessLogData(original_req_data),
      )
        .then((res) => {
          if (res.status === SHARE_RESPONSE_STATUS.SUCCESS) {
            fetch_shared_log();
            fetch_access_log();
          } else if (res.status === SHARE_RESPONSE_STATUS.WARNING) {
            // console.log(res)
            // modal_dispatch({modal_show_type:CLIENT_MODAL_TYPE.DECLINE_REQUEST, payload: {res}});
            modal_dispatch({
              modal_show_type: CLIENT_MODAL_TYPE.SHARE_WARNING,
              payload: {
                share_modal_type: SHARE_MODAL_TYPE.WARNING,
                share_res: res,
                from: "logs",
              },
            });
            // console.log(shareWarningData);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // modal_dispatch({modal_show_type:CLIENT_MODAL_TYPE.SHARE_MODAL, payload: {share_modal_type:SHARE_MODAL_TYPE.WARNING,shared_email:user?.shared_to_user_email}});
  };

  return (
    <div className="client-shared-user-card glass-container glass-container-hover">
      <div className="client-shared-user-card-info client-share-user">
        <span className="client-shared-user-card-info-head">User</span>
        <span className="request-client-shared-user-card-info-value">
          {user?.shared_to_user_email || "-"}
        </span>
      </div>
      <div className="client-shared-user-card-info client-share-shared-by">
        <span className="client-shared-user-card-info-head">Shared By</span>
        <span className="request-client-shared-user-card-info-value">
          {user?.shared_by || "-"}
        </span>
      </div>
      <div className="client-shared-user-card-info client-share-time-accessed client-shared-user-card-info-center">
        <span className="client-shared-user-card-info-head">
          Times Accessed
        </span>
        <span className="request-client-shared-user-card-info-value">-</span>
      </div>
      <div className="client-shared-user-card-info client-share-last-download-ed-version client-shared-user-card-info-center">
        <span className="client-shared-user-card-info-head">
          Last Downloaded Version
        </span>
        <span className="request-client-shared-user-card-info-value">-</span>
      </div>
      <div className="client-shared-user-card-info client-share-last-accessed-on client-shared-user-card-info-center">
        <span className="client-shared-user-card-info-head client-share-last-accessed-on-head">
          Last Accessed on
        </span>
        <span className="client-shared-user-card-info-head client-share-last-accessed-on-head-mobile">
          Approve Share Request?
        </span>
        <span className="request-client-shared-user-card-info-value client-share-last-accessed-on-value">
          Approve Share Request?
        </span>
        <span className="request-client-shared-user-card-info-value client-share-last-accessed-on-value-mobile">
          -
        </span>
      </div>
      <div className="client-shared-user-card-info client-share-accept-icon">
        <span
          className="client-shared-user-card-info-value approve-request-icon"
          onClick={openShareWarningModal}
        >
          <FaCheckCircle />
        </span>
      </div>
      <div className="client-shared-user-card-info client-share-decline-icon">
        <span
          className="client-shared-user-card-info-value decline-request-icon"
          onClick={openDeclineRequestModal}
        >
          <FaMinusCircle />
        </span>
      </div>
    </div>
  );
};

const ClientShareCard = ({ displayedFile, setDisplayedFile }) => {
  // const {setRenderShareModal} = useContext(renderShareModalContext);
  const { devEnv } = useContext(DevelopmentContext);
  const route = useContext(ClientRouteContext);
  const modal_dispatch = useContext(ModalDispatchContext);
  const API_URL = useContext(ApiContext);
  const { clientDisplayedFile } = useContext(ClientDashboardFileContext);
  const { clientArchiveFile } = useContext(ClientArchiveFileContext);
  // const {setNotFoundError} = useContext(NotFoundErrorContext);
  const { sharedLogData, setSharedLogData } = useContext(
    ClientSharingLogContext,
  );
  const [reqSharedLogData, setReqSharedLogData] = useState();
  const [shareCardMsg, setShareCardMsg] = useState(null);
  const { asgn_id } = useParams();
  let page = "";

  // If 0 logs are found then set a message
  useEffect(() => {
    if (sharedLogData && sharedLogData?.length === 0) {
      setShareCardMsg("No logs found!");
    } else {
      setShareCardMsg(null);
    }
  }, [sharedLogData, reqSharedLogData]);

  const fetch_shared_log = () => {
    setShareCardMsg("Fetching...");
    if (route === CLIENT_ROUTES.ASSIGNMENTS) page = `misc`;
    else if (route === CLIENT_ROUTES.ARCHIVE) page = `archive`;

    if (devEnv) {
      setSharedLogData(logs_data);
      setReqSharedLogData(logs_data);
    } else {
      getAuthData(
        `${API_URL}/api-client/${page}/v1/sharedlog/${displayedFile?.id}/`,
      )
        .then((res) => {
          if (res.success === true) {
            setSharedLogData(res);
            // console.log(res)
          } else {
            setShareCardMsg("Can't fetch logs.");
          }
        })
        .catch((err) => {
          setShareCardMsg("Can't fetch logs.");
        });
    }
  };

  // re-rendering only the first time
  // useEffect(() => {
  //     fetch_shared_log();
  // }, [])

  // re-rendering at change of displayed file
  useEffect(() => {
    fetch_shared_log();
  }, [displayedFile]);

  useEffect(() => {
    if (route === CLIENT_ROUTES.ASSIGNMENTS) {
      setDisplayedFile(clientDisplayedFile);
    } else if (route === CLIENT_ROUTES.ARCHIVE) {
      setDisplayedFile(clientArchiveFile);
    }
  }, [clientDisplayedFile, clientArchiveFile]);

  useEffect(() => {
    setShareCardMsg("Fetching...");
  }, [asgn_id]);

  const openSharingModal = (e) => {
    e.preventDefault();
    modal_dispatch({
      modal_show_type: CLIENT_MODAL_TYPE.SHARE_MODAL,
      payload: { share_modal_type: SHARE_MODAL_TYPE.NORMAL },
    });
  };

  return (
    <div className="client-dashboard-card glass-container-active client-log-container">
      <div className="client-dashboard-card-head client-log-card-head">
        <span>Users</span>
        {route === CLIENT_ROUTES.ASSIGNMENTS && (
          <button
            className="client-share-open-modal-btn"
            onClick={openSharingModal}
          >
            <FaUserPlus />
          </button>
        )}
      </div>

      <div className="client-shared-users-container-head">
        <span className="client-shared-users-container-head-value">User</span>
        <span className="client-shared-users-container-head-value">
          Shared By
        </span>
        <span className="client-shared-users-container-head-value client-shared-users-container-head-value-center">
          Times Accessed
        </span>
        <span className="client-shared-users-container-head-value client-shared-users-container-head-value-center">
          Last Downloaded Version
        </span>
        <span className="client-shared-users-container-head-value client-shared-users-container-head-value-center">
          Last Accessed on
        </span>
      </div>

      <div className="client-log-items-container client-shared-users-container">
        {shareCardMsg && <div className="client-log-msg">{shareCardMsg}</div>}

        {displayedFile.client_user_type === CLIENT_USER_TYPE.ORIGINAL &&
          !shareCardMsg &&
          sharedLogData?.request_shared_log?.map((user, i) => {
            return <RequestClientSharedUserCard key={i} user={user} />;
          })}

        {displayedFile.client_user_type === CLIENT_USER_TYPE.ORIGINAL &&
          !shareCardMsg &&
          sharedLogData?.shared_log?.map((user, i) => {
            return <OriginalClientSharedUserCard key={i} user={user} />;
          })}

        {displayedFile.client_user_type === CLIENT_USER_TYPE.SHARED &&
          !shareCardMsg &&
          sharedLogData?.shared_log?.map((user, i) => {
            return <ClientSharedUserCard key={i} user={user} />;
          })}
      </div>
    </div>
  );
};

// ========================= Access Logs =======================================

// this function will split the text upon a specific point and apply hover on the 2nd part after the split
const HoverText = ({ tooltip_txt, hover_str, split_txt }) => {
  const log_str = hover_str.split(split_txt)[0] + split_txt;
  const log_hover = hover_str.split(split_txt)[1];
  const { setHoverTooltipStr } = useContext(HoverToolTipTextContext);

  return (
    <span>
      {log_str + " "}
      {getDeviceResponsiveness() === DEVICE_RESPONSIVENESS.MOBILE ? (
        <span
          className="hover-tooltip"
          onMouseOver={(e) => {
            setHoverTooltipStr(tooltip_txt);
            showHoverToolTip(e, TOOLTIP_HOVER_TYPE.OPEN);
          }}
          onMouseLeave={(e) => {
            showHoverToolTip(e, TOOLTIP_HOVER_TYPE.CLOSE, null);
          }}
        >
          {log_hover}
        </span>
      ) : (
        <u
          className="hover-tooltip"
          onMouseOver={(e) => {
            setHoverTooltipStr(tooltip_txt);
            showHoverToolTip(e, TOOLTIP_HOVER_TYPE.OPEN);
          }}
          onMouseLeave={(e) => {
            showHoverToolTip(e, TOOLTIP_HOVER_TYPE.CLOSE, null);
          }}
        >
          {log_hover}
        </u>
      )}
    </span>
  );
};

const ClientAccessLogItemCard = ({ access_log }) => {
  return (
    <div className="client-log-item-container client-access-log-item-container glass-container glass-container-hover">
      <div className="client-log-item-card client-access-log-type-logo">
        <div className="client-log-item-value">
          <AccessLogTypeIcon type={access_log.type} user={"client"} />
        </div>
      </div>
      <div className="client-log-item-card client-access-log-string">
        <div className="client-log-item-value">
          {[
            ACCESS_LOG_TYPES.DOWNLOAD,
            ACCESS_LOG_TYPES.SHARED,
            ACCESS_LOG_TYPES.SHARED_OUTSIDE,
            ACCESS_LOG_TYPES.ACCEPT,
            ACCESS_LOG_TYPES.DECLINE,
            ACCESS_LOG_TYPES.REQUEST,
            ACCESS_LOG_TYPES.REVOKE,
          ].includes(access_log.type) ? (
            <>
              {access_log.type === ACCESS_LOG_TYPES.DOWNLOAD && (
                <HoverText
                  tooltip_txt={access_log.tooltip.files}
                  hover_str={access_log.access_log_string}
                  split_txt={"downloaded "}
                />
              )}
              {access_log.type === ACCESS_LOG_TYPES.SHARED && (
                <HoverText
                  tooltip_txt={access_log.tooltip.shared_users}
                  hover_str={access_log.access_log_string}
                  split_txt={"with "}
                />
              )}
              {access_log.type === ACCESS_LOG_TYPES.SHARED_OUTSIDE && (
                <HoverText
                  tooltip_txt={access_log.tooltip.shared_users}
                  hover_str={access_log.access_log_string}
                  split_txt={"with "}
                />
              )}
              {access_log.type === ACCESS_LOG_TYPES.REQUEST && (
                <HoverText
                  tooltip_txt={access_log.tooltip.shared_users}
                  hover_str={access_log.access_log_string}
                  split_txt={"with "}
                />
              )}
              {access_log.type === ACCESS_LOG_TYPES.DECLINE && (
                <HoverText
                  tooltip_txt={access_log.tooltip.shared_users}
                  hover_str={access_log.access_log_string}
                  split_txt={"for "}
                />
              )}
              {access_log.type === ACCESS_LOG_TYPES.REVOKE && (
                <HoverText
                  tooltip_txt={access_log.tooltip.shared_users}
                  hover_str={access_log.access_log_string}
                  split_txt={"for "}
                />
              )}
              {access_log.type === ACCESS_LOG_TYPES.ACCEPT && (
                <HoverText
                  tooltip_txt={access_log.tooltip.shared_users}
                  hover_str={access_log.access_log_string}
                  split_txt={"for "}
                />
              )}
            </>
          ) : (
            access_log.access_log_string
          )}
        </div>
      </div>
      <div className="client-log-item-card client-access-log-platform client-blue-access-log">
        <div className="client-log-item-value">
          {/* <HoverText tooltip_txt={access_log.tooltip.ip} hover_str={access_log.platform} split_txt={"from "}/> */}
          {access_log?.tooltip?.ip ? (
            <HoverText
              tooltip_txt={access_log.tooltip.ip}
              hover_str={access_log.platform}
              split_txt={"from "}
            />
          ) : (
            access_log.platform
          )}
        </div>
      </div>
      <div className="client-log-item-card client-access-log-date client-blue-access-log">
        <div className="client-log-item-value">
          {date_time_convertor(access_log.date)}
        </div>
      </div>
    </div>
  );
};

export const access_log_sort_order = {
  ASCENDING: "ascending",
  DESCENDING: "descending",
};

const ClientAccessLogCard = ({ displayedFile, setDisplayedFile }) => {
  const { devEnv } = useContext(DevelopmentContext);
  const API_URL = useContext(ApiContext);
  const [sort, setSort] = useState(access_log_sort_order.ASCENDING);
  const access_log_search_word = useRef(null);
  const route = useContext(ClientRouteContext);
  const { clientDisplayedFile } = useContext(ClientDashboardFileContext);
  const { clientArchiveFile } = useContext(ClientArchiveFileContext);
  const { accessLogData, setAccessLogData } = useContext(
    ClientAccessLogContext,
  );
  // const {setNotFoundError} = useContext(NotFoundErrorContext);
  const [accessCardMsg, setAccessCardMsg] = useState(null);
  const { asgn_id } = useParams();
  let page = "";

  // If 0 logs are found then set a message
  useEffect(() => {
    if (accessLogData && accessLogData?.length === 0) {
      setAccessCardMsg("No logs found!");
    } else {
      setAccessCardMsg(null);
    }
  }, [accessLogData]);

  const fetch_access_log = () => {
    setAccessCardMsg("Fetching...");
    if (route === CLIENT_ROUTES.ASSIGNMENTS) page = `misc`;
    else if (route === CLIENT_ROUTES.ARCHIVE) page = `archive`;
    if (devEnv) {
      setAccessLogData(logs_data.access_logs);
    } else {
      getAuthData(
        `${API_URL}/api-client/${page}/v1/access-log/${displayedFile?.id}/`,
      )
        .then((res) => {
          if (res.success === true) {
            setAccessLogData(res.data);
          } else {
            setAccessCardMsg("Can't fetch logs.");
          }
        })
        .catch((err) => {
          setAccessCardMsg("Can't fetch logs.");
        });
    }
  };

  const fetch_sorted_access_log = (sort) => {
    setAccessCardMsg("Fetching...");
    if (route === CLIENT_ROUTES.ASSIGNMENTS) page = `misc`;
    else if (route === CLIENT_ROUTES.ARCHIVE) page = `archive`;
    getAuthData(
      `${API_URL}/api-client/${page}/v1/access-log/${displayedFile?.id}/?sort=${sort}&search=${access_log_search_word?.current?.value}`,
    )
      .then((res) => {
        if (res.success === true) {
          setAccessCardMsg(null);
          setAccessLogData(res.data);
        } else {
          setAccessCardMsg("Can't fetch logs.");
        }
      })
      .catch((err) => {
        setAccessCardMsg("Can't fetch logs.");
      });
  };

  // re-rendering only the first time
  // useEffect(() => {
  //     fetch_access_log();
  // }, [])

  // re-rendering at change of displayed file
  useEffect(() => {
    access_log_search_word.current.value = "";
    fetch_access_log();
  }, [displayedFile]);

  useEffect(() => {
    if (route === CLIENT_ROUTES.ASSIGNMENTS) {
      setDisplayedFile(clientDisplayedFile);
    } else if (route === CLIENT_ROUTES.ARCHIVE) {
      setDisplayedFile(clientArchiveFile);
    }
  }, [clientDisplayedFile, clientArchiveFile]);

  useEffect(() => {
    setAccessCardMsg("Fetching...");
  }, [asgn_id]);

  const handleSort = (e) => {
    e.preventDefault();
    setSort((prev) => {
      if (prev === access_log_sort_order.ASCENDING) {
        fetch_sorted_access_log(access_log_sort_order.DESCENDING);
        return access_log_sort_order.DESCENDING;
      } else if (prev === access_log_sort_order.DESCENDING) {
        fetch_sorted_access_log(access_log_sort_order.ASCENDING);
        return access_log_sort_order.ASCENDING;
      }
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetch_sorted_access_log(access_log_sort_order.ASCENDING);
  };

  return (
    <div className="client-dashboard-card glass-container-active client-log-container">
      <div className="client-dashboard-card-head client-log-card-head">
        <div className="client-access-head">
          <span>Access Logs</span>
          {sort === access_log_sort_order.ASCENDING && (
            <button
              className="client-sort-btn client-access-log-sort-btn"
              onClick={handleSort}
            >
              <FaSortAmountDownAlt />
            </button>
          )}
          {sort === access_log_sort_order.DESCENDING && (
            <button
              className="client-sort-btn client-access-log-sort-btn"
              onClick={handleSort}
            >
              <FaSortAmountDown />
            </button>
          )}
        </div>
        <div className="client-access-log-search-container">
          <form
            className="client-access-log-search-form"
            onSubmit={handleSearch}
          >
            <input
              type="text"
              className="client-dashboard-input client-access-log-search-input"
              ref={access_log_search_word}
              onChange={(e) => {
                ToolTipError(e, [ERROR_REGEX.SYMBOLS_ERROR]);
              }}
              name="client-log-search"
              id="client-log-search"
              placeholder="Search..."
            />
            <button type="submit" className="client-access-log-search-btn">
              <FaSearch />
            </button>
          </form>
        </div>
      </div>

      <div className="client-log-items-container">
        {accessCardMsg ? (
          <div className="client-log-msg">{accessCardMsg}</div>
        ) : (
          accessLogData?.map((access_log, i) => {
            {
              /* {test_data.access_logs?.map((access_log,i)=>{ */
            }
            return <ClientAccessLogItemCard key={i} access_log={access_log} />;
          })
        )}
      </div>
    </div>
  );
};

// This combines all the logs tab i.e. shared and access

export default function LogsTab({ file }) {
  const [displayedFile, setDisplayedFile] = useState(file);

  return (
    <div className="client-logs-container">
      <ClientShareCard
        displayedFile={displayedFile}
        setDisplayedFile={setDisplayedFile}
      />
      <ClientAccessLogCard
        displayedFile={displayedFile}
        setDisplayedFile={setDisplayedFile}
      />
    </div>
  );
}
