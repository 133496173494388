import React, { useEffect, useState, useRef } from "react";
import { FaTimes } from "react-icons/fa";

import "./ToolsShareRevokeModal.scss";
import { useDispatch, useSelector } from "react-redux";
import { resetModal } from "../../../../store/utilsDataSlice/utilsDataSlice";
import { getAuthData, postAuthData } from "../../../../helpers/request";
import { useParams } from "react-router-dom";
import {
  setAccessLogData,
  setSharedLogData,
} from "../../../../store/userSharedata/userShareDataSlice";
import { getAccessLogData } from "../../../..";
import toast from "react-hot-toast";

const ToolsShareRevokeModal = () => {
  const [requesterNames, setRequesterNames] = useState();
  const [shareErrNameArr, setShareErrNameArr] = useState([]); // eslint-disable-line no-unused-vars
  const [disableShareBtn, setDisableShareBtn] = useState(false); // eslint-disable-line no-unused-vars
  const [shareError, setShareError] = useState(null);
  const [error, setError] = useState(null); // eslint-disable-line no-unused-vars
  const [sharedNamesList, setSharedNamesList] = useState([]);
  const dispatch = useDispatch();
  const shareModalRef = useRef(null);
  const shareSubmitBtn = useRef(null);
  const { asgn_id } = useParams();
  const { tools_details_data } = useSelector((state) => state.toolsData);
  const { shared_res } = useSelector((state) => state.accessLog);
  const removeName = (e, idx) => {
    setSharedNamesList([
      ...sharedNamesList.slice(0, idx),
      ...sharedNamesList.slice(idx + 1, sharedNamesList.length),
    ]);
  };

  // const openSharingModal = (e) => {
  //     e?.preventDefault();
  //     setRenderShareModal(SHARE_MODAL_TYPE.NORMAL);
  // };

  const closeShareRevokeModal = (e) => {
    e?.preventDefault();
    dispatch(resetModal());
  };

  const submitBtnSuccess = () => {
    const shareBtn = shareSubmitBtn.current;
    shareBtn.style.background = "var(--light-orange,#e98742)";
    shareBtn.innerText = "Request sent!";
    setTimeout(() => {
      shareBtn.style.background = "var(--light-blue,#3cc3f2)";
      shareBtn.innerText = "Send Request";
      closeShareRevokeModal();
    }, 1000);
  };

  const fetch_access_log = () => {
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/access/log/${asgn_id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          dispatch(setAccessLogData(res.data));
        } else {
          toast.error(res.message || "Error Fetching Data");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetch_shared_log = () => {
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/share/log/${asgn_id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          dispatch(setSharedLogData(res));
        } else {
          toast.error(res.message || "Error Fetching Data");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const getRequesterNames = () => {
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/share/request/${asgn_id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setRequesterNames(res.data);
        } else {
          toast.error(res.message || "Error Fetching Data");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // data validation
  const validation = () => {
    if (sharedNamesList?.length < 1) {
      setError("No emails present to share the file. Please try again");
      setTimeout(() => {
        setError(null);
        closeShareRevokeModal();
      }, 2000);
      return true;
    } else {
      return false;
    }
  };

  const postRevokeShareData = () => {
    if (validation()) {
      return;
    }

    let shared_emails = sharedNamesList;

    let data = {
      assignment_id: tools_details_data?.data?.tools_assignment_id,
      email: shared_emails,
    };

    // Disabling the share button to prevent multiple clicks
    setDisableShareBtn(true);

    // if (devEnv) {
    //   submitBtnSuccess();
    //   console.log(data);
    // } else {
    postAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/share/request/`,
      getAccessLogData(data),
    )
      .then((res) => {
        if (res.success === true) {
          submitBtnSuccess();
          setSharedNamesList(null);
          setDisableShareBtn(false);
          fetch_shared_log();
          fetch_access_log();
        } else {
          setShareError(res.message);
          setDisableShareBtn(false);
          setTimeout(() => {
            closeShareRevokeModal();
          }, 2000);
          if (res?.emails?.length > 0) {
            setShareErrNameArr([...res.emails]);
          }
        }
      })
      .catch((err) => {
        setDisableShareBtn(false);
        console.error(err);
      });
  };

  useEffect(() => {
    // if (devEnv) {
    //   setRequesterNames(revoke_share_data);
    // } else {
    getRequesterNames();
    if (shared_res.length > 0) {
      setSharedNamesList(shared_res);
    }
  }, [shared_res]);

  return (
    <section className="tools_share_revoke_main">
      <div
        className="tool-client-modal-container tool-client-revoke-share-modal-container"
        ref={shareModalRef}
      >
        <div className="client-modal-head">
          <div
            className="client-modal-container-head"
            id="tool-client-revoke-share-heading"
          >
            Share Assignment
          </div>
          <button
            className="tool-client-modal-container-close-btn"
            id="tool-client-revoke-share-heading-close-btn"
            onClick={closeShareRevokeModal}
          >
            <FaTimes />
          </button>
        </div>
        <p className="tool-client-revoke-share-heading-para-white">
          {" "}
          You have requested to share the assignment with the below email
          addresses. Please verify and remove any incorrect or unauthorized
          email addresses.
        </p>

        <div className="tool-client-revoke-share-names-container">
          {shareError && (
            <div className="client-input-error tool-client-share-error">
              {shareError}
            </div>
          )}

          {!shareError && (
            <div className="tool-client-revoke-share-names-list">
              {sharedNamesList &&
                sharedNamesList?.map((item, idx) => {
                  return (
                    <div
                      key={idx}
                      className="tool-client-revoke-share-name-item"
                    >
                      <span className="client-revoke-share-name">{item}</span>
                      <button
                        className="tool-client-revoke-share-remove-btn"
                        onClick={(e) => {
                          removeName(e, idx);
                        }}
                      >
                        <FaTimes />
                      </button>
                    </div>
                  );
                })}
            </div>
          )}
        </div>

        <p className="tool-client-revoke-share-heading-para">
          A share request will be sent to the below users for approval.{" "}
        </p>

        <div className="tool-client-revoke-share-names-container">
          <div className="tool-client-revoke-share-requester-names-list">
            {requesterNames?.requester_emails?.map((item, idx) => {
              return (
                <div
                  key={idx}
                  className="tool-client-revoke-share-requester-name-item"
                >
                  <span className="client-revoke-share-requester-name">
                    {item}
                  </span>
                </div>
              );
            })}
          </div>
        </div>

        <div className="tool-client-revoke-share-btn-container">
          <button
            className="client-btns"
            id="tool-client-revoke-share-modal-cancel-btn"
            onClick={closeShareRevokeModal}
          >
            {" "}
            Cancel{" "}
          </button>
          <button
            className="client-btns"
            id="client-revoke-share-modal-okay-btn"
            ref={shareSubmitBtn}
            onClick={() => postRevokeShareData()}
          >
            Send Request
          </button>
        </div>
      </div>
    </section>
  );
};

export default ToolsShareRevokeModal;
