import React, { useEffect, useState } from "react";
import "./ToolsConsultChatUpload.scss";
import toolInfoData from "../toolInfoData";
import { useDispatch, useSelector } from "react-redux";
import ToolsConsultFIleUpload from "../ToolsConsultFIleUpload/ToolsConsultFIleUpload";
import { useNavigate } from "react-router-dom";
import {
  createNewChat,
  resetChatData,
  setConsultChatType,
  updateRootChatId,
} from "../../../../store/consultgptDataSlice/consultgptDataSlice";
import { CHAT_TYPE } from "../../../../store/consultgptDataSlice/common";
import { v4 as uuidv4 } from "uuid";
import { resetFilesObj } from "../../../../store/multiPartUploadDataSlice/multiPartUploadDataSlice";

// tool_tip_data
import tooltipData from "../../FileUpload/fileUploadData";

const ToolsConsultChatUpload = () => {
  const { active_tool_type } = useSelector((state) => state.utilsData);
  const content = toolInfoData?.[active_tool_type];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tooltipInfo = tooltipData[active_tool_type].hover_data;

  const [showTooltipInfoData, setShowTooltipInfoData] = useState(false);

  const handleStartNewChat = () => {
    const new_chat_id = uuidv4();
    dispatch(resetChatData());
    dispatch(resetFilesObj());
    dispatch(createNewChat(true));
    dispatch(updateRootChatId(new_chat_id));
    dispatch(setConsultChatType(CHAT_TYPE.TALK_TO_CONSULT));
    navigate(`/tools/consult-file/${new_chat_id}`);
  };

  useEffect(() => {
    dispatch(resetChatData());
    dispatch(resetFilesObj());
  }, []);

  return (
    <div className="tools_consult_chat_upload_wrapper">
      <div className="tool_consult_chat_start_wrapper">
        <div className="tools_consult_chat_start_desc_wrapper">
          <span className="tools_consult_chat_start_desc_text">
            {content?.about_content}
          </span>
        </div>
        <div className="tools_consult_btn_wrapper">
          <button
            className="tools_consult_start_chat_btn"
            onClick={handleStartNewChat}
          >
            {content?.btn_text}
          </button>
        </div>
      </div>
      <div className="tool_consult_upload_divider">
        <span className="vertical_line"></span>
        <span className="divider_text">or</span>
        <span className="vertical_line"></span>
      </div>
      <div className="tool_consult_file_upload">
        <ToolsConsultFIleUpload />
      </div>

      <div
        className="consult_info_button"
        onMouseEnter={() => setShowTooltipInfoData(true)}
        onMouseLeave={() => setShowTooltipInfoData(false)}
      >
        <img src="/info.svg" alt="i button" className="consult_i_button_img" />
        {showTooltipInfoData && (
          <div className="consult_tooltip_info">
            <ol className="consult_tooltip_ordered_container">
              {tooltipInfo?.map((item, index) =>
                index === 0 ||
                index === 1 ||
                index === tooltipInfo.length - 1 ? (
                  <p key={index} className="consult_tooltip_non_ordered_item">
                    {item}
                  </p>
                ) : (
                  <li key={index} className="consult_tooltip_list">
                    {item}
                  </li>
                ),
              )}
            </ol>
          </div>
        )}
      </div>
    </div>
  );
};

export default ToolsConsultChatUpload;
