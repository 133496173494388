import { React, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  CLIENT_ROUTES,
  NotFoundErrorContext,
  ClientRouteContext,
  BrokenLinkErrorContext,
} from "../../ClientComponents";
import { lazy_load_image } from "../../../index.js";
// import { FaArchive } from "react-icons/fa";
// import { FiArchive } from "react-icons/fi";

// Client Sidenav Css
import "./ClientSidenav.css";
import { toggleShowToolsSidebar } from "../../../store/utilsDataSlice/utilsDataSlice.js";
import { useDispatch } from "react-redux";
import { resetToolsDetailsData } from "../../../store/toolsDataSlice/toolsDataSlice.js";

export const toggleFilesSidebar = (e) => {
  e?.preventDefault();
  document
    .querySelector("#client-file-sidebar")
    ?.classList.toggle("client-sidebar-show");
};

export const closeFilesSidebar = (e) => {
  e?.preventDefault();
  document
    .querySelectorAll("#client-file-sidebar")
    .forEach((el) => el?.classList.remove("client-sidebar-show"));
  // document
  //   .querySelector("#client-file-sidebar")
  //   ?.classList.remove("client-sidebar-show");
};

export const toggleArchiveSidebar = (e, route) => {
  if (route.includes(CLIENT_ROUTES.ARCHIVE)) e?.preventDefault(); // if already on archive route then dont redirect it
  document
    .querySelector("#client-archive-sidebar")
    ?.classList.toggle("client-sidebar-show");
};

export const closeArchiveSidebar = (e) => {
  e?.preventDefault();
  document
    .querySelector("#client-archive-sidebar")
    ?.classList.remove("client-sidebar-show");
};

export const openArchiveSidebar = (e) => {
  e?.preventDefault();
  document
    .querySelector("#client-archive-sidebar")
    ?.classList.add("client-sidebar-show");
};

const ClientSidenav = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setNotFoundError } = useContext(NotFoundErrorContext);
  const { setBrokenLinkError } = useContext(BrokenLinkErrorContext);
  const route = useContext(ClientRouteContext);

  const hoverHiglight = (e) => {
    const navBtn = e.target.closest(".client-sidenav-btn");
    if (e.type === "mouseenter") {
      navBtn.classList.add("client-sidenav-btn-hover");
    } else if (e.type === "mouseleave") {
      navBtn.classList.remove("client-sidenav-btn-hover");
    }
  };

  const toggleSidebar = () => {
    dispatch(resetToolsDetailsData());
    dispatch(toggleShowToolsSidebar());
  };

  return (
    <div className="client-sidenav">
      <Link
        className="client-ez-logo"
        to="/home"
        // to="/decrypt"
        onClick={() => {
          setNotFoundError(false);
          setBrokenLinkError(false);
        }}
      >
        <img
          className="lazy-img"
          onLoad={lazy_load_image}
          src={process.env.PUBLIC_URL + "/logo.png"}
          alt="EZ"
        />
      </Link>
      <Link
        className={`client-sidenav-btn files-btn }`}
        id="st-client-dashboard-show-btn"
        to="/home"
        onMouseEnter={hoverHiglight}
        onMouseLeave={hoverHiglight}
        onClick={toggleSidebar}
      >
        <img
          src={process.env.PUBLIC_URL + "/resources/nav_icons/home.svg"}
          className="client-sidenav-icon"
          onLoad={lazy_load_image}
          alt="Btn"
        />
      </Link>
      {/* <Link className='client-sidenav-btn files-btn' id="client-dashboard-show-btn" to="/assignments" onClick={()=>{setNotFoundError(false)}}><img  className="client-sidenav-icon lazy-img" onLoad={lazy_load_image} src={process.env.PUBLIC_URL + "/resources/nav_icons/assignment.svg"} alt="Btn"/></Link> */}
      <Link
        className={`client-sidenav-btn files-btn client-dashboard-sidebar-show-btn ${
          (route === CLIENT_ROUTES.ASSIGNMENTS ||
            route === CLIENT_ROUTES.DECRYPT) &&
          "client-sidenav-btn-active"
        }`}
        id="client-dashboard-show-btn"
        to="/assignments"
        onClick={() => {
          closeArchiveSidebar();
          setNotFoundError(false);
          setBrokenLinkError(false);
        }}
        onMouseEnter={hoverHiglight}
        onMouseLeave={hoverHiglight}
      >
        <img
          className="client-sidenav-icon lazy-img"
          onLoad={lazy_load_image}
          // src={process.env.PUBLIC_URL + "/resources/nav_icons/Assignment.svg"}
          src={
            process.env.PUBLIC_URL +
            "/resources/nav_icons/assignment_turned_in.svg"
          }
          alt="Btn"
        />
      </Link>
      <button
        className={`client-sidenav-btn client-mobile-sidebar-show-btn ${
          (route === CLIENT_ROUTES.ASSIGNMENTS ||
            route === CLIENT_ROUTES.DECRYPT) &&
          "client-sidenav-btn-active"
        }`}
        id="client-file-show-btn"
        onClick={(e) => {
          closeArchiveSidebar();
          toggleFilesSidebar(e);
          navigate("/assignments");
          setNotFoundError(false);
          setBrokenLinkError(false);
        }}
      >
        <img
          className="client-sidenav-icon lazy-img"
          onLoad={lazy_load_image}
          // src={process.env.PUBLIC_URL + "/resources/nav_icons/Assignment.svg"}
          src={
            process.env.PUBLIC_URL +
            "/resources/nav_icons/assignment_turned_in.svg"
          }
          alt="Btn"
        />
      </button>

      <Link
        className={`client-sidenav-btn files-btn`}
        id="st-client-dashboard-show-btn"
        to="/tools/flip-home"
        onMouseEnter={hoverHiglight}
        onMouseLeave={hoverHiglight}
        onClick={toggleSidebar}
      >
        <img
          src={process.env.PUBLIC_URL + "/ai_icon_sidenav.svg"}
          className="client-sidenav-icon"
          // onLoad={lazy_load_image}
          alt="Btn"
        />
      </Link>
      <Link
        className={`client-sidenav-btn client-archive-btn ${
          route === CLIENT_ROUTES.ARCHIVE && "client-sidenav-btn-active"
        }`}
        to="/archive"
        // onClick={()=>{closeFilesSidebar();setNotFoundError(false);setBrokenLinkError(false)}}
        onMouseEnter={hoverHiglight}
        onMouseLeave={hoverHiglight}
        onClick={(e) => {
          closeFilesSidebar();
          toggleArchiveSidebar(e, route);
          setNotFoundError(false);
          setBrokenLinkError(false);
        }}
      >
        {/* <FaArchive /> */}
        <img
          src={process.env.PUBLIC_URL + "/resources/nav_icons/inventory_2.svg"}
          className="client-sidenav-icon "
          // onLoad={lazy_load_image}
          alt="Btn"
        />
      </Link>

      {/* <button
                className={`client-sidenav-btn client-archive-btn client-mobile-sidebar-show-btn ${(route===CLIENT_ROUTES.ARCHIVE) && "client-sidenav-btn-active"}`}
                id="client-archive-show-btn"
                onClick={(e)=>{closeFilesSidebar();toggleArchiveSidebar(e);setNotFoundError(false)}}
            >
                    <FaArchive/>
            </button> */}

      <Link
        className={`client-sidenav-btn client-settings-btn ${
          route === CLIENT_ROUTES.FAQ && "client-sidenav-btn-active"
        }`}
        to="/faq"
        onClick={() => {
          setTimeout(() => {
            closeFilesSidebar();
            closeArchiveSidebar();
          }, 100);
          setNotFoundError(false);
          setBrokenLinkError(false);
        }}
        onMouseEnter={hoverHiglight}
        onMouseLeave={hoverHiglight}
      >
        {/* <img
          className="client-sidenav-icon lazy-img"
          onLoad={lazy_load_image}
          src={process.env.PUBLIC_URL + "/resources/nav_icons/faq.svg"}
          alt="Btn"
        /> */}
        <img
          className="st-client-sidenav-icon-faq"
          // onLoad={lazy_load_image}
          src={process.env.PUBLIC_URL + "/resources/nav_icons/feedback.svg"}
          alt="Btn"
        />
      </Link>
    </div>
  );
};

export { ClientSidenav };
