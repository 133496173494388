import React from "react";
import "./HomeCapabilityCenter.scss";
import offeringData from "./HomeCapabilityCenterData.json";
import HomeCapabilityOffering from "./HomeCapabilityOffering";
import { useDispatch } from "react-redux";
import TOOLS_MODALS from "../../ToolsConstants/TOOLS_MODALS";
import { setModal } from "../../../store/utilsDataSlice/utilsDataSlice";

const HomeCapabilityCenter = () => {
  const dispatch = useDispatch();

  const handleNewRequest = () => {
    dispatch(setModal(TOOLS_MODALS.NEW_REQ));
  };
  return (
    <section className="st_offering_main">
      <div className="st_offering_heading_main">
        <span className="st_offering_heading">Capability Centre</span>
        <span className="offering_ad_icon_mobile" onClick={handleNewRequest}>
          <img className="plus_btn_icon" src="/add_box.svg" alt="add icon" />
        </span>
      </div>
      <div className="st_offerings">
        <div className="offering_wrapper">
          {offeringData.map((offering, id) => {
            return (
              <div className="offering_single" key={id}>
                <HomeCapabilityOffering
                  offering_name={offering.title}
                  subservices={offering.subservices}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default HomeCapabilityCenter;
