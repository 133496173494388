import React from "react";
import "./SearchDropdownFileIcon.scss";

const SearchDropdownFileIcon = ({ articleFormat }) => {
  let url;
  if (!articleFormat) {
    url = "/Search-Dropdown/article.svg";
  } else {
    switch (articleFormat) {
      case "Services":
        url = "/Search-Dropdown/services.svg";
        break;
      case "Article":
        url = "/Search-Dropdown/article.svg";
        break;
      case "Report":
        url = "/Search-Dropdown/report.svg";
        break;
      case "Video":
        url = "/Search-Dropdown/video.svg";
        break;
      case "Audio":
        url = "/Search-Dropdown/audio.svg";
        break;
      default:
        url = "/Search-Dropdown/article.svg";
        break;
    }

    // let iconComponent;

    // switch (articleFormat) {
    //   case "Services":
    //     iconComponent = <ServiceIcon />;
    //     break;
    //   case "Article":
    //     iconComponent = <ArticleIcon />;
    //     break;
    //   case "Report":
    //     iconComponent = <ReportIcon />;
    //     break;
    //   case "Video":
    //     iconComponent = <VideoIcon />;
    //     break;
    //   case "Audio":
    //     iconComponent = <AudioIcon />;
    //     break;
    //   default:
    //     iconComponent = <ArticleIcon />;
    //     break;
    // }
  }
  return (
    <span className="search-suggestion-article-type-image-wrapper">
      <img
        className="search-suggestion-article-type-image"
        src={url}
        alt={articleFormat}
        title=""
      ></img>
    </span>
  );
};

export default SearchDropdownFileIcon;
