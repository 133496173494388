import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import initialState, { CHART_DATA_TIME } from "./common";
import { getAuthData } from "../../helpers/request";

export const getChartData = createAsyncThunk(
  "utils-data/getChartData",
  async (data_type, thunkAPI) => {
    let url = `${process.env.REACT_APP_API_URL}/api-client/tools/v1/chart-data?data_type=${data_type}`;
    try {
      const res = await getAuthData(url);
      if (res.success) {
        const all_time_data = res.data.all_time;
        const six_months_data = res.data.six_months;
        const this_month_data = res.data.this_month;
        const all_time_success_rate = res.data.all_time.success_percentage;
        const six_months_success_rate = res.data.six_months.success_percentage;
        const this_month_success_rate = res.data.this_month.success_percentage;
        delete all_time_data.success_percentage;
        delete six_months_data.success_percentage;
        delete this_month_data.success_percentage;
        const combined_data = {
          all_time: {
            vals: all_time_data,
            success_rate: all_time_success_rate,
          },
          six_months: {
            vals: six_months_data,
            success_rate: six_months_success_rate,
          },
          this_month: {
            vals: this_month_data,
            success_rate: this_month_success_rate,
          },
        };
        return { data: combined_data, data_type: data_type };
      } else {
        return thunkAPI.rejectWithValue(res?.message || "Profile Error!");
      }
    } catch (err) {
      throw thunkAPI.rejectWithValue("Something went wrong!");
    }
  },
);

const utilsDataSlice = createSlice({
  name: "utils-data",
  initialState,
  reducers: {
    setToolsPage: (state, { payload }) => {
      state.tools_page = payload;
    },
    setModal: (state, { payload }) => {
      state.modal_type = payload;
    },
    resetModal: (state) => {
      state.modal_type = initialState.modal_type;
    },
    setActiveToolType: (state, { payload }) => {
      state.active_tool_type = payload;
    },
    resetActiveToolType: (state) => {
      state.active_tool_type = initialState.active_tool_type;
    },
    toggleShowToolsSidebar: (state, { payload = "" }) => {
      let val = payload?.toString();
      if (payload === "") {
        state.tools_show_mob_sidebar = !state.tools_show_mob_sidebar;
        return;
      }
      if (val === "true") {
        state.tools_show_mob_sidebar = true;
      } else if (val === "false") {
        state.tools_show_mob_sidebar = false;
      }
    },
    setConsultNewReqForm: (state, { payload }) => {
      const new_req_data = {
        consult_instruction: payload.consult_instruction,
        consult_s3_file_path: payload.consult_s3_file_path,
        consult_file_name: payload.consult_file_name,
      };
      state.consult_new_req_data = new_req_data;
      state.isConsultMode = true;
    },
    resetConsultNewReqForm: (state) => {
      state.consult_new_req_data = initialState.consult_new_req_data;
      state.isConsultMode = false;
    },
    setServiceOffering: (state, { payload }) => {
      state.active_service_offering = payload;
    },
    resetServiceOffering: (state) => {
      state.active_service_offering = initialState.active_service_offering;
    },
    setSourceLoader: (state) => {
      state.source_loader = true;
    },
    resetSourceLoader: (state) => {
      state.source_loader = false;
    },
    setReferenceLoader: (state) => {
      state.reference_loader = true;
    },
    resetReferenceLoader: (state) => {
      state.reference_loader = false;
    },
    setActiveFileCard: (state, { payload }) => {
      state.active_file_card = payload;
    },
    resetActiveFileCard: (state) => {
      state.active_file_card = initialState.active_file_card;
    },
    setAutoEncryptTimer: (state, { payload }) => {
      state.auto_encrypt_file_timer = payload;
    },
    setToolSecureKey: (state, { payload }) => {
      state.tool_secure_key = payload;
    },
    setEncrypted: (state, { payload }) => {
      state.tool_encrypted_state = payload;
    },
    setCurChartTime: (state, { payload }) => {
      if (payload) {
        state.chart_data.cur_data_time = payload;
      } else {
        state.chart_data.cur_data_time = CHART_DATA_TIME.ALL_TIME;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChartData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getChartData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.chart_data[payload.data_type].data = payload.data;
    });
    builder.addCase(getChartData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const {
  setToolsPage,
  setModal,
  resetModal,
  setConsultNewReqForm,
  resetConsultNewReqForm,
  setActiveToolType,
  resetActiveToolType,
  toggleShowToolsSidebar,
  setServiceOffering,
  resetServiceOffering,
  setSourceLoader,
  resetSourceLoader,
  setReferenceLoader,
  resetReferenceLoader,
  setCurChartTime,
  setActiveFileCard,
  resetActiveFileCard,
  setAutoEncryptTimer,
  setToolSecureKey,
  setEncrypted,
} = utilsDataSlice.actions;

export default utilsDataSlice.reducer;
