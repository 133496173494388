import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./ToolsRevokeTab.scss";
const ToolsRevokeTab = () => {
  const [requesterNames, setRequesterNames] = useState();
  const { tools_details_data } = useSelector((state) => state.toolsData);

  useEffect(() => {
    setRequesterNames(tools_details_data?.data?.requester_emails);
  }, [tools_details_data?.id]);

  return (
    <div className="tool-revoked-bottom-client-dashboard-container">
      <div className="tool-revoked-bottom-client-dashboard-container-top">
        <p className="tool-revoked-bottom-client-dashboard-container-top-heading">
          Your access has been revoked!
        </p>
        <span>
          <img
            className="tool-revoked-bottom-client-dashboard-container-top-img"
            src={
              process.env.PUBLIC_URL +
              "/resources/backgrounds/revoked_access_img.svg"
            }
            alt="revoked_access"
          />
        </span>
      </div>
      <div className="tool-revoked-bottom-client-dashboard-container-bottom">
        <p className="tool-revoked-bottom-client-dashboard-para">
          You can request the users listed below to regain access
        </p>
        <div className="tool-client-revoked-tab-names-container">
          {requesterNames?.map((item, idx) => {
            return (
              <div key={idx} className="client-revoke-share-warning-name-item">
                <span className="client-share-warning-name">{item}</span>
                {/* <button className="client-share-remove-btn"><FaTimes /></button> */}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default ToolsRevokeTab;
