import React from "react";
import ServiceList from "../ServiceList/ServiceList";
import styles from "./Service.module.scss";
import { data } from "../data";
const Service = ({ headService, index, displayHeading, setShowIconList }) => {
  return (
    <div
      className={`${styles.service} ${
        index === data.length - 1 && styles.border_right_none
      } ${!displayHeading && styles.single_service_top_padding}`}
    >
      {displayHeading && (
        <span className={styles.service_name}>{headService?.heading}</span>
      )}
      <div
        className={`${styles.list_of_services} ${
          !displayHeading && styles.list_of_services_gap
        }`}
      >
        {headService?.services.map((service, idx) => {
          return (
            <ServiceList
              key={idx}
              service={service}
              setShowIconList={setShowIconList}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Service;
