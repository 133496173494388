import React from "react";
import { ToolsTabData } from "./ToolsTabData";
import "./ToolsTabHomeNav.scss";
import ToolsTabSingle from "./ToolsTabSingle";

const ToolsTabHomeNav = () => {
  return (
    <div className="st_dashboard_cards_main">
      <div className="st_dashboard_heading_main">
        <span className="st_dashboard_heading">Welcome to</span>
        <img
          className="st_dashboard_tool_img_new"
          src={"/EZtools_logo.svg"}
          alt="EZ"
        />
      </div>
      <div className="st_dashboard_sub_heading_container">
        <span className="mobile_heading_ez_tools">
          Data Scientists at EZ have trained Proprietary AI models to ensure you
          can leverage the latest technology without worrying about Information
          Security. Have Fun!
        </span>
        <span className="subheading_one">
          Data Scientists at EZ have trained Proprietary AI models to ensure you
          can leverage the latest technology
        </span>
        <span className="subheading_two">
          without worrying about Information Security.
        </span>
        <span className="subheading_three">Have Fun!</span>
      </div>

      <div className="tools_single_card">
        {ToolsTabData.map((singleCard) => {
          return (
            <ToolsTabSingle
              key={singleCard.id}
              name={singleCard.name}
              content_question={singleCard.content_question}
              content_answer={singleCard.content_answer}
              tool_type={singleCard.tool_type}
              src={singleCard.src}
              alt={singleCard.alt}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ToolsTabHomeNav;
