import React, { useContext, useEffect, useState, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import {
  ApiContext,
  getAccessLogData,
  DevelopmentContext,
} from "../../../index";
import {
  ClientAccessLogContext,
  ClientDashboardFileContext,
  ClientSharingLogContext,
  CLIENT_MODAL_TYPE,
  ModalDispatchContext,
  NotFoundErrorContext,
} from "../../ClientComponents";
import { getAuthData, postAuthData } from "../../../helpers/request";
// import {
//   renderShareModalContext,
//   ShareWarningDataContext,
// } from "../SharingModal/SharingModal";
// import { SHARE_MODAL_TYPE } from "../../../utils/ShareModalType";
import { SHARE_RESPONSE_STATUS } from "../../../utils/ShareResponseStatus";

// ============ Dummy Json data ==================== //
import warning_data from "../../../archive/test_data/dummy_data-v1/dummy_share_warning.json";
// import warning_fail_data from '../../../archive/test_data/dummy_data-v1/dummy_share_fail.json';
// import warning_success_data from '../../../archive/test_data/dummy_data-v1/dummy_share_success.json';
// import post_warning_data from '../../../archive/test_data/dummy_data-v1/dummy_share_warning_post.json'
// import profile from "../../../archive/test_data/dummy_data-v1/dummy_profile.json";

const ShareWarningModal = ({ payload }) => {
  // console.log(payload)
  // contexts used
  const { devEnv } = useContext(DevelopmentContext);
  const API_URL = useContext(ApiContext);
  const modal_dispatch = useContext(ModalDispatchContext);
  const { clientDisplayedFile } = useContext(ClientDashboardFileContext);
  const { setSharedLogData } = useContext(ClientSharingLogContext);
  const { setAccessLogData } = useContext(ClientAccessLogContext);
  const { setNotFoundError } = useContext(NotFoundErrorContext);

  const shareSubmitBtn = useRef(null);

  // states used
  const [disableShareBtn, setDisableShareBtn] = useState(false); // eslint-disable-line no-unused-vars
  const [warningData, setWarningData] = useState();
  const [sharedWithinOrg, setWithinOrg] = useState([]);
  const [sharedOutsideOrg, setOutsideOrg] = useState([]);
  const [shareErrNameArr, setShareErrNameArr] = useState([]); // eslint-disable-line no-unused-vars
  const [shareError, setShareError] = useState(null); // eslint-disable-line no-unused-vars
  const [error, setError] = useState(null);
  const maxSharingNames = 3; // eslint-disable-line no-unused-vars

  // setting a limit for names
  // if (sharedWithinOrg.length>maxSharingNames){setShareError(`More than ${maxSharingNames} names shared.`);return}
  // if (sharedOutsideOrg.length>maxSharingNames){setShareError(`More than ${maxSharingNames} names shared.`);return}

  const openShareWarningModal = (e) => {
    e?.preventDefault();
    modal_dispatch({ modal_show_type: CLIENT_MODAL_TYPE.SHARE_WARNING });
  };

  const closeSharingWarningModal = (e) => {
    e?.preventDefault();
    modal_dispatch({ modal_show_type: CLIENT_MODAL_TYPE.NONE });
  };

  // instantly remove Within_org email and change state
  const removeInsideSharedEmail = (e, idx) => {
    setWithinOrg([
      ...sharedWithinOrg.slice(0, idx),
      ...sharedWithinOrg.slice(idx + 1, sharedWithinOrg.length),
    ]);
  };

  // instantly remove Outside_org email and change state
  const removeOutsideSharedEmail = (e, idx) => {
    setOutsideOrg([
      ...sharedOutsideOrg.slice(0, idx),
      ...sharedOutsideOrg.slice(idx + 1, sharedOutsideOrg.length),
    ]);
  };

  const submitBtnSuccess = () => {
    const shareBtn = shareSubmitBtn.current;
    shareBtn.style.background = "var(--light-orange,#e98742)";
    shareBtn.innerText = "Shared!";
    setTimeout(() => {
      shareBtn.style.background = "var(--light-blue,#3cc3f2)";
      shareBtn.innerText = "Share";
      closeSharingWarningModal();
    }, 1000);
  };

  const fetch_access_log = () => {
    getAuthData(
      `${API_URL}/api-client/misc/v1/access-log/${clientDisplayedFile?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setAccessLogData(res.data);
        } else {
          setNotFoundError(true);
        }
      })
      .catch((err) => {
        setNotFoundError(true);
      });
  };

  const fetch_shared_log = () => {
    getAuthData(
      `${API_URL}/api-client/misc/v1/sharedlog/${clientDisplayedFile?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setSharedLogData(res);
        } else {
          setNotFoundError(true);
        }
      })
      .catch((err) => {
        setNotFoundError(true);
      });
  };

  // data validation
  const validation = (data) => {
    let valid = false;
    if (payload.from === "logs" && !data?.email && data?.email === undefined) {
      setError("No emails present to share the file. Please try again");
      setTimeout(() => {
        setError(null);
        closeSharingWarningModal();
      }, 2000);
      valid = true;
    } else if (payload.from === "share" && data?.emails?.length < 1) {
      setError("No emails present to share the file. Please try again");
      setTimeout(() => {
        setError(null);
        closeSharingWarningModal();
      }, 2000);
      valid = true;
    }
    return valid;
  };

  // sending warning data
  const postWarningData = () => {
    let insideEmails = sharedWithinOrg;
    let outsideEmails = sharedOutsideOrg;
    let sharedEmailList = insideEmails.concat(outsideEmails);

    let newSharedEmail = sharedEmailList.values(); // access the value of array when original_requester accepts the request
    let sharedEmail = newSharedEmail.next().value; // value of the original_requester

    let data_url =
      payload.from === "logs"
        ? `${API_URL}/api-client/misc/v1/accept-request/`
        : `${API_URL}/api-client/misc/v1/client-shared-file/`;

    const getPostData = () => {
      let data = {
        delivery_id: clientDisplayedFile.batch_id,
        client: payload?.share_res?.client,
        accept_warning: true, //
        emails: sharedEmailList,
      };

      let accept_request_data = {
        delivery_id: clientDisplayedFile.batch_id,
        client: payload?.share_res?.client,
        accept_warning: true, //
        email: sharedEmail,
      };

      if (payload.from === "logs") {
        return accept_request_data;
      } else {
        return data;
      }
    };

    const validateData = validation(getPostData());
    if (validateData) {
      return;
    }

    if (devEnv) {
      setDisableShareBtn(true);
      setOutsideOrg(sharedOutsideOrg);
      setWithinOrg(sharedWithinOrg);
      setWarningData(warningData);
      submitBtnSuccess();
    } else {
      postAuthData(data_url, getAccessLogData(getPostData()))
        .then((res) => {
          if (res.status === SHARE_RESPONSE_STATUS.SUCCESS) {
            submitBtnSuccess();
            setDisableShareBtn(false);
            // closeSharingWarningModal();
            fetch_shared_log();
            fetch_access_log();
          } else if (res.status === SHARE_RESPONSE_STATUS.WARNING) {
            openShareWarningModal();
          } else {
            setShareError(res.message);
            setDisableShareBtn(false);
            if (res.emails.length > 0) {
              setShareErrNameArr([...res.emails]);
            }
          }
        })
        .catch((err) => {
          setDisableShareBtn(false);
        });
    }
  };

  useEffect(() => {
    if (devEnv) {
      setWarningData(warning_data);
      setWithinOrg(warning_data.emails.within_org);
      setOutsideOrg(warning_data.emails.outside_org);
    }

    if (!payload?.from) return;
    setWarningData(payload?.share_res);
    setWithinOrg(warningData?.emails?.within_org);
    setOutsideOrg(warningData?.emails?.outside_org);
  }, [payload, warningData]);

  return (
    <>
      <div className="client-modal-container client-share-warning-modal-container">
        <div className="client-modal-head">
          <div
            className="client-modal-container-head"
            id="client-share-warning-heading"
          >
            {" "}
            Warning!{" "}
          </div>
          <button
            className="client-modal-container-close-btn"
            id="client-share-warning-heading-close-btn"
            onClick={closeSharingWarningModal}
          >
            {" "}
            <FaTimes />
          </button>
        </div>

        <div className="client-share-warning-modal-text-container">
          <div className="client-share-warning-para">
            <span className="client-share-warning-modal-para-span client-share-warning-modal-para-span-orange">
              {"You're sharing the file outside the client entity."}
            </span>
            <span className="client-share-warning-modal-para-span client-share-warning-modal-para-span-orange">
              This might result in a breach of contract and Information
              Security.
            </span>
          </div>
          <div className="client-share-warning-modal-para">
            <p className="client-share-warning-modal-para-span">
              Please ensure that all the people listed below are authorised by{" "}
              <span> {warningData?.client}</span> to receive these files.
              <strong> Remove the people not authorised to access.</strong>
            </p>
          </div>
        </div>

        <div className="client-share-warning-names-container">
          {sharedWithinOrg && sharedWithinOrg.length > 0 && (
            <div className="client-share-warning-name-org-heading">
              Within Organisation
            </div>
          )}

          <div className="client-share-warning-names-list">
            {sharedWithinOrg?.map((item, idx) => (
              <div key={idx} className="client-share-warning-name-item">
                <span className="client-share-warning-name">{item}</span>
                <button
                  className="client-share-warning-remove-btn"
                  onClick={(e) => {
                    removeInsideSharedEmail(e, idx);
                  }}
                >
                  <FaTimes />
                </button>
              </div>
            ))}
          </div>

          {sharedOutsideOrg && sharedOutsideOrg.length > 0 && (
            <div className="client-share-warning-name-org-heading">
              Outside Organisation
            </div>
          )}

          <div className="client-share-warning-names-list">
            {sharedOutsideOrg?.map((item, idx) => {
              return (
                <div key={idx} className="client-share-warning-name-item">
                  <span className="client-share-warning-name">{item}</span>
                  <button
                    className="client-share-warning-remove-btn"
                    onClick={(e) => {
                      removeOutsideSharedEmail(e, idx);
                    }}
                  >
                    {" "}
                    <FaTimes />{" "}
                  </button>
                </div>
              );
            })}
          </div>
          {error && (
            <div className="client-share-warning-modal-err-msg">{error}</div>
          )}
        </div>

        <div className="client-share-warning-btn-container">
          <button
            className="client-btns"
            id="client-share-warning-modal-cancel-btn"
            onClick={closeSharingWarningModal}
          >
            {" "}
            Cancel{" "}
          </button>
          <button
            className="client-btns"
            id="client-share-warning-modal-okay-btn"
            ref={shareSubmitBtn}
            onClick={() => postWarningData()}
          >
            {" "}
            Share{" "}
          </button>
        </div>
      </div>
    </>
  );
};

export { ShareWarningModal };
