import React, { useState, useContext, useEffect } from "react";
// import assignments from '../../test_data/client_assignments.json';
// import {
//   FaDownload,
//   FaLock,
// FaAngleLeft,
// FaUserPlus,
// FaSortAmountDown,
// FaSortAmountDownAlt,
// FaSearch,
// FaUnlock,
// FaKey,
// FaMinusCircle,
// } from "react-icons/fa";
import {
  ApiContext,
  HomeRecentAsgnContext,
  // ToolTipError,
  // ERROR_REGEX,
  // date_time_convertor,
  // lazy_load_image,
  // showHoverToolTip,
  // getAccessLogData,
  // TOOLTIP_HOVER_TYPE,
  // AccessLogTypeIcon,
  // ACCESS_LOG_TYPES,
  // HoverToolTipTextContext,
  // getAccessDataUrl,
  // getDeviceResponsiveness,
  // DEVICE_RESPONSIVENESS,
  // FileType,
  // DownloadFiles,
  // createMarkup,
  // emojis,
  // emoticons,
  // FEEDBACK_EMOTIONS,
  DevelopmentContext,
} from "../../../index";
import {
  ClientDashboardFileContext,
  ClientArchiveFileContext,
  ClientFilesArrayContext,
  ModalDispatchContext,
  CLIENT_MODAL_TYPE,
  NotFoundErrorContext,
  // ClientSharingLogContext,
  // ClientAccessLogContext,
  EncryptedContext,
} from "../../ClientComponents";
import {
  getAuthData,
  // postAuthData,
  // getDownloadData,
} from "../../../helpers/request";
import { useParams } from "react-router";
// import { MdInfoOutline } from "react-icons/md";
// import { assignment_services } from "../../../req_jsons/assignment_services";
import LogsTab from "../components/Tabs/LogsTab/LogsTab.js";
import TopDashboard from "../components/TopDashboard/TopDashboard.js";
import HistoryTab from "../components/Tabs/HistoryTab/HistoryTab.js";
import DeliveryTab from "../components/Tabs/DeliveryTab/DeliveryTab";

// Importing Dummy Data
import dummy_assignment from "../../../archive/test_data/dummy_data-v1/dummy_last_assignment.json";
import dummy_all_assignemnts from "../../../archive/test_data/dummy_data-v1/dummy_all_assignments.json";
// import dummy_all_external_assignemnts from "../../../archive/test_data/dummy_external_assignments/dummy_all_external_assignments.json";
// import dummy_last_external_assignments from "../../../archive/test_data/dummy_external_assignments/dummy_last_external_assignments.json";
import requester_names from "../../../archive/test_data/dummy_revoke_assignments/revoke_share.json";
import { FILE_ENCRYPTION_STATES } from "../../ClientComponents.js";
// import { FileIcon } from "../../../index.js";
// import { ShareRevokeModal } from "../../Modal/ShareRevokeModal/ShareRevokeModal";

// All contexts
export const SecureKeyContext = React.createContext(); // To store the form information from Deliver File Modal
export const DeliverNewVersionInfoContext = React.createContext(); // To store the form information from New Version Deliver File Modal
export const AutoEncryptTimerContext = React.createContext();

// =================================== OUTPUT CARD ==============================================

// const TargetFile = ({ target_file }) => {
//   const { encryptedState } = useContext(EncryptedContext);
//   const { setNotFoundError } = useContext(NotFoundErrorContext);

//   const donwloadFileClick = () => {
//     try {
//       // getDownloadURL(target_file.target_file_name,target_file.target_file)
//       DownloadFiles([target_file]);
//     } catch (err) {
//       setNotFoundError(true);
//     }
//   };

//   return (
//     <div className="client-target-files">
//       <div className="client-target-file">
//         <span className="client-target-file-logo">
//           <FileIcon filename={target_file.name} />
//         </span>
//         <span className="client-target-file-name">{target_file.name}</span>
//       </div>
//       <span className="client-target-download">
//         {encryptedState === FILE_ENCRYPTION_STATES.DOWNLOAD ? (
//           <span
//             className="client-file-download-link"
//             onClick={() => {
//               donwloadFileClick();
//             }}
//           >
//             <FaDownload />
//           </span>
//         ) : (
//           <FaLock />
//         )}
//       </span>
//     </div>
//   );
// };

// const ClientOutputCard = () => {
//   const { clientDisplayedFile } = useContext(ClientDashboardFileContext);

//   return (
//     <div className="client-dashboard-card glass-container-active">
//       <div className="client-dashboard-card-head">Output</div>

//       <div className="client-dashboard-block-head">Delivery Notes</div>
//       <div className="client-delivery-notes-content">
//         {clientDisplayedFile?.delivery_notes}
//       </div>

//       <div className="client-dashboard-block-head">File(s)</div>
//       <div className="client-dashboard-file-block">
//         <div className="client-dashboard-file-content">
//           {/* {Object.values(target_files_object)?.map((cur,idx)=> {return <TargetFile key={idx} target_file={cur} />})} */}
//           {/* {clientDisplayedFile.target_versions[parseInt(clientDisplayedFile?.current_version)-1]?.files?.map((file,idx)=> {return <TargetFile key={idx} target_file={file} />})} */}
//           {clientDisplayedFile.target_versions
//             .find((cur) => cur.version == clientDisplayedFile.current_version)
//             ?.files?.map((file, idx) => {
//               return <TargetFile key={idx} target_file={file} />;
//             })}
//         </div>
//         <div className="client-dashboard-file-content">
//           {/* <div className="client-dashboard-file-right">
//                             <DownloadBtn target_files_object={target_files_object}/>
//                         </div> */}
//         </div>
//       </div>
//     </div>
//   );
// };

// ======================================= FEEDback card =====================================

// const RatingContainer = (props) => {
//   const { clientDisplayedFile } = useContext(ClientDashboardFileContext);
//   const default_val = props?.default_val;
//   const [selectedEmotion, setSelectedEmotion] = useState(null);
//   const [hoverEmotion, setHoverEmotion] = useState(null);

//   const activateEmoji = (e, key) => {
//     const feedback = e.target
//       .closest(".client-feedback-question")
//       .querySelector(".client-feedback-label").dataset.feedback;
//     e.preventDefault();
//     if (default_val) return; // this line prevents hover action when a default value is already available
//     setSelectedEmotion(key);
//     props.setFeedbackState((prev) => {
//       return { ...prev, [feedback]: key };
//     });
//     // REMOVING ERRORS
//     props.setRatingEmptyError(false);
//   };

//   const hoverEmoji = (e, key) => {
//     const feedback = e.target
//       .closest(".client-feedback-question")
//       .querySelector(".client-feedback-label").dataset.feedback;
//     e.preventDefault();
//     props.setRatingEmptyError(false);
//     if (default_val) return; // this line prevents hover action when a default value is already available
//     if (e.type === "mouseenter") {
//       setHoverEmotion(key);
//       props.setFeedbackState((prev) => {
//         return { ...prev, [feedback]: key };
//       });
//     } else if (e.type === "mouseleave") {
//       setHoverEmotion(null);
//       props.setFeedbackState((prev) => {
//         return { ...prev, [feedback]: selectedEmotion ?? default_val };
//       });
//     }
//   };

//   //*Changes
//   useEffect(() => {
//     setSelectedEmotion(FEEDBACK_EMOTIONS.EMPTY);
//     setHoverEmotion(null);
//   }, [clientDisplayedFile]);

//   return (
//     <div className="client-feedback-rating-container">
//       {Object.keys(emojis).map((key, i) => {
//         return (
//           <label
//             className="rating-label"
//             key={i}
//             onMouseEnter={(e) => {
//               hoverEmoji(e, key);
//             }}
//             onMouseLeave={(e) => {
//               hoverEmoji(e, null);
//             }}
//           >
//             <input
//               type="radio"
//               name="rating"
//               onClick={(e) => {
//                 activateEmoji(e, key);
//               }}
//             />
//             {default_val === key ? (
//               <div className="client-feedback-emoji">
//                 {emojis[default_val].active}
//               </div>
//             ) : !hoverEmotion ? (
//               selectedEmotion === key ? (
//                 <div className="client-feedback-emoji">
//                   {emojis[selectedEmotion].active}
//                 </div>
//               ) : (
//                 <div className="client-feedback-emoji">
//                   {emojis[key].normal}
//                 </div>
//               )
//             ) : hoverEmotion === key ? (
//               <div className="client-feedback-emoji">
//                 {emojis[hoverEmotion].active}
//               </div>
//             ) : (
//               <div className="client-feedback-emoji">{emojis[key].normal}</div>
//             )}
//           </label>
//         );
//       })}
//     </div>
//   );
// };

// const DeliveryDetails = () => {
//   const { clientDisplayedFile } = useContext(ClientDashboardFileContext);

//   return (
//     <>
//       {clientDisplayedFile?.service_details?.map((cur, i) => {
//         return (
//           <section key={i} className="client-dashboard-delivery-details">
//             <div
//               className="client-service-details"
//               key={i}
//               dangerouslySetInnerHTML={createMarkup(cur)}
//             />
//           </section>
//         );
//       })}
//     </>
//   );
// };

// const ClientFeedbackCard = () => {
//   const { clientDisplayedFile, setClientDisplayedFile } = useContext(
//     ClientDashboardFileContext,
//   );
//   const defaultFeedback = clientDisplayedFile.feedback;
//   const assignmentId = clientDisplayedFile.batch_id;
//   const API_URL = useContext(ApiContext);
//   const { _, setNotFoundError } = useContext(NotFoundErrorContext);
//   const [ratingEmptyError, setRatingEmptyError] = useState(false);

//   const commentsRef = useRef();

//   const [qualityOpt, setQualityOpt] = useState(null);

//   const [serviceOpt, setServiceOpt] = useState(null);

//   const [feedbackState, setFeedbackState] = useState({
//     quality: defaultFeedback?.quality || 0,
//     service: defaultFeedback?.service || 0,
//   });

//   const setValues = () => {
//     switch (feedbackState.quality) {
//       case Object.keys(emojis)[0]:
//         setQualityOpt("Positive");
//         break;
//       case Object.keys(emojis)[1]:
//         setQualityOpt("Neutral");
//         break;
//       case Object.keys(emojis)[2]:
//         setQualityOpt("Negative");
//         break;
//       default:
//         setQualityOpt(FEEDBACK_EMOTIONS.EMPTY);
//         break;
//     }

//     switch (feedbackState.service) {
//       case Object.keys(emojis)[0]:
//         setServiceOpt("Positive");
//         break;
//       case Object.keys(emojis)[1]:
//         setServiceOpt("Neutral");
//         break;
//       case Object.keys(emojis)[2]:
//         setServiceOpt("Negative");
//         break;
//       default:
//         setServiceOpt(FEEDBACK_EMOTIONS.EMPTY);
//         break;
//     }
//   };

//   // const setValues = ()=>{
//   //     switch (feedbackState.quality) {
//   //         case Object.keys(emoticons)[0]:
//   //             setQualityOpt(FEEDBACK_EMOTIONS[0])
//   //             break;
//   //         case Object.keys(emoticons)[1]:
//   //             setQualityOpt(FEEDBACK_EMOTIONS[1])
//   //             break;
//   //         case Object.keys(emoticons)[2]:
//   //             setQualityOpt(FEEDBACK_EMOTIONS[2])
//   //             break;
//   //         case Object.keys(emoticons)[3]:
//   //             setQualityOpt(FEEDBACK_EMOTIONS[3])
//   //             break;
//   //         case Object.keys(emoticons)[4]:
//   //             setQualityOpt(FEEDBACK_EMOTIONS[4])
//   //             break;
//   //         default:
//   //             setQualityOpt(FEEDBACK_EMOTIONS[5])
//   //             break;
//   //     }

//   //     switch (feedbackState.service) {
//   //         case Object.keys(emoticons)[0]:
//   //             setServiceOpt(FEEDBACK_EMOTIONS[0])
//   //             break;
//   //         case Object.keys(emoticons)[1]:
//   //             setServiceOpt(FEEDBACK_EMOTIONS[1])
//   //             break;
//   //         case Object.keys(emoticons)[2]:
//   //             setServiceOpt(FEEDBACK_EMOTIONS[2])
//   //             break;
//   //         case Object.keys(emoticons)[3]:
//   //             setServiceOpt(FEEDBACK_EMOTIONS[3])
//   //             break;
//   //         case Object.keys(emoticons)[4]:
//   //             setServiceOpt(FEEDBACK_EMOTIONS[4])
//   //             break;
//   //         default:
//   //             setServiceOpt(FEEDBACK_EMOTIONS[5])
//   //             break;
//   //     }

//   // }

//   useEffect(() => {
//     // This renders the results from the backend
//     setFeedbackState((prev) => {
//       return {
//         ...prev,
//         quality: defaultFeedback?.quality ?? FEEDBACK_EMOTIONS.EMPTY,
//         service: defaultFeedback?.service ?? FEEDBACK_EMOTIONS.EMPTY,
//       };
//     });
//     setValues();
//   }, [clientDisplayedFile]);

//   useEffect(setValues, [feedbackState]); // This updates the Remarks and Overall values on real time

//   const sendData = (data) => {
//     postAuthData(
//       `${API_URL}/api-client/misc/v1/feedback/`,
//       getAccessLogData(data),
//     )
//       .then((res) => {
//         if (res.success === true) {
//           getAuthData(
//             `${API_URL}/api-client/client/v1/all-assignments/${clientDisplayedFile?.id}/`,
//           )
//             .then((res) => {
//               if (res.success === true) {
//                 setClientDisplayedFile(res.last_assignment);
//               }
//             })
//             .catch((err) => {
//               setNotFoundError(true);
//             });
//         }
//       })
//       .catch((err) => {
//         setNotFoundError(true);
//       });
//   };

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const data = {
//       comments: commentsRef.current.value,
//       batch_id: assignmentId,
//       ...feedbackState,
//     };
//     // Validation check
//     if (
//       data.quality === FEEDBACK_EMOTIONS.EMPTY &&
//       data.service === FEEDBACK_EMOTIONS.EMPTY
//     ) {
//       setRatingEmptyError("Please fill one rating.");
//     } else {
//       setRatingEmptyError(false);
//       sendData(data);
//     }
//   };

//   return (
//     <div className="client-dashboard-card glass-container-active">
//       <div className="client-dashboard-card-head">Feedback</div>

//       <section className="client-delivery-details-block">
//         <span className="client-feedback-label">Delivery Details</span>
//         <div className="client-delivery-details">
//           <DeliveryDetails />
//         </div>
//         {/* <div className="client-delivery-details">{clientDisplayedFile?.delivery_details}</div> */}
//       </section>

//       <form onSubmit={handleSubmit}>
//         <div className="client-feedback-input">
//           <div className="client-feedback-question" id="client-service-feeback">
//             <label className="client-feedback-label" data-feedback="service">
//               How was your experience with our service quality?
//             </label>
//             <div className="client-feedback-rating">
//               <RatingContainer
//                 default_val={defaultFeedback?.service}
//                 setFeedbackState={setFeedbackState}
//                 setRatingEmptyError={setRatingEmptyError}
//               />
//               <label
//                 className="client-feedback-text"
//                 htmlFor="distinctiveFeedback"
//                 data-feedback="distinctVal"
//               >
//                 {serviceOpt === FEEDBACK_EMOTIONS.EMPTY ? "" : serviceOpt}
//               </label>
//               {ratingEmptyError && (
//                 <div className="client-feedback-rating-error">
//                   {ratingEmptyError}
//                 </div>
//               )}
//             </div>
//           </div>

//           <div className="client-feedback-question" id="client-quality-feeback">
//             <label className="client-feedback-label" data-feedback="quality">
//               How would you rate the quality of the output?
//             </label>
//             <div className="client-feedback-rating">
//               <RatingContainer
//                 default_val={defaultFeedback?.quality}
//                 setFeedbackState={setFeedbackState}
//                 setRatingEmptyError={setRatingEmptyError}
//               />
//               <label
//                 className="client-feedback-text"
//                 htmlFor="expectationsFeedback"
//                 data-feedback="expectVal"
//               >
//                 {qualityOpt === FEEDBACK_EMOTIONS.EMPTY ? "" : qualityOpt}
//               </label>
//               {ratingEmptyError && (
//                 <div className="client-feedback-rating-error">
//                   {ratingEmptyError}
//                 </div>
//               )}
//             </div>
//           </div>

//           <div id="client-feedback-comment">
//             <label
//               className="client-feedback-label"
//               id="comments-client-feedback-label"
//               htmlFor="commentsFeedback"
//             >
//               Would you like to add any comments?
//             </label>
//             {!defaultFeedback?.quality || !defaultFeedback.service ? (
//               <textarea
//                 className="client-feedback-comment"
//                 ref={commentsRef}
//                 id="commentsFeedback"
//                 name="commentsFeedback"
//                 placeholder="Type Here.."
//               />
//             ) : (
//               <div
//                 className="client-feedback-comment-div"
//                 style={{ border: "none" }}
//               >
//                 {defaultFeedback?.comments || "-"}
//               </div>
//             )}
//           </div>

//           {(!defaultFeedback?.quality || !defaultFeedback.service) && (
//             <button
//               className="client-btns client-feedback-submit-btn"
//               type="submit"
//               onClick={handleSubmit}
//             >
//               Submit
//             </button>
//           )}
//         </div>
//       </form>
//     </div>
//   );
// };

const RevokeTab = ({ file }) => {
  const { devEnv } = useContext(DevelopmentContext);
  const [requesterNames, setRequesterNames] = useState();

  useEffect(() => {
    if (devEnv) {
      setRequesterNames(requester_names.requester_emails);
    } else {
      setRequesterNames(file?.requester_emails);
    }
  }, [file?.id]);

  return (
    <div className="revoked-bottom-client-dashboard-container">
      <div className="revoked-bottom-client-dashboard-container-top">
        <p className="revoked-bottom-client-dashboard-container-top-heading">
          Your access has been revoked!
        </p>
        <span>
          <img
            className="revoked-bottom-client-dashboard-container-top-img"
            src={
              process.env.PUBLIC_URL +
              "/resources/backgrounds/revoked_access_img.svg"
            }
          />
        </span>
      </div>
      <div className="revoked-bottom-client-dashboard-container-bottom">
        <p className="revoked-bottom-client-dashboard-para">
          You can request the users listed below to regain access
        </p>
        <div className="client-revoked-tab-names-container">
          {requesterNames?.map((item, idx) => {
            return (
              <div key={idx} className="client-revoke-share-warning-name-item">
                <span className="client-share-warning-name">{item}</span>
                {/* <button className="client-share-remove-btn"><FaTimes /></button> */}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export const card_states = {
  OUTPUT: "output",
  FEEDBACK: "feedback",
  SHARE: "share",
  DELIVERY: "delivery",
  HISTORY: "history",
};

const Dashboard = () => {
  const { clientDisplayedFile } = useContext(ClientDashboardFileContext);
  const [showCard, setShowCard] = useState(card_states.DELIVERY);
  // const showDashboardOutput = (e) => {
  //     e.preventDefault();
  //     document.querySelectorAll(".client-dashboard-selector-btn").forEach(el => el.classList.remove("client-dashboard-selector-btn-active"))
  //     const btn = e.target.closest(".client-dashboard-selector-btn")
  //     btn.classList.toggle("client-dashboard-selector-btn-active")
  //     setShowCard(btn.innerText.toLowerCase())
  // }

  return (
    <div className="client-dashboard">
      <TopDashboard
        file={clientDisplayedFile}
        showCard={showCard}
        setShowCard={setShowCard}
      />
      {clientDisplayedFile.file_stage !== "revoked" ? (
        <div className="bottom-client-dashboard">
          {showCard === card_states.DELIVERY && (
            <DeliveryTab file={clientDisplayedFile} />
          )}
          {showCard === card_states.HISTORY && (
            <HistoryTab file={clientDisplayedFile} />
          )}
          {showCard === card_states.SHARE && (
            <LogsTab file={clientDisplayedFile} />
          )}
        </div>
      ) : (
        <div className="revoked-bottom-client-dashboard glass-container-active">
          <RevokeTab file={clientDisplayedFile} />
        </div>
      )}
    </div>
  );
};

export const ENCRYPTION_TYPE = {
  encrypted: "encrypted",
  decrypted: "decrypted",
  email_sent: "email_sent",
  encrypt_now: "encrypt_now",
  default: null,
};

const ClientLiveDashboard = () => {
  const { devEnv } = useContext(DevelopmentContext);
  const API_URL = useContext(ApiContext);
  const { setClientFilesArray } = useContext(ClientFilesArrayContext);
  const modal_dispatch = useContext(ModalDispatchContext);
  const { clientDisplayedFile, setClientDisplayedFile } = useContext(
    ClientDashboardFileContext,
  );
  const { setUserId } = useContext(HomeRecentAsgnContext);
  const { setClientArchiveFile } = useContext(ClientArchiveFileContext);
  const time_to_encrypt = 3600;
  const { asgn_id } = useParams();

  const [secureKey, setSecureKey] = useState({}); // Stores the values from the deliver file modal to be displayed when edit is clicked.
  const [deliverNewVersionInfo, setDeliverNewVersionInfo] = useState({}); // Stores the values from the new version deliver file modal to be displayed when edit is clicked.
  const [autoEncryptTimer, setAutoEncryptTimer] = useState(time_to_encrypt);
  const { encryptedState, setEncryptedState } = useContext(EncryptedContext);

  const { setNotFoundError } = useContext(NotFoundErrorContext);
  // const {setSharedLogData} = useContext(ClientSharingLogContext);
  // const {setAccessLogData} = useContext(ClientAccessLogContext);

  // const fetch_access_log = (id) => {
  //     getAuthData(`${API_URL}/api-client/misc/v1/client-accesslog/${id}`)
  //         .then((res) => {
  //             if (res.success === true) {
  //                 setAccessLogData(res.data)
  //             }
  //             else {
  //                 setNotFoundError(true)
  //             }
  //         })
  //         .catch(err => {
  //             setNotFoundError(true)
  //         })
  // }

  // const fetch_shared_log = (id) => {
  //     getAuthData(`${API_URL}/api-client/misc/v1/sharedlog/${id}`)
  //         .then((res) => {
  //             if (res.success === true) {
  //                 setSharedLogData(res.data)
  //             }
  //             else {
  //                 setNotFoundError(true)
  //             }
  //         })
  //         .catch(err => {
  //             setNotFoundError(true)
  //         })
  // }

  useEffect(() => {
    if (devEnv) {
      setClientDisplayedFile(dummy_assignment.last_assignment); // Setting Dummy Data
      setUserId(dummy_assignment.last_assignment);
      // setClientDisplayedFile(dummy_last_external_assignments.last_assignment); // Setting External assignments Dummy Data

      return;
    }

    // Re-rendering to get the file stages
    // if(clientDisplayedFile?.id){
    //     getAuthData(`${API_URL}/api-client/client/v1/all-assignments/${clientDisplayedFile?.id}/`)
    //         .then((res) => {
    //             if (res.success === true) {
    //                 setClientDisplayedFile(res.last_assignment);
    //             }
    //         })
    //         .catch(err => {
    //             setNotFoundError(true);
    //         })
    // }
  }, [encryptedState]);

  useEffect(() => {
    setEncryptedState(clientDisplayedFile?.file_stage);
    let interval = null;

    if (clientDisplayedFile?.file_stage === FILE_ENCRYPTION_STATES.OLD_FILE) {
      // opening the modal if there is an old file
      modal_dispatch({
        modal_show_type: CLIENT_MODAL_TYPE.ENCRYPTED_ASSIGNMENT,
      });
    } else if (
      clientDisplayedFile?.file_stage === FILE_ENCRYPTION_STATES.DOWNLOAD
    ) {
      const timer_date = new Date(clientDisplayedFile?.expire_time);
      const time_left_to_encrypt = Math.floor(
        (timer_date.getTime() - Date.now()) / 1000,
      );

      setAutoEncryptTimer(time_left_to_encrypt);
      interval = setInterval(() => {
        setAutoEncryptTimer((prev) => {
          if (prev > 0) {
            return prev - 1;
          } else {
            if (devEnv) {
              setClientDisplayedFile(dummy_assignment.last_assignment); // Setting Dummy Data
              setUserId(dummy_assignment.last_assignment);
              // setClientDisplayedFile(dummy_last_external_assignments.last_assignment); // Setting External assignments Dummy Data

              return;
            } else {
              // getAuthData(`${API_URL}/api-client/client/v1/all-assignments/?id=${clientDisplayedFile?.id}`)
              getAuthData(
                `${API_URL}/api-client/client/v1/all-assignments/?id=${asgn_id}`,
              )
                .then((res) => {
                  if (res.success === true) {
                    setClientDisplayedFile(res.last_assignment);
                    // setNotFoundError(false)
                  }
                })
                .catch((err) => {
                  // console.error(err);
                  setNotFoundError(true);
                });
            }

            return time_to_encrypt;
          }
        });
      }, 1000);
    }

    return () => clearInterval(interval);
  }, [clientDisplayedFile]);

  useEffect(() => {
    if (devEnv) {
      setClientFilesArray(dummy_all_assignemnts.data); // Setting Dummy Data
      setClientDisplayedFile(dummy_assignment.last_assignment); // Setting Dummy Data
      setUserId(dummy_assignment.last_assignment);
      // setClientDisplayedFile(dummy_last_external_assignments.last_assignment); // Setting External assignments Dummy Data
      // setClientFilesArray(dummy_all_external_assignemnts.data) // Setting External Dummy Data

      return;
    }

    // getAuthData(`${API_URL}/api-client/client/v1/all-assignments/?id=${clientDisplayedFile?.id}`)
    getAuthData(
      `${API_URL}/api-client/client/v1/all-assignments/?id=${asgn_id}`,
    )
      .then((res) => {
        if (res.success === true) {
          // setClientFilesArray(res.data);
          setClientDisplayedFile(res.last_assignment);
          setUserId(res.last_assignment);
          if (!res?.last_assignment?.batch_id) return; // if no assignment then it blocks fetchinbg logs
          // fetch_shared_log(res.last_assignment.batch_id)
          // fetch_access_log(res.last_assignment.batch_id)
          setNotFoundError(false);
        } else {
          setNotFoundError(true);
        }
      })
      .catch((err) => {
        setNotFoundError(true);
      });
  }, [asgn_id]);

  useEffect(() => {
    setClientArchiveFile(null); // removes active card in archive sidebar
  }, []);

  return (
    <>
      {!clientDisplayedFile?.id ? (
        <ClientEmptyDashboard />
      ) : (
        <SecureKeyContext.Provider value={{ secureKey, setSecureKey }}>
          <DeliverNewVersionInfoContext.Provider
            value={{ deliverNewVersionInfo, setDeliverNewVersionInfo }}
          >
            <AutoEncryptTimerContext.Provider
              value={{ autoEncryptTimer, setAutoEncryptTimer }}
            >
              <Dashboard />
            </AutoEncryptTimerContext.Provider>
          </DeliverNewVersionInfoContext.Provider>
        </SecureKeyContext.Provider>
      )}
    </>
  );
};

const ClientEmptyDashboard = () => {
  return (
    <section className="client-empty-dashboard">
      <span className="client-empty-dashbaord-text"></span>
    </section>
  );
};

export { ClientLiveDashboard, ClientEmptyDashboard };
