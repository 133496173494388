import React, { useEffect, useRef, useState } from "react";
import styles from "./ServiceSection.module.scss";
import { v4 as uuidv4 } from "uuid";
import Card from "./Card/Card";
import CarouselWrap from "./Carousel/Carousel";
import ServiceNameCarousel from "./ServiceNameCarousel/ServiceNameCarousel";
import ServiceNameCard from "./ServiceNameCarousel/ServiceNameCard/ServiceNameCard";
import { data } from "./ListedServicesSectionData";
import Gradient from "../../../public_icons/HomePage/Icons/Gradient";
import { MODAL_TYPES } from "../../../store/loginHomeDataSlice/common";
import { useDispatch, useSelector } from "react-redux";
import {
  setModalShow,
  setModalTitle,
  setServiceName,
} from "../../../store/loginHomeDataSlice/loginHomeDataSlice";
import { GetAQuoteModal } from "../GetAQuoteModal/GetAQuoteModal";

const ServiceSection = () => {
  const dispatch = useDispatch();
  const { modalTitle, modalShow } = useSelector((state) => state.loginHomeData);
  const [serivceTitleCardActiveIndex, setSerivceTitleCardActiveIndex] =
    useState(0);
  const [serivceCardActiveIndex, setSerivceCardActiveIndex] = useState(0);
  const [activeCardData, setActiveCardData] = useState("Presentation Design");
  const [PreviousCardIndex, setPreviousCardIndex] = useState(0);
  const [showNavigation] = useState(true);
  const [isSmallDevice, setIsSmallDevice] = useState(false);
  // const [activeIndicatorIndex, setActiveIndicatorIndex] = useState(0);
  const [indicatorSet, setIndicatorSet] = useState([13, 14, 0, 1, 2, 3]);
  const [sectionIntoView, setSectionIntoView] = useState(false);
  const serviceSectionRef = useRef(null);
  const totalSlides = 15;
  const [serviceCardGoToSlide, setServiceCardGoToSlide] = useState(0);

  const allSubservices = data.reduce((acc, curr) => {
    return [...acc, ...curr.subservices];
  }, []);

  // Create cards for each subservice
  const cards = allSubservices.map((subservice) => {
    // console.log(allSubservices.subservice, "all");
    return {
      key: uuidv4(),
      content: <Card subservice={subservice} />,
    };
  });

  const serviceCard = data.map((category) => {
    return {
      key: uuidv4(),
      content: (
        <ServiceNameCard
          category={category?.title}
          desc={category?.text}
          id={category.id}
        />
      ),
    };
  });

  useEffect(() => {
    const handleResize = () => {
      if (
        window.innerWidth < 1023 ||
        (window.innerWidth === 1024 && window.innerHeight === 1366)
      ) {
        setIsSmallDevice(true);
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const handleIntersection = (entries) => {
      const entry = entries[0];
      if (entry.isIntersecting) {
        setSectionIntoView(true);
      } else {
        setSectionIntoView(false);
      }
    };
    const observer = new IntersectionObserver(handleIntersection, {
      root: null,
      threshold: 0.5,
    });
    if (serviceSectionRef.current) {
      observer.observe(serviceSectionRef.current);
    }
    return () => {
      if (serviceSectionRef.current) {
        observer.unobserve(serviceSectionRef.current);
      }
    };
  }, []);

  function handleSendBrief() {
    dispatch(setServiceName(activeCardData));
    dispatch(setModalTitle("Send a brief"));
    dispatch(setModalShow(MODAL_TYPES.GET_A_QUOTE));
  }

  useEffect(() => {
    if (serivceCardActiveIndex === indicatorSet[indicatorSet.length - 1]) {
      let nextIndex = serivceCardActiveIndex + 1;
      if (nextIndex >= totalSlides) {
        nextIndex = 0;
      }
      const newIndicatorSet = [...indicatorSet.slice(1), nextIndex];
      setIndicatorSet(newIndicatorSet);
    } else if (serivceCardActiveIndex === indicatorSet[0]) {
      let prevIndex = serivceCardActiveIndex - 1;
      if (prevIndex < 0) {
        prevIndex = totalSlides - 1;
      }
      const newIndicatorSet = [prevIndex, ...indicatorSet.slice(0, -1)];
      setIndicatorSet(newIndicatorSet);
    }
    // setActiveIndicatorIndex(serivceCardActiveIndex);
  }, [serivceCardActiveIndex, indicatorSet]);

  const handleIndicatorClick = (index) => {
    // setActiveIndicatorIndex(index);
    setSerivceCardActiveIndex(index);
  };

  return (
    <div className={styles.outersection} ref={serviceSectionRef}>
      <div className={styles.top_gradient}>
        <Gradient />
      </div>
      <div className={styles.bottom_gradient}>
        <Gradient />
      </div>
      <div className={styles.serivce_section_inner_wrapper}>
        <div className={styles.service_section_header_wrapper}>
          <h3 className={styles.service_section_header_text}>
            70+ Offerings that allow you to Focus on your core tasks
          </h3>
        </div>
        <div className={styles.carousels_wrapper}>
          <ServiceNameCarousel
            cards={serviceCard}
            offset={isSmallDevice ? 0 : 1}
            showArrows={false}
            showNavigation={showNavigation}
            serivceCardActiveIndex={serivceCardActiveIndex}
            setSerivceCardActiveIndex={setSerivceCardActiveIndex}
            setIndicatorSet={setIndicatorSet}
            indicatorSet={indicatorSet}
            serviceCardGoToSlide={serviceCardGoToSlide}
            setServiceCardGoToSlide={setServiceCardGoToSlide}
            PreviousCardIndex={PreviousCardIndex}
            setPreviousCardIndex={setPreviousCardIndex}
            serivceTitleCardActiveIndex={serivceTitleCardActiveIndex}
            setSerivceTitleCardActiveIndex={setSerivceTitleCardActiveIndex}
          />
          <CarouselWrap
            cards={cards}
            opacity={1}
            offset={isSmallDevice ? 2 : 3}
            showArrows={false}
            PreviousCardIndex={PreviousCardIndex}
            setPreviousCardIndex={setPreviousCardIndex}
            setSerivceCardActiveIndex={setSerivceCardActiveIndex}
            serivceCardActiveIndex={serivceCardActiveIndex}
            serivceTitleCardActiveIndex={serivceTitleCardActiveIndex}
            setActiveCardData={setActiveCardData}
            sectionIntoView={sectionIntoView}
            serviceCardGoToSlide={serviceCardGoToSlide}
            setServiceCardGoToSlide={setServiceCardGoToSlide}
          />
        </div>
      </div>
      <div className={styles.indicator_wrapper}>
        {indicatorSet.map((index) => (
          <div key={index}>
            <div
              className={`${styles.indicator} ${
                index === serivceCardActiveIndex ? styles.active : ""
              }`}
              onClick={() => handleIndicatorClick(index)}
            ></div>
          </div>
        ))}
      </div>
      <div className={styles.cta_section}>
        <p className={styles.cta_title}>
          Send us your requirements, and get a response within 10 minutes
        </p>
        <p className={styles.cta_desc}>
          {"That's how we keep ourselves Faster than the Fastest"}
        </p>
        <button className={styles.cta_button} onClick={handleSendBrief}>
          GET IN TOUCH
        </button>
      </div>

      <GetAQuoteModal
        title={modalTitle}
        show={modalShow === MODAL_TYPES.GET_A_QUOTE}
        onHide={() => dispatch(setModalShow(MODAL_TYPES.NULL))}
      />
    </div>
  );
};

export default ServiceSection;
