import { Link } from "react-router-dom";
import style from "./USPCard.module.scss";
import { PiArrowCircleUpRight } from "react-icons/pi";
const USPCard = ({ Icon, cardText, hovertextContent, redirecturl }) => {
  return (
    <div className={style.uspcard_outerContainer}>
      <div className={style.uspcard_backgroundImageWrappr}>
        <img src={Icon} alt={cardText} />
      </div>
      <Link
        to={`${process.env.REACT_APP_WEBSITE_URL}/${redirecturl}`}
        aria-label={cardText}
      >
        <div className={style.uspcard_outerWrapper}>
          <div className={style.uspcard_mainContainer}>
            <span>
              <img src={Icon} alt={cardText} />
            </span>
            <span className={style.uspcard_PiArrowCircleIcons}>
              <span>
                <PiArrowCircleUpRight />
              </span>
            </span>
          </div>
          <h2 className={style.uspcard_mainTextPara}>{cardText}</h2>
        </div>
      </Link>
      <Link
        to={`${process.env.REACT_APP_WEBSITE_URL}/${redirecturl}`}
        aria-label={cardText}
      >
        <div className={style.hoveruspcard_Container}>
          <div className={style.hoveruspcard_TextWrapper}>
            <h3 className={style.hoveruspcard_HeadermainText}>{cardText}</h3>
            <p className={style.hoveruspcard_mainTextParagraph}>
              {hovertextContent}
            </p>
          </div>
          <span className={style.hoverReadMoreText}>
            <span>Read More</span>
          </span>
        </div>
      </Link>
    </div>
  );
};

export default USPCard;
