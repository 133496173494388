import React from "react";
import styles from "./SingleServiceComponent/SingleService.module.scss";
import { BiSolidUpArrow } from "react-icons/bi";

const SingleServiceName = ({
  item,
  show,
  setShow,
  openTitle,
  setOpenTitle,
}) => {
  return (
    <section
      className={styles.open_container} //rendering conditionally as per the id taken from their usestates, i have used bool
      onClick={() => {
        if (openTitle && openTitle === item.title) {
          setShow((prevShow) => ({
            ...prevShow,
            [openTitle]: false,
          }));
          setOpenTitle(null);
        } else {
          if (openTitle) {
            setShow((prevShow) => ({
              ...prevShow,
              [openTitle]: false,
              [item.title]: true,
            }));
          } else {
            setShow((prevShow) => ({
              ...prevShow,
              [item.title]: true,
            }));
          }
          setOpenTitle(item.title);
        }
      }}
    >
      <div className={styles.serviceIndivisual_IconsContainer}>
        <span className={styles.serviceContainer_Iconimage}>
          <img
            className={styles.img_svg}
            src={`https://dxw9jueyijhmn.cloudfront.net/ez_website/frontend_img/iconsList/${item.sub_img_src}.svg`}
            alt={item?.cardIconName}
          />
        </span>
        <h3 className={styles.serviceIndivisual_IconsTextContainer}>
          {item.title}
        </h3>
        {openTitle == item.title ? ( //arrow icon is being dispalyed as per which title is open, arrow is being displayed from the above component useStates
          <BiSolidUpArrow size="28" color="#0e1929" className={styles.svg} />
        ) : (
          <></>
        )}
      </div>
    </section>
  );
};

export default SingleServiceName;
