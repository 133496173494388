import { useState } from "react";
import FILE_UPLOAD_PROGRESS from "../constants/FILE_UPLOAD_PROGRESS";
import axios from "axios";
import toast from "react-hot-toast";
import { postAuthData } from "../helpers/request";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getToolsFileList } from "../store/toolsDataSlice/toolsDataSlice";
import { resetActiveFileCard } from "../store/utilsDataSlice/utilsDataSlice";
// import { resetModal, setModal } from "../store/utilsDataSlice/utilsDataSlice";
// import TOOLS_MODALS from "../ToolsModule/ToolsConstants/TOOLS_MODALS";

var timeStart = 0;
var timeEnd = 0;
function useMultiFileChunkUpload({
  chunk_generation_url,
  file_upload_complete_url,
  tool_service,
  activeLang,
  source,
  setIsDisabled,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [filePaths, setFilePaths] = useState([]);
  let s3_file_path = ""; //file path to be sent in upload complete url
  const [filesObj, setFilesObj] = useState({});
  // GENERATE THE FILES STATE USING THIS FUNC
  const filesChunksGenerator = (files) => {
    let filesObj = {};
    const chunkSize = process.env.REACT_APP_MEDIA_CHUNK_SIZE * 1024 * 1024;
    files.forEach((file, i) => {
      const file_id = `f_${i}`; // creating file id for recognition
      const totalChunks = Math.ceil(file.size / chunkSize); // getting chunks number
      const chunkObj = {};
      // creaing chunk slices
      Array.from({ length: totalChunks }).forEach((_, i) => {
        const chunk_id = `c_${i}`;
        const initialPointer = i * chunkSize;
        const finalPointer = initialPointer + chunkSize;
        chunkObj[chunk_id] = {
          chunkIdentifier: { fileId: file_id, chunkId: chunk_id },
          chunkProgress: FILE_UPLOAD_PROGRESS.IN_QUEUE,
          chunkUploadLink: null,
          chunkSliceInfo: {
            initialPoint: initialPointer,
            finalPoint: finalPointer,
          },
          chunkUploadData: { ETag: null, PartNumber: null },
        };
      });
      filesObj[file_id] = {
        file: file,
        fileIdentifier: file_id,
        fileName: file.name,
        fileSize: file.size,
        fileType: file.type,
        fileUploadId: null,
        fileProgress: {
          cur_uploading_chunk: null,
          total_uploaded_chunk: null,
          type: FILE_UPLOAD_PROGRESS.IN_QUEUE,
        },
        totalChunks: totalChunks,
        chunks: chunkObj,
      };
    });
    return filesObj;
  };

  // RE-ATTACH THE FILES INTO THE STATE AGAIN
  const reAttachFile = ({ original_files_obj, updated_json_file_obj }) => {
    Object.keys(original_files_obj).forEach((file_id) => {
      updated_json_file_obj[file_id].file = original_files_obj[file_id].file;
    });
    return updated_json_file_obj;
  };

  // FOR UPDATING THE CHUNKS IN FILE OBJ
  const updateChunkDataInFileObj = ({
    file_obj_state,
    chunk_id,
    file_id,
    chunk_update,
  }) => {
    let updatedFileObjState = JSON.parse(JSON.stringify(file_obj_state));
    const chunk_idx = Object.keys(updatedFileObjState[file_id].chunks).indexOf(
      chunk_id,
    );
    // updating chunks progress
    updatedFileObjState[file_id].chunks[chunk_id] = {
      ...updatedFileObjState[file_id].chunks[chunk_id],
      ...chunk_update,
    };
    // updating file progress based on chunk upload
    updatedFileObjState[file_id].fileProgress = {
      ...updatedFileObjState[file_id].fileProgress,
      cur_uploading_chunk: chunk_idx + 1,
      total_uploaded_chunk: Object.values(
        updatedFileObjState[file_id].chunks,
      ).filter((chunk) => chunk.chunkProgress === FILE_UPLOAD_PROGRESS.SUCCESS)
        .length,
    };
    // attaching file bytes into state again
    updatedFileObjState = reAttachFile({
      original_files_obj: file_obj_state,
      updated_json_file_obj: updatedFileObjState,
    });
    return updatedFileObjState;
  };

  // FOR UPDATING THE FILE OBJ ITSELF
  const updateFileObj = ({ file_obj_state, file_id, file_data_update }) => {
    let updatedFileObjState = JSON.parse(JSON.stringify(file_obj_state));
    updatedFileObjState[file_id] = {
      ...updatedFileObjState[file_id],
      ...file_data_update,
    };
    updatedFileObjState = reAttachFile({
      original_files_obj: file_obj_state,
      updated_json_file_obj: updatedFileObjState,
    });
    return updatedFileObjState;
  };

  // ITS FOR CHECKING WHICH FILE TO UPLOAD NEXT
  const nextUploadFile = (file_obj_state) => {
    const next_file = Object.values(file_obj_state).find(
      (file_obj) =>
        file_obj.fileProgress.type !== FILE_UPLOAD_PROGRESS.SUCCESS &&
        file_obj.fileProgress.type !== FILE_UPLOAD_PROGRESS.UPLOADING,
    );
    return next_file;
  };

  // ############## FETCHING CHUNKS UPLOAD URLS ################
  // OLD NAME: fetchFileChunksUrls
  // filesObj is neeeded to be passed to keep th estate updated based on upload success
  const startFileUpload = async ({ fileObj, filesObj }) => {
    try {
      const file_data = {
        file_name: fileObj.fileName,
        file_size: fileObj.fileSize,
        chunk_size: parseInt(process.env.REACT_APP_MEDIA_CHUNK_SIZE),
        tool_service,
      };
      const res = await postAuthData(chunk_generation_url, file_data);
      setIsDisabled(true);
      // dispatch(setModal(TOOLS_MODALS.LOADER));
      let filePath;
      let successToastShown = false;
      if (res.success) {
        timeStart = new Date();
        filePath = res?.s3_file_path;
        setFilePaths((prevFilePath) => [...prevFilePath, filePath]);
        let file_chunks_data = JSON.parse(JSON.stringify(fileObj.chunks));
        res.part_urls?.forEach(
          (url, i) =>
            (file_chunks_data[
              `${Object.values(file_chunks_data)[i].chunkIdentifier.chunkId}`
            ].chunkUploadLink = url),
        );
        s3_file_path = res?.s3_file_path;
        const updatedFileObjState = updateFileObj({
          file_obj_state: filesObj,
          file_id: fileObj.fileIdentifier,
          file_data_update: {
            chunks: file_chunks_data,
            fileUploadId: res?.upload_id,
            fileProgress: {
              cur_uploading_chunk: 0,
              total_uploaded_chunk: 0,
              type: FILE_UPLOAD_PROGRESS.UPLOADING,
            },
          },
        });
        setFilesObj(updatedFileObjState);
        uploadChunksFn({
          file_obj_data: updatedFileObjState,
          file_id: fileObj.fileIdentifier,
        });
        successToastShown = true;
      }

      if (successToastShown) {
        toast.success("File upload in progress. Do not leave this page", {
          id: "upload-started-tool",
        });
      }

      return {
        file_id: fileObj.fileIdentifier,
        chunk_urls: res?.part_urls,
        uploadId: res?.upload_id,
      };
    } catch (error) {
      setIsDisabled(false);
      // dispatch(resetModal());
      console.error("Error occurred during file upload:", error);
      toast.error(
        "Error occurred during file upload. Please try again later.",
        {
          id: "upload-error",
        },
      );
      return;
    }
  };

  // ############## UPLOADING CHUNKS RECURRING FUNCTION ###############
  const uploadChunksFn = async ({
    file_obj_data,
    file_id,
    chunk_num_to_upload = 0,
  }) => {
    const file_data = file_obj_data[file_id];
    let updatedFileObjState = file_obj_data;
    // Function to upload a individual chunk ;  It will be called in recurring way
    const uploadChunk = async (chunk_data) => {
      const sendAxios = axios.create();
      let fileChunkSlice = file_data.file.slice(
        chunk_data.chunkSliceInfo.initialPoint,
        chunk_data.chunkSliceInfo.finalPoint,
        file_data.file.type,
      );
      return sendAxios.put(chunk_data.chunkUploadLink, fileChunkSlice, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    };

    const checkAllChunkProgress = ({ chunks }) => {
      let checked_all = Object.values(chunks).some((chunk_data) => {
        return (
          chunk_data.chunkProgress === FILE_UPLOAD_PROGRESS.IN_QUEUE ||
          chunk_data.chunkProgress === FILE_UPLOAD_PROGRESS.UPLOADING
        );
      });
      return checked_all;
    };

    // Uploading chunks
    let chunk_keys = Object.keys(file_data.chunks);
    let attempts_count = 0; // number of attempts before failure
    const recurringUploadFn = async (chunk_num) => {
      const chunk_data = file_data.chunks[chunk_keys[chunk_num]];
      // we need chunk_num and not the chunk id bcz it will do recurring based on the next key in the arr.
      if (attempts_count > 3) {
        updatedFileObjState = updateChunkDataInFileObj({
          file_obj_state: updatedFileObjState,
          file_id: chunk_data.chunkIdentifier.fileId,
          chunk_id: chunk_data.chunkIdentifier.chunkId,
          chunk_update: {
            chunkProgress: FILE_UPLOAD_PROGRESS.FAILED,
          },
        });
        updatedFileObjState = updateFileObj({
          file_obj_state: updatedFileObjState,
          file_id: file_data.fileIdentifier,
          file_data_update: {
            fileProgress: {
              cur_uploading_chunk: null,
              total_uploaded_chunk: null,
              type: FILE_UPLOAD_PROGRESS.FAILED,
            },
          },
        });
        setFilesObj(updatedFileObjState);
        setIsDisabled(false);
        // dispatch(resetModal());
        console.error("Failed to upload chunk");
        // throw new Error("Failed to upload chunk");
      }
      uploadChunk(chunk_data)
        .then((res) => {
          let parts = {
            ETag: JSON.parse(res.headers.etag),
            PartNumber: chunk_num + 1,
          };
          attempts_count = 0;
          updatedFileObjState = updateChunkDataInFileObj({
            file_obj_state: updatedFileObjState,
            file_id: chunk_data.chunkIdentifier.fileId,
            chunk_id: chunk_data.chunkIdentifier.chunkId,
            chunk_update: {
              chunkUploadData: parts,
              chunkProgress: FILE_UPLOAD_PROGRESS.SUCCESS,
            },
          });
          setFilesObj(updatedFileObjState);
          const check_chunks_all_success = checkAllChunkProgress({
            chunks:
              updatedFileObjState[chunk_data.chunkIdentifier.fileId].chunks,
          });
          if (check_chunks_all_success) {
            recurringUploadFn(chunk_num + 1);
          } else {
            sendFileCombination({
              file_obj_data: updatedFileObjState,
              file_id: file_data.fileIdentifier,
            });
            return updatedFileObjState;
          }
        })
        .catch(() => {
          attempts_count = attempts_count + 1; // this increases the number of retries already done
          recurringUploadFn(chunk_num); // uploading the same chunk
        });
    };
    recurringUploadFn(chunk_num_to_upload);
  };

  // ############## SEND FILE COMBINATION (FINAL STEP) ##############
  const sendFileCombination = async ({ file_obj_data, file_id }) => {
    const file_data = file_obj_data[file_id];
    let updatedFileObjState = file_obj_data;
    const checkAllFileProgress = (file_obj_state) => {
      // if there is still some queued file pending to be uploaeded then it will return true
      let checked_all = Object.values(file_obj_state).some((file_data) => {
        return (
          file_data.fileProgress.type === FILE_UPLOAD_PROGRESS.IN_QUEUE ||
          file_data.fileProgress.type === FILE_UPLOAD_PROGRESS.UPLOADING
        );
      });
      return checked_all;
    };
    const combined_chunk_data = {
      s3_file_path,
      // upload_id: file_data.fileUploadId,
      parts: Object.values(file_data.chunks).map(
        (chunk) => chunk.chunkUploadData,
      ),
    };
    postAuthData(file_upload_complete_url, combined_chunk_data)
      .then((res) => {
        if (res.success) {
          timeEnd = new Date();
          const uploadTimeInSeconds = timeEnd - timeStart;
          const s3Path = s3_file_path;
          // toast.success(`${tool_service} process has started`);
          const logMessage = `log_type=st_client_upload_time\ntime=${uploadTimeInSeconds}\nfile_size=${file_data?.fileSize}\npath=${s3Path}`;
          postAuthData(
            `${process.env.REACT_APP_API_URL}/api-client/client/v1/log-data/
`,
            { log: logMessage },
          )
            .then((res) => {
              // console.log(res);
            })
            .catch((err) => {
              console.error(err);
            });
          updatedFileObjState = updateFileObj({
            file_obj_state: updatedFileObjState,
            file_id: file_data.fileIdentifier,
            file_data_update: {
              fileProgress: {
                cur_uploading_chunk: 0,
                total_uploaded_chunk: Object.values(file_data.chunks).length,
                type: FILE_UPLOAD_PROGRESS.SUCCESS,
              },
            },
          });
          setFilesObj(updatedFileObjState);
          if (checkAllFileProgress(updatedFileObjState)) {
            const next_file_to_upload = nextUploadFile(updatedFileObjState);
            if (next_file_to_upload) {
              startFileUpload({
                fileObj: next_file_to_upload,
                filesObj: updatedFileObjState,
              });
            }
            toast.success(
              "File uploaded successfully and it is being processed",
            );
            return updatedFileObjState;
          }

          let startToolBody = {
            assignment_id: res.assignment_id,
            s3_file_path: s3_file_path, //defualt body for flip
          };
          if (tool_service === "read") {
            startToolBody = {
              assignment_id: res.assignment_id,
              s3_file_path: s3_file_path,
              active_language: activeLang, //active languauge for read
            };
          }
          if (tool_service === "translate") {
            startToolBody = {
              assignment_id: res.assignment_id,
              s3_file_path: s3_file_path,
              source_language: source,
              target_language: source === "english" ? "arabic" : "english", //source and target for translate
            };
          }
          postAuthData(
            `${process.env.REACT_APP_API_URL}/api-client/tools/v1/${tool_service}/ 
        `,
            startToolBody,
          )
            .then((response) => {
              if (response.success) {
                setIsDisabled(false);
                // dispatch(resetModal());
                toast.success(
                  `${tool_service} file has been successfully processed`,
                );
                dispatch(getToolsFileList({ tool_type: tool_service }));
                dispatch(resetActiveFileCard());
                navigate(
                  `/tools/${tool_service}-file/${response.data.assignment_id}`,
                );
                // setIsDisabled(false);
              } else {
                toast.error("Tool file processing failed");
                // throw new Error("Tool file processing failed");
                setIsDisabled(false);
              }
            })
            .catch((error) => {
              setIsDisabled(false);
              // dispatch(resetModal());
              console.error("Error:", error);
              toast.error("An error occurred while processing the tool file.");
            });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("An error occurred while uploading the file.");
      });
  };

  return [
    filePaths,
    filesObj,
    setFilesObj,
    filesChunksGenerator,
    startFileUpload,
    uploadChunksFn, // in case for reuploading a file
  ];
}

export default useMultiFileChunkUpload;
