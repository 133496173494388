export const ClientTestimonialsData = [
  {
    id: 1,
    rating: 5,
    message:
      '"Sit back, relax, and let us make your presentation design shine," Team EZ. I received this response when I reached out to them at the last minute regarding a presentation design project. I can\'t thank the whole team enough for being available round the clock and making this project a huge success in such a short period. All my best wishes!',
    clientCountryIcon: "/HomePage/Icons/sa.svg",
    clientName: "Saif Sakinah Khalid",
    clientProfile: "Head of Designs, Consulting firm",
  },
  {
    id: 2,
    rating: 5,
    message:
      'EZ delivered exceptional high-quality Arabic subtitles for our documentary. Their meticulous attention to detail and sharp cultural sensitivity were instrumental to our success." This is what our client said about our service.',
    clientCountryIcon: "/HomePage/Icons/usa.svg",
    clientName: "Jacqueline Kingston",
    clientProfile: "Associate Director, Big 4",
  },
  {
    id: 3,
    rating: 5,
    message:
      "It was 2 am, and my government project deadline loomed large. But a crucial forecasting element had me pulling an all-nighter – pure panic mode! Then I remembered EZ Market Research, the round the clock available market research superheroes. A quick message, and boom! Within 30 minutes, their team was on it. The result? My project aced the deadline! EZ Market Research is a lifesaver, always there when you need them most.",
    clientCountryIcon: "/HomePage/Icons/canada.svg",
    clientName: "Sophia Greene",
    clientProfile: "CMO, Government Organization",
  },
  {
    id: 4,
    rating: 4.5,
    message:
      "Needed stunning background graphics to showcase industry growth across domains. EZ Design came through in a pinch! Their team of experts, even with the time zone difference, delivered exceptional visuals that perfectly captured the story. Highly recommended!",
    clientCountryIcon: "/HomePage/Icons/uk.svg",
    clientName: "Robin Davies",
    clientProfile: "Senior Consultant, Fintech Startup",
  },
  {
    id: 5,
    rating: 4.5,
    message:
      "“You sit back and let us take your headache”, - Team EZ. I received this response when I reached out to them for a documentary project. Needed high-quality Arabic subtitles for an English documentary - fast! Working with EZ, we achieved both time efficiency and consistent quality. Their team delivered flawless subtitles, ensuring a clear and impactful viewing experience for our Arabic audience. All my best wishes!",
    clientCountryIcon: "/HomePage/Icons/be.svg",
    clientName: "Brigitte Rousseau",
    clientProfile: "Director of Content, Media Production",
  },
];
