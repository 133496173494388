const HomepageQuoteIconOne = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      // width="591.699"
      // height="505.361"
      viewBox="0 0 691.699 605.361"
    >
      <path
        id="Icon_awesome-quote-left"
        data-name="Icon awesome-quote-left"
        d="M625.946,305.306H518.024V218.968a86.417,86.417,0,0,1,86.337-86.337h10.792a32.3,32.3,0,0,0,32.376-32.377V35.5A32.3,32.3,0,0,0,615.154,3.125H604.361c-119.253,0-215.843,96.59-215.843,215.843V542.733a64.77,64.77,0,0,0,64.753,64.753H625.946A64.77,64.77,0,0,0,690.7,542.733V370.059A64.77,64.77,0,0,0,625.946,305.306Zm-388.518,0H129.506V218.968a86.417,86.417,0,0,1,86.337-86.337h10.792a32.3,32.3,0,0,0,32.376-32.377V35.5A32.3,32.3,0,0,0,226.635,3.125H215.843C96.59,3.125,0,99.715,0,218.968V542.733a64.77,64.77,0,0,0,64.753,64.753H237.428a64.77,64.77,0,0,0,64.753-64.753V370.059A64.77,64.77,0,0,0,237.428,305.306Z"
        transform="translate(0.5 -2.625)"
        fill="#ffffff"
        stroke="rgba(0,0,0,0)"
        strokeMiterlimit="10"
        strokeWidth="1"
        opacity="1"
      />
    </svg>
  );
};

export default HomepageQuoteIconOne;
