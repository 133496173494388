import React from "react";
import { LuRefreshCw } from "react-icons/lu";
import "./ErrorMessage.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  CHAT_ERROR_MESSAGE_TYPE,
  CHAT_MESSAGE_TYPE,
} from "../../../../../../store/consultgptDataSlice/common";
import { setModal } from "../../../../../../store/utilsDataSlice/utilsDataSlice";
import TOOLS_MODALS from "../../../../../ToolsConstants/TOOLS_MODALS";
import resendUserMessage from "../../../../../../utils/resendUserMessage";
import { resetChatErrorMessage } from "../../../../../../store/consultgptDataSlice/consultgptDataSlice";

const ErrorMessage = ({ content }) => {
  const dispatch = useDispatch();
  const { socket, chat_data } = useSelector((state) => state.consultGptData);

  const getLastUserMessage = () => {
    const lastUserMessage = Object.values(chat_data.messages)
      .filter((message) => message.type === CHAT_MESSAGE_TYPE.USER)
      .pop();

    return lastUserMessage;
  };

  const handleRegenerateClick = () => {
    switch (content.error_type) {
      //   case CHAT_ERROR_MESSAGE_TYPE.PARENT_NOT_FOUND:
      //     dispatch(handleParentNotFound(content));
      //     break;
      //   case CHAT_ERROR_MESSAGE_TYPE.SAME_CURRENT_CHILD_ID:
      //     dispatch(handleSameCurrentChildId(content));
      //     break;
      // case CHAT_ERROR_MESSAGE_TYPE.INVALID_CURRENT_ID:
      // dispatch(handleInvalidCurrentId(content));
      // break;

      case CHAT_ERROR_MESSAGE_TYPE.QUERY_LIMIT_EXCEED:
        dispatch(setModal(TOOLS_MODALS.CHAT_QUERY_LIMIT));
        break;

      //   case CHAT_ERROR_MESSAGE_TYPE.CURRENT_CHAT_NOT_FOUND:
      //     dispatch(handleCurrentChatNotFound(content));
      //     break;
      case CHAT_ERROR_MESSAGE_TYPE.AI_PROCESSING_FAIL: {
        const getMessageData = getLastUserMessage();
        if (getMessageData) {
          resendUserMessage({ socket, getMessageData });
        }
        dispatch(resetChatErrorMessage());
        break;
      }
      default:
        console.error("Unknown error type:", content.errorType);
    }
  };
  return (
    <div>
      <>
        {content?.error_type === CHAT_ERROR_MESSAGE_TYPE.AI_PROCESSING_FAIL && (
          <div className="tools_consult_regenerate_message">
            <span className="tools_consult_regenerate_message_text">
              There was an error while generating a response.
            </span>
            <button
              className="tools_consult_regenerate_message_btn"
              onClick={() => handleRegenerateClick(content)}
            >
              <span>
                <LuRefreshCw className="tools_consult_regenerate_message_btn_icon" />
              </span>
              <span>Regenerate Response</span>
            </button>
          </div>
        )}
      </>
      <>
        {content?.error_type === CHAT_ERROR_MESSAGE_TYPE.UNKNOWN_ERROR && (
          <div className="tools_consult_regenerate_message">UNKNOWN_ERROR</div>
        )}
      </>
    </div>
  );
};

export default ErrorMessage;
