const initialState = {
  profile_data: {
    first_name: null,
    last_name: null,
    organization: null,
    phone_number_ext: null,
    phone_number: null,
    whatsapp_number_ext: null,
    whatsapp_number: null,
    image: null,
    email: null,
    profile_id: null,
  },
  comp_code: null,
  error: null,
  loading: false,
};

export default initialState;
