import toast from "react-hot-toast";
import { CHAT_ERROR_MESSAGE_TYPE } from "../store/consultgptDataSlice/common";
import { setModal } from "../store/utilsDataSlice/utilsDataSlice";
import TOOLS_MODALS from "../ToolsModule/ToolsConstants/TOOLS_MODALS";
import { v4 as uuidv4 } from "uuid";
import {
  removeLatestMessage,
  updateChatMessageOnError,
} from "../store/consultgptDataSlice/consultgptDataSlice";

export const handleChatSocketErrorMessage = ({
  data,
  dispatch,
  msgData,
  latestMsgId,
  socket,
}) => {
  const generateMessageIds = () => {
    // Generating new UUIDs
    const oldMessageId = data?.current_node_id;
    const newCurrentNodeId = uuidv4();
    const newMessageId = newCurrentNodeId;
    const newChildNodeId = uuidv4();

    const updatedMessage = {
      uuid: newCurrentNodeId,
      child_message: [newChildNodeId],
    };
    dispatch(
      updateChatMessageOnError({
        oldId: oldMessageId,
        newId: newMessageId,
        updatedMessage,
      }),
    );

    const newMessage = {
      query_type: "new",
      parent_node_id: data?.parent_node_id,
      current_node_id: newCurrentNodeId,
      child_node_id: newChildNodeId,
      message_content: data?.message_content,
      timestamp: data?.timestamp,
    };

    if (socket) {
      socket.emit("query", newMessage);
    }
  };

  const removeLastUserMessage = () => {
    dispatch(removeLatestMessage());
  };

  switch (data?.error_type) {
    case CHAT_ERROR_MESSAGE_TYPE.PARENT_NOT_FOUND:
      toast.error("Something went wrong, Please create a new chat");
      break;
    case CHAT_ERROR_MESSAGE_TYPE.SAME_CURRENT_CHILD_ID:
      //generate new uuid and child id
      generateMessageIds();
      break;
    case CHAT_ERROR_MESSAGE_TYPE.INVALID_CURRENT_ID:
      //regenerate current id
      generateMessageIds();
      break;
    case CHAT_ERROR_MESSAGE_TYPE.QUERY_LIMIT_EXCEED:
      dispatch(setModal(TOOLS_MODALS.CHAT_QUERY_LIMIT));
      break;

    case CHAT_ERROR_MESSAGE_TYPE.QUERY_CHAR_LIMIT_EXCEED:
      removeLastUserMessage();
      break;
    case CHAT_ERROR_MESSAGE_TYPE.CURRENT_CHAT_NOT_FOUND:
      toast.error("Something went wrong, Please create a new chat");
      break;
    case CHAT_ERROR_MESSAGE_TYPE.UNKNOWN_ERROR:
      toast.error("Something went wrong, Please create a new chat");
      break;
    case CHAT_ERROR_MESSAGE_TYPE.CHAT_NOT_FOUND:
      toast.error("This chat does not exists");
      break;
    case CHAT_ERROR_MESSAGE_TYPE.INVALID_PARENT:
      toast.error("This chat does not exists");
      break;
    default:
      toast.error("Something went wrong");
  }
};
