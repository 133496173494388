import React, { useEffect, useRef, useState } from "react";
import styles from "./SearchDropdown.module.scss";
import { FaAngleDown, FaCheck } from "react-icons/fa";
import { SearchDropdownData } from "../GetAQuoteModalSearchData";
import { GrClose } from "react-icons/gr";
import { useSelector } from "react-redux";

const SearchDropdown = ({
  selectedService,
  setSelectedServices,
  selectedSubServices,
  setSelecteSubServices,
  selectedServiceData,
  setSelecteServicesData,
}) => {
  const [isSearchDropdownActive, setIsSearchDropdownActive] = useState(false);
  const [searchDropdownData, setSearchDropdownData] =
    useState(SearchDropdownData);
  const [activeServiceDropdown, setActiveServiceDropdown] = useState([]);
  const dropdownRef = useRef(null);
  const inputCOntainerRef = useRef(null);

  const searchInputRef = useRef(null);
  const { serviceName, btnString } = useSelector(
    (state) => state.loginHomeData,
  );
  const toggleSearchDropdown = () => {
    setIsSearchDropdownActive((prev) => !prev);
    setActiveServiceDropdown([]);
  };

  useEffect(() => {
    if (
      serviceName &&
      SearchDropdownData.some((service) => service.servicesName === serviceName)
    ) {
      // Add the service to selectedService
      setSelectedServices((prevSelected) => [...prevSelected, serviceName]);

      // Find the service data matching the serviceName
      const serviceData = SearchDropdownData.find(
        (service) => service.servicesName === serviceName,
      );

      // Create selected service data object
      let selectedServiceData = {
        service: serviceData.servicesName,
        sub_services: [],
      };

      // If btnString exists and contains a subservice name, add it to the sub_services array
      if (
        btnString &&
        serviceData.subServicesName.some(
          (subService) => subService.subService === btnString,
        )
      ) {
        selectedServiceData = {
          ...selectedServiceData,
          sub_services: [btnString],
        };
      }

      // Update selected service data array
      setSelecteSubServices([...selectedSubServices, btnString]);
      setSelecteServicesData([selectedServiceData]);
    }
  }, []);

  const toggleServiceDropdown = (index) => {
    setActiveServiceDropdown((prevSelected) => {
      if (prevSelected.includes(index)) {
        return prevSelected.filter((item) => item !== index);
      } else {
        return [...prevSelected, index];
      }
    });
  };

  // const handleSelectService = (serviceData) => {
  //   // Check if the service is already selected
  //   if (selectedService.includes(serviceData.servicesName)) {
  //     // Remove the service from selectedService
  //     const updatedSelectedService = selectedService.filter(
  //       (service) => service !== serviceData.servicesName
  //     );
  //     setSelectedServices(updatedSelectedService);

  //     // Remove the service and its associated data from selectedServiceData
  //     const updatedSelectedServiceData = selectedServiceData.filter(
  //       (service) => service.service !== serviceData.servicesName
  //     );
  //     setSelecteServicesData(updatedSelectedServiceData);
  //   } else {
  //     // Add the service to the selected services array
  //     setSelectedServices((prevSelected) => [
  //       ...prevSelected,
  //       serviceData.servicesName,
  //     ]);

  //     // Create selected service data object
  //     const selectedServiceDataItem = {
  //       service: serviceData.servicesName,
  //       sub_services: [],
  //     };

  //     // Update the selected service data array
  //     setSelecteServicesData((prevSelectedData) => [
  //       ...prevSelectedData,
  //       selectedServiceDataItem,
  //     ]);
  //   }
  // };

  const handleSelectService = (serviceData) => {
    if (selectedService.includes(serviceData.servicesName)) {
      // Remove the service from selectedService
      const updatedSelectedService = selectedService.filter(
        (service) => service !== serviceData.servicesName,
      );
      setSelectedServices(updatedSelectedService);

      // Remove the service and its associated data from selectedServiceData
      const updatedSelectedServiceData = selectedServiceData.filter(
        (service) => service.service !== serviceData.servicesName,
      );
      setSelecteServicesData(updatedSelectedServiceData);

      // Remove the subservices associated with the deselected service from selectedSubServices
      const updatedSelectedSubServices = selectedSubServices.filter(
        (subService) =>
          !serviceData.subServicesName.some(
            (sub) => sub.subService === subService,
          ),
      );
      setSelecteSubServices(updatedSelectedSubServices);
    } else {
      // Add the service to the selected services array
      setSelectedServices((prevSelected) => [
        ...prevSelected,
        serviceData.servicesName,
      ]);

      // Create selected service data object
      const selectedServiceDataItem = {
        service: serviceData.servicesName,
        sub_services: [],
      };

      // Update the selected service data array
      setSelecteServicesData((prevSelectedData) => [
        ...prevSelectedData,
        selectedServiceDataItem,
      ]);
    }
  };

  const handleSelectSubService = (subServiceName, parentServiceName) => {
    setSelecteSubServices((prevSelected) => {
      // Check if the subservice is already selected
      if (prevSelected.includes(subServiceName)) {
        // Remove the subservice from selectedSubServices
        const updatedSelectedSubServices = prevSelected.filter(
          (subService) => subService !== subServiceName,
        );
        setSelecteSubServices(updatedSelectedSubServices);

        // Remove the subservice from selectedServiceData
        setSelecteServicesData((prevSelectedData) => {
          const updatedServiceData = prevSelectedData.map((service) => {
            if (service?.service === parentServiceName) {
              return {
                ...service,
                sub_services: service?.sub_services.filter(
                  (subService) => subService !== subServiceName,
                ),
              };
            }
            return service;
          });
          return updatedServiceData;
        });

        return updatedSelectedSubServices;
      } else {
        // Include the subservice
        let updatedSelected = [...prevSelected, subServiceName];

        // Include the parent service if it's not already selected
        if (!selectedService.includes(parentServiceName)) {
          setSelectedServices((prevSelected) => [
            ...prevSelected,
            parentServiceName,
          ]);

          // Create selected service data if it doesn't exist
          setSelecteServicesData((prevSelectedData) => {
            // Check if the parent service is already in selectedServiceData
            const parentServiceExists = prevSelectedData.some(
              (service) => service.service === parentServiceName,
            );

            // If the parent service doesn't exist, create it
            if (!parentServiceExists) {
              return [
                ...prevSelectedData,
                {
                  service: parentServiceName,
                  sub_services: [subServiceName],
                },
              ];
            }

            return prevSelectedData;
          });
        } else {
          // Update the selected service data array with the subservice
          setSelecteServicesData((prevSelectedData) => {
            const updatedData = prevSelectedData.map((service) => {
              if (service.service === parentServiceName) {
                // Check if the subservice already exists
                if (!service.sub_services.includes(subServiceName)) {
                  return {
                    ...service,
                    sub_services: [...service.sub_services, subServiceName],
                  };
                }
              }
              return service;
            });
            return updatedData;
          });
        }

        return updatedSelected;
      }
    });
  };

  const handleSearch = () => {
    const inputValue = searchInputRef.current.value.toLowerCase().trim();
    let filteredData = [];

    // Filter services based on service names and sub-service names
    if (inputValue) {
      filteredData = SearchDropdownData.map((service) => ({
        ...service,
        subServicesName: service.subServicesName.filter((subService) =>
          subService.subService.toLowerCase().includes(inputValue),
        ),
      })).filter(
        (service) =>
          service.servicesName.toLowerCase().includes(inputValue) ||
          service.subServicesName.length > 0,
      );
    } else {
      // If no input value, show all services
      filteredData = [...SearchDropdownData];
    }

    setSearchDropdownData(filteredData);

    // Determine which services have search results
    const servicesWithResults = filteredData.map((service) => service.id);
    setActiveServiceDropdown(servicesWithResults);

    setIsSearchDropdownActive(true); // Show dropdown when search results are updated

    // If input value is empty, close active service dropdowns
    if (!inputValue.trim()) {
      setActiveServiceDropdown([]);
    }
  };

  const handleRemoveService = (serviceName) => {
    // Remove the service and its sub-services from selectedServiceData
    const updatedServiceData = selectedServiceData.filter(
      (service) => service.service !== serviceName,
    );
    setSelecteServicesData(updatedServiceData);

    // Remove the service from selectedService
    const updatedSelectedService = selectedService.filter(
      (service) => service !== serviceName,
    );
    setSelectedServices(updatedSelectedService);

    // Remove the sub-services of the removed service from selectedSubServices
    const updatedSelectedSubServices = searchDropdownData.filter(
      (subService) => {
        const isSubServiceOfRemovedService = updatedServiceData.some(
          (service) => service.sub_services.includes(subService),
        );
        return !isSubServiceOfRemovedService;
      },
    );

    setSelecteSubServices(updatedSelectedSubServices);
  };

  const handleRemoveSubService = (serviceName, subServiceName) => {
    // Remove the sub-service from selectedServiceData
    const updatedServiceData = selectedServiceData.map((service) => {
      if (service.service === serviceName) {
        return {
          ...service,
          sub_services: service.sub_services.filter(
            (subService) => subService !== subServiceName,
          ),
        };
      }
      return service;
    });
    setSelecteServicesData(updatedServiceData);

    // Remove the sub-service from selectedSubServices
    const updatedSelectedSubServices = selectedSubServices.filter(
      (subService) => subService !== subServiceName,
    );

    setSelecteSubServices(updatedSelectedSubServices);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        inputCOntainerRef.current &&
        !inputCOntainerRef.current.contains(event.target) &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsSearchDropdownActive(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.search_input_dropdown_wrapper}>
      <div className={styles.search_input_wrapper} ref={inputCOntainerRef}>
        <input
          type="text"
          placeholder="Service"
          className={styles.search_input_input_field}
          ref={searchInputRef}
          onChange={handleSearch}
        />
        <FaAngleDown
          className={styles.search_input_dropdown_icon}
          style={{
            transform: `rotate(${isSearchDropdownActive ? "180deg" : "0deg"})`,
            transition: "transform 0.3s ease-in-out",
          }}
          onClick={toggleSearchDropdown}
        />
      </div>
      {/* {isSearchDropdownActive && ( */}
      <div
        className={`${styles.search_dropdown_wrapper} ${
          isSearchDropdownActive
            ? styles.search_dropdown_active
            : styles.search_dropdown_unactive
        } `}
        ref={dropdownRef}
      >
        {searchDropdownData.length ? (
          searchDropdownData.map((serviceData, index) => (
            <>
              <div className={styles.service_outer_wrapper_container}>
                <div key={index} className={styles.service_wrapper_container}>
                  <div className={styles.dropdown_arrow}>
                    <FaAngleDown
                      className={styles.search_input_dropdown_icon}
                      style={{
                        transform: `rotate(${
                          activeServiceDropdown.includes(serviceData.id)
                            ? "90deg"
                            : "0deg"
                        })`,
                        transition: "transform 0.3s ease-in-out",
                      }}
                      onClick={() => toggleServiceDropdown(serviceData.id)}
                    />
                  </div>
                  <div
                    onClick={() => handleSelectService(serviceData)}
                    className={styles.servicedata_mainwrappers}
                  >
                    <p className={styles.service_wrapper}>
                      {serviceData?.servicesName}
                    </p>
                    <span
                      className={`${
                        selectedService.includes(serviceData.servicesName)
                          ? styles.tick_icon_wrapper_checked
                          : styles.tick_icon_wrapper_unchecked
                      }`}
                    >
                      <FaCheck />
                    </span>
                  </div>
                </div>
              </div>
              {activeServiceDropdown.includes(serviceData.id) && (
                <div className={styles.subservice_container_outer_wrapper}>
                  <div className={styles.subservice_container_wrapper}>
                    {serviceData?.subServicesName?.map(
                      (subService, subIndex) => (
                        <div
                          key={subIndex}
                          className={styles.subservice_wrapper}
                          onClick={() =>
                            handleSelectSubService(
                              subService.subService,
                              serviceData?.servicesName,
                            )
                          }
                        >
                          <p
                            key={subIndex}
                            className={styles.subservice_name_wrapper}
                          >
                            {subService.subService}
                          </p>

                          <span
                            className={`${
                              selectedSubServices.includes(
                                subService.subService,
                              )
                                ? styles.tick_icon_wrapper_checked
                                : styles.tick_icon_wrapper_unchecked
                            }`}
                          >
                            <FaCheck />
                          </span>
                        </div>
                      ),
                    )}
                  </div>
                </div>
              )}
            </>
          ))
        ) : (
          <div className={styles.no_results_found}>
            <p className={styles.no_results_found_text}>No results found</p>
          </div>
        )}
      </div>
      {/* )} */}
      <div
        className={`${styles.selected_services_wrapper_container} ${
          selectedServiceData?.length > 0 ? styles.top_spacing : ""
        }`}
      >
        {selectedServiceData?.length > 0 &&
          selectedServiceData.map((service, index) => (
            <div className={styles.selected_services_wrapper} key={index}>
              <div className={styles.selected_services_individual_wrapper}>
                <span className={styles.selected_service_name}>
                  {service.service}
                </span>
                <span className={styles.selected_service_icon}>
                  <GrClose
                    onClick={() => handleRemoveService(service.service)}
                  />
                </span>
              </div>
              {service.sub_services.map((subService, subIndex) => (
                <div
                  key={subIndex}
                  className={styles.selected_services_individual_wrapper}
                >
                  <span className={styles.selected_service_name}>
                    {subService}
                  </span>
                  <span className={styles.selected_service_icon}>
                    <GrClose
                      onClick={() =>
                        handleRemoveSubService(service.service, subService)
                      }
                    />
                  </span>
                </div>
              ))}
            </div>
          ))}
      </div>
    </div>
  );
};

export default SearchDropdown;
