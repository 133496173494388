import { useDispatch, useSelector } from "react-redux";
import { setModal } from "../../../../store/utilsDataSlice/utilsDataSlice";
import TOOLS_MODALS from "../../../ToolsConstants/TOOLS_MODALS";
import { getAccessLogData } from "../../../..";
import { FaCheckCircle, FaMinusCircle } from "react-icons/fa";
import "./ToolsAccessCard.scss";
import { getAuthData, postAuthData } from "../../../../helpers/request";
import { useParams } from "react-router-dom";
import {
  setAccessLogData,
  setFrom,
  setShareModalType,
  setSharedEmail,
  setSharedLogData,
  setSharedRes,
} from "../../../../store/userSharedata/userShareDataSlice";
import { SHARE_RESPONSE_STATUS } from "../../../../utils/ShareResponseStatus";
import { SHARE_MODAL_TYPE } from "../../../../utils/ShareModalType";
import toast from "react-hot-toast";

const ToolsRequestedUserCard = ({ user }) => {
  const dispatch = useDispatch();
  const { asgn_id } = useParams();

  const { tools_details_data } = useSelector((state) => state.toolsData);
  const fetch_access_log = () => {
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/access/log/${asgn_id}/`,
    )
      .then((res) => {
        if (res?.success === true) {
          dispatch(setAccessLogData(res?.data));
        } else {
          toast.error(res?.message || "Error while fetching Data!!");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetch_shared_log = () => {
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/access/log/${asgn_id}/`,
    )
      .then((res) => {
        if (res?.success === true) {
          dispatch(setSharedLogData(res));
        } else {
          toast.error(res?.message || "Error while fetching Data!!");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const openDeclineRequestModal = (e) => {
    e.preventDefault();
    dispatch(setModal(TOOLS_MODALS.DECLINE_REQUEST));
    dispatch(setSharedEmail(user?.shared_to_user_email));
    // modal_dispatch({
    //   modal_show_type: CLIENT_MODAL_TYPE.DECLINE_REQUEST,
    // payload: { shared_email: user?.shared_to_user_email },
  };

  const openShareWarningModal = (e) => {
    e?.preventDefault();

    const original_req_data = {
      assignment_id: tools_details_data?.data?.tools_assignment_id,
      email: user?.shared_to_user_email,
      accept_warning: false, //to check for warning
    };

    // if (devEnv) {
    //   modal_dispatch({ modal_show_type: CLIENT_MODAL_TYPE.SHARE_WARNING });
    // } else {
    postAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/share/accept/`,
      getAccessLogData(original_req_data),
    )
      .then((res) => {
        if (res?.status === SHARE_RESPONSE_STATUS.SUCCESS) {
          fetch_shared_log();
          fetch_access_log();
        } else if (res?.status === SHARE_RESPONSE_STATUS.WARNING) {
          dispatch(setModal(TOOLS_MODALS.SHARE_WARNING));
          dispatch(setShareModalType(SHARE_MODAL_TYPE.WARNING));
          dispatch(setSharedRes(res));
          dispatch(setFrom("logs"));
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="tool-client-shared-user-card glass-container glass-container-hover">
      <div className="tool-client-shared-user-card-info client-share-user">
        <span className="tool-client-shared-user-card-info-head">User</span>
        <span className="tool-request-client-shared-user-card-info-value">
          {user?.shared_to_user_email || "-"}
        </span>
      </div>
      <div className="tool-client-shared-user-card-info client-share-shared-by">
        <span className="tool-client-shared-user-card-info-head">
          Shared By
        </span>
        <span className="tool-request-client-shared-user-card-info-value">
          {user?.shared_by || "-"}
        </span>
      </div>
      <div className="tool-client-shared-user-card-info client-share-time-accessed tool-client-shared-user-card-info-center">
        <span className="tool-client-shared-user-card-info-head">
          Times vbnvb
        </span>
        <span className="tool-request-client-shared-user-card-info-value">
          -
        </span>
      </div>
      {/* <div className="client-shared-user-card-info client-share-last-download-ed-version client-shared-user-card-info-center">
        <span className="client-shared-user-card-info-head">
          Last Downloaded Version
        </span>
        <span className="request-client-shared-user-card-info-value">-</span>
      </div> */}
      <div className="tool-client-shared-user-card-info client-share-last-accessed-on tool-client-shared-user-card-info-center">
        <span className="tool-client-shared-user-card-info-head client-share-last-accessed-on-head">
          Last Accessed on
        </span>
        <span className="tool-client-shared-user-card-info-head client-share-last-accessed-on-head-mobile">
          Approve Share Request?
        </span>
        <span className="tool-request-client-shared-user-card-info-value client-share-last-accessed-on-value">
          Approve Share Request?
        </span>
        <span className="tool-request-client-shared-user-card-info-value client-share-last-accessed-on-value-mobile">
          -
        </span>
      </div>
      <div className="tool-client-shared-user-card-info client-share-accept-icon">
        <span
          className="tool-client-shared-user-card-info-value approve-request-icon"
          onClick={openShareWarningModal}
        >
          <FaCheckCircle />
        </span>
      </div>
      <div className="tool-client-shared-user-card-info client-share-decline-icon">
        <span
          className="tool-client-shared-user-card-info-value decline-request-icon"
          onClick={openDeclineRequestModal}
        >
          <FaMinusCircle />
        </span>
      </div>
    </div>
  );
};
export default ToolsRequestedUserCard;
