import React from "react";
import { components } from "react-select";
import { FaAngleDown } from "react-icons/fa";

export const customSelectStyles = {
  // this will deactivate or active the default styles based on requirements
  control: (provided, state) => ({
    ...provided,
    borderColor: "#394D67",
    minHeight: "38px",
    // boxShadow: state.isFocused && "0 0 0 1px transparent",
    // "&:hover": {
    //   borderColor: "var(--light-grey)",
    // },
  }),

  menu: (provided) => ({
    ...provided,
  }),

  valueContainer: (provided) => ({
    ...provided,
    width: "100%",
    padding: "2px 5px",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "5px",
    color: "var(--white)",
    // "&:hover": {
    //   color: "var(--white)",
    // },
  }),
  loadingIndicator: (provided) => ({
    ...provided,
    color: "var(--white)",
    // "&:hover": {
    //   color: "var(--white)",
    // },
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: "var(--white)",
    // "&:hover": {
    //   color: "var(--white)",
    // },
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  menuList: (provided) => ({
    ...provided,
  }),

  multiValue: (provided) => ({
    ...provided,
    background: " rgba(10, 24, 42, 1)",
    borderRadius: "4px",
    border: "1px solid #394D67",
    color: "var(--white)",
  }),

  multiValueLabel: (provided) => ({
    ...provided,
    color: "var(--white-color)",
  }),

  multiValueRemove: (provided, state) => {
    return state.data.isFixed
      ? { ...provided, cursor: "pointer", display: "none" }
      : { ...provided, cursor: "pointer", color: "var(--white-color)" };
  },

  singleValue: (provided) => ({
    ...provided,
    background: "var(--white)",
    width: "fit-content",
    color: "var(--white)",
    padding: "2px",
    borderRadius: "4px",
  }),
  option: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),
};

export const customSelectTheme = (theme) => {
  return {
    ...theme,
    colors: {
      ...theme.colors,
      //       primary: '#2684FF', // outline color of the input
      //       primary75: '#4C9AFF',
      //       primary50: 'var(--light-blue)', // on click color of item
      primary25: "var(--label-blue)", // active/hover item color

      danger: "var(--white)", // item cross icon color
      dangerLight: "var(--white)", // item cross background color

      //       neutral0: 'var(--background-blue)', // color of select options background as wel as the input background
      //       neutral5: 'hsl(0, 0%, 95%)',
      //       neutral10: 'var(--light-blue)', // this is item background color
      //       neutral20: 'hsl(0, 0%, 80%)', // border color for the input
      //       neutral30: 'hsl(0, 0%, 70%)', // hover color for the input
      //       neutral40: 'var(--dark-blue)', // no options message color
      //       neutral50: 'hsl(0, 0%, 50%)', // placeholder color
      //       neutral60: 'hsl(0, 0%, 40%)', // all cross button
      //       neutral70: 'hsl(0, 0%, 30%)',
      //       neutral80: 'var(--dark-blue)', // item text color and icon color
      //       neutral90: 'hsl(0, 0%, 10%)',
    },
  };
};

// custom dropdown
export const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaAngleDown size={20} />
    </components.DropdownIndicator>
  );
};
