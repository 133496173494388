// import Link from "next/link";
import React from "react";
import styles from "./ServiceList.module.scss";
// import ArticleBannerQuoteModal from "../../../home/ArticleBannerQuoteModal";
import { useDispatch } from "react-redux";
import {
  setModalShow,
  setModalTitle,
  setServiceName,
} from "../../../../store/loginHomeDataSlice/loginHomeDataSlice";
import { MODAL_TYPES } from "../../../../store/loginHomeDataSlice/common";

const ServiceList = ({ service, setShowIconList }) => {
  const dispatch = useDispatch();
  const handleServiceClick = () => {
    // if(service.redirectLink === "")
    setShowIconList(false);
  };

  // const { modalShow, setModalShow } = useContext(modalShowContext);
  // const { modalTitle, setModalTitle } = useContext(modalTitleContext);
  // const { servicName, setServiceName } = useContext(serviceNameContext);
  // const [showBannerQuoteModal, setShowBannerQuoteModal] = useState(false);

  function handleSendBrief() {
    setShowIconList(false);
    dispatch(setServiceName(service.serviceName.name));
    dispatch(setModalTitle("Get a Quote"));
    dispatch(setModalShow(MODAL_TYPES.GET_A_QUOTE));
  }
  return (
    <>
      {service.redirectLink !== "" ? (
        <a
          href={`${process.env.REACT_APP_WEBSITE_URL}/${service.redirectLink}`}
          rel="noopener noreferrer"
        >
          <div className={styles.service_list} onClick={handleServiceClick}>
            <div className={styles.service}>
              <span className={styles.service_name}>
                {service?.serviceName.name}
              </span>
              <img
                src={`https://dxw9jueyijhmn.cloudfront.net/ez_website/frontend_img/iconsList/${service.serviceName.src}.svg`}
                alt="service_icon"
                className={styles.service_icon}
              />
            </div>
            <div className={styles.sub_services}>
              {service?.subServices.map((subservice, idx) => {
                return (
                  <div key={idx} className={styles.sub_service}>
                    <img
                      src={`https://dxw9jueyijhmn.cloudfront.net/ez_website/frontend_img/iconsList/${subservice.src}.svg`}
                      alt="service_icon"
                      className={styles.sub_service_icon}
                    />
                    <span className={styles.sub_service_name}>
                      {subservice.name}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
        </a>
      ) : (
        <div className={styles.service_list} onClick={handleSendBrief}>
          <div className={styles.service}>
            <span className={styles.service_name}>
              {service?.serviceName.name}
            </span>
            <img
              src={`https://dxw9jueyijhmn.cloudfront.net/ez_website/frontend_img/iconsList/${service.serviceName.src}.svg`}
              alt="service_icon"
              className={styles.service_icon}
            />
          </div>
          <div className={styles.sub_services}>
            {service?.subServices.map((subservice, idx) => {
              return (
                <div key={idx} className={styles.sub_service}>
                  <img
                    src={`https://dxw9jueyijhmn.cloudfront.net/ez_website/frontend_img/iconsList/${subservice.src}.svg`}
                    alt="service_icon"
                    className={styles.sub_service_icon}
                  />
                  <span className={styles.sub_service_name}>
                    {subservice.name}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ServiceList;
