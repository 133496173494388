import React, { useContext, useEffect, useState } from "react";
import "./NewRequest.scss";
import { IoReloadCircleSharp } from "react-icons/io5";
import {
  resetConsultNewReqForm,
  resetModal,
  resetReferenceLoader,
  resetServiceOffering,
  resetSourceLoader,
} from "../../../store/utilsDataSlice/utilsDataSlice";
import GeneralTextAreaComp from "./GeneralTextAreaComp/GeneralTextAreaComp";
import SearchableSelectInputComp from "./SearchableSelectInputComp/SearchableSelectInputComp";
import entity_list from "../../../archive/test_data/dummy_data-v1/entity_list.json";
import { useDispatch, useSelector } from "react-redux";
import InputFiles from "./InputFiles";
import ReferenceFiles from "./ReferenceFiles";
import { getAuthData, postAuthData } from "../../../helpers/request";
import CreatableSelectInputComp from "./CreatableSelectInputComp/CreatableSelectInputComp";
import GeneralInputComp from "./GeneralInputComp/GeneralInputComp";
import toast from "react-hot-toast";
import { DevelopmentContext } from "../../..";
import WSLoader from "../../WSLoader/WSLoader";

const NewRequest = () => {
  const {
    source_loader,
    reference_loader,
    consult_new_req_data,
    isConsultMode,
  } = useSelector((state) => state.utilsData);
  const [entity, setEntity] = useState([]);
  const [clear, setClear] = useState(false);
  const [loading, setLoading] = useState(false);
  const [referenceFile, setReferenceFile] = useState(null);

  const dispatch = useDispatch();
  const { devEnv } = useContext(DevelopmentContext);

  const { profile_data } = useSelector((state) => state.profileData);
  const handleNewReqClose = () => {
    if (!source_loader && !reference_loader) {
      dispatch(resetServiceOffering());
      dispatch(resetModal());
      dispatch(resetReferenceLoader());
      dispatch(resetSourceLoader());
      dispatch(resetConsultNewReqForm());
    } else {
      toast.error("Previous File(s) are being uploaded please wait", {
        id: "resetError",
      });
    }
  };

  function dateConvertor(date) {
    const isoString = date.toISOString();
    return isoString.slice(0, -5) + "Z"; // Removing last 5 characters (milliseconds) and append "Z"
  }
  const { active_service_offering } = useSelector((state) => state.utilsData);
  const dropDownConvertor = (initialData) => {
    //converting  data from API to dropDown Format
    if (Array.isArray(initialData)) {
      return initialData.map((elem, idx) => ({
        label: elem.value,
        value: idx,
        id: elem.id,
      }));
    } else if (typeof initialData === "string") {
      return [{ label: initialData, value: 1 }];
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (devEnv) {
      const entities = entity_list.map((entity, id) => {
        return {
          value: entity.entity_code,
          id: entity.id,
        };
      });
      setEntity(dropDownConvertor(entities));
    } else {
      getAuthData(
        `${process.env.REACT_APP_API_URL}/api-client/client/v1/entity-list/`,
      )
        .then((response) => {
          if (response.success) {
            const entities = response?.data.map((entity, id) => {
              return {
                value: entity.entity_code,
                id: entity.id,
              };
            });
            setEntity(dropDownConvertor(entities));
          } else {
            toast.error("Failed to fetch entity list");
          }
        })
        .catch((error) => {
          console.error("Error fetching entity list:", error);
          toast.error("An error occurred while fetching entity list.", {
            id: "entity-error",
          });
        });
    }
  }, []);

  const nonRemovableValue = {
    label: profile_data.email,
    value: profile_data.email,
    isFixed: true,
    __isNew__: true,
  };

  const [formData, setFormData] = useState({
    entity: "",
    charge_code_po_number: "",
    service_offering: "",
    instructions: isConsultMode
      ? consult_new_req_data?.consult_instruction
      : "",
    requested_delivery: "",
    engagement_code: "",
    approver: "",
    discount_code: "",
    shared_user_email: [nonRemovableValue],

    source_file: isConsultMode
      ? [`${consult_new_req_data?.consult_s3_file_path}`]
      : [],
    reference_file: [],
  });

  // useEffect(() => {
  //   console.log(formData);
  // }, [formData]);

  // useEffect(() => {
  //   console.log(formData.source_file);
  // }, [formData]);
  const handleResetInputs = () => {
    // if (isConsultMode) {
    //   toast.error("Instruction field is disabled", { id: "consult-inst" });
    // }
    setFormData({
      ...formData,
      entity: "",
      charge_code_po_number: "",
      service_offering: "",
      instructions: "",
      requested_delivery: "",
      engagement_code: "",
      approver: "",
      discount_code: "",
      shared_user_email: [nonRemovableValue],
    });
  };
  const handleUpload = async (e) => {
    e.preventDefault();
    if (!formData?.source_file || formData?.source_file.length === 0) {
      toast.error("Input files are mandatory", { id: "input-file-req" });
      return;
    }
    if (
      !formData.shared_user_email ||
      formData.shared_user_email.length === 0
    ) {
      toast.error("Email is mandatory", {
        id: "mandatory-shared-email",
      });
      return;
    }
    if (!isSharedUserEmailValid(formData.shared_user_email)) {
      toast.error("Email must be valid Email Id", {
        id: "invalid-shared-email",
      });
      return;
    }
    if (!formData?.instructions) {
      toast.error("Instructions Field is required", {
        id: "empty-instruction-id",
      });
      return;
    }
    if (!formData?.entity?.id) {
      toast.error("Entity Field is required", { id: "empty-entity-id" });
      return;
    }
    if (!formData?.charge_code_po_number) {
      toast.error("Charge Code Field is required", {
        id: "empty-charge-id",
      });
      return;
    }
    if (!formData?.engagement_code) {
      toast.error("Engagement Code Field is required", {
        id: "eng-code",
      });
      return;
    }
    if (!formData?.approver) {
      toast.error("Approver Field is required", {
        id: "appr-code",
      });
      return;
    }

    if (!formData.requested_delivery) {
      toast.error("Requested delivery date is required", {
        id: "empty-requested-delivery",
      });
      return;
    }
    const currentDate = new Date();
    const requestedDeliveryDate = new Date(formData.requested_delivery);
    if (requestedDeliveryDate < currentDate) {
      toast.error("Requested delivery date cannot be in the past", {
        id: "invalid-requested-delivery",
      });
      return;
    }

    const shared_user_email_list = formData?.shared_user_email?.map((elem) => {
      return elem?.label;
    });

    const finalData = {
      entity: formData?.entity?.id,
      charge_code_po_number: formData?.charge_code_po_number.trimEnd(),
      engagement_code: formData?.engagement_code.trimEnd(),
      approver: formData?.approver.trimEnd(),
      discount_code: formData?.discount_code.trimEnd(),
      service_offering: active_service_offering || "N/A",
      instructions: formData?.instructions.trimEnd(),
      requested_delivery: dateConvertor(new Date(formData?.requested_delivery)),
      shared_user_email: shared_user_email_list,
      source_file: formData.source_file,
      reference_file: formData.reference_file,
    };

    if (!devEnv) {
      setLoading(true);
      try {
        const response = await postAuthData(
          `${process.env.REACT_APP_API_URL}/api-client/client/v1/assignment-request/`,
          finalData,
        );
        if (response?.success) {
          dispatch(resetServiceOffering());
          dispatch(resetModal());
          dispatch(resetConsultNewReqForm());
          toast.success("The Request has been submitted", {
            id: "assignment-data-saved",
          });
          dispatch(resetServiceOffering());
        } else {
          toast.error(response?.message || "please enter valid inforrmation", {
            id: "res-mes",
          });
        }
      } catch (error) {
        toast.error("There has been an error while submitting form", {
          id: "assignment-confirm",
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const isSharedUserEmailValid = (sharedUserEmail) => {
    return sharedUserEmail.every((elem) => isValidEmail(elem.label));
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div className="st_new_request_main">
      <div className="st_new_heading_container">
        <div className="st_new_heading_name_main">
          <span className="st_new_heading_name">New Request</span>
        </div>
        <div className="st_new_heading_cross" onClick={handleNewReqClose}>
          {/* <RxCross2 size={28} color="var(--white)" /> */}
          <img
            className="st_new_req_close_btn"
            src="/resources/new_req_close_btn.svg"
            alt="close button"
          />
        </div>
      </div>
      <div className="st_files_section">
        <div className="st_new_files">
          <div className="st_new_files_heading_main">
            <span className="st_new_files_heading">FILES</span>
          </div>
          <InputFiles
            clear={clear}
            setReferenceFile={setReferenceFile}
            setClear={setClear}
            formData={formData}
            setFormData={setFormData}
          />
          <ReferenceFiles
            referenceFile={referenceFile}
            setReferenceFile={setReferenceFile}
            formData={formData}
            clear={clear}
            setFormData={setFormData}
          />
        </div>
      </div>
      <div className="st_request_inputs">
        <div className="st_files_section">
          <div className="st_new_files">
            <div className="st_new_files_heading_main">
              <span className="st_new_files_heading">REQUEST</span>
            </div>

            <div className="st_request_deliver_input_main">
              <div className="st_input_files_container_main">
                <div className="st_input_files_container">
                  <span className="st_input_files_heading">DELIVER TO</span>
                  <span className="st_input_reset_icon">
                    <IoReloadCircleSharp
                      color="var(--white)"
                      onClick={handleResetInputs}
                    />
                  </span>
                </div>
              </div>
              <span className="st_request_deliver_input">
                <CreatableSelectInputComp
                  multi={true}
                  handleInputChange={(newValue) => {
                    setFormData({
                      ...formData,
                      shared_user_email: newValue,
                    });
                  }}
                  placeholder={"Enter Email Ids here"}
                  selectedValue={formData?.shared_user_email}
                  nonRemovableValue={nonRemovableValue}
                />
              </span>
            </div>
          </div>
          <div className="st_request_text_input_container">
            <div className="st_input_text_container_main">
              <div className="st_input_text_container">
                <span className="st_input_text_heading">INSTRUCTIONS*</span>
              </div>
            </div>

            <div className="st_request_deliver_input_main">
              <span className="st_request_deliver_input">
                <GeneralTextAreaComp
                  handleInputChange={(newValue) => {
                    setFormData({ ...formData, instructions: newValue });
                  }}
                  // disabled={isConsultMode}
                  selectedValue={formData?.instructions}
                  placeholder={"Enter Instructions here"}
                />
              </span>
            </div>
          </div>

          <div className="st_entity_container_main">
            <div className="st_request_text_input_container">
              <div className="st_input_text_container_main">
                <div className="st_input_text_container">
                  <span className="st_input_text_heading">ENTITY*</span>
                </div>
              </div>

              <div className="st_request_deliver_input_main">
                <span className="st_request_deliver_input">
                  <SearchableSelectInputComp
                    handleInputChange={(options) => {
                      setFormData({ ...formData, entity: options });
                    }}
                    options={entity}
                    placeholder={"Select Entity"}
                    selectedValue={formData.entity}
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="st_charge_and_delivery_container">
            <div className="st_input_charge_container">
              <span className="st_input_charge_heading">CHARGE CODE*</span>
              <span className="st_input_charge_component">
                <GeneralInputComp
                  placeholder={"Enter PO Number / Charge Code / Project Code"}
                  handleInputChange={(newValue) => {
                    setFormData({
                      ...formData,
                      charge_code_po_number: newValue,
                    });
                  }}
                  value={formData?.charge_code_po_number}
                />
              </span>
            </div>
            <div className="st_input_charge_container">
              <span className="st_input_charge_heading">ENGAGEMENT CODE*</span>
              <span className="st_input_charge_component">
                <GeneralInputComp
                  placeholder={"Enter FMNO / Engagement Code"}
                  handleInputChange={(newValue) => {
                    setFormData({
                      ...formData,
                      engagement_code: newValue,
                    });
                  }}
                  value={formData?.engagement_code}
                />
              </span>
            </div>
          </div>
          <div className="st_charge_and_delivery_container">
            <div className="st_input_charge_container">
              <span className="st_input_charge_heading">APPROVER*</span>
              <span className="st_input_charge_component">
                <GeneralInputComp
                  placeholder={"Enter Approving Partner / ED & EM Name"}
                  handleInputChange={(newValue) => {
                    setFormData({
                      ...formData,
                      approver: newValue,
                    });
                  }}
                  value={formData?.approver}
                />
              </span>
            </div>
            <div className="st_delivery_container">
              <span className="st_input_delivery_heading">
                REQUESTED DELIVERY*
              </span>
              <span className="st_input_delivery_calendar">
                <input
                  id="calendar_id_new_req"
                  className="delivery_calendar"
                  type="datetime-local"
                  placeholder="Select Date & Time"
                  value={formData.requested_delivery}
                  onChange={(event) => {
                    const newDateValue = event.target.value;
                    setFormData({
                      ...formData,
                      requested_delivery: newDateValue,
                    });
                  }}
                />
              </span>
            </div>
          </div>
          <div className="st_charge_and_delivery_container">
            <div className="st_input_charge_container discount_code_input">
              <span className="st_input_charge_heading">DISCOUNT CODE</span>
              <span className="st_input_charge_component">
                <GeneralInputComp
                  placeholder={"Enter Discount Code"}
                  handleInputChange={(newValue) => {
                    setFormData({
                      ...formData,
                      discount_code: newValue,
                    });
                  }}
                  value={formData?.discount_code}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="new_request_submit_main">
        <button
          className="new_request_submit_btn"
          onClick={
            source_loader || reference_loader
              ? () =>
                  toast.error(
                    "Please wait till your files are being uploaded",
                    { id: "wait-upload" },
                  )
              : handleUpload
          }
          disabled={loading}
        >
          {source_loader || reference_loader ? (
            <div className="loader_button_files">
              <WSLoader />
              <span>Uploading Files...</span>
            </div>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  );
};

export default NewRequest;
