import React from "react";
// import styles from "./ClientTestimonials.module.scss";
import TestimonialAutoScroll from "./TestimonialAutoScroll/TestimonialAutoScroll";
import TestimonialCarousel from "./TestimonialCarousel/TestimonialCarousel";

const ClientTestimonials = () => {
  return (
    <>
      <TestimonialAutoScroll />
      <TestimonialCarousel />
    </>
  );
};

export default ClientTestimonials;
