import React from "react";
import "./SearchableSelectInputComp.scss";
import Select from "react-select";
import {
  DropdownIndicator,
  customSelectStyles,
  customSelectTheme,
} from "../CustomSelectInputComp/CustomSelectStyles";

const SearchableSelectInputComp = ({
  label,
  selectedValue,
  options,
  handleInputChange,
  placeholder,
  disabled = false,
  multi = false,
}) => {
  return (
    <div className="select-form-group">
      {label && <div className="select-form-label">{label}</div>}
      <Select
        onChange={(option) => {
          handleInputChange(option);
        }}
        noOptionsMessage={() => "No options found."}
        placeholder={placeholder || label || "Select"}
        isMulti={multi}
        isDisabled={disabled}
        isSearchable
        value={selectedValue}
        options={options}
        className="select-form-select basic-multi-select"
        classNamePrefix="select"
        theme={customSelectTheme}
        styles={customSelectStyles}
        menuPosition={"fixed"}
        components={{ DropdownIndicator }}
      />
    </div>
  );
};

export default SearchableSelectInputComp;
