import React from "react";
import styles from "./SingleService.module.scss";
import SingleServiceName from "../SingleServiceName";
import SubSingleService from "../SubSingleService/SubSingleService";
import { RxCross2 } from "react-icons/rx";
const SingleService = ({
  iconlist,
  show,
  setShow,
  openTitle,
  setOpenTitle,
  setShowIconList,
}) => {
  const onServiceCancelHandler = () => {
    setShowIconList(false);
  };

  return (
    <>
      <div
        className={styles.mainservice_DropdownHide}
        onClick={onServiceCancelHandler}
      >
        <RxCross2 />
      </div>

      <section className={styles.mainservices_container}>
        <div className={styles.mainservice_heading_container}>
          <h3 className={styles.mainservice_heading}>{iconlist.title}</h3>
        </div>

        <div className={styles.mainservice_icons_container}>
          {iconlist.subservices.map((item, id) => {
            return (
              <div key={id} className={styles.single_container}>
                <SingleServiceName
                  key={id}
                  item={item}
                  show={show}
                  setShow={setShow}
                  openTitle={openTitle}
                  setOpenTitle={setOpenTitle}
                />
              </div>
            );
          })}
        </div>
      </section>
      {iconlist.subservices.map((item, id) => {
        return (
          <div key={id}>
            {show[item.title] && (
              <div className={styles.subservice_main_container}>
                {item.sub_subserives.map((subSinglelist, id) => (
                  <SubSingleService
                    key={id}
                    service={item.title}
                    redirectLink={item.redirectLink}
                    setShowIconList={setShowIconList}
                    subSinglelist={subSinglelist}
                  />
                ))}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default SingleService;
