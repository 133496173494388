import React, { useEffect, useContext, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ApiContext,
  UserContext,
  ToolTipError,
  ERROR_REGEX,
  lazy_load_image,
  getAccessLogData,
  DELIVERY_TYPE,
  HomeRecentAsgnContext,
} from "../../index.js";

import { postAuthData, getAuthData } from "../../helpers/request";
import {
  ClientDashboardFileContext,
  NewFileContext,
  video_controls,
  VideoControls,
  NotFoundErrorContext,
  ClientArchiveFileContext,
} from "../ClientComponents";

import "./FetchPage.css";
import { useDispatch } from "react-redux";
import { toggleShowToolsSidebar } from "../../store/utilsDataSlice/utilsDataSlice.js";
import { closeFilesSidebar } from "../Nav/ClientSidenav/ClientSidenav.js";

const FetchPage = () => {
  const dispatch = useDispatch();
  const API_URL = useContext(ApiContext);
  const { setClientDisplayedFile } = useContext(ClientDashboardFileContext);
  const { setUserId } = useContext(HomeRecentAsgnContext);
  const { setClientArchiveFile } = useContext(ClientArchiveFileContext);
  const history = useNavigate();
  const keyRef = useRef(null);
  const { profile } = useContext(UserContext);
  const [error, setError] = useState(null);
  const { setNewFile } = useContext(NewFileContext);
  const { setNotFoundError } = useContext(NotFoundErrorContext);

  useEffect(() => {
    setClientDisplayedFile(null);
    setClientArchiveFile(null);
    dispatch(toggleShowToolsSidebar(false));
    closeFilesSidebar();
  }, []);

  const validation = (keyVal) => {
    if (keyVal === "") {
      setError("Enter Delivery ID");
      return false;
    } else {
      return true;
    }
  };

  // const getUpdatedFile = (id) => {
  //   getAuthData(`${API_URL}/api-client/client/v1/all-assignments/${id}/`)
  //     .then((res) => {
  //       if (res.success === true) {
  //         setClientDisplayedFile(res.last_assignment);
  //       }
  //     })
  //     .catch((err) => {
  //       // console.error(err)
  //     });
  // };

  const getLiveUpdatedFilesList = (id) => {
    getAuthData(`${API_URL}/api-client/client/v1/all-assignments/?id=${id}`)
      .then((res) => {
        if (res.success === true) {
          setClientDisplayedFile(res.last_assignment);
          setUserId(res.last_assignment);

          // Plays Video
          const video = VideoControls(video_controls.play);
          video.addEventListener("ended", () => {
            history(`/assignments/${id}`);
          });
        } else {
          setNotFoundError(true);
        }
      })
      .catch((err) => {
        setNotFoundError(true);
      });
  };

  // this function is fetching archive assignment of specific ID and redirects to "/archive" route
  const getArchiveUpdatedFilesList = (id) => {
    getAuthData(`${API_URL}/api-client/archive/v1/all-assignments/?id=${id}`)
      .then((res) => {
        if (res.success === true) {
          setClientArchiveFile(res.last_assignment);

          // Plays Video
          const video = VideoControls(video_controls.play);
          video.addEventListener("ended", () => {
            history(`/archive/${id}`);
          });
        } else {
          setNotFoundError(true);
        }
      })
      .catch((err) => {
        setNotFoundError(true);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setError(null);
    const validationError = validation(keyRef.current.value);
    const data = { delivery_id: keyRef.current.value };
    if (validationError === true) {
      postAuthData(
        `${API_URL}/api-client/client/v1/fetch-delivery/`,
        getAccessLogData(data),
      )
        .then((res) => {
          if (res.success === true) {
            if (
              res.file_type === DELIVERY_TYPE.LIVE ||
              res.file_type === DELIVERY_TYPE.NONE
            ) {
              setClientDisplayedFile(res.last_assignment);
              (res.last_assignment.file_stage === "new_file" ||
                res.last_assignment.file_stage === "old_file") &&
                setNewFile(res.last_assignment); // set the new file here but only if the file is new_file or old_file and not already decrypted and present in the sidebar in that case only the file gets selected.
              getLiveUpdatedFilesList(res.last_assignment.id);
              setUserId(res.last_assignment);
            } else if (res.file_type === DELIVERY_TYPE.ARCHIVE) {
              setClientArchiveFile(res.last_assignment);
              getArchiveUpdatedFilesList(res.last_assignment.id);
            }
          } else {
            setError(res.message);
          }
        })
        .catch((err) => {
          setNotFoundError(true);
        });
    }
  };

  return (
    <div className="client-fetch-page">
      <div className="client-fetch-head">
        <div className="client-fetch-page-logo">
          {profile?.client_logo && profile?.client_logo !== "" && (
            <img
              className="lazy-img"
              onLoad={lazy_load_image}
              src={`${API_URL}` + profile?.client_logo}
              alt="Client Logo"
            />
          )}
        </div>
        <span>
          Please enter the Delivery ID you have received via email to fetch your
          files and decrypt them for download
        </span>
      </div>
      <form className="client-fetch-form" onSubmit={handleSubmit}>
        <div className="client-fetch-input-error-container">
          <input
            className="client-dashboard-input client-fetch-input"
            type="text"
            ref={keyRef}
            onChange={(e) => {
              setError(null);
              ToolTipError(e, [ERROR_REGEX.SYMBOLS_ERROR]);
            }}
            placeholder="Enter Delivery ID Here"
          />
          {error && <div className="client-fetch-error-msg">{error}</div>}
        </div>
        <button className="client-btns" type="submit">
          Fetch & Decrypt
        </button>
      </form>
    </div>
  );
};

export { FetchPage };
