import { useState, useEffect } from "react";
import { config } from "@react-spring/web";
import styles from "./Carousel.module.scss";
import Carousel from "react-spring-3d-carousel";
import { useSelector } from "react-redux";

// const Carousel = dynamic(() => import("react-spring-3d-carousel"), {
//   ssr: false,
// });

const CarouselWrap = (props) => {
  const table = props.cards.map((element, index) => {
    return { ...element, onClick: () => handleCardClick(index, element) };
  });
  const { deltaXPosition } = useSelector((state) => state.loginHomeData);
  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showArrows, setShowArrows] = useState(false);
  const [cards] = useState(table);

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
  }, [props.offset, props.showArrows]);

  const handleCardClick = (index, data) => {
    props.setActiveCardData(
      data?.content?.props?.subservice?.serviceName?.name,
    ); // Store the clicked element's data
    props.setServiceCardGoToSlide(index);
  };

  useEffect(() => {
    if (deltaXPosition >= 10) {
      if (props.serivceCardActiveIndex === 0) {
        props.setServiceCardGoToSlide(14);
      } else {
        props.setServiceCardGoToSlide(props.serivceCardActiveIndex - 1);
      }
    } else if (deltaXPosition <= -10) {
      if (props.serivceCardActiveIndex === 14) {
        props.setServiceCardGoToSlide(0);
      } else {
        props.setServiceCardGoToSlide(props.serivceCardActiveIndex + 1);
      }
    }
  }, [deltaXPosition]);

  function findClosestNumber(arr, num) {
    const totalSlides = 15;
    let closest = null;
    let minDistance = Infinity;

    for (const value of arr) {
      // Calculate distance considering circular arrangement
      const distance = Math.min(
        Math.abs(num - value),
        totalSlides - Math.abs(num - value),
      );

      if (distance < minDistance) {
        closest = value;
        minDistance = distance;
      }
    }

    return closest;
  }

  function updateSlide(props, slides, index) {
    var nearestSlide = findClosestNumber(slides, index);
    props.setServiceCardGoToSlide(nearestSlide);
    props.setActiveCardData(
      props.cards[nearestSlide]?.content.props?.subservice?.serviceName?.name,
    );
    props.setSerivceCardActiveIndex(nearestSlide);
  }

  useEffect(() => {
    var slides;
    if (props.serivceTitleCardActiveIndex === 0) {
      slides = [0, 1, 2, 3];
      updateSlide(props, slides, props.PreviousCardIndex);
    } else if (props.serivceTitleCardActiveIndex === 1) {
      slides = [4, 5, 6];
      updateSlide(props, slides, props.PreviousCardIndex);
    } else if (props.serivceTitleCardActiveIndex === 2) {
      slides = [7, 8];
      updateSlide(props, slides, props.PreviousCardIndex);
    } else if (props.serivceTitleCardActiveIndex === 3) {
      slides = [9, 10, 11];
      updateSlide(props, slides, props.PreviousCardIndex);
    } else if (props.serivceTitleCardActiveIndex === 4) {
      slides = [12, 13, 14];
      updateSlide(props, slides, props.PreviousCardIndex);
    }
  }, [props.serivceTitleCardActiveIndex]);

  useEffect(() => {
    if (props.serviceCardGoToSlide !== props.serivceCardActiveIndex) {
      props.setPreviousCardIndex(props.serivceCardActiveIndex);
      props.setSerivceCardActiveIndex(props.serviceCardGoToSlide);
    }
  }, [props.serviceCardGoToSlide]);

  // function updateSlide(props, slides, index) {
  //   const nearestSlide = findClosestNumber(slides, index);
  //   props.setServiceCardGoToSlide(nearestSlide);
  //   props.setActiveCardData(
  //     props.cards[nearestSlide]?.content.props?.subservice?.serviceName?.name,
  //   );
  //   props.setSerivceCardActiveIndex(nearestSlide);
  // }

  useEffect(() => {
    if (props.sectionIntoView) {
      const intervalId = setInterval(() => {
        if (props.serviceCardGoToSlide === 14) {
          props.setServiceCardGoToSlide(0);
        } else {
          props.setServiceCardGoToSlide(props.serviceCardGoToSlide + 1);
        }
      }, 5000);

      return () => clearInterval(intervalId);
    }
  }, [props.sectionIntoView, props.serviceCardGoToSlide]);

  return (
    <div className={styles.carousel_wrapper}>
      <Carousel
        slides={cards}
        goToSlide={props.serviceCardGoToSlide}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        animationConfig={config.stiff}
      />
    </div>
  );
};

export default CarouselWrap;
