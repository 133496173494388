import React, { useRef, useContext, useState } from "react";
import { FaTimes } from "react-icons/fa";
import {
  ApiContext,
  getAccessLogData,
  DevelopmentContext,
} from "../../../index";
import {
  ClientAccessLogContext,
  ClientDashboardFileContext,
  ClientSharingLogContext,
  CLIENT_MODAL_TYPE,
  ModalDispatchContext,
  NotFoundErrorContext,
} from "../../ClientComponents";
import { getAuthData, postAuthData } from "../../../helpers/request";

function DeclineRequestModal({ payload }) {
  const { devEnv } = useContext(DevelopmentContext);
  const API_URL = useContext(ApiContext);
  const { clientDisplayedFile } = useContext(ClientDashboardFileContext);
  const modal_dispatch = useContext(ModalDispatchContext);
  const { setNotFoundError } = useContext(NotFoundErrorContext);
  const { setSharedLogData } = useContext(ClientSharingLogContext);
  const { setAccessLogData } = useContext(ClientAccessLogContext);

  // const [revokeData, setRevokeData] = useState();
  const [shareErrNameArr, setShareErrNameArr] = useState([]); // eslint-disable-line no-unused-vars
  const [disableShareBtn, setDisableShareBtn] = useState(false);
  const [shareError, setShareError] = useState(null); // eslint-disable-line no-unused-vars
  // const [requesterNames ,setRequesterNames] = useState();

  const shareModalRef = useRef(null);
  const shareSubmitBtn = useRef(null);

  const closeDeclineRequestModal = (e) => {
    e?.preventDefault();
    modal_dispatch({ modal_show_type: CLIENT_MODAL_TYPE.NONE });
  };

  const submitBtnSuccess = () => {
    const shareBtn = shareSubmitBtn.current;
    shareBtn.style.background = "var(--light-orange,#e98742)";
    shareBtn.innerText = "Declined!";
    setTimeout(() => {
      shareBtn.style.background = "var(--light-blue,#3cc3f2)";
      shareBtn.innerText = "Decline";
      closeDeclineRequestModal();
    }, 1000);
  };

  const fetch_access_log = () => {
    getAuthData(
      `${API_URL}/api-client/misc/v1/access-log/${clientDisplayedFile?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setAccessLogData(res.data);
        } else {
          setNotFoundError(true);
        }
      })
      .catch((err) => {
        setNotFoundError(true);
      });
  };

  const fetch_shared_log = () => {
    getAuthData(
      `${API_URL}/api-client/misc/v1/sharedlog/${clientDisplayedFile?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setSharedLogData(res);
        } else {
          setNotFoundError(true);
        }
      })
      .catch((err) => {
        setNotFoundError(true);
      });
  };

  const postDeclineRequestData = () => {
    let data = {
      delivery_id: clientDisplayedFile.batch_id,
      email: payload.shared_email,
    };

    // Disabling the share button to prevent multiple clicks
    setDisableShareBtn(true);

    if (devEnv) {
      submitBtnSuccess();
      console.log(data);
    } else {
      postAuthData(
        `${API_URL}/api-client/misc/v1/decline-request/`,
        getAccessLogData(data),
      )
        .then((res) => {
          if (res.success === true) {
            submitBtnSuccess();
            setDisableShareBtn(false);
            fetch_shared_log();
            fetch_access_log();
          } else {
            setShareError(res.message);
            setDisableShareBtn(false);
            if (res.emails.length > 0) {
              setShareErrNameArr([...res.emails]);
            }
          }
        })
        .catch((err) => {
          setDisableShareBtn(false);
          setNotFoundError(true);
        });
    }
  };
  return (
    <div
      className="client-modal-container client-shared-modal-container"
      ref={shareModalRef}
    >
      <div className="client-modal-head">
        <div className="client-modal-container-head" id="client-share-heading">
          Decline Share Request
        </div>
        <button
          className="client-modal-container-close-btn"
          id="client-share-heading-close-btn"
          onClick={closeDeclineRequestModal}
        >
          <FaTimes />
        </button>
      </div>
      <p>You are declining the share request for the below users -</p>
      <div className="client-revoke-share-names-container">
        <div className="client-revoke-share-warning-name-item">
          <span className="client-revoke-share-name">
            {payload.shared_email}
          </span>
        </div>
      </div>

      <div className="client-share-btn-container">
        <button
          className="client-btns"
          id="client-share-modal-cancel-btn"
          onClick={closeDeclineRequestModal}
        >
          Cancel
        </button>
        <button
          className="client-btns"
          ref={shareSubmitBtn}
          id="client-share-modal-okay-btn"
          disabled={disableShareBtn}
          onClick={postDeclineRequestData}
        >
          Decline
        </button>
      </div>
    </div>
  );
}

export { DeclineRequestModal };
