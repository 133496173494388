import React from "react";
import "./GeneralTextAreaComp.scss";

const GeneralTextAreaComp = ({
  label,
  placeholder,
  value,
  handleInputChange,
  selectedValue,
  disabled,
}) => {
  return (
    <div className="text-area-input-container">
      {label && <div className="text-area-input-label">{label}</div>}
      <textarea
        name={label}
        value={selectedValue}
        className="text-area-input"
        defaultValue={value}
        onChange={(e) => handleInputChange(e.target.value.trimStart())}
        placeholder={placeholder || label || ""}
        disabled={disabled}
      />
    </div>
  );
};

export default GeneralTextAreaComp;
