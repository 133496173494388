import React from "react";

const TestimonialIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="109.714"
      height="96"
      viewBox="0 0 109.714 96"
    >
      <path
        id="Icon_awesome-quote-left"
        data-name="Icon awesome-quote-left"
        d="M99.429,51.125H82.286V37.411A13.727,13.727,0,0,1,96,23.7h1.714a5.131,5.131,0,0,0,5.143-5.143V8.268a5.131,5.131,0,0,0-5.143-5.143H96A34.276,34.276,0,0,0,61.714,37.411V88.839A10.288,10.288,0,0,0,72,99.125H99.429a10.288,10.288,0,0,0,10.286-10.286V61.411A10.288,10.288,0,0,0,99.429,51.125Zm-61.714,0H20.571V37.411A13.727,13.727,0,0,1,34.286,23.7H36a5.131,5.131,0,0,0,5.143-5.143V8.268A5.131,5.131,0,0,0,36,3.125H34.286A34.276,34.276,0,0,0,0,37.411V88.839A10.288,10.288,0,0,0,10.286,99.125H37.714A10.288,10.288,0,0,0,48,88.839V61.411A10.288,10.288,0,0,0,37.714,51.125Z"
        transform="translate(0 -3.125)"
        fill="rgba(255, 255, 255, 0.7)"
        opacity="0.2"
      />
    </svg>
  );
};

export default TestimonialIcon;
