import React, { useContext } from "react";
import {
  FaAngleLeft,
  FaKey,
  FaLock,
  FaUnlock,
  FaUserPlus,
} from "react-icons/fa";
import { MdInfoOutline } from "react-icons/md";
// import { useEffect } from 'react/cjs/react.development';
import {
  date_time_convertor,
  FeedbackRatingEmoji,
  // FileIcon,
  HoverToolTipTextContext,
  showHoverToolTip,
  TOOLTIP_HOVER_TYPE,
  // DELIVERY_USER_TYPE,
} from "../../../..";
import { assignment_services } from "../../../../req_jsons/assignment_services";
import {
  ClientRouteContext,
  // CLIENT_MODAL_TYPE,
  CLIENT_ROUTES,
  FILE_ENCRYPTION_STATES,
  // ModalDispatchContext,
} from "../../../ClientComponents";
import { card_states } from "../../ClientLiveDashboard/ClientLiveDashboard";
import DownloadBtn from "../DownloadButton/DownloadBtn";

import "./TopDashboard.css";

// ======================= TOP Dashboard =================

const FileService = ({ service }) => {
  const { setHoverTooltipStr } = useContext(HoverToolTipTextContext);

  const collapseServices = (e) => {
    const sub_services = Array.from(
      e.target
        .closest(".client-file-info")
        .querySelectorAll(".client-file-info-value-item"),
    );
    const service = e.target
      .closest(".client-file-info")
      .querySelector(".client-file-info-head-services-count");
    sub_services.forEach((el) => {
      el.classList.toggle("client-file-info-value-item-collapse");
    });
    service.classList.toggle("client-file-info-head-services-count-show");
  };
  return (
    <div className="client-file-info">
      <div className="client-file-info-head" onClick={collapseServices}>
        <div className="client-file-info-head-logo">
          <img
            src={
              process.env.PUBLIC_URL +
              `/resources/services_icons/white/${
                assignment_services[service.service].code
              }.svg`
            }
            alt={assignment_services[service.service].code}
          />
        </div>
        <div className="client-file-info-head-text">
          <span>{service.service}</span>{" "}
          <span className="client-file-info-head-services-count">
            ({service.sub_services.length})
          </span>
        </div>
      </div>
      <div className="client-file-info-value-container">
        {service.sub_services.map((sub_service, idx) => {
          return (
            <div key={idx} className="client-file-info-value-item">
              <div className="client-file-info-value-item-text">{`${sub_service.unit_count} ${sub_service.unit_type} ${sub_service.sub_service}`}</div>
              <div
                className="client-file-info-value-item-tooltip hover-tooltip"
                onMouseOver={(e) => {
                  setHoverTooltipStr(
                    assignment_services[service.service]["sub_service"][
                      sub_service.sub_service
                    ],
                  );
                  showHoverToolTip(e, TOOLTIP_HOVER_TYPE.OPEN);
                }}
                onMouseLeave={(e) => {
                  showHoverToolTip(e, TOOLTIP_HOVER_TYPE.CLOSE);
                }}
              >
                <MdInfoOutline />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

// const targetFiles = (file) => {

//     // Coverting the target files from json string to an iterable object
//     const target_file_names = file.target_file_name?.split(",");
//     // const target_files = file.target_file?.split(",");
//     let target_files_object={};
//     target_file_names?.forEach((cur,idx) => {target_files_object[idx]={target_file_name:cur, target_file:file.target_file[idx]}})
// }

const ClientDefaultDownloadSection = ({ file }) => {
  const route = useContext(ClientRouteContext);

  // useEffect(() => {
  //     targetFiles({file});
  // }, [])

  return (
    <>
      <div className="client-key-button-section">
        <DownloadBtn />
      </div>

      {file.file_stage === FILE_ENCRYPTION_STATES.ENCRYPTED &&
        route === CLIENT_ROUTES.ASSIGNMENTS && (
          <div className="client-file-lock" id="client-file-lock-encrypted">
            <FaLock />
          </div>
        )}
      {file.file_stage === FILE_ENCRYPTION_STATES.DOWNLOAD &&
        route === CLIENT_ROUTES.ASSIGNMENTS && (
          <div className="client-file-lock" id="client-file-lock-download">
            <FaUnlock />
          </div>
        )}
      {file.file_stage === FILE_ENCRYPTION_STATES.DECRYPTED &&
        route === CLIENT_ROUTES.ASSIGNMENTS && (
          <div className="client-file-lock" id="client-file-lock-decrypted">
            <FaKey />
          </div>
        )}
    </>
  );
};

const TopDashboard = ({ file, showCard, setShowCard }) => {
  // const modal_dispatch = useContext(ModalDispatchContext);
  const route = useContext(ClientRouteContext);
  const active_target_version = file.target_versions.find(
    (cur) => cur.version == file.current_version,
  );

  // useEffect(() => {
  //     targetFiles({file});
  // }, [file])

  const showDashboardOutput = (e) => {
    e.preventDefault();
    const btn = e.target.closest(".client-dashboard-selector-btn");
    setShowCard(btn.value);
  };

  const showFilesSidebar = (e) => {
    e.preventDefault();
    document
      .querySelector("#client-file-sidebar")
      .classList.toggle("client-sidebar-show");
  };

  // // Apply this on the element to apply it and open the source file download modal (onClick={openSourceFileDownloadModal})
  // const openSourceFileDownloadModal = (e) => {
  //     e.preventDefault();
  //     modal_dispatch({modal_show_type:CLIENT_MODAL_TYPE.SOURCE_FILE_MODAL});
  // }

  return (
    <div className="top-client-dashboard">
      <div className="client-file-logo">
        <button
          className="client-dashboard-back-btn"
          onClick={(e) => {
            showFilesSidebar(e);
          }}
        >
          <FaAngleLeft className="client-responsive-back-btn" />
        </button>
        {/* <FileIcon filename={file?.source_files[0].name}/> */}
        <FeedbackRatingEmoji
          feedback={active_target_version.overall_feedback}
        />
        <div className="client-filename">
          {file?.source_files &&
            `${file?.source_files[0].name || "-"} ${
              file?.source_files?.length > 1
                ? "+ " + String(file?.source_files?.length - 1)
                : ""
            }`}
        </div>
      </div>

      {file?.current_version && (
        <div className="client-file-version-name">
          Version {file?.current_version}
        </div>
      )}
      {/* {console.log(file.file_stage)} */}
      {route === CLIENT_ROUTES.ASSIGNMENTS &&
        file.file_stage !== FILE_ENCRYPTION_STATES.REVOKED && (
          <ClientDefaultDownloadSection file={file} />
        )}

      {/* {(route === CLIENT_ROUTES.ASSIGNMENTS) && <ClientDefaultDownloadSection file={file}/>} */}

      <section className="client-top-dashboard-section">
        {file?.services?.map((service, id) => {
          return <FileService key={id} service={service} />;
        })}

        <div className="client-file-info">
          <div className="client-top-details">
            <div className="client-label-head client-file-info-head-text">
              Committed:
            </div>
            <div className="client-label-value">
              {date_time_convertor(active_target_version?.committed_delivery) ||
                "-"}
            </div>
          </div>
          <div className="client-top-details">
            <div className="client-label-head client-file-info-head-text">
              Received:
            </div>
            <div className="client-label-value">
              {date_time_convertor(active_target_version?.actual_delivery) ||
                "-"}
            </div>
          </div>
        </div>
      </section>

      <div className="client-dashboard-selector">
        <button
          className={`client-dashboard-selector-btn ${
            showCard == card_states.DELIVERY &&
            "client-dashboard-selector-btn-active"
          }`}
          onClick={(e) => showDashboardOutput(e)}
          value={card_states.DELIVERY}
        >
          DELIVERY
        </button>
        <button
          className={`client-dashboard-selector-btn ${
            showCard == card_states.HISTORY &&
            "client-dashboard-selector-btn-active"
          }`}
          onClick={(e) => showDashboardOutput(e)}
          value={card_states.HISTORY}
        >
          HISTORY
        </button>
        <button
          className={`client-dashboard-selector-btn ${
            showCard == card_states.SHARE &&
            "client-dashboard-selector-btn-active"
          }`}
          id="client-share-selector-btn"
          onClick={(e) => showDashboardOutput(e)}
          value={card_states.SHARE}
          title="Share this file"
        >
          <span>SHARE</span>
          <FaUserPlus />
        </button>
      </div>
    </div>
  );
};

export default TopDashboard;
