export const PlaceholderInputData = [
  "Translation Services",
  "Interpretation Services",
  "Voice-over Services",
  "Transcription & Subtitling Services",
  "Proofreading Services",
  "Localisation Services",
  "Market Research",
  "Market Analysis",
  "Quick Turnaround Video",
  "Explainer Video",
  "2D Animation",
  "3D Animation",
  "Whiteboard Animation",
  "Presentation Design",
  "Corporate Presentations",
  "Animated Presentation",
  "Interactive Presentation",
  "Visual Graphics Services",
  "Data Analytics Services",
  "Data Processing Services",
  "Business Market Analysis",
  "EZ Flip",
  "Computer Aided Translation",
  "CAT Tool",
  "OCR",
  "Machine Translation",
  "Artificial Intelligence",
  "Deep Learning AI",
  "Data Privacy",
  "Data Protection",
];
