import React, { useEffect } from "react";
import styles from "../ServicesDropdownSupport/ServicesDropdown.module.scss";
import ServicesDropdown from "./ServiceDropDown";
import ServiceDropDown from "./ServiceDropDown/ServiceDropDown";
import { RxCross2 } from "react-icons/rx";

const ServiceDropLists = ({ setShowIconList, showIconList, refElement }) => {
  useEffect(() => {
    if (showIconList) {
      document.body.style.overflow = "hidden";
      return () => {
        document.body.style.overflow = "auto";
      };
    }
  }, [showIconList]);

  const onDropDownunFoldHandler = () => {
    setShowIconList(false);
  };
  return (
    <div
      className={`${styles.service_drop_list_main} ${
        showIconList ? styles.service_drop_list_main_open : ""
      }`}
      ref={refElement}
    >
      <ServicesDropdown
        setShowIconList={setShowIconList}
        showIconList={showIconList}
      />
      <ServiceDropDown
        setShowIconList={setShowIconList}
        showIconList={showIconList}
      />
      {showIconList && (
        <div
          className={styles.serviceDropdown_RemoveContainer}
          onClick={onDropDownunFoldHandler}
        >
          <RxCross2 />
        </div>
      )}
    </div>
  );
};

export default ServiceDropLists;
