import TOOL_FILE_CARD from "../../ToolsModule/ToolsConstants/TOOL_FILE_CARD";

export const CHART_DATA_TYPE = {
  DELIVERY: "delivery",
  QUALITY: "quality",
};

export const CHART_DATA_TIME = {
  ALL_TIME: "all_time",
  SIX_MONTHS: "six_months",
  THIS_MONTH: "this_month",
};

export const chartColorCodes = {
  background: ["#1C9658", "#3CC3F2", "#F1B715", "#C52221", "transparent"],
  border: ["#1C9658", "#3CC3F2", "#F1B715", " #C52221", "#C52221"],
};

const initialState = {
  tools_page: null,
  modal_type: null,
  consult_new_req_data: {
    consult_instruction: "",
    consult_s3_file_path: "",
    consult_file_name: "",
  },
  isConsultMode: false, // flag to track the new req form open from consult after retriving file data from backend
  active_tool_type: null,
  tools_show_mob_sidebar: false,
  active_service_offering: null,
  source_loader: false,
  reference_loader: false,
  active_file_card: TOOL_FILE_CARD.PREVIEW, //change between preview and share
  auto_encrypt_file_timer: 3600, //tool file auto encrypt timer
  tool_secure_key: {}, //contains secure key
  tool_encrypted_state: null,
  chart_data: {
    cur_data_time: CHART_DATA_TIME.ALL_TIME,
    delivery: {
      data: {
        all_time: {
          vals: null,
          success_rate: null,
        },
        six_months: {
          vals: null,
          success_rate: null,
        },
        this_month: {
          vals: null,
          success_rate: null,
        },
      },
      loading: false,
    },
    quality: {
      data: {
        all_time: {
          vals: null,
          success_rate: null,
        },
        six_months: {
          vals: null,
          success_rate: null,
        },
        this_month: {
          vals: null,
          success_rate: null,
        },
      },
      loading: false,
    },
  },
};

export default initialState;
