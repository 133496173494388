import React, { useContext, useEffect, useState } from "react";
import "./HomeRecentAsgns.scss";
import HomeSingleAssgn from "./HomeSingleAsssg";
import { useNavigate } from "react-router-dom";
import dummy_all_assignemnts from "../../../archive/test_data/dummy_data-v1/dummy_all_assignments.json";
import { ApiContext, DevelopmentContext } from "../../..";
import { getAuthData } from "../../../helpers/request";
import { HomeRecentAsgnContext } from "../../..";
import { setModal } from "../../../store/utilsDataSlice/utilsDataSlice";
import { useDispatch } from "react-redux";
import TOOLS_MODALS from "../../ToolsConstants/TOOLS_MODALS";
import { FiPlus } from "react-icons/fi";

const HomeRecentAsgns = () => {
  const { devEnv } = useContext(DevelopmentContext);
  const API_URL = useContext(ApiContext);
  const dispatch = useDispatch();

  const [recentAssgn, setRecentAssgn] = useState([]);
  const { userId } = useContext(HomeRecentAsgnContext);
  const navigate = useNavigate();
  const handleToAssignment = () => {
    navigate("/assignments");
  };

  const handleNewRequest = () => {
    dispatch(setModal(TOOLS_MODALS.NEW_REQ));
  };

  useEffect(() => {
    if (devEnv) {
      const recentAssignments = [];
      dummy_all_assignemnts?.data[0]?.info?.forEach((assgn, idx) => {
        recentAssignments.push(assgn);
      });
      setRecentAssgn(recentAssignments);
      return;
    }

    getAuthData(
      `${API_URL}/api-client/client/v1/all-assignments/?id=${userId?.id}`,
    )
      .then((res) => {
        if (res.success === true) {
          const recentAssignments = [];
          res?.data[0]?.info?.forEach((assgn, idx) => {
            recentAssignments.push(assgn);
          });
          setRecentAssgn(recentAssignments);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <div className="home_recent_assg">
      <div className="home_recent_heading_container">
        <span className="home_recent_heading">Recent Assignments</span>
        {recentAssgn && recentAssgn.length < 5 ? (
          <></>
        ) : (
          <button className="home_new_asgn_req_btn" onClick={handleNewRequest}>
            <FiPlus /> New Request
          </button>
        )}
      </div>
      {recentAssgn && recentAssgn.length === 0 ? (
        <div className="no_assignment_container">No Assignments Yet</div>
      ) : (
        <div className="home_assignment_container">
          {recentAssgn &&
            recentAssgn.length > 0 &&
            recentAssgn.slice(0, 4).map((elem, id) => {
              return <HomeSingleAssgn key={id} data={elem} />;
            })}
          <span></span>
          <span className="home_view_more" onClick={handleToAssignment}>
            {recentAssgn && recentAssgn.length > 4
              ? `+ ${recentAssgn.length - 4} more`
              : ""}
          </span>
        </div>
      )}
    </div>
  );
};

export default HomeRecentAsgns;
