import React, { useEffect } from "react";
import "./HomePieChart.scss";
import { Doughnut } from "react-chartjs-2";
import { Chart, ArcElement } from "chart.js";
import { FaChevronDown } from "react-icons/fa";
import {
  CHART_DATA_TIME,
  CHART_DATA_TYPE,
  chartColorCodes,
} from "../../../store/utilsDataSlice/common";
import { useDispatch, useSelector } from "react-redux";
import {
  getChartData,
  setCurChartTime,
} from "../../../store/utilsDataSlice/utilsDataSlice";
Chart.register(ArcElement);

const PiechartComp = ({ data }) => {
  const options = {
    maintainAspectRatio: 1,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      responsive: false,
    },
  };

  return (
    <Doughnut
      data={{
        // labels: Object.keys(data).map((el) => {
        //   let label = el.split("_").join(" ").toUpperCase();
        //   // label[0].toUpperCase() + label.substring(1);
        //   return label;
        // }),
        datasets: [
          {
            label: "Count",
            data: Object.values(data),
            backgroundColor: chartColorCodes.background,
            borderColor: "transparent",
            cutout: "70%",
          },
        ],
      }}
      className="pie_chart"
      options={options}
    />
  );
};

const ChartSection = ({ flip = false, data_type }) => {
  const { chart_data } = useSelector((state) => state.utilsData);

  return (
    <div className={`chart_section ${flip && "chart_section-flip"}`}>
      <div className="chart_graph_head">{data_type}</div>

      <div className="chart_graph_wrapper">
        <div className="chart_legends">
          {chart_data[data_type].data[chart_data.cur_data_time]?.vals &&
            Object.keys(
              chart_data[data_type].data[chart_data.cur_data_time]?.vals,
            ).map((label, i) => {
              return (
                <div className="chart_legend_item" key={i}>
                  <div
                    className="chart_legend_item_color"
                    style={{ backgroundColor: chartColorCodes.background[i] }}
                  ></div>
                  <div className="chart_legend_item_label">
                    {label.split("_").join(" ")}
                  </div>
                </div>
              );
            })}
        </div>
        <div className="chart_graph">
          {chart_data[data_type].data[chart_data.cur_data_time]?.vals && (
            <PiechartComp
              data={chart_data[data_type].data[chart_data.cur_data_time].vals}
            />
          )}
          {chart_data[data_type].data[chart_data.cur_data_time]
            ?.success_rate ? (
            <div className="chart_success">
              <div className="chart_succes_text">Success</div>
              <div className="chart_succes_val">
                {
                  chart_data[data_type].data[chart_data.cur_data_time]
                    ?.success_rate
                }
                %
              </div>
            </div>
          ) : (
            <span className="chart_no_data">No data</span>
          )}
        </div>
      </div>
    </div>
  );
};

const HomePieChart = () => {
  const dispatch = useDispatch();
  const { chart_data } = useSelector((state) => state.utilsData);

  const handleSelectChange = (e) => {
    dispatch(setCurChartTime(e.target.value));
  };

  useEffect(() => {
    dispatch(getChartData(CHART_DATA_TYPE.DELIVERY));
    dispatch(getChartData(CHART_DATA_TYPE.QUALITY));
  }, []);

  return (
    <section className="home_pie_chart_section">
      <div className="home_pie_chart_head">
        <span className="home_pie_chart_heading">Performance Dashboard</span>
        <div className="home_pie_chart_select">
          <div className="home_pie_chart_select_wrapper">
            <select
              value={chart_data.cur_data_time}
              onChange={handleSelectChange}
              className="home_pie_chart_select_input"
            >
              <option value={CHART_DATA_TIME.THIS_MONTH}>This Month</option>
              <option value={CHART_DATA_TIME.SIX_MONTHS}>6 Months</option>
              <option value={CHART_DATA_TIME.ALL_TIME}>All Time</option>
            </select>
            <FaChevronDown className="home_pie_chart_select_icon" />
          </div>
        </div>
      </div>
      <div className="chart_section_wrapper">
        <ChartSection flip={false} data_type={CHART_DATA_TYPE.DELIVERY} />
        <ChartSection flip={true} data_type={CHART_DATA_TYPE.QUALITY} />
      </div>
    </section>
  );
};

export default HomePieChart;
