export const daysNum = (sd, ed) => {
  let d1 = sd;
  let d2 = ed;
  if (typeof sd === "string") {
    d1 = new Date(sd);
  }
  if (typeof ed === "string") {
    d2 = new Date(ed);
  }
  let diff = Math.abs(d1 - d2);
  let noOfDays = Math.round(diff / (1000 * 60 * 60 * 24));
  return noOfDays + 1;
};

// ====================== Date Time Formatter to local date ========================

export const DATE_FORMATS = {
  DD_MM_YY: "dd-mm-yy",
  DAY_DD_MONTH_YEAR: "day-dd-month-year",
  DD_MONTH_YEAR: "dd-month-year",
  DAY_DD_MONTH_YEAR_TIME: "day-dd-month-year-hh-mn-tz",
  DD_MONTH_YEAR_TIME: "dd-month-year-hh-mn",
  DD_MM_YY_TIME: "dd-mm-yy-hh-mn-tz",
  ALL: "all",
};

const dateTimeConverter = (utc_date, date_format = DATE_FORMATS.ALL) => {
  let converted_date;
  const day_arr = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const month_arr = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (!utc_date || !Date.parse(utc_date)) return null;
  const date_time = new Date(
    utc_date.slice(-1) === "Z" ? utc_date : utc_date + "Z",
  ); // checks if UTC is comming with a Z in the end or not

  // Dissecting date
  const day = day_arr[date_time.getDay()];
  const month = month_arr[date_time.getMonth()];
  const date = date_time.getDate();
  const year = date_time.getFullYear();
  const hours = date_time.getHours();
  const minutes = date_time.getMinutes();
  const seconds = date_time.getSeconds();
  const time_zone = date_time?.toString()?.split("(")[1]?.split(")")[0];

  //Abbreviating the time zone
  const time_zone_arr = time_zone.split(" ");
  const reduce_time_abrv = (tz__arr) => {
    const abrv = [];
    tz__arr.map((cur) => abrv.push(cur.substr(0, 1)));
    return abrv.join("");
  };
  const time_zone_abrv =
    time_zone_arr.length > 1
      ? reduce_time_abrv(time_zone_arr)
      : time_zone_arr[0];

  // 12 Hr converter
  let timeString = `${String(hours).length === 2 ? hours : `0${hours}`}:${
    String(minutes).length === 2 ? minutes : `0${minutes}`
  }:${String(seconds).length === 2 ? seconds : `0${seconds}`}`;
  const H = +timeString.substr(0, 2);
  const h = H % 12 || 12;
  const ampm = H < 12 || H === 24 ? " AM" : " PM";
  timeString =
    (String(h).length === 2 ? h : `0${h}`) + timeString.substr(2, 3) + ampm;

  switch (date_format) {
    case DATE_FORMATS.ALL:
      converted_date = `${day}, ${date} ${month} ${year}, ${timeString} (${time_zone_abrv})`;
      break;
    case DATE_FORMATS.DAY_DD_MONTH_YEAR:
      converted_date = `${day}, ${date} ${month} ${year}`;
      break;
    case DATE_FORMATS.DD_MONTH_YEAR:
      converted_date = `${date} ${month} ${year}`;
      break;
    case DATE_FORMATS.DAY_DD_MONTH_YEAR_TIME:
      converted_date = `${day}, ${date} ${month} ${year}, ${timeString}`;
      break;
    case DATE_FORMATS.DD_MONTH_YEAR_TIME:
      converted_date = `${date} ${month} ${year}, ${timeString}`;
      break;
    case DATE_FORMATS.DD_MM_YY_TIME:
      converted_date = `${date} ${month} ${year}, ${timeString} ${time_zone_abrv}`;
      break;
    default:
      converted_date = `${day}, ${date} ${month} ${year}, ${timeString} (${time_zone_abrv})`;
      break;
  }

  return converted_date;
};

export default dateTimeConverter;
