export const SearchDropdownData = [
  {
    id: 1,
    servicesName: "Presentation Design",
    isopen: false,
    subServicesName: [
      {
        id: 11,
        subService: "Flipping & Formatting",
      },
      {
        id: 12,
        subService: "Slide Design",
      },
      {
        id: 13,
        subService: "Report Design",
      },
      {
        id: 14,
        subService: "Animated Presentation",
      },
      {
        id: 15,
        subService: "Interactive Presentation",
      },
    ],
  },
  {
    id: 2,
    servicesName: "Visual Graphics",
    isopen: false,
    subServicesName: [
      {
        id: 21,
        subService: "Iconography",
      },
      {
        id: 22,
        subService: "Infographic Design",
      },
      {
        id: 23,
        subService: "HTML Designs",
      },
      {
        id: 24,
        subService: "Social Media Visuals",
      },
      {
        id: 25,
        subService: "Creative & Collateral",
      },
      {
        id: 26,
        subService: "UI/UX Design",
      },
    ],
  },
  {
    id: 3,
    servicesName: "Animation Videos",
    isopen: false,
    subServicesName: [
      {
        id: 31,
        subService: "Stock Videos",
      },
      {
        id: 32,
        subService: "Character Animation",
      },
      {
        id: 33,
        subService: "Quickturn 2D Animation",
      },
      {
        id: 34,
        subService: "3D Animation",
      },
    ],
  },
  {
    id: 4,
    servicesName: "Film Production",
    isopen: false,
    subServicesName: [
      {
        id: 41,
        subService: "Videography",
      },
      {
        id: 42,
        subService: "Individual Interviews",
      },
      {
        id: 43,
        subService: "Corporate Films",
      },
      {
        id: 44,
        subService: "Ads & Commercials",
      },
    ],
  },
  {
    id: 5,
    servicesName: "Language Services",
    isopen: false,
    subServicesName: [
      {
        id: 51,
        subService: "Translation",
      },
      {
        id: 52,
        subService: "Proofreading",
      },
      {
        id: 53,
        subService: "Transcription & Subtitling",
      },
      {
        id: 54,
        subService: "Voice Over",
      },
      {
        id: 55,
        subService: "Interpretation",
      },
      {
        id: 56,
        subService: "Localization",
      },
    ],
  },
  {
    id: 6,
    servicesName: "Content Creation",
    isopen: false,
    subServicesName: [
      {
        id: 61,
        subService: "Blog Writing",
      },
      {
        id: 62,
        subService: "Article Writing",
      },
      {
        id: 63,
        subService: "e-Commerce Content",
      },
      {
        id: 64,
        subService: "Technical Writing",
      },
      {
        id: 65,
        subService: "Writing Projects",
      },
      {
        id: 66,
        subService: "Copywriting",
      },
    ],
  },
  {
    id: 7,
    servicesName: "Digital Marketing",
    isopen: false,
    subServicesName: [
      {
        id: 71,
        subService: "Search Engine Optimization",
      },
      {
        id: 72,
        subService: "Pay Per Click",
      },
      {
        id: 73,
        subService: "Social Media Marketing",
      },
    ],
  },
  {
    id: 8,
    servicesName: "Research & Analytics",
    isopen: false,
    subServicesName: [
      {
        id: 81,
        subService: "Desk Research",
      },
      {
        id: 82,
        subService: "Primary Research",
      },
      {
        id: 83,
        subService: "Global Surveys",
      },
      {
        id: 84,
        subService: "Sentiment Analysis",
      },
      {
        id: 85,
        subService: "Expert Engagement",
      },
      {
        id: 86,
        subService: "Focus Groups",
      },
      {
        id: 87,
        subService: "Report Writing",
      },
    ],
  },
  {
    id: 9,
    servicesName: "Data Processing",
    isopen: false,
    subServicesName: [
      {
        id: 91,
        subService: "Spreadsheet Engineering",
      },
      {
        id: 92,
        subService: "Process Digitization",
      },
      {
        id: 93,
        subService: "Document Digitization",
      },
      {
        id: 94,
        subService: "Dashboarding",
      },
      {
        id: 95,
        subService: "Data Visualization",
      },
    ],
  },
  {
    id: 10,
    servicesName: "IT Support Desk",
    isopen: false,
    subServicesName: [
      {
        id: 101,
        subService: "Cloud Management",
      },
      {
        id: 102,
        subService: "Remote Helpdesk",
      },
      {
        id: 103,
        subService: "Customer Support",
      },
    ],
  },
  {
    id: 11,
    servicesName: "Tech Development",
    isopen: false,
    subServicesName: [
      {
        id: 111,
        subService: "Website Creation",
      },
      {
        id: 112,
        subService: "Product Documentation",
      },
      {
        id: 113,
        subService: "Product Testing",
      },
      {
        id: 114,
        subService: "No-code Development",
      },
      {
        id: 115,
        subService: "Mobile App Development",
      },
      {
        id: 116,
        subService: "Web App Development",
      },
    ],
  },
  {
    id: 12,
    servicesName: "Applied AI",
    isopen: false,
    subServicesName: [
      {
        id: 121,
        subService: "OpenAI Integration",
      },
      {
        id: 122,
        subService: "AI Development",
      },
      {
        id: 123,
        subService: "Process Optimization",
      },
    ],
  },
  {
    id: 13,
    servicesName: "Human Resources",
    isopen: false,
    subServicesName: [
      {
        id: 131,
        subService: "HR Operations",
      },
      {
        id: 132,
        subService: "Payroll Preparation",
      },
      {
        id: 133,
        subService: "Shared Recruiter",
      },
      {
        id: 134,
        subService: "Performance Review",
      },
      {
        id: 135,
        subService: "Survey & Feedback",
      },
    ],
  },
  {
    id: 14,
    servicesName: "Operations Support",
    isopen: false,
    subServicesName: [
      {
        id: 141,
        subService: "Shared EA",
      },
      {
        id: 142,
        subService: "Accounting & Bookkeeping",
      },
      {
        id: 143,
        subService: "Payment Processing",
      },
      {
        id: 144,
        subService: "Contact Centre",
      },
      {
        id: 145,
        subService: "No Code ERP",
      },
    ],
  },
  {
    id: 15,
    servicesName: "Service Procurement",
    isopen: false,
    subServicesName: [
      {
        id: 151,
        subService: "Legal Resources",
      },
      {
        id: 152,
        subService: "Training & Development",
      },
      {
        id: 153,
        subService: "Certifications",
      },
    ],
  },
];
