import React, { useEffect, useState } from "react";
import "./ToolUploadInput.scss";
import ToolUploadInput from "./ToolUploadInput";
import useMultiFileChunkUpload from "../../../../hooks/useMultiFileChunkUpload";
import toast from "react-hot-toast";

const UploadSourceFile = ({
  file_text,
  active_tool_type,
  activeLang,
  source,
}) => {
  const [files, setFiles] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const [
    filePaths,
    filesObj,
    setFilesObj,
    filesChunksGenerator,
    startFileUpload,
  ] = useMultiFileChunkUpload({
    activeLang,
    source,
    setIsDisabled,
    tool_service: active_tool_type,
    chunk_generation_url: `${process.env.REACT_APP_API_URL}/api-client/tools/v1/upload-get/`,
    file_upload_complete_url: `${process.env.REACT_APP_API_URL}/api-client/tools/v1/mulipart-complete/`,
  });

  const [uploadStarted, setUploadStarted] = useState(false);
  const [trackedFilePaths, setTrackedFilePaths] = useState(null); // eslint-disable-line no-unused-vars

  useEffect(() => {
    if (!uploadStarted && Object.keys(filesObj).length > 0) {
      handleFileUpload();
      setIsDisabled(true);
      setUploadStarted(true);
    }
  }, [filesObj, uploadStarted]);

  // useEffect(() => {
  //   console.log(filePaths, "filePaths");
  //   console.log(filesObj, "filesObj");
  // }, [filePaths, filesObj]);

  const handleFileUpload = async () => {
    if (Object.values(filesObj).length === 0) return;
    const fileObj = filesObj[Object.values(filesObj)[0].fileIdentifier];
    await startFileUpload({ fileObj, filesObj });
    // setIsDisabled(true);
  };

  const maxCollectiveFileSizeFnErr = () => {
    toast.error("File size exceeds the maximum allowed size of 200 MB", {
      id: "file-size-error",
    });
  };
  useEffect(() => {
    setTrackedFilePaths(filePaths);
  }, [filePaths]);

  const handleFileChange = (files) => {
    const filesObj = filesChunksGenerator(files);
    setFilesObj(filesObj);
    setUploadStarted(false); // resetting the uploadstart flag
    // setIsDisabled(false);
  };

  return (
    <ToolUploadInput
      uploadStarted={uploadStarted}
      active_tool_type={active_tool_type}
      accept={
        active_tool_type === "flip"
          ? ".pptx,.docx"
          : active_tool_type === "read"
          ? ".pdf"
          : active_tool_type === "translate"
          ? ".docx,.xlsx, .pptx"
          : // : active_tool_type === "consult"
            // ? ".ppt"
            ""
      }
      setIsDisabled={setIsDisabled}
      isDisabled={isDisabled}
      files={files}
      setFiles={setFiles}
      file_text={file_text}
      handleChange={handleFileChange}
      maxFileSize={200}
      maxCollectiveFileSize={maxCollectiveFileSizeFnErr}
      maxFileSizeErrFn={maxCollectiveFileSizeFnErr}
      clear={!Object.keys(filesObj)}
      styleClass="flip_file_upload_class"
    />
  );
};

export default UploadSourceFile;
