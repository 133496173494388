import React from "react";
import { useDispatch } from "react-redux";
import "./ChatQueryLimitModal.scss";
import { setModal } from "../../../../store/utilsDataSlice/utilsDataSlice";
import TOOLS_MODALS from "../../../ToolsConstants/TOOLS_MODALS";
import { useNavigate } from "react-router-dom";

const ChatQueryLimitModal = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeModal = () => {
    dispatch(setModal(TOOLS_MODALS.NONE));
  };

  const handleRedirectToChatUpload = () => {
    navigate("/tools/consult-upload");
    dispatch(setModal(TOOLS_MODALS.NONE));
  };

  return (
    <section className="chat_query_limit_warning_modal">
      <div className="chat_query_limit_container chat_query_limit_warning-container">
        <div className="chat_query_limit_modal_container">
          <div className="chat_query_limit_modal_wrapper">
            <div className="chat_query_limit_modal_section">
              <div className="chat_query_limit_modal_heading_text">
                <span className="chat_query_limit_modal_main_text">
                  You have run out of available queries
                </span>
                <span className="chat_query_limit_modal_para_text">
                  You can start a new chat to continue asking more questions or
                  stay here and go through your chat and downlaod relevant
                  documents.
                </span>
              </div>
              <div className="chat_query_limit_modal_button_section">
                <button
                  className="chat_query_limit_modal_left_button"
                  onClick={handleRedirectToChatUpload}
                >
                  Start New Chat
                </button>
                <button
                  className="chat_query_limit_modal_right_button"
                  onClick={closeModal}
                >
                  Stay Here
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ChatQueryLimitModal;
