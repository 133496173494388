export const nameLimiter = (name, limit = 30, mobileLimit = 15) => {
  const reducedName = name ? String(name) : "-";

  const isMobile = window.innerWidth < 500;
  const maxLimit = isMobile ? mobileLimit : limit;

  let slicedName;
  if (reducedName.length <= maxLimit) {
    slicedName = reducedName;
  } else {
    slicedName = reducedName.slice(0, maxLimit) + "...";
  }

  return slicedName;
};
