import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import initialState from "./common";
import { getAuthData } from "../../helpers/request";

export const getProfileData = createAsyncThunk(
  "profile-data/getProfileData",
  async (_, thunkAPI) => {
    let url = `${process.env.REACT_APP_API_URL}/api-client/client/v1/profile/`;
    try {
      const res = await getAuthData(url);
      if (res.success) {
        return res?.data;
      } else {
        return thunkAPI.rejectWithValue(res?.message || "Profile Error!");
      }
    } catch (err) {
      throw thunkAPI.rejectWithValue("Something went wrong!");
    }
  },
);

const profileDataSlice = createSlice({
  name: "profile-data",
  initialState,
  reducers: {
    setProfileData: (state, { payload }) => {
      state.profile_data = payload;
    },
    setCompanyCode: (state, { payload }) => {
      state.comp_code = payload;
    },
    resetProfile: (state) => {
      state.profile_data = initialState.profile_data;
      state.comp_code = initialState.comp_code;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfileData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProfileData.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.profile_data = payload;
    });
    builder.addCase(getProfileData.rejected, (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    });
  },
});

export const { setProfileData, setCompanyCode, resetProfile } =
  profileDataSlice.actions;

export default profileDataSlice.reducer;
