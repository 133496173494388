export const data = [
  {
    heading: "Graphics & Video",
    services: [
      {
        serviceName: { name: "Presentation Design", src: "PresentationDesign" },
        redirectLink: "presentation-design-services",
        subServices: [
          { name: "Flipping & Formatting", src: "Flipping&Formatting" },
          { name: "Slide Design", src: "SlideDesign" },
          { name: "Report Design", src: "ReportDesign" },
          { name: "Animated Presentation", src: "AnimatedPresentation" },
          { name: "Interactive Presentation", src: "InteractivePresentation" },
        ],
      },
      {
        serviceName: { name: "Visual Graphics", src: "VisualGraphics" },
        redirectLink: "visual-graphics-services",
        subServices: [
          { name: "Iconography", src: "Iconography" },
          { name: "Infographic Design", src: "InfographicDesign" },
          { name: "HTML Designs", src: "HTMLDesigns" },
          { name: "Social Media Visuals", src: "SocialMediaVisuals" },
          { name: "Creative & Collateral", src: "Creative&Collateral" },
          { name: "UI/UX Design", src: "UXDesign" },
        ],
      },
      {
        serviceName: { name: "Animation Videos", src: "AnimationVideos" },
        redirectLink: "quick-turnaround-videos",
        subServices: [
          { name: "Stock Videos", src: "Stockvideos" },
          { name: "Character Animation", src: "CharacterAnimation" },
          { name: "Quick-turn 2D Animation", src: "Quickturn2DAnimation" },
          { name: "3D Animation", src: "3DAnimation" },
        ],
      },
      {
        serviceName: { name: "Film Production", src: "FilmProduction" },
        redirectLink: "",
        subServices: [
          { name: "Videography", src: "Videography" },
          { name: "Individual Interviews", src: "IndividualInterviews" },
          { name: "Corporate Films", src: "CorporateFilms" },
          { name: "Ads & Commercials", src: "Ads&Commercials" },
        ],
      },
    ],
  },
  {
    heading: "Language & Communication",
    services: [
      {
        serviceName: { name: "Language Services", src: "LanguageServices" },
        redirectLink: "language-services",
        subServices: [
          { name: "Translation", src: "Translation" },
          { name: "Proofreading", src: "Proofreading" },
          {
            name: "Transcription & Subtitling",
            src: "Transcription&Subtitling",
          },
          { name: "Voice Over", src: "VoiceOver" },
          { name: "Interpretation", src: "Interpretation" },
          { name: "Localization", src: "Localization" },
        ],
      },
      {
        serviceName: { name: "Content Creation", src: "ContentCreation" },
        redirectLink: "",
        subServices: [
          { name: "Blog Writing", src: "BlogWriting" },
          { name: "Article Writing", src: "ArticleWriting" },
          { name: "e-Commerce Content", src: "E-commerceContent" },
          { name: "Technical Writing", src: "TechnicalWriting" },
          { name: "Writing Projects", src: "WritingProjects" },
          { name: "Copywriting", src: "Copywriting" },
        ],
      },
      {
        serviceName: { name: "Digital Marketing", src: "DigitalMarketing" },
        redirectLink: "",
        subServices: [
          {
            name: "Search Engine Optimization",
            src: "SearchEngineOptimization",
          },
          { name: "Pay Per Click", src: "PayPerClick" },
          { name: "Social Media Marketing", src: "SocialMediaMarketing" },
        ],
      },
    ],
  },
  {
    heading: "Research & Data",
    services: [
      {
        serviceName: {
          name: "Research & Analytics",
          src: "Research&Analytics",
        },
        redirectLink: "research-and-analytics",
        subServices: [
          { name: "Desk Research", src: "DeskResearch" },
          { name: "Primary Research", src: "PrimaryResearch" },
          { name: "Global Surveys", src: "GlobalSurveys" },
          { name: "Sentiment Analysis", src: "SentimentAnalysis" },
          { name: "Expert Engagement", src: "ExpertEngagement" },
          { name: "Focus Groups", src: "FocusGroups" },
          { name: "Report Writing", src: "ReportWriting" },
        ],
      },
      {
        serviceName: { name: "Data Processing", src: "DataProcessing" },
        redirectLink: "",
        subServices: [
          { name: "Spreadsheet Engineering", src: "SpreadsheetEngineering" },
          { name: "Process Digitization", src: "ProcessDigitization" },
          { name: "Document Digitization", src: "DocumentDigitization" },
          { name: "Dashboarding", src: "Dashboarding" },
          { name: "Data Visualization", src: "DataVisualization" },
        ],
      },
    ],
  },
  {
    heading: "Technology & AI",
    services: [
      {
        serviceName: { name: "IT Support Desk", src: "ITSupportDesk" },
        redirectLink: "",
        subServices: [
          { name: "Cloud Management", src: "CloudManagement" },
          { name: "Remote Helpdesk", src: "RemoteHelpdesk" },
          { name: "Customer Support", src: "CustomerSupport" },
        ],
      },
      {
        serviceName: { name: "Tech Development", src: "TechDevelopment" },
        redirectLink: "",
        subServices: [
          { name: "Website Creation", src: "WebsiteCreation" },
          { name: "Product Documentation", src: "ProductDocumentation" },
          { name: "Product Testing", src: "ProductTesting" },
          { name: "No-code Development", src: "NoCodeDevelopment" },
          { name: "Mobile App Development", src: "MobileAppDevelopment" },
          { name: "Web App Development", src: "WebAppDevelopment" },
        ],
      },
      {
        serviceName: { name: "Applied AI", src: "AppliedAI" },
        redirectLink: "",
        subServices: [
          { name: "OpenAI Integration", src: "OpenAIIntegration" },
          { name: "AI Development", src: "AIDevelopment" },
          { name: "Process Optimization", src: "ProcessOptimization" },
        ],
      },
    ],
  },
  {
    heading: "Back-Office & Administrative",
    services: [
      {
        serviceName: { name: "Human Resources", src: "HumanResources" },
        redirectLink: "",
        subServices: [
          { name: "HR Operations", src: "HROperations" },
          { name: "Payroll Preparation", src: "PayrollPreparation" },
          { name: "Shared Recruiter", src: "ShareRecruiter" },
          { name: "Performance Review", src: "PerformanceReview" },
          { name: "Survey & Feedback", src: "SurveyandFeedback" },
        ],
      },
      {
        serviceName: { name: "Operations Support", src: "OperationsSupport" },
        redirectLink: "",
        subServices: [
          { name: "Shared EA", src: "SharedEA" },
          { name: "Accounting & Bookkeeping", src: "AccountingandBookkeeping" },
          { name: "Payment Processing", src: "PaymentProcessing" },
          { name: "Contact Centre", src: "ContactCentre" },
          { name: "No Code ERP", src: "NoCodeERP" },
        ],
      },
      {
        serviceName: { name: "Service Procurement", src: "ServiceProcurement" },
        redirectLink: "",
        subServices: [
          { name: "Legal Resources", src: "LegalResources" },
          { name: "Training & Development", src: "Training&Development" },
          { name: "Certifications", src: "Certifications" },
        ],
      },
    ],
  },
];
