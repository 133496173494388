import React from "react";
import "./ToolsLayout.scss";
import ToolsLeftSideNav from "../ToolsComponents/ToolsLeftSideNav/ToolsLeftSideNav";
import ToolsTopNav from "../ToolsComponents/ToolsTopNav/ToolsTopNav";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import TOOLS_PAGES from "../ToolsConstants/TOOLS_PAGE";
import FilesPane from "../ToolsComponents/FilesPane/FilesPane";
// import ToolsTabHomeNav from "../ToolsComponents/ToolsTabHomeNav/ToolsTabHomeNav";
import ToolsSidebarTabNav from "../ToolsComponents/ToolsSidebarTabNav/ToolsSidebarTabNav";

const ToolsLayout = ({ children }) => {
  const { tools_page, tools_show_mob_sidebar } = useSelector(
    (state) => state.utilsData,
  );

  const hideLogoutbox = (e) => {
    if (
      !e.target.closest(".st-client-user") &&
      !e.target.closest(".st-client-logout-box")
    ) {
      let userLogoutBox = document.querySelector(".st-client-logout-box");
      if (userLogoutBox) {
        userLogoutBox.style.display = "none";
      }
    }
  };

  return (
    <section className="tools_layout" onClick={hideLogoutbox}>
      <ToolsLeftSideNav />
      <ToolsTopNav />
      <div
        className={`tools_sidebar ${
          tools_show_mob_sidebar && "tools_sidebar-show"
        }`}
      >
        {(tools_page === TOOLS_PAGES.TAB_FILE_DASHBOARD ||
          tools_page === TOOLS_PAGES.TAB_FILE_UPLOAD ||
          tools_page === TOOLS_PAGES.TAB_HOME) && <FilesPane />}
        {tools_page === TOOLS_PAGES.HOME && <ToolsSidebarTabNav />}
      </div>
      <Outlet />
      {children && children}
    </section>
  );
};

export default ToolsLayout;
