import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addMessage,
  addNotificationsToFlow,
  handleChatDisbale,
  resetMessageEditMode,
  setEditMessageId,
  setEditMessageParentId,
  setEditMessageText,
  setIsMessageEditMode,
  updateChatFlowOnEdit,
  updateChatFlowOnThreadSwitch,
  updateLastestMessageId,
  updateMessageChildren,
} from "../../../../../store/consultgptDataSlice/consultgptDataSlice";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { lazy_load_image } from "../../../../..";
import { CHAT_MESSAGE_TYPE } from "../../../../../store/consultgptDataSlice/common";
import { v4 as uuidv4 } from "uuid";
import { setModal } from "../../../../../store/utilsDataSlice/utilsDataSlice";
import TOOLS_MODALS from "../../../../ToolsConstants/TOOLS_MODALS";
import { validateChatMessage } from "../../../../../utils/validateChatMessage";
import { nameLimiter } from "../../../../../utils/nameLimiter";

const UserMessage = ({ id, content }) => {
  const { chat_data, userMessageEdit, socket } = useSelector(
    (state) => state.consultGptData,
  );
  const { profile_data } = useSelector((state) => state.profileData);
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current && userMessageEdit?.isMessageEditMode) {
      const inputElement = inputRef.current;
      inputElement.focus();

      // Set the cursor to the end of the text
      const textLength = inputElement.value.length;
      inputElement.setSelectionRange(textLength, textLength);
    }
  }, [userMessageEdit?.isMessageEditMode]);

  const handleStartMessageEdit = (parentId, msgId, text) => {
    if (chat_data.is_chat_input_disabled) return;
    dispatch(setIsMessageEditMode(true));
    dispatch(setEditMessageText(text));
    dispatch(setEditMessageId(msgId));
    dispatch(setEditMessageParentId(parentId));
    dispatch(handleChatDisbale(true));
  };
  const handleEditChange = (e) => {
    dispatch(setEditMessageText(e.target.value));
  };

  const toggleChatDisable = (disable) => {
    dispatch(handleChatDisbale(disable));
  };

  const handleShowQueryLimitModal = () => {
    dispatch(setModal(TOOLS_MODALS.CHAT_QUERY_LIMIT));
  };

  const handleEditSave = (newText) => {
    const isValid = validateChatMessage(
      newText,
      chat_data,
      toggleChatDisable,
      handleShowQueryLimitModal,
    );

    if (!isValid) {
      return;
    }

    const { editMessageParentId, editMessageId } = userMessageEdit;
    const newId = uuidv4();
    const messageTimeStamp = Date.now();

    const newMessage = {
      uuid: newId,
      parent_message: editMessageParentId,
      child_message: [],
      type: CHAT_MESSAGE_TYPE.USER,
      content: newText,
      timestamp: new Date().toISOString(),
    };

    const messageObj = {
      query_type: "edit",
      current_node_id: newId,
      parent_node_id: editMessageParentId,
      child_node_id: uuidv4(),
      message_content: newText,
      timestamp: messageTimeStamp,
    };
    socket.emit("query", messageObj);
    dispatch(addMessage(newMessage));
    dispatch(
      updateMessageChildren({
        parentId: editMessageParentId,
        newChildId: newId,
      }),
    );

    const index = chat_data.currentChatFlow.indexOf(editMessageId);

    if (index !== -1) {
      const updatedFlow = [...chat_data.currentChatFlow.slice(0, index), newId];
      dispatch(updateChatFlowOnEdit(updatedFlow));
      dispatch(updateLastestMessageId(newId));
      dispatch(addNotificationsToFlow());
    }
    dispatch(resetMessageEditMode());
  };

  const handleEditSubmit = () => {
    handleEditSave(userMessageEdit.editMessageText, id);
  };

  const handleCancelEdit = () => {
    dispatch(setIsMessageEditMode(false));
    dispatch(resetMessageEditMode());
    dispatch(handleChatDisbale(false));
  };

  const getInitials = (profileData) => {
    const { first_name, last_name } = profileData;

    if (!first_name && !last_name) {
      return "UN"; // Return "UN" if both first_name and last_name are null
    }

    let initials = "";

    if (first_name) {
      initials += first_name[0].toUpperCase();
    }

    if (last_name) {
      initials += last_name[0].toUpperCase();
    }

    return initials;
  };

  const parentId = content?.parent_message;
  const parentMessage = parentId ? chat_data.messages[parentId] : null;
  const currentChildIndex = parentMessage
    ? parentMessage?.child_message.indexOf(id)
    : -1;
  const isFirstChild = currentChildIndex === 0;
  const isLastChild = parentMessage
    ? currentChildIndex === parentMessage?.child_message.length - 1
    : true;

  const handleSwitchThread = (parentId, newChildId) => {
    const newFlow = [];
    for (let id of chat_data.currentChatFlow) {
      newFlow.push(id);
      if (id === parentId) break;
    }
    newFlow.push(newChildId);
    let currentId = newChildId;
    let currentMessage = chat_data.messages[currentId];

    while (
      currentMessage &&
      currentMessage.child_message &&
      currentMessage.child_message.length > 0
    ) {
      const lastChildIndex = currentMessage.child_message.length - 1;
      currentId = currentMessage.child_message[lastChildIndex];
      if (currentId) {
        newFlow.push(currentId);
        currentMessage = chat_data.messages[currentId];
      } else {
        break;
      }
    }
    dispatch(updateChatFlowOnThreadSwitch(newFlow));
    dispatch(addNotificationsToFlow());
  };

  return (
    <div
      className={`${
        userMessageEdit?.isMessageEditMode &&
        userMessageEdit?.editMessageId === content?.uuid
          ? "tools_consult_reciever_edit_message_wrapper"
          : "tools_consult_reciever_message_wrapper"
      }`}
      key={content?.uuid}
      id={content?.uuid}
    >
      <div
        className={`${
          userMessageEdit?.isMessageEditMode &&
          userMessageEdit?.editMessageId === content?.uuid
            ? "tools_consult_reciever_edit_message_container"
            : "tools_consult_reciever_message_container"
        }`}
      >
        <div className="tools_consult_reciever_name_text_wrapper">
          <span>
            {profile_data?.first_name
              ? nameLimiter(profile_data.first_name)
              : ""}
            {profile_data?.first_name && profile_data?.last_name ? " " : ""}
            {profile_data?.last_name ? nameLimiter(profile_data.last_name) : ""}
            {!(profile_data?.first_name || profile_data?.last_name)
              ? "User Name"
              : ""}
          </span>
        </div>
        <div
          className={`${
            userMessageEdit?.isMessageEditMode &&
            userMessageEdit?.editMessageId === content?.uuid
              ? "tools_consult_user_message_edit_wrapper"
              : "tools_consult_user_message_tools_wrapper"
          }`}
        >
          {userMessageEdit?.isMessageEditMode &&
          userMessageEdit?.editMessageId === content?.uuid ? (
            <></>
          ) : (
            <div className="tools_consult_user_message_tools">
              <div className="tools_consult_message_edit_icon_wrapper">
                <img
                  onLoad={lazy_load_image}
                  src={process.env.PUBLIC_URL + "/Chat_edit_message_icon.svg"}
                  alt="consult.ai"
                  className="tools_consult_message_edit_icon"
                  onClick={() =>
                    handleStartMessageEdit(
                      content.parent_message,
                      content.uuid,
                      content?.content,
                    )
                  }
                  disabled={chat_data.is_chat_input_disabled}
                />
              </div>
            </div>
          )}
          <div className="tools_message_and_thread_switch_wrapper">
            <div
              className={`${
                userMessageEdit?.isMessageEditMode &&
                userMessageEdit?.editMessageId === content?.uuid
                  ? "tools_consult_reciever_text_message_edit_mode"
                  : "tools_consult_reciever_text_message"
              }`}
            >
              {userMessageEdit?.isMessageEditMode &&
              userMessageEdit?.editMessageId === content?.uuid ? (
                <div className="tools_consult_message_edit_wrapper">
                  <textarea
                    rows={5}
                    ref={inputRef}
                    className="tools_consult_message_edit_input"
                    value={userMessageEdit.editMessageText}
                    onChange={(e) => handleEditChange(e)}
                  />
                </div>
              ) : (
                <span>{content?.content}</span>
              )}
            </div>
            {!userMessageEdit?.isMessageEditMode &&
              userMessageEdit?.editMessageId !== content?.uuid && (
                <>
                  {parentMessage && parentMessage?.child_message.length > 1 && (
                    <div className="tools_consult_chat_flow_switch_btn_wrapper">
                      <button
                        onClick={() =>
                          handleSwitchThread(
                            parentId,
                            parentMessage?.child_message[currentChildIndex - 1],
                          )
                        }
                        className="tools_consult_chat_switch_btn"
                        disabled={isFirstChild}
                      >
                        <FaAngleLeft />
                      </button>
                      <span className="tools_consult_user_message_thread_info">
                        {currentChildIndex + 1}/
                        {parentMessage?.child_message.length}
                      </span>
                      <button
                        onClick={() =>
                          handleSwitchThread(
                            parentId,
                            parentMessage?.child_message[currentChildIndex + 1],
                          )
                        }
                        className="tools_consult_chat_switch_btn"
                        disabled={isLastChild}
                      >
                        <FaAngleRight />
                      </button>
                    </div>
                  )}
                </>
              )}
          </div>
          {userMessageEdit?.isMessageEditMode &&
            userMessageEdit?.editMessageId === content?.uuid && (
              <div className="tools_consult_message_edit_btn_wrapper">
                <button
                  onClick={handleCancelEdit}
                  className="tools_consult_message_cancel_submit_btn"
                >
                  Cancel
                </button>
                <button
                  onClick={handleEditSubmit}
                  className="tools_consult_message_edit_submit_btn"
                >
                  Submit
                </button>
              </div>
            )}
        </div>
      </div>
      <div className="tools_consult_reciever_image_container">
        <div className="tools_consult_reciever_image_wrapper">
          <span className="tools_consult_reciever_name_initials">
            {getInitials(profile_data)}
          </span>
        </div>
      </div>
    </div>
  );
};

export default UserMessage;
