import React, { useEffect, useState } from "react";
import "./ToolsModals.scss";
import TOOLS_MODALS from "../../ToolsConstants/TOOLS_MODALS";
import ToolsNewRequestModal from "./ToolsNewRequestModal/ToolsNewRequestModal";
import ToolsWalletModal from "./ToolsWalletModal/ToolsWalletModal";
import ToolsYoutubeModal from "./ToolsYoutubeModal/ToolsYoutubeModal";
import ToolsShareModal from "./ToolsShareModal/ToolsShareModal";
import ToolsShareWarningModal from "./ToolsShareWarningModal/ToolsShareWarningModal";
import ToolsShareRevokeModal from "./ToolsShareRevokeModal/ToolsShareRevokeModal";
import ToolsRevokeRequestErrorModal from "./ToolsRevokeRequestErrorModal/ToolsRevokeRequestErrorModal";
import ToolsDeclineRequestModal from "./ToolsDeclineRequestModal/ToolsDeclineRequestModal";
import ToolsRevokeRequestModal from "./ToolsRevokeRequestModal/ToolsRevokeRequestModal";
import ConsultResponseEditModal from "./ConsultResponseEditModal/ConsultResponseEditModal";
import ChatQueryLimitModal from "./ChatQueryLimitModal/ChatQueryLimitModal";
import LoaderModal from "./LoaderModal/LoaderModal";
import ConsultPdfPreviewPopUpModal from "./ConsultPdfPreviewPopUpModal/ConsultPdfPreviewPopUpModal";

const ToolsModals = ({ modal_type, tool_type }) => {
  const [overlay, setOverlay] = useState(true);

  useEffect(() => {
    switch (modal_type) {
      case TOOLS_MODALS.NEW_REQ:
        setOverlay(true);
        break;
      case TOOLS_MODALS.WALLET:
        setOverlay(false);
        break;
      case TOOLS_MODALS.YOUTUBE:
        setOverlay(true);
        break;
      case TOOLS_MODALS.SHARE_MODAL:
        setOverlay(true);
        break;
      case TOOLS_MODALS.SHARE_WARNING:
        setOverlay(true);
        break;
      case TOOLS_MODALS.SHARE_REVOKE:
        setOverlay(true);
        break;
      case TOOLS_MODALS.REVOKE_REQUEST_ERROR:
        setOverlay(true);
        break;
      case TOOLS_MODALS.DECLINE_REQUEST:
        setOverlay(true);
        break;
      case TOOLS_MODALS.REVOKE_REQUEST:
        setOverlay(true);
        break;
      case TOOLS_MODALS.CHAT_QUERY_LIMIT:
        setOverlay(true);
        break;
      case TOOLS_MODALS.CONSULT_RESPONSE_EDIT:
        setOverlay(true);
        break;
      case TOOLS_MODALS.LOADER:
        setOverlay(true);
        break;
      case TOOLS_MODALS.CONSULT_PDF_PREVIEW_POP_UP:
        setOverlay(true);
        break;
      default:
        break;
    }
  }, []);

  return (
    <section className={`tools_modal ${overlay && "tools_modal_overlay"}`}>
      {modal_type === TOOLS_MODALS.NEW_REQ && (
        <ToolsNewRequestModal tool_type={tool_type} />
      )}
      {modal_type === TOOLS_MODALS.WALLET && <ToolsWalletModal />}

      {modal_type === TOOLS_MODALS.YOUTUBE && (
        <ToolsYoutubeModal
          tool_type={tool_type}
          // onVideoClose={() => setOverlay(false)}
          // videoIframeUrl=""
        />
      )}
      {modal_type === TOOLS_MODALS.SHARE_MODAL && <ToolsShareModal />}
      {modal_type === TOOLS_MODALS.SHARE_WARNING && <ToolsShareWarningModal />}
      {modal_type === TOOLS_MODALS.SHARE_REVOKE && <ToolsShareRevokeModal />}
      {modal_type === TOOLS_MODALS.REVOKE_REQUEST_ERROR && (
        <ToolsRevokeRequestErrorModal />
      )}
      {modal_type === TOOLS_MODALS.DECLINE_REQUEST && (
        <ToolsDeclineRequestModal />
      )}
      {modal_type === TOOLS_MODALS.REVOKE_REQUEST && (
        <ToolsRevokeRequestModal />
      )}
      {modal_type === TOOLS_MODALS.CONSULT_RESPONSE_EDIT && (
        <ConsultResponseEditModal />
      )}
      {modal_type === TOOLS_MODALS.CHAT_QUERY_LIMIT && <ChatQueryLimitModal />}

      {modal_type === TOOLS_MODALS.LOADER && <LoaderModal />}
      {modal_type === TOOLS_MODALS.CONSULT_PDF_PREVIEW_POP_UP && (
        <ConsultPdfPreviewPopUpModal />
      )}
    </section>
  );
};

export default ToolsModals;
