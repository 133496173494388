import { useEffect, useState } from "react";
import { postData } from "../helpers/request";

export const useGetPerformanceReport = ({ screenName, isLoading }) => {
  const [startTime, setStartTime] = useState(0);
  const [loadTime, setLoadTime] = useState(0);
  const [loadTimeMsg, setLoadTimeMsg] = useState("");

  const logAccessPost = (load) => {
    let log_data = {
      stc_load_time: load,
      ip: null,
      project_name: "ST",
    };
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        log_data.ip = data.ip;
        // sending log
        postData(process.env.REACT_APP_LOG_API, log_data)
          .then(() => {})
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((error) => {
        postData(process.env.REACT_APP_LOG_API, log_data)
          .then(() => {})
          .catch((err) => {
            console.error(err);
          });
        console.log(error);
      });
  };

  const getPerformanceReport = () => {
    const endTime = window.performance.now();
    const timeToRender = endTime - startTime;
    const logMessage = `${screenName} Load Time: ${timeToRender.toFixed(
      0,
    )} milliseconds`;

    setLoadTime(+timeToRender.toFixed(0));
    setLoadTimeMsg(logMessage);
    // only log in production
    if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
      // dev code
    } else {
      logAccessPost(+timeToRender.toFixed(0));
      // production code
    }
    console.log(logMessage);
    //Send data to any logging tool such as Amplitude
  };

  useEffect(() => {
    setStartTime(window.performance.now());
    if (!isLoading) {
      getPerformanceReport();
    }
  }, [isLoading]);

  return {
    loadTime,
    loadTimeMsg,
  };
};
