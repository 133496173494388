import USPCard from "./USPCard";
import style from "./USPModels.module.scss";
import USPCardsData from "./USPCardsData";
const USPModels = () => {
  return (
    <div className={style.uspmodels_outerWrapper}>
      <div className={style.uspmodels_mainSection}>
        <div className={style.uspmodels_HeadingtextContainer}>
          <h2 className={style.uspmodels_Headingtext}>
            What makes us so special?
          </h2>
        </div>
        <div className={style.uspmodels_mainContainer}>
          <div className={style.uspmodels_leftmainContainer}>
            <h3 className={style.uspmodels_leftHeadingPara}>
              The 10-20-30 Rule at EZ
            </h3>
            <div className={style.uspmodels_roundclockMainSection}>
              <div className={style.uspmodels_clocktimemainWrapper}>
                <div
                  className={style.uspmodels_clockIndiviContainer}
                  id={style.uspmodels_specialStraightLine}
                >
                  <span
                    className={style.uspmodels_BulletPointsConatiner}
                  ></span>
                  <span
                    className={style.uspmodels_verticalLineContainer}
                  ></span>
                  <span
                    className={style.uspmodels_BulletPointsConatiner}
                  ></span>
                  <span
                    className={style.uspmodels_verticalLineContainer}
                  ></span>
                  <span
                    className={style.uspmodels_BulletPointsConatiner}
                  ></span>
                </div>
                <div className={style.uspmodels_clockIndiviContainer}>
                  <div
                    style={{ marginTop: "-2rem" }}
                    className={style.uspmodels_clocktimecontainer}
                  >
                    <h1>10</h1>Minutes
                  </div>
                  <div className={style.uspmodels_clocktimecontainer}>
                    <h1>20</h1>Minutes
                  </div>
                  <div
                    style={{ marginBottom: "-1.5rem" }}
                    className={style.uspmodels_clocktimecontainer}
                  >
                    <h1>30</h1>Minutes
                  </div>
                </div>
              </div>
              <div className={style.uspmodels_clockIndiviContainer}>
                <p
                  style={{ marginTop: "-2rem" }}
                  className={style.uspmodels_clockIndiviParagraph}
                >
                  Acknowledge Request
                </p>
                <p className={style.uspmodels_clockIndiviParagraph}>
                  Allocate Experts
                </p>
                <p
                  style={{ marginBottom: "-1.5rem" }}
                  className={style.uspmodels_clockIndiviParagraph}
                >
                  Begin Assignment
                </p>
              </div>
            </div>
          </div>
          <div className={style.uspmodels_centerMainContainer}></div>
          <div className={style.uspmodels_rightmainwrapperContainer}>
            <h2 className={style.uspmodels_CardsHeadingText}>
              What makes us so special?
            </h2>
            <div className={style.uspmodels_rightmainContainer}>
              {USPCardsData.map((item) => {
                return (
                  <USPCard
                    key={item.id}
                    Icon={item.Icon}
                    cardText={item.cardText}
                    hovertextContent={item.hovertextContent}
                    redirecturl={item.redirecturl}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default USPModels;
