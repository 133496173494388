export const commercialModalsData = [
  {
    id: 1,
    heading_text: "EZ Credits",
    model_desc: "Redeem Across Our Comprehensive Capability Center",
    banner_text: "Recurring Clients",
    image_src: "/HomePage/CommercialModels/EZ Credits.svg",
    featured_text: [
      { label: "Redeem at any time", text: "Redeem at any time" },
      { label: "Buy in bulk", text: "Buy in bulk" },
      {
        label: "Get free bonus",
        text: "Get free bonus",
      },
    ],
    cta_btn_text: "CLAIM EZ CREDITS",
  },
  {
    id: 2,
    heading_text: "Ad-Hoc Requests",
    model_desc: "Pay Only for What You Use",
    banner_text: "Urgent Requests",
    image_src: "/HomePage/CommercialModels/Ad-hoc.svg",
    featured_text: [
      { label: "Send requests at any hour", text: "Send requests at any hour" },
      { label: "Get invoiced as per MSLA", text: "Get invoiced as per MSLA" },
      {
        label: "Unlimited Capacity of SMEs",
        text: "Unlimited Capacity of SMEs",
      },
      {
        label: "Consistently High Quality at Moderate Price",
        text: "Consistently High Quality at Moderate Price",
      },
    ],
    cta_btn_text: "GET IN TOUCH",
  },
  {
    id: 3,
    heading_text: "Off-site Resources",
    model_desc: "Tap into Offsite Talent for Your Project Within 24 Hours",
    banner_text: "Remote Teams",
    image_src: "/HomePage/CommercialModels/Offsite Resources.svg",
    featured_text: [
      {
        label: "Assured quality of delivery & requires Minimal Oversight",
        text: "Assured quality of delivery & requires Minimal Oversight",
      },
      {
        label: "Flexible shift schedules to sync work hours with clients",
        text: "Flexible shift schedules to sync work hours with clients",
      },
      {
        label: "Leveraging EZ’s vast network of SMEs",
        text: "Leveraging EZ’s vast network of SMEs",
      },
      {
        label: "More Cost effective than On-site resources",
        text: "More Cost effective than On-site resources",
      },
    ],
    cta_btn_text: "GET IN TOUCH",
  },
];
