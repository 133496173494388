import React from "react";
import "./ToolsFileUpload.scss";
// import ToolInfo from "../../ToolsComponents/ToolInfo/ToolInfo";
import ToolsInfoMain from "../../ToolsComponents/ToolInfo/ToolsInfoMain";
import FileUploadMain from "../../ToolsComponents/FileUpload/FileUploadMain";
import { useSelector } from "react-redux";
import TOOLS_TYPE from "../../ToolsConstants/TOOLS_TYPE";
import ToolsConsultInfoBanner from "../../ToolsComponents/ToolInfo/ToolsConsultInfoBanner/ToolsConsultInfoBanner";
import ToolsConsultChatUpload from "../../ToolsComponents/ToolInfo/ToolsConsultChatUpload/ToolsConsultChatUpload";

const ToolsFileUpload = () => {
  const { active_tool_type } = useSelector((state) => state.utilsData);

  // console.log(active_tool_type, "active_tool_type");
  return (
    <section
      className={`${
        active_tool_type === TOOLS_TYPE.CONSULT
          ? "tools_consult_request_file_upload_main"
          : "tools_file_upload_main"
      }`}
    >
      {active_tool_type === TOOLS_TYPE.CONSULT ? (
        <>
          <ToolsConsultInfoBanner />
          <ToolsConsultChatUpload />
        </>
      ) : (
        <>
          <ToolsInfoMain />
          <FileUploadMain />
        </>
      )}
    </section>
  );
};

export default ToolsFileUpload;
