import { SHARE_MODAL_TYPE } from "../../utils/ShareModalType";

export const initialState = {
  sharedData: [],
  status: "idle",
  error: null,
  accessLogData: null,
  sharedLogData: null,
  shared_email: [],
  shared_res: {},
  from: null,
  share_modal_type: SHARE_MODAL_TYPE.NORMAL,
};
