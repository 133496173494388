import React, { useEffect, useState } from "react";
import styles from "./FeaturedArticles.module.scss";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import { getData } from "../../../helpers/request";
import Gradient from "../../../public_icons/HomePage/Icons/Gradient";
import { Link } from "react-router-dom";

const ButtonGroup = ({ next, previous }) => {
  return (
    <div className="carousel-button-group">
      <button
        onClick={() => previous()}
        className={styles.featured_article_carousel_btn_left}
      >
        <FaAngleLeft />
      </button>
      <button
        onClick={() => next()}
        className={styles.featured_article_carousel_btn_right}
      >
        <FaAngleRight />
      </button>
    </div>
  );
};

const FeaturedArticles = () => {
  const [isHoverable, setIsHoverable] = useState(false);
  const [latestArticleData, setLatestArticleData] = useState(null);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 2000 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 2000, min: 1200 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1199, min: 768 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
    },
  };

  const isHoverSupported = () => {
    // Check if the browser supports the 'hover' media feature
    const hoverMediaQuery = window.matchMedia("(hover: hover)");
    // Check if the browser supports the 'any-hover' media feature
    const anyHoverMediaQuery = window.matchMedia("(any-hover: hover)");
    // Return true if either 'hover' or 'any-hover' media feature is supported
    return hoverMediaQuery.matches || anyHoverMediaQuery.matches;
  };

  useEffect(() => {
    getLatestArticleData();
    // Check if the device supports hover interactions
    const hoverSupported = isHoverSupported();
    // If hover is supported, set the hoverState value to true
    if (hoverSupported === true) {
      setIsHoverable(true);
    } else {
      setIsHoverable(false);
    }
  }, []);

  const getLatestArticleData = () => {
    getData(`${process.env.REACT_APP_WEBSITE_URL}/api/web/v1/latest-article/`)
      .then((res) => {
        if (res.success === true) {
          setLatestArticleData(res.data);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <div className={styles.featured_articles_outer_container}>
      <div className={styles.top_gradient}>
        <Gradient />
      </div>
      <div className={styles.bottom_gradient}>
        <Gradient />
      </div>
      <div
        className={`container g-0 ${styles.featured_articles_content_wrapper}`}
        style={{ zIndex: "5" }}
      >
        <div className={styles.featured_articles_heading_wrapper}>
          <h2 className={styles.featured_articles_heading_text}>
            Articles You Should Read
          </h2>
        </div>
        <div className="row g-0">
          <div className={`col-md-12 ${styles.carousel_wrapper}`}>
            <div className={styles.carousel_movable_container}>
              <Carousel
                responsive={responsive}
                infinite="true"
                containerClass={styles.carousel_container_small}
                removeArrowOnDeviceType={["tablet", "mobile"]}
                arrows={false}
                renderButtonGroupOutside={true}
                customButtonGroup={<ButtonGroup />}
              >
                {latestArticleData === null ? (
                  <div></div>
                ) : (
                  latestArticleData.map((item, i) => {
                    return (
                      <div
                        className={styles.featured_article_outermost_container}
                        key={i}
                      >
                        <Link
                          to={`${process.env.REACT_APP_WEBSITE_URL}/${item?.slug}`}
                        >
                          <div
                            className={`${
                              isHoverable
                                ? styles.hoverable_featured_article_container
                                : styles.non_hovered_featured_article_container
                            }`}
                          >
                            <div
                              className={`${styles.display_card_wrapper} ${
                                isHoverable
                                  ? styles.display_card_hoverable
                                  : styles.display_card_non_hoverable
                              }`}
                            >
                              <div
                                className={`${
                                  isHoverable
                                    ? styles.display_card_image_wrapper
                                    : styles.non_hovered_display_card_image_wrapper
                                }`}
                              >
                                {item?.image?.split(".").pop() == "webm" ||
                                item?.image?.split(".").pop() == "mp4" ? (
                                  <video
                                    autoPlay
                                    muted
                                    loop
                                    playsInline
                                    className={styles.display_card_image}
                                  >
                                    <source src={item?.image} />
                                  </video>
                                ) : (
                                  <img
                                    className={styles.display_card_image}
                                    src={item?.image}
                                    alt={"featured article"}
                                  />
                                )}
                              </div>
                              <div
                                className={`${
                                  isHoverable
                                    ? styles.hovered_featured_article_text_wrapper
                                    : styles.non_hovered_featured_article_text_wrapper
                                }`}
                              >
                                <div
                                  className={
                                    styles.featured_article_heading_wrapper
                                  }
                                >
                                  <p
                                    className={
                                      styles.featured_article_heading_text
                                    }
                                  >
                                    {item?.title}
                                  </p>
                                </div>
                                <div
                                  className={`${
                                    isHoverable
                                      ? styles.featured_article_desc_wrapper
                                      : styles.non_hoverable_featured_article_desc_wrapper
                                  }`}
                                >
                                  <p
                                    className={`${
                                      isHoverable
                                        ? styles.hovered_featured_article_desc_text
                                        : styles.non_hovered_featured_article_desc_text
                                    }`}
                                  >
                                    {item?.content}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    );
                  })
                )}
              </Carousel>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeaturedArticles;
