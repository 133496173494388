import React, { useContext, useEffect, useRef, useState } from "react";
import { FaDownload, FaExternalLinkAlt, FaCopy } from "react-icons/fa";
import { MdSend } from "react-icons/md";
import {
  ApiContext,
  date_time_convertor,
  DevelopmentContext,
  DownloadFiles,
  emojis,
  FEEDBACK_EMOTIONS,
  FeedbackRatingEmoji,
  // getAccessLogData,
  DELIVERY_USER_TYPE,
  HomeRecentAsgnContext,
} from "../../../../..";
import { getAuthData, postAuthData } from "../../../../../helpers/request";
import {
  ClientArchiveFileContext,
  ClientDashboardFileContext,
  ClientFilesArrayContext,
  ClientRouteContext,
  CLIENT_ROUTES,
  FILE_ENCRYPTION_STATES,
  NotFoundErrorContext,
} from "../../../../ClientComponents";
import dummy_comments from "../../../../../archive/test_data/dummy_data-v1/dummy_comments.json";

import "./DeliveryTab.css";

const FileInfoCard = ({ activeTargetVersion }) => {
  return (
    <article className="file-info-section">
      <div className="file-info-head">
        <div className="file-info-head-title">
          Version {activeTargetVersion.version}
        </div>
        {/* <div className='file-info-head-feedback'><FeedbackRatingEmoji feedback={activeTargetVersion.overall_feedback}/></div> */}
      </div>
      <div className="file-info-item" id="file-info-delivery-notes">
        <div className="file-info-item-head">Delivery Notes</div>
        <div className="file-info-item-value">
          {activeTargetVersion.delivery_notes?.map((source, idx) => {
            return (
              <div className="delivery-notes" key={idx}>
                {source.names.map((name, idx) => (
                  <span key={idx} className="delivery-notes-file-name">
                    @{name}
                  </span>
                ))}
                <span className="delivery-note">{source.notes}</span>
              </div>
            );
          })}
        </div>
      </div>
    </article>
  );
};

// ========================= File Download Card ========================

// const TargetDownloadBtn = ({ext,link,name}) => {

//     const {setNotFoundError} = useContext(NotFoundErrorContext);

//     const DownloadTarget = (e) => {
//         const download_file_name = name.split('.').slice(0,-1).join('.')+"."+ext;   // making the file name with the extention provided
//         // DownloadFiles([{name:download_file_name,link:link}])
//         try{
//             DownloadFiles([{name:download_file_name,link:link}])
//         } catch (err) {
//             setNotFoundError(true)
//         }
//     }

//     return (
//         <span className='file-info-file-download-btn' onClick={DownloadTarget}>
//             <span className='file-info-file-download-btn-ext-text'>{ext.toUpperCase()}</span>
//             <span><FaDownload/></span>
//         </span>
//     )
// }

export const file_links_obj = (file_obj) => {
  /*
    This functions converts the links object to this:
        const obj1 = {
            name: "new_file.jpg",
            links: {
                ppt: "https://link.com",
                docx: "https://link2.com",
                external: "https://ex_link.com"
            }
        }
    to
        const obj3 = {
            file_links:[
                {
                    name: "new_file.ppt",
                    link: "https://link.com"
                },
                {
                    name: "new_file.docx",
                    link: "https://link2.com"
                }
            ],
            external_link:[
                {
                    name: "new_file.jpg",
                    link: "https://ex_link.com"
                },
            ]
        }
    */
  let file_link_obj = {
    file_links: [],
    external_link: [],
    external_text: [],
  };
  file_obj?.links &&
    Object.entries(file_obj?.links)?.forEach(([ext, link]) => {
      if (ext !== "external_link" && ext !== "external_text") {
        const download_file_name =
          file_obj.name.split(".").slice(0, -1).join(".") + "." + ext; // making the file name with the extention provided
        file_link_obj.file_links.push({
          name: download_file_name,
          link: link,
          ext,
        });
      } else if (ext === "external_link") {
        file_link_obj.external_link.push({
          name: file_obj.name,
          link: link,
          ext,
        });
      } else if (ext === "external_text") {
        file_link_obj.external_text.push({
          name: file_obj.name,
          text: link,
          ext,
        });
      }
    });
  return file_link_obj;
};

const DownloadBtnGroup = ({ file, link_obj, text_obj }) => {
  return (
    <>
      <div className="file-info-file-download-btn-group">
        {file_links_obj(file)?.external_link?.map((link_obj, i) => {
          return <FileExternalLink key={i} link_obj={link_obj} />;
        })}
        {file_links_obj(file)?.external_text?.map((text_obj, i) => {
          return <FileExternalText key={i} text_obj={text_obj} />;
        })}
        {/* <div className="file-info-file-download"> */}
        {file_links_obj(file)?.file_links?.map((link_obj, i) => {
          return <FileDownloadExtensions key={i} link_obj={link_obj} />;
        })}
        {/* </div> */}
      </div>
    </>
  );
};

const FileDownloadExtensions = ({ link_obj }) => {
  const { setNotFoundError } = useContext(NotFoundErrorContext);

  const DownloadTarget = () => {
    try {
      DownloadFiles([{ name: link_obj.name, link: link_obj?.link }]);
    } catch (err) {
      setNotFoundError(true);
    }
  };

  return (
    <div className="file-info-file-download-btn" onClick={DownloadTarget}>
      <span className="file-info-file-download-btn-ext-text">
        {link_obj.ext.toUpperCase()}
      </span>
      <span>
        <FaDownload />
      </span>
    </div>
  );
};

const FileExternalLink = ({ link_obj }) => {
  return (
    <a
      className="ext-link-btns file-info-file-download-btn"
      target="_blank"
      href={link_obj?.link}
      rel="noreferrer"
    >
      <span>External Link</span>
      <span>
        <FaExternalLinkAlt />
      </span>
    </a>
  );
};

const FileExternalText = ({ text_obj }) => {
  const copyTextRef = useRef(null);
  const copyBtnRef = useRef(null);
  const copyCode = () => {
    try {
      navigator.clipboard.writeText(text_obj.text);
      copyTextRef.current.innerText = "Code Copied!";
      copyBtnRef.current.classList.add("file-info-file-copy-btn-active");
      setTimeout(() => {
        copyTextRef.current.innerText = "Copy Code";
        copyBtnRef.current.classList.remove("file-info-file-copy-btn-active");
      }, 1000);
    } catch (error) {
      alert("Failed to copy");
    }
  };
  return (
    <span
      ref={copyBtnRef}
      className="ext-link-btns file-info-file-download-btn"
      onClick={copyCode}
    >
      <span ref={copyTextRef}>Copy Code</span>
      <span>
        <FaCopy />
      </span>
    </span>
  );
};

const TargetFile = ({ file, idx, feedbackFiles, setFeedbackFiles }) => {
  const route = useContext(ClientRouteContext);
  const [isChecked, setIsChecked] = useState(false);
  const { clientDisplayedFile } = useContext(ClientDashboardFileContext);
  // const { devEnv } = useContext(DevelopmentContext);

  const addFile = () => {
    setFeedbackFiles([...feedbackFiles, file]);
  };

  const removeFile = () => {
    let removedFileIdx = feedbackFiles.indexOf(file);
    if (removedFileIdx > -1) {
      // checking if the file even exists in the array of files.
      setFeedbackFiles((prevFiles) => {
        let new_arr = prevFiles.slice(); // using a copied array bcz if you use splice on prevFile then it would remove the file on the UI as well.
        new_arr.splice(removedFileIdx, 1);
        return new_arr;
      });
    }
  };

  const handleOnChange = () => {
    setIsChecked((prev) => {
      let checked = !prev;
      if (checked) {
        addFile(); // adding a file if checked
      } else {
        removeFile(); // removing a file if unchecked
      }
      return checked;
    });
  };

  useEffect(() => {
    feedbackFiles.includes(file) ? setIsChecked(true) : setIsChecked(false);
  }, [feedbackFiles]);

  return (
    <div className="file-info-file">
      <div className="file-info-file-name">
        {route === CLIENT_ROUTES.ASSIGNMENTS && (
          <input
            type="checkbox"
            value={idx}
            checked={isChecked}
            onChange={handleOnChange}
          />
        )}
        <div>{file.name}</div>
      </div>
      {route === CLIENT_ROUTES.ASSIGNMENTS &&
        clientDisplayedFile.file_stage === FILE_ENCRYPTION_STATES.DOWNLOAD &&
        (clientDisplayedFile?.delivery_user_type === DELIVERY_USER_TYPE.NONE ||
          clientDisplayedFile.delivery_user_type ===
            DELIVERY_USER_TYPE.DEFAULT) && (
          <div className="file-info-file-download">
            {file_links_obj(file)?.file_links?.map((link_obj, i) => {
              return <FileDownloadExtensions key={i} link_obj={link_obj} />;
            })}{" "}
          </div>
        )}

      {route === CLIENT_ROUTES.ASSIGNMENTS &&
        clientDisplayedFile.file_stage === FILE_ENCRYPTION_STATES.DOWNLOAD &&
        clientDisplayedFile?.delivery_user_type ===
          DELIVERY_USER_TYPE.EXTERNAL && <DownloadBtnGroup file={file} />}

      {/* {(route===CLIENT_ROUTES.ASSIGNMENTS && clientDisplayedFile.file_stage===FILE_ENCRYPTION_STATES.DOWNLOAD) && (clientDisplayedFile?.delivery_user_type===DELIVERY_USER_TYPE.EXTERNAL) && file_links_obj(file)?.external_link?.map((link_obj, i) => {
            return <FileExternalLink key={i} link_obj={link_obj} />; })}
          
             {(route===CLIENT_ROUTES.ASSIGNMENTS && clientDisplayedFile.file_stage===FILE_ENCRYPTION_STATES.DOWNLOAD) && (clientDisplayedFile?.delivery_user_type===DELIVERY_USER_TYPE.EXTERNAL) && file_links_obj(file)?.external_text?.map((text_obj, i) => {
            return <FileExternalText key={i} text_obj={text_obj} />; })} */}
    </div>
  );
};

const FileDownload = ({
  activeTargetVersion,
  feedbackFiles,
  setFeedbackFiles,
}) => {
  const route = useContext(ClientRouteContext);
  const { clientDisplayedFile } = useContext(ClientDashboardFileContext);
  const { setNotFoundError } = useContext(NotFoundErrorContext);
  const [isAllChecked, setIsAllChecked] = useState(false);

  const handleOnChange = () => {
    setIsAllChecked((prev) => {
      let checked = !prev;
      if (checked) {
        setFeedbackFiles(activeTargetVersion.files);
      } else {
        setFeedbackFiles([]);
      }
      return checked;
    });
  };

  const handleDownloadTargetAll = (e) => {
    e.preventDefault();
    // let allFileLinks = activeTargetVersion?.files?.map((file)=>{
    //     return Object.entries(file.links).map((link)=>{
    //         const filename = file.name.split('.').slice(0,-1).join('.')+'.'+link[0]
    //         return {link:link[1],name:filename}
    //     })
    // }).flat()

    let allFileLinks = activeTargetVersion?.files
      ?.map((file) => file_links_obj(file).file_links)
      .flat();

    let zip_link_arr = activeTargetVersion?.zip_link &&
      activeTargetVersion?.zip_link.name &&
      activeTargetVersion?.zip_link.link && [
        {
          name: activeTargetVersion?.zip_link?.name,
          link: activeTargetVersion?.zip_link?.link,
        },
      ];

    try {
      if (zip_link_arr) {
        DownloadFiles(zip_link_arr, true);
      } else {
        DownloadFiles(allFileLinks, true);
      }
    } catch (err) {
      setNotFoundError(true);
    }
  };

  useEffect(() => {
    feedbackFiles.length === activeTargetVersion.files.length
      ? setIsAllChecked(true)
      : setIsAllChecked(false);
  }, [feedbackFiles]);

  return (
    <article>
      <div className="file-info-item" id="file-info-files-list">
        <div className="file-info-item-head">
          <div className="file-info-file-name">
            {route === CLIENT_ROUTES.ASSIGNMENTS && (
              <input
                type="checkbox"
                name="all-files"
                value="all"
                checked={isAllChecked}
                onChange={handleOnChange}
              />
            )}
            <span>Files</span>
          </div>
          {route === CLIENT_ROUTES.ASSIGNMENTS &&
            clientDisplayedFile?.file_stage ==
              FILE_ENCRYPTION_STATES.DOWNLOAD &&
            activeTargetVersion?.files?.every((file) => {
              return file?.links && Object.entries(file?.links)?.length > 0;
            }) /* Checks if all links are present only then Downlaod all will appear */ && (
              <button
                className="client-btns file-info-file-download-btn"
                id="file-info-download-all-btn"
                onClick={handleDownloadTargetAll}
              >
                <span>Download All</span>
                <span>
                  <FaDownload />
                </span>
              </button>
            )}
        </div>
        <div className="file-info-item-value" id="file-info-files-container">
          {activeTargetVersion?.files.map((file, idx) => {
            return (
              <TargetFile
                key={idx}
                file={file}
                idx={idx}
                feedbackFiles={feedbackFiles}
                setFeedbackFiles={setFeedbackFiles}
              />
            );
          })}
        </div>
      </div>
    </article>
  );
};

// =========================== Feedback Card ===========================

const RatingContainer = (props) => {
  const { setClientFilesArray } = useContext(ClientFilesArrayContext);
  const { clientDisplayedFile, setClientDisplayedFile } = useContext(
    ClientDashboardFileContext,
  );
  const { setUserId } = useContext(HomeRecentAsgnContext);
  const { setNotFoundError } = useContext(NotFoundErrorContext);
  const default_val = props?.default_val;
  const feedbackFiles = props.feedbackFiles;
  const API_URL = useContext(ApiContext);
  const [selectedEmotion, setSelectedEmotion] = useState(null);
  const [hoverEmotion, setHoverEmotion] = useState(null);
  const assignmentId = clientDisplayedFile.batch_id;

  const sendData = (data) => {
    postAuthData(`${API_URL}/api-client/misc/v1/feedback/`, data)
      .then((res) => {
        if (res.success === true) {
          getAuthData(
            `${API_URL}/api-client/client/v1/all-assignments/?id=${clientDisplayedFile?.id}`,
          )
            .then((res) => {
              if (res.success === true) {
                setClientFilesArray(res.data);
                setClientDisplayedFile(res.last_assignment);
                setUserId(res.last_assignment);
              }
            })
            .catch((err) => {
              setNotFoundError(true);
            });
        }
      })
      .catch((err) => {
        setNotFoundError(true);
      });
  };

  const activateEmoji = (e, key) => {
    const feedback = e.target
      .closest(".client-feedback-question")
      .querySelector(".client-feedback-label").dataset.feedback;
    if (feedbackFiles.length === 0) {
      props.setRatingEmptyError("No File Selected for Feedback");
      return;
    }
    e.preventDefault();
    if (default_val) return; // this line prevents hover action when a default value is already available
    setSelectedEmotion(key);

    let qlty = "";
    [feedback] == "quality" ? (qlty = "output") : (qlty = "service");
    const data = {
      type: "feedback",
      quality_type: qlty,
      quality_value: key,
      delivery_id: assignmentId,
    };
    // const feedback_data = feedbackFiles.map(({name})=>{return {...data,filename:name}});
    // feedback_data.map((cur)=>sendData(cur));
    const feedback_data = {
      ...data,
      filename: feedbackFiles.map((cur) => cur.name),
    };
    sendData(feedback_data);

    props.setFeedbackState((prev) => {
      return { ...prev, [feedback]: key };
    });
    // REMOVING ERRORS
    props.setRatingEmptyError(false);
  };

  const hoverEmoji = (e, key) => {
    const feedback = e.target
      .closest(".client-feedback-question")
      .querySelector(".client-feedback-label").dataset.feedback;
    e.preventDefault();
    props.setRatingEmptyError(false);
    // if (default_val) return         // this line prevents hover action when a default value is already available
    if (e.type === "mouseenter") {
      setHoverEmotion(key);
      props.setFeedbackState((prev) => {
        return { ...prev, [feedback]: key };
      });
    } else if (e.type === "mouseleave") {
      setHoverEmotion(null);
      props.setFeedbackState((prev) => {
        return { ...prev, [feedback]: selectedEmotion };
      });
    }
  };

  useEffect(() => {
    setSelectedEmotion(FEEDBACK_EMOTIONS.EMPTY);
    setHoverEmotion(null);
  }, [clientDisplayedFile]);

  useEffect(() => {
    if (feedbackFiles.length === 0) {
      setSelectedEmotion(FEEDBACK_EMOTIONS.EMPTY);
      setHoverEmotion(null);
    }
  }, [feedbackFiles]);

  return (
    <div className="client-feedback-rating-container">
      {Object.keys(emojis).map((key, i) => {
        return (
          <label
            className="rating-label"
            key={i}
            onMouseEnter={(e) => {
              hoverEmoji(e, key);
            }}
            onMouseLeave={(e) => {
              hoverEmoji(e, null);
            }}
          >
            <input
              type="radio"
              name="rating"
              onClick={(e) => {
                activateEmoji(e, key);
              }}
            />
            {
              // (default_val === key) ? (<div className="client-feedback-emoji">{emojis[default_val].active}</div>) :
              !hoverEmotion ? (
                selectedEmotion === key ? (
                  <div className="client-feedback-emoji">
                    {emojis[selectedEmotion].active}
                  </div>
                ) : (
                  <div className="client-feedback-emoji">
                    {emojis[key].normal}
                  </div>
                )
              ) : hoverEmotion === key ? (
                <div className="client-feedback-emoji">
                  {emojis[hoverEmotion].active}
                </div>
              ) : (
                <div className="client-feedback-emoji">
                  {emojis[key].normal}
                </div>
              )
            }
          </label>
        );
      })}
    </div>
  );
};

const FileFeedbackForm = ({ feedbackFiles, setFeedbackFiles }) => {
  const { clientDisplayedFile, setClientDisplayedFile } = useContext(
    ClientDashboardFileContext,
  );
  const { setUserId } = useContext(HomeRecentAsgnContext);
  const assignmentId = clientDisplayedFile.batch_id;
  const API_URL = useContext(ApiContext);
  const { setNotFoundError } = useContext(NotFoundErrorContext);
  const [ratingEmptyError, setRatingEmptyError] = useState(false);

  const commentsRef = useRef();

  const [qualityOpt, setQualityOpt] = useState(null);

  const [serviceOpt, setServiceOpt] = useState(null);

  const [feedbackState, setFeedbackState] = useState({
    quality: 0,
    service: 0,
  });

  const setValues = () => {
    switch (feedbackState.quality) {
      case Object.keys(emojis)[0]:
        setQualityOpt("Positive");
        break;
      case Object.keys(emojis)[1]:
        setQualityOpt("Neutral");
        break;
      case Object.keys(emojis)[2]:
        setQualityOpt("Negative");
        break;
      default:
        setQualityOpt(FEEDBACK_EMOTIONS.EMPTY);
        break;
    }

    switch (feedbackState.service) {
      case Object.keys(emojis)[0]:
        setServiceOpt("Positive");
        break;
      case Object.keys(emojis)[1]:
        setServiceOpt("Neutral");
        break;
      case Object.keys(emojis)[2]:
        setServiceOpt("Negative");
        break;
      default:
        setServiceOpt(FEEDBACK_EMOTIONS.EMPTY);
        break;
    }
  };

  useEffect(() => {
    // This clears the selection if some other file us selected
    setFeedbackState((prev) => {
      return {
        ...prev,
        quality: FEEDBACK_EMOTIONS.EMPTY,
        service: FEEDBACK_EMOTIONS.EMPTY,
      };
    });
    setValues();
  }, [clientDisplayedFile]);

  useEffect(setValues, [feedbackState]); // This updates the Remarks and Overall values on real time

  const sendData = (data) => {
    postAuthData(`${API_URL}/api-client/misc/v1/feedback/`, data)
      .then((res) => {
        if (res.success === true) {
          getAuthData(
            `${API_URL}/api-client/client/v1/all-assignments/${clientDisplayedFile?.id}/`,
          )
            .then((res) => {
              if (res.success === true) {
                setClientDisplayedFile(res.last_assignment);
                setUserId(res.last_assignment);
              }
            })
            .catch((err) => {
              setNotFoundError(true);
            });
        }
      })
      .catch((err) => {
        setNotFoundError(true);
      });
  };

  const clearFeedbackSelections = () => {
    // setFeedbackFiles([]);   // removing feedback files from the list
    commentsRef.current.value = ""; // Clearing Comments
    setFeedbackState({ quality: 0, service: 0 });
  };

  const validation = () => {
    if (commentsRef?.current?.value.trim().length < 1) {
      alert("Please write some Comment");
      clearFeedbackSelections();
      return true;
    } else {
      return false;
    }
  };

  const handleKeyDown = (e) => {
    if (e.which === 13 && !e.shiftKey) {
      e.preventDefault();
      handleSubmit();
    }
  };

  const handleSubmit = (e) => {
    // e.preventDefault();
    if (validation()) {
      return;
    }
    const data = {
      message: commentsRef.current.value,
      type: "send",
      delivery_id: assignmentId,
    };
    // const feedback_data = feedbackFiles.map(({name})=>{return {...data,filename:name}});    // Attaching feedback values to the feedback files
    const feedback_data = {
      ...data,
      filename: feedbackFiles.map((cur) => cur.name),
    };
    // Validation check
    if (
      data.quality === FEEDBACK_EMOTIONS.EMPTY &&
      data.service === FEEDBACK_EMOTIONS.EMPTY
    ) {
      setRatingEmptyError("Please fill one rating.");
    } else {
      setRatingEmptyError(false);
      sendData(feedback_data);
      clearFeedbackSelections();
    }
  };

  return (
    <article className="file-feedback-section">
      <form onSubmit={handleSubmit}>
        <div className="client-feedback-input">
          <div className="client-feedback-question" id="client-service-feeback">
            <label className="client-feedback-label" data-feedback="service">
              How was your experience with our service quality?
            </label>
            <div className="client-feedback-rating">
              <RatingContainer
                setFeedbackState={setFeedbackState}
                setRatingEmptyError={setRatingEmptyError}
                feedbackFiles={feedbackFiles}
              />
              <label
                className="client-feedback-text"
                htmlFor="distinctiveFeedback"
                data-feedback="distinctVal"
              >
                {serviceOpt === FEEDBACK_EMOTIONS.EMPTY ? "" : serviceOpt}
              </label>
              {ratingEmptyError && (
                <div className="client-feedback-rating-error">
                  {ratingEmptyError}
                </div>
              )}
            </div>
          </div>

          <div className="client-feedback-question" id="client-quality-feeback">
            <label className="client-feedback-label" data-feedback="quality">
              How would you rate the quality of the output?
            </label>
            <div className="client-feedback-rating">
              <RatingContainer
                setFeedbackState={setFeedbackState}
                setRatingEmptyError={setRatingEmptyError}
                feedbackFiles={feedbackFiles}
              />
              <label
                className="client-feedback-text"
                htmlFor="expectationsFeedback"
                data-feedback="expectVal"
              >
                {qualityOpt === FEEDBACK_EMOTIONS.EMPTY ? "" : qualityOpt}
              </label>
              {ratingEmptyError && (
                <div className="client-feedback-rating-error">
                  {ratingEmptyError}
                </div>
              )}
            </div>
          </div>

          <div className="client-message-feedback">
            <div id="client-feedback-comment">
              <textarea
                className="client-feedback-comment"
                ref={commentsRef}
                id="commentsFeedback"
                name="commentsFeedback"
                placeholder="Comments (Select a checkbox above to share comments on a specific file)"
                // autoFocus stop autoficusing on tab change
                onKeyDown={handleKeyDown}
              />
            </div>
            <MdSend
              className="client-feedback-submit-btn"
              type="submit"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </form>
    </article>
  );
};

// ======================= COMMENTS Section ===========================
const COMMENTS_TYPE = {
  NOTIFICATION: "notification",
  SEND: "send",
  RECEIVE: "receive",
  FEEDBACK: "feedback",
};

const EmptyComment = () => {
  return <div>There are no Comments yet on this assignment!</div>;
};

const UnknownComment = ({ comment }) => {
  return (
    <div className="comment-msg comment-msg-unknown glass-container">
      An unknown type comment received.
    </div>
  );
};

const FeedbackComment = ({ comment }) => {
  return (
    <div className="comment-msg comment-msg-feedback glass-container">
      <div className="comment-msg-user">{comment.user}</div>
      <div className="comment-msg-date">
        {date_time_convertor(comment?.datetime)}
      </div>
      <div className="comment-msg-feedback-file">
        {comment?.message?.filename?.map((name, i) => {
          return <span key={i}>{name}</span>;
        })}
      </div>
      <div className="comment-msg-feedback-container">
        {comment?.message?.service && (
          <div className="comment-feedback-quality comment-msg-service-feedback">
            <span className="comment-feedback-quality-label">
              Quality of Service
            </span>
            <FeedbackRatingEmoji feedback={comment?.message?.service} />
          </div>
        )}
        {comment?.message?.output && (
          <div className="comment-feedback-quality comment-msg-output-feedback">
            <span className="comment-feedback-quality-label">
              Quality of Output
            </span>
            <FeedbackRatingEmoji feedback={comment?.message?.output} />
          </div>
        )}
      </div>
      {/* <div className='comment-msg-chat-text'>{comment?.message?.comments}</div> */}
    </div>
  );
};

const SentComment = ({ comment }) => {
  return (
    <div className="comment-msg comment-msg-chat comment-msg-sent glass-container">
      <div className="comment-msg-user">{comment?.user}</div>
      <div className="comment-msg-date">
        {date_time_convertor(comment?.datetime)}
      </div>
      <div className="comment-msg-feedback-file">
        {comment?.message?.filename?.map((name, i) => {
          return <span key={i}>{name}</span>;
        })}
      </div>
      <div className="comment-msg-chat-text">{comment?.message?.chat}</div>
    </div>
  );
};

const ReceivedComment = ({ comment }) => {
  return (
    <div className="comment-msg comment-msg-chat comment-msg-receive glass-container">
      <div className="comment-msg-user">{comment?.user}</div>
      <div className="comment-msg-date">
        {date_time_convertor(comment?.datetime)}
      </div>
      <div className="comment-msg-feedback-file">
        {comment?.message?.filename?.map((name, i) => {
          return <span key={i}>{name}</span>;
        })}
      </div>
      <div className="comment-msg-chat-text">{comment?.message?.chat}</div>
    </div>
  );
};

const NotificationComment = ({ comment }) => {
  return (
    <div className="comment-msg comment-msg-notification glass-container">
      <div className="comment-msg-date">
        {date_time_convertor(comment?.datetime)}
      </div>
      <div className="comment-msg-chat-text">{comment?.message?.chat}</div>
    </div>
  );
};

const CommentsSection = ({ displayedFile, page }) => {
  const { devEnv } = useContext(DevelopmentContext);
  const API_URL = useContext(ApiContext);
  const [comments, setComments] = useState(null);
  const [fetching, setFetching] = useState(null); // eslint-disable-line no-unused-vars

  const getComments = () => {
    getAuthData(
      `${API_URL}/api-client/${page}/v1/feedback/${displayedFile?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setComments(res.comments);
          setFetching(null); // must be below setting comments
        }
      })
      .catch((err) => {
        setFetching("Can't fetch comments!");
      });
  };

  useEffect(() => {
    setFetching("Loading Comments..."); // Setting a loader

    if (devEnv) {
      setFetching(null);
      setComments(dummy_comments.comments); // Setting Dummy Data
      return;
    }

    // Re-rendering to comments
    displayedFile?.batch_id && getComments();
  }, [displayedFile]);

  return (
    <div className="comments-section glass-container-active">
      {/* {(fetching) && <span>{fetching}</span>} */}
      {comments &&
        comments?.length > 0 &&
        comments?.map((comment, idx) => {
          switch (comment.type) {
            case COMMENTS_TYPE.FEEDBACK:
              return <FeedbackComment key={idx} comment={comment} />;
            case COMMENTS_TYPE.SEND:
              return <SentComment key={idx} comment={comment} />;
            case COMMENTS_TYPE.RECEIVE:
              return <ReceivedComment key={idx} comment={comment} />;
            case COMMENTS_TYPE.NOTIFICATION:
              return <NotificationComment key={idx} comment={comment} />;
            default:
              return <UnknownComment key={idx} comment={comment} />;
          }
        })}
      {comments && comments?.length < 1 && <EmptyComment />}
    </div>
  );
};

const FileFeedback = ({ displayedFile, feedbackFiles, setFeedbackFiles }) => {
  const route = useContext(ClientRouteContext);
  return (
    <article className="file-feedback-section">
      <div className="file-info-item" id="file-info-delivery-notes">
        <div className="file-info-item-head">Feedback</div>
        {route === CLIENT_ROUTES.ASSIGNMENTS && (
          <FileFeedbackForm
            feedbackFiles={feedbackFiles}
            setFeedbackFiles={setFeedbackFiles}
          />
        )}
        {route === CLIENT_ROUTES.ASSIGNMENTS && (
          <CommentsSection
            displayedFile={displayedFile}
            feedbackFiles={feedbackFiles}
            page={`misc`}
          />
        )}
        {route === CLIENT_ROUTES.ARCHIVE && (
          <CommentsSection
            displayedFile={displayedFile}
            feedbackFiles={feedbackFiles}
            page={`archive`}
          />
        )}
      </div>
    </article>
  );
};

// ======================= Delivery tab UI ==================================

const DeliveryTab = ({ file }) => {
  const [activeTargetVersion, setActiveTargetVersion] = useState(
    file.target_versions.find((cur) => cur.version == file.current_version),
  );
  const [feedbackFiles, setFeedbackFiles] = useState([]);

  const [displayedFile, setDisplayedFile] = useState(file);
  const route = useContext(ClientRouteContext);
  const { clientDisplayedFile } = useContext(ClientDashboardFileContext);
  const { clientArchiveFile } = useContext(ClientArchiveFileContext);

  useEffect(() => {
    if (route === CLIENT_ROUTES.ASSIGNMENTS) {
      setDisplayedFile(clientDisplayedFile);
      setActiveTargetVersion(
        clientDisplayedFile.target_versions.find(
          (cur) => cur.version == clientDisplayedFile.current_version,
        ),
      );
    } else if (route === CLIENT_ROUTES.ARCHIVE) {
      setDisplayedFile(clientArchiveFile);
      setActiveTargetVersion(
        clientArchiveFile.target_versions.find(
          (cur) => cur.version == clientArchiveFile.current_version,
        ),
      );
    }
  }, [clientDisplayedFile, clientArchiveFile]);

  // we use the client id in this useEffect bcz we want to clear the files selected only when the id changes (and there is a all assignments API call when feedback is pushed which can trigger an update and clear the feedbackFiles State)
  // useEffect(() => {
  //     setFeedbackFiles([])
  // }, [clientDisplayedFile?.id,clientArchiveFile?.id])

  useEffect(() => {
    setActiveTargetVersion(
      file.target_versions.find((cur) => cur.version == file.current_version),
    );
    setFeedbackFiles([]);
  }, []);

  useEffect(() => {
    setFeedbackFiles([]);
  }, [clientDisplayedFile, clientArchiveFile]); // This clears the selection if some other file is selected

  return (
    <section className="delivery-tab-section glass-container-active">
      <FileInfoCard
        displayedFile={displayedFile}
        activeTargetVersion={activeTargetVersion}
      />
      <FileDownload
        displayedFile={displayedFile}
        activeTargetVersion={activeTargetVersion}
        feedbackFiles={feedbackFiles}
        setFeedbackFiles={setFeedbackFiles}
      />
      <FileFeedback
        displayedFile={displayedFile}
        activeTargetVersion={activeTargetVersion}
        feedbackFiles={feedbackFiles}
        setFeedbackFiles={setFeedbackFiles}
      />
    </section>
  );
};

export default DeliveryTab;
