import React from "react";
import styles from "./TestimonialCarousel.module.scss";
import { ClientTestimonialsData } from "../TestimonialData";
import TestimonialCard from "../TestimonialCard/TestimonialCard";
import Carousel from "react-multi-carousel";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import "react-multi-carousel/lib/styles.css";
import HomepageQuoteIconOne from "../../../../public_icons/Homepage/Icons/QuoteIcon";

const TestimonialCarousel = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 3.5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 3.5,
    },
    tablet: {
      breakpoint: { max: 1199, min: 1000 },
      items: 2.5,
    },
    tabletPotrait: {
      breakpoint: { max: 999, min: 768 },
      items: 1.5,
    },
    midDevice: {
      breakpoint: { max: 700, min: 501 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 500, min: 0 },
      items: 1,
    },
  };

  const ButtonGroup = ({ next, previous }) => {
    return (
      <div className={styles.testimonial_button_container}>
        <div className={styles.testimonial_button_wrapper}>
          <button
            onClick={() => previous()}
            className={`${styles.testimonial_button}`}
            name="previous"
          >
            <FaAngleLeft className={styles.button_icon} />
          </button>
          <button
            onClick={() => next()}
            className={`${styles.testimonial_button}`}
            name="next"
          >
            <FaAngleRight className={styles.button_icon} />
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.testimonial_outer_container}>
      <div className={` ${styles.testimonial_content_wrapper}`}>
        <div className={styles.testimonial_section_heading_wrapper}>
          <div className={styles.quote_icon_wrapper}>
            <HomepageQuoteIconOne />
          </div>
          <h2 className={styles.testimonial_section_heading_text}>
            What our clients say about us
          </h2>
        </div>
        <div style={{ height: "100%" }}>
          <div className={styles.testimonial_carousel_movable_container}>
            <Carousel
              responsive={responsive}
              infinite="true"
              containerClass="carousel-container-small"
              removeArrowOnDeviceType={["tablet", "mobile"]}
              arrows={false}
              renderButtonGroupOutside={true}
              customButtonGroup={<ButtonGroup />}
            >
              {ClientTestimonialsData.map((details) => {
                return (
                  <div
                    className={styles.testimonial_card_carousel_wrapper}
                    key={details.id}
                  >
                    <TestimonialCard details={details} />
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialCarousel;
