import React, { useState, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import {
  resetModal,
  setModal,
} from "../../../../store/utilsDataSlice/utilsDataSlice";
import TOOLS_MODALS from "../../../ToolsConstants/TOOLS_MODALS";
import { useDispatch, useSelector } from "react-redux";
import { getAuthData, postAuthData } from "../../../../helpers/request";
import { useParams } from "react-router-dom";
import {
  setAccessLogData,
  setFrom,
  setShareModalType,
  setSharedLogData,
  setSharedRes,
} from "../../../../store/userSharedata/userShareDataSlice";
import "./ToolsShareModal.scss";
import { SHARE_RESPONSE_STATUS } from "../../../../utils/ShareResponseStatus";
import { CLIENT_USER_TYPE, getAccessLogData } from "../../../..";
import { SHARE_MODAL_TYPE } from "../../../../utils/ShareModalType";
import useDebounceEffect from "../../../../hooks/useDebounceEffect";
import toast from "react-hot-toast";

export const renderShareModalContext = React.createContext(); // this is to show share modal and share warning in same modal
export const ShareWarningDataContext = React.createContext(); // this is to show response when status: "warning"
export const SharedInputNamesContext = React.createContext(); // this is to show input emails in cards
export const RevokeShareDataContext = React.createContext(); // this is to store revoke share request data

const ToolsShareModal = () => {
  // states used
  const [shareNameInput, setShareNameInput] = useState(null);
  const suggestionContainerRef = useRef(null);
  const shareModalRef = useRef(null);
  const sharedNameInputRef = useRef(null);
  const [shareError, setShareError] = useState(null);
  const [shareNameSuggestions, setShareNameSuggestions] = useState([]);
  const [sharedNames, setSharedNames] = useState([]);
  const [shareErrNameArr, setShareErrNameArr] = useState([]);
  const [shareWarningData, setSharewarningData] = useState(); // eslint-disable-line no-unused-vars
  // const [revokeShareData, setRevokeShareData] = useState(null);
  const [disableShareBtn, setDisableShareBtn] = useState(false);
  const dispatch = useDispatch();
  const { asgn_id } = useParams();
  const maxSharingNames = 10;
  const { tools_details_data } = useSelector((state) => state.toolsData);

  // ref used
  const shareSubmitBtn = useRef(null);
  const closeSharingModal = (e) => {
    e?.preventDefault();
    dispatch(resetModal());
  };

  const openShareWarningModal = (res) => {
    dispatch(setModal(TOOLS_MODALS.SHARE_WARNING));
    dispatch(setSharedRes(res));
    dispatch(setFrom("share"));
    dispatch(setShareModalType(SHARE_MODAL_TYPE.WARNING));
  };

  const openShareRevokeModal = (sharedNames) => {
    dispatch(setModal(TOOLS_MODALS.SHARE_REVOKE));
    dispatch(setSharedRes(sharedNames));
    dispatch(setShareModalType(SHARE_MODAL_TYPE.REVOKE));
  };

  const addSuggestion = (e) => {
    if (sharedNames.length === maxSharingNames) {
      setShareError(`Only ${maxSharingNames} names can be shared at a time.`);
      return;
    } // setting a limit for names
    const emailRegex = /^\w*(\.|-)?\w+@\w*(\.|-)?\w+\.\w*$/g;
    const share_form_input = sharedNameInputRef.current.value?.toLowerCase();
    const suggested_item = e?.target.innerText?.toLowerCase();
    setShareError(null); // clears the error message before submitting and checcking
    // only submit if its an email
    if (suggested_item && sharedNames.includes(suggested_item)) {
      setShareError("Email already added.");
      return;
    } else if (suggested_item && emailRegex.test(suggested_item)) {
      setSharedNames([...sharedNames, suggested_item]);
      clearShareInput();
      return;
    } else if (!suggested_item && sharedNames.includes(share_form_input)) {
      setShareError("Email already added.");
      return;
    } else if (!suggested_item && emailRegex.test(share_form_input)) {
      setSharedNames([...sharedNames, share_form_input]);
      clearShareInput();
      return;
    } else {
      setShareError("Please enter an email.");
      return;
    }
  };

  const removeName = (e, idx) => {
    // instantly change the state when remove
    setSharedNames([
      ...sharedNames.slice(0, idx),
      ...sharedNames.slice(idx + 1, sharedNames.length),
    ]);
  };

  const extractEmailArrays = (data) => {
    const new_arr = data?.map((cur) => {
      return Object.values(cur)[0];
    });
    return new_arr;
  };

  const getSuggestions = (e) => {
    // if (devEnv) {
    //   setShareNameSuggestions(shareNameSuggestions);
    // } else {
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/client-suggestion?assignment_id=${tools_details_data?.data?.tools_assignment_id}&search=${shareNameInput}`,
    )
      .then((res) => {
        if (res?.success === true) {
          setShareNameSuggestions([...extractEmailArrays(res?.data)]);
        } else {
          toast.error(res?.message || "Error Fetching Data");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useDebounceEffect(getSuggestions, [shareNameInput], 700);

  const clearShareInput = () => {
    // Clearing the input and its suggestions after submitting the name
    sharedNameInputRef.current.value = "";
    setShareNameInput(null);
  };

  const submitBtnSuccess = () => {
    const shareBtn = shareSubmitBtn.current;
    shareBtn.style.background = "var(--light-orange,#e98742)";
    shareBtn.innerText = "Shared!";
    setTimeout(() => {
      shareBtn.style.background = "var(--light-blue,#3cc3f2)";
      shareBtn.innerText = "Share";
      closeSharingModal();
    }, 1000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addSuggestion();
  };
  const removeShareErrors = () => {
    setShareError(null);
    Array.from(document.querySelectorAll(".tool-client-share-name-item")).map(
      (el) => {
        el.classList.remove("tool-client-share-name-item-err");
      },
    );
  };

  const fetch_access_log = () => {
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/access/log/${asgn_id}/`,
    )
      .then((res) => {
        if (res?.success === true) {
          dispatch(setAccessLogData(res?.data));
        } else {
          toast.error(res?.message || "Error Fetching Data");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetch_shared_log = () => {
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/share/log/${asgn_id}/`,
    )
      .then((res) => {
        if (res?.success === true) {
          dispatch(setSharedLogData(res));
        } else {
          toast.error(res?.message || "Error Fetching Data");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const submitSharedArray = (e) => {
    e.preventDefault();
    const original_req_data = {
      assignment_id: tools_details_data?.data?.tools_assignment_id,
      email: sharedNames,
      accept_warning: false, //to check for warning
    };
    // const share_req_data = {
    //   assignment_id: tools_details_data?.data?.tools_assignment_id,
    //   emails: sharedNames,
    // };

    // checking of empty list is being sent
    if (sharedNames.length < 1) {
      setShareError("Please enter an email!");
      return;
    }

    // Disabling the share button to prevent multiple clicks
    setDisableShareBtn(true);

    // checking if more than 10 names are shared
    if (sharedNames.length > maxSharingNames) {
      setShareError(`More than ${maxSharingNames} names shared.`);
      return;
    } // setting a limit for names

    // sending data
    // if (devEnv) {
    //   if (clientDisplayedFile.client_user_type === CLIENT_USER_TYPE.ORIGINAL) {
    //     openShareWarningModal();
    //     console.log(original_req_data);
    //   } else {
    //     openShareRevokeModal();
    //     console.log(share_req_data);
    //   }
    //   setDisableShareBtn(false);
    // } else {
    if (
      tools_details_data?.data?.client_user_type === CLIENT_USER_TYPE.ORIGINAL
    ) {
      postAuthData(
        `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/share/log/`,
        getAccessLogData(original_req_data),
      )
        .then((res) => {
          if (res?.status === SHARE_RESPONSE_STATUS.SUCCESS) {
            removeShareErrors();
            submitBtnSuccess();
            clearShareInput();
            setSharedNames([]);
            setDisableShareBtn(false);
            fetch_shared_log();
            fetch_access_log();
          } else if (res?.status === SHARE_RESPONSE_STATUS.WARNING) {
            setSharewarningData(res);
            setDisableShareBtn(false);
            openShareWarningModal(res);
          } else if (res?.status === SHARE_RESPONSE_STATUS.FAILED) {
            setShareError(res?.message);
            setDisableShareBtn(false);
            setTimeout(() => {
              closeSharingModal();
            }, 2000);
            if (res?.emails?.length > 0) {
              setShareErrNameArr([...res.emails]);
            }
          } else {
            setShareError(res?.message);
            setDisableShareBtn(false);
            setTimeout(() => {
              closeSharingModal();
            }, 2000);
            if (res?.emails?.length > 0) {
              setShareErrNameArr([...res.emails]);
            }
          }
        })
        .catch((err) => {
          setDisableShareBtn(false);
          console.error(err);
        });
    } else {
      //open revoke share modal
      openShareRevokeModal(sharedNames);
    }

    // // temp data
    // const err_res = {
    //     "message": "Assignment can not be shared outside organisation",
    //     "emails": ["bhavya.pruthi@arabeasy.com","shubham.singh@ez.works"],
    //     "success": false
    // }
    // // error handling
    // setShareError(err_res.message);
    // if (err_res.emails.length>0){
    //     wrongName(err_res.emails);
    // }
  };

  return (
    <section className="tools_share_modal">
      <div
        className="tool-client-modal-container tool-client-shared-modal-container"
        ref={shareModalRef}
      >
        <div className="client-modal-head">
          <div
            className="client-modal-container-head"
            id="tool-client-share-heading"
          >
            Share Assignment
          </div>
          <button
            className="tool-client-modal-container-close-btn"
            id="tool-client-share-heading-close-btn"
            onClick={closeSharingModal}
          >
            <FaTimes />
          </button>
        </div>
        <p>
          The assignment can only be shared with people from within your
          organisation.
        </p>

        <div className="tool-client-share-names-container">
          {sharedNames?.map((item, idx) => {
            return (
              <div
                key={idx}
                className={`tool-client-share-name-item ${
                  shareErrNameArr.includes(item) &&
                  "tool-client-share-name-item-err"
                }`}
              >
                <span className="client-share-name">{item}</span>
                <button
                  className="client-share-remove-btn"
                  onClick={(e) => {
                    removeName(e, idx);
                  }}
                >
                  <FaTimes />
                </button>
              </div>
            );
          })}
        </div>

        <form className="tool-client-share-modal-form" onSubmit={handleSubmit}>
          <input
            type="text"
            className="client-dashboard-input tool-client-share-modal-input"
            ref={sharedNameInputRef}
            defaultValue={shareNameInput}
            onChange={(e) => {
              setShareError(null);
              setShareNameInput(e.target.value);
            }}
            autoFocus
          />

          {shareError && (
            <div className="client-input-error tool-client-share-error">
              {shareError}
            </div>
          )}

          {shareNameInput && (
            <div
              className="tool-client-share-suggestions-container"
              ref={suggestionContainerRef}
            >
              <span
                className="tool-client-share-suggestion-item"
                onClick={addSuggestion}
              >
                {shareNameInput}
              </span>
              {shareNameSuggestions.map((item, idx) => {
                return (
                  <span
                    className="tool-client-share-suggestion-item"
                    key={idx}
                    onClick={addSuggestion}
                  >
                    {item}
                  </span>
                );
              })}
            </div>
          )}
        </form>
        <div className="tool-client-share-btn-container">
          <button
            className="client-btns"
            id="tool-client-share-modal-cancel-btn"
            onClick={closeSharingModal}
          >
            Cancel
          </button>
          <button
            className="client-btns"
            ref={shareSubmitBtn}
            id="client-share-modal-okay-btn"
            disabled={disableShareBtn}
            onClick={submitSharedArray}
          >
            Share
          </button>
        </div>
      </div>
    </section>
  );
};

export default ToolsShareModal;
