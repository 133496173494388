import React, { useEffect } from "react";
import "./ToolsFileDashboard.scss";
import FileInfoSection from "../../ToolsComponents/FileInfoSection/FileInfoSection";
import FilesPreview from "../../ToolsComponents/FilesPreview/FilesPreview";
import { useDispatch, useSelector } from "react-redux";
import {
  resetOutputStatus,
  resetToolsDetailsData,
} from "../../../store/toolsDataSlice/toolsDataSlice";
import TOOL_FILE_CARD from "../../ToolsConstants/TOOL_FILE_CARD";
import FilesShare from "../../ToolsComponents/FilesShare/FilesShare";
import ToolsRevokeTab from "../../ToolsComponents/FilesShare/ToolsRevokeTab/ToolsRevokeTab";
import TOOLS_TYPE from "../../ToolsConstants/TOOLS_TYPE";
import ToolConsulGptChatSection from "../../ToolsComponents/ToolConsulGptChatSection/ToolConsulGptChatSection";

const ToolsFileDashboard = () => {
  const dispatch = useDispatch();
  const { active_file_card, tools_page, active_tool_type } = useSelector(
    (state) => state.utilsData,
  );
  const { tools_details_data } = useSelector((state) => state.toolsData);
  const { consult_file_pane_sidebar } = useSelector(
    (state) => state.consultGptData,
  );

  useEffect(() => {
    dispatch(resetToolsDetailsData());
    dispatch(resetOutputStatus());
  }, [dispatch, tools_page]);

  return (
    <section
      className={`tools_files_dashboard ${
        consult_file_pane_sidebar ? "consult_file_pane_open" : ""
      } ${
        active_tool_type === TOOLS_TYPE.CONSULT ? "tools_consult_dashboard" : ""
      }`}
    >
      <div className="tools_file_dashboard_wrapper">
        {active_tool_type === TOOLS_TYPE.CONSULT ? (
          <ToolConsulGptChatSection />
        ) : (
          <>
            <FileInfoSection />
            {tools_details_data?.data?.file_stage !== "revoked" ? (
              <>
                {active_file_card === TOOL_FILE_CARD.PREVIEW && (
                  <FilesPreview />
                )}
                {active_file_card === TOOL_FILE_CARD.SHARE && <FilesShare />}
              </>
            ) : (
              <section className="tools_file_share_revoke_container">
                <ToolsRevokeTab />
              </section>
            )}
          </>
        )}
      </div>
    </section>
  );
};

export default ToolsFileDashboard;
