import React, { useEffect, useState } from "react";
import "./ToolsAccessCard.scss";
import { useDispatch, useSelector } from "react-redux";
import { FaUserPlus } from "react-icons/fa";
// import SharedCardData from "./SharedCardData/SharedCardData";
// import SharedCardData from "./ToolsSharedUserCard";
// import sharedDelivery from "../../../../../../../data/dummy/sharedDelivery";
// import sharedDelivery from "./SharedDeliveryJson";
import { setSharedLogData } from "../../../../store/userSharedata/userShareDataSlice";
import { setModal } from "../../../../store/utilsDataSlice/utilsDataSlice";
import TOOLS_MODALS from "../../../ToolsConstants/TOOLS_MODALS";
import { useParams } from "react-router-dom";
import { getAuthData } from "../../../../helpers/request";
import ToolsSharedDataUserCard from "./ToolsSharedUserCard";
import { CLIENT_USER_TYPE } from "../../../..";
import ToolsRequestedUserCard from "./ToolsRequestedUserCard";
import ToolsOriginalSharedCard from "./ToolsOriginalSharedCard";

const ToolsAccessCard = () => {
  const dispatch = useDispatch();
  const { asgn_id } = useParams();
  const { sharedLogData } = useSelector((state) => state.accessLog);
  const [shareCardMsg, setShareCardMsg] = useState(null);
  const { tools_details_data } = useSelector((state) => state.toolsData);
  useEffect(() => {
    if (sharedLogData && sharedLogData?.length === 0) {
      setShareCardMsg("No logs found!");
    } else {
      setShareCardMsg(null);
    }
  }, [sharedLogData]);

  const fetch_shared_log = () => {
    setShareCardMsg("Fetching...");
    // if (devEnv) {
    //   setSharedLogData(dummy_shared_logs);
    //   return;
    // }
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/share/log/${asgn_id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          dispatch(setSharedLogData(res));
        } else {
          setShareCardMsg("Can't fetch logs.");
        }
      })
      .catch(() => {
        setShareCardMsg("Can't fetch logs.");
      });
  };
  useEffect(() => {
    asgn_id && fetch_shared_log();
  }, [asgn_id]);

  useEffect(() => {
    setShareCardMsg("Fetching...");
  }, [asgn_id]);

  const handleShareModal = () => {
    dispatch(setModal(TOOLS_MODALS.SHARE_MODAL));
  };

  return (
    <div className="client-dashboard-card glass-container-active client-log-container">
      <div className="client-dashboard-card-head client-log-card-head">
        <span>Users</span>
        <button
          className="client-share-open-modal-btn"
          onClick={handleShareModal}
        >
          <FaUserPlus />
        </button>
      </div>

      <div
        className={
          tools_details_data?.data?.client_user_type === CLIENT_USER_TYPE.SHARED
            ? "tool-shared-main-head"
            : "tool-client-shared-users-container-head"
        }
      >
        <span className="tool-client-shared-users-container-head-value tool-client-small-desktop">
          User
        </span>
        <span className="tool-client-shared-users-container-head-value">
          Shared By
        </span>
        <span className="tool-client-shared-users-container-head-value tool-client-shared-users-container-head-value-center tool-client-small-desktop-access">
          Times Accessed
        </span>
        {/* <span className="tool-client-shared-users-container-head-value tool-client-shared-users-container-head-value-center">
          Last Downloaded Version
        </span> */}
        <span className="tool-client-shared-users-container-head-value tool-client-shared-users-container-head-value-center">
          Last Accessed on
        </span>
      </div>

      <div className="client-log-items-container client-shared-users-container">
        {shareCardMsg && <div className="client-log-msg">{shareCardMsg}</div>}

        {tools_details_data?.data?.client_user_type ===
          CLIENT_USER_TYPE.ORIGINAL &&
          !shareCardMsg &&
          sharedLogData?.request_shared_log?.map((user, i) => {
            return <ToolsRequestedUserCard key={i} user={user} />;
          })}

        {tools_details_data?.data?.client_user_type ===
          CLIENT_USER_TYPE.ORIGINAL &&
          !shareCardMsg &&
          sharedLogData?.shared_log?.map((user, i) => {
            return <ToolsOriginalSharedCard key={i} user={user} />;
          })}

        {tools_details_data?.data?.client_user_type ===
          CLIENT_USER_TYPE.SHARED &&
          !shareCardMsg &&
          sharedLogData?.shared_log?.map((user, i) => {
            return <ToolsSharedDataUserCard key={i} user={user} />;
          })}
      </div>
    </div>
  );
};

export default ToolsAccessCard;
