import React, { useEffect, useState } from "react";
import "./FilesPreview.scss";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css"; // for pdf styles
import "@react-pdf-viewer/page-navigation/lib/styles/index.css";
import { useDispatch, useSelector } from "react-redux";
import TOOLS_PROGRESS from "../../ToolsConstants/TOOLS_PROGRESS";
import {
  getToolsFileDetails,
  getToolsFileOutputStatus,
  resetOutputStatus,
} from "../../../store/toolsDataSlice/toolsDataSlice";
import { MdOutlineFileDownload } from "react-icons/md";
import TOOLS_TYPE from "../../ToolsConstants/TOOLS_TYPE";
import { getAccessLogData } from "../../..";
import { useParams } from "react-router-dom";
// import { ImWarning } from "react-icons/im";
import PdfCustomPageLayer from "../../../utils/PdfCustomPageLayer";
import { FILE_ENCRYPTION_STATES } from "../../../client_module/ClientComponents";
import { ImWarning } from "react-icons/im";
import { getAuthData } from "../../../helpers/request";
import toast from "react-hot-toast";
import { setSrcFullPreview } from "../../../store/toolsDataSlice/toolsDataSlice";
import { setTrgFullPreview } from "../../../store/toolsDataSlice/toolsDataSlice";
import { getSrcFilePdfFullLink } from "../../../store/toolsDataSlice/toolsDataSlice";
import { getTrgFilePdfFullLink } from "../../../store/toolsDataSlice/toolsDataSlice";
import TOOL_ENUM_FOR_MAIL from "../../../constants/TOOL_ENUM_FOR_EMAIL.js";

const OutputFilePreview = ({
  trgPdfloaded,
  setTrgPdfLoaded,
  previewFile,
  showTrgMore,
  isPartial,
}) => {
  const [loaderMsg, setLoaderMsg] = useState(null);
  const { tools_details_data, output_status, preview_full_data } = useSelector(
    (state) => state.toolsData,
  );
  const { asgn_id } = useParams();
  const dispatch = useDispatch();
  const renderPage = (props) => <PdfCustomPageLayer renderPageProps={props} />;

  useEffect(() => {
    //to update output section
    dispatch(getToolsFileDetails({ file_id: asgn_id }));
  }, [asgn_id]);

  useEffect(() => {
    if (tools_details_data.loading) {
      setLoaderMsg("Loading preview!");
    } else if (!trgPdfloaded && tools_details_data.data?.target_pdf_path) {
      setLoaderMsg("Downloading Target preview...");
    }
    if (output_status.data.status === TOOLS_PROGRESS.IN_PROGRESS) {
      setLoaderMsg("Generating output. Please wait!");
    } else if (output_status.data.status === TOOLS_PROGRESS.FAILED) {
      setLoaderMsg("Output generation failed. Retrying!");
    } else if (output_status.data.status === TOOLS_PROGRESS.SUCCESS) {
      if (output_status.data.trg_preview.err) {
        setLoaderMsg("Preveiw can't be generated!");
      } else if (output_status.data.trg_preveiw?.link && !trgPdfloaded) {
        setLoaderMsg("Downloading Target preview now...");
      }
    }
  }, [tools_details_data, output_status]);

  return (
    <>
      {tools_details_data?.data?.file_stage ===
        FILE_ENCRYPTION_STATES.DOWNLOAD && (
        <div className="file_preview">
          {output_status.data?.status === TOOLS_PROGRESS.IN_PROGRESS && (
            <div className="output_progress_section">
              <div className="output_progress_bar">
                <div
                  className="output_progress_bar_complete"
                  style={{ width: `${output_status.data?.progress}%` }}
                ></div>
              </div>
            </div>
          )}

          {!trgPdfloaded && loaderMsg && (
            <div className="file_preview_downloading">{loaderMsg}</div>
          )}
          {previewFile && (
            <>
              {/* 3.9.179 */}

              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                {/* displaying partial error with output preview if translation output preview is generated but translated output is partially translated */}
                {isPartial && (
                  <div className="file_preview_partial_error">
                    <span>
                      <ImWarning />
                    </span>
                    <FileSizeLimitExceededError is_partial={true} />
                  </div>
                )}
                <Viewer
                  renderPage={renderPage}
                  fileUrl={previewFile}
                  onDocumentLoad={() => {
                    setTrgPdfLoaded(true);
                  }}
                />
              </Worker>
              {!preview_full_data.trg_pdf.link && showTrgMore && (
                <ShowMoreBtn src={false} />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

const ShowMoreBtn = () => {
  const { asgn_id } = useParams();
  const dispatch = useDispatch();

  // const handleClickFn = () => {
  //   if (src) {
  //     dispatch(setSrcFullPreview(true));
  //     dispatch(
  //       getSrcFilePdfFullLink({
  //         file_id: asgn_id,
  //       }),
  //     );
  //   } else {
  //     dispatch(setTrgFullPreview(true));
  //     dispatch(
  //       getTrgFilePdfFullLink({
  //         file_id: asgn_id,
  //       }),
  //     );
  //   }
  // };

  const handleClickFn = () => {
    dispatch(setSrcFullPreview(true));
    dispatch(
      getSrcFilePdfFullLink({
        file_id: asgn_id,
      }),
    );
    dispatch(setTrgFullPreview(true));
    dispatch(
      getTrgFilePdfFullLink({
        file_id: asgn_id,
      }),
    );
  };

  return (
    <div className="pdf_show_more" onClick={handleClickFn}>
      Show More
    </div>
  );
};

const DownloadBtn = ({ file_links }) => {
  const [downloadTypeBox, setDownloadTypeBox] = useState(false);
  // const [canDownload, setCanDownload] = useState(false);
  const { active_tool_type } = useSelector((state) => state.utilsData);
  // const { tools_details_data } = useSelector((state) => state.toolsData);

  const getAccessDataUrl = (url) => {
    let tracking_data = getAccessLogData();
    // replacing spaces with +
    Object.entries(tracking_data).forEach((tracker_item) => {
      if (tracker_item[1].includes(" ")) {
        tracking_data[tracker_item[0]] = tracker_item[1].replace(" ", "+");
      }
    });
    return `${url}&ip=${tracking_data.ip}&address=${tracking_data.address}&platform=${tracking_data.platform}&device=${tracking_data.device}`;
  };

  function downloadFileAnchor(link) {
    let a = document.createElement("a");
    a.setAttribute("href", link);
    a.setAttribute("download", link);
    a.click();
    a.remove();
  }

  function handleDownloadFileAll(files) {
    if (files.length === 0) return;
    let file = files.pop();
    let theAnchor = document.createElement("a");
    theAnchor.setAttribute("href", file?.file_path);
    theAnchor.setAttribute("download", file?.file_path);
    theAnchor.click();
    theAnchor.remove();
    setTimeout(() => {
      handleDownloadFileAll(files);
    }, 2000);
  }

  //for individual file for translate and read
  function ToolDownloadIndButton(file) {
    try {
      const url = getAccessDataUrl(
        process.env.REACT_APP_API_URL + file?.file_path,
      );
      getAuthData(url)
        .then((res) => {
          if (res?.success) {
            if (res?.data?.length > 1) {
              handleDownloadFileAll(res?.data);
            } else {
              downloadFileAnchor(res?.data[0]?.file_path);
            }
          } else {
            toast.error("Error downloading the file");
          }
        })
        .catch((err) => [toast.error("Error Downloading the file")]);
    } catch (err) {
      toast.error("Something went wrong");
    }
  }
  //for flip
  const ToolDownloadFiles = (filePath) => {
    try {
      const url = getAccessDataUrl(process.env.REACT_APP_API_URL + filePath);
      getAuthData(url)
        .then((res) => {
          if (res?.success) {
            downloadFileAnchor(res?.data[0]?.file_path);
          } else {
            toast.error("Error Downloading the file");
          }
        })
        .catch((err) => toast.error(err || "Something went wrong!!"));
    } catch (err) {
      toast.error(
        "There was a problem while downloading the files. Please try again after sometime.",
      );
    }
  };
  const handleDownloadToggle = () => {
    if (
      active_tool_type === TOOLS_TYPE.TRANSLATE ||
      active_tool_type === TOOLS_TYPE.READ
    ) {
      setDownloadTypeBox((prev) => {
        return !prev;
      });
    } else {
      if (file_links) {
        ToolDownloadFiles(file_links[0]?.file_path);
      }
    }
  };

  // const handleDownloadAll = () => {
  //   let download_arr = file_links?.map((el) => {
  //     return [
  //       `${tools_details_data.data?.filename || "EZFile"}_output.${el.type}`,
  //       el.file_path,
  //     ];
  //   });
  //   downloadAll(download_arr);
  // };

  return (
    <div className="file_download_btn">
      <div className="file_download_txt_wrapper" onClick={handleDownloadToggle}>
        <MdOutlineFileDownload />
        <span className="file_download_txt">Download</span>
      </div>
      {downloadTypeBox && (
        <div className="download_file_box">
          {file_links.map((file, i) => {
            return (
              <div
                className="download_file_link_btn"
                key={i}
                onClick={
                  () => {
                    ToolDownloadIndButton(file);
                  }
                  // handleDownload({
                  //   file_name: `${
                  //     tools_details_data.data?.filename || "EZFile"
                  //   }_output.${file.type}`,
                  //   link: file.file_path,
                  // })
                }
              >
                {file.type === "all" ? "All Formats" : file.type}
              </div>
            );
          })}
          {/* <div
            className="download_file_link_btn"
            onClick={() => handleDownloadAll()}
          >
            All Formats
          </div> */}
        </div>
      )}
    </div>
  );
};

const FileSizeLimitExceededError = ({ is_partial }) => {
  const { active_tool_type } = useSelector((state) => state.utilsData);

  const supportMailId = "support@ez.works";

  let file_upload_note1 =
    "To process files with more than 100,000 words, contact ";
  if (active_tool_type === TOOLS_TYPE.READ)
    file_upload_note1 = "To process files with more than 100 pages, contact ";
  if (is_partial)
    file_upload_note1 =
      "Some parts of your file failed to be translated. Please go through the output and contact ";

  let file_upload_note2 = "";
  if (active_tool_type === TOOLS_TYPE.TRANSLATE)
    file_upload_note2 = " to get these fixed.";

  let mail_subject = "File%20too%20large%20for%20";
  if (is_partial) mail_subject = "File%20translation%20incomplete%20on%20";

  return (
    <span>
      {file_upload_note1}
      <a
        className="file_size_limit_exceed_error_anchor"
        // onClick={handleSupportIdClick}
        href={`mailto:${supportMailId}?subject=${mail_subject}${TOOL_ENUM_FOR_MAIL[active_tool_type]}`}
        target="_blank"
        rel="noreferrer"
      >
        {supportMailId}
      </a>
      {file_upload_note2}
    </span>
  );
};

const FilesPreview = () => {
  const dispatch = useDispatch();
  const { asgn_id } = useParams();
  const { tools_details_data, output_status, preview_full_data } = useSelector(
    (state) => state.toolsData,
  );
  const { active_tool_type } = useSelector((state) => state.utilsData);
  const [srcPdfloaded, setSrcPdfLoaded] = useState(false);
  const [trgPdfloaded, setTrgPdfLoaded] = useState(false);
  const [downloadLinks, setDownloadLinks] = useState(null);
  const [errorTrgMsg, setErrorTrgMsg] = useState(null);
  const [errorSrcMsg, setErrorSrcMsg] = useState(null);
  const [srcPreviewFile, setSrcPreviewFile] = useState(null);
  const [trgPreviewFile, setTrgPreviewFile] = useState(null);
  // const [showSrcMore, setShowSrcMore] = useState(false);
  const [showTrgMore, setShowTrgMore] = useState(false);
  const [fileSizeLimitExceeded, setFileSizeLimitExceeded] = useState(false);
  const [partialOutput, setPartialOutput] = useState(false);

  const scrollPdf = ({ target_pdf, el }) => {
    // const p = Math.floor(
    //   (100 * el.scrollTop) / (el.scrollHeight - el.clientHeight),
    // );
    target_pdf.scrollTo(el.scrollLeft, el.scrollTop);
  };

  const renderPage = (props) => <PdfCustomPageLayer renderPageProps={props} />;

  // for scroll in preveiew to be sync
  useEffect(() => {
    if (!srcPdfloaded || !trgPdfloaded) return;
    if (active_tool_type === TOOLS_TYPE.READ) return;
    let source_pdf_preview = document
      .querySelector(".file_source_preview_container")
      .querySelector(".file_preview")
      .querySelector(".rpv-core__inner-pages");

    let target_pdf_preview = document
      .querySelector(".file_target_preview_container")
      .querySelector(".file_preview")
      .querySelector(".rpv-core__inner-pages");

    source_pdf_preview?.addEventListener("scroll", (e) => {
      scrollPdf({
        el: e.target,
        source_pdf: source_pdf_preview,
        target_pdf: target_pdf_preview,
      });
    });
    return () => {
      source_pdf_preview?.removeEventListener("scroll", scrollPdf);
    };
  }, [srcPdfloaded, trgPdfloaded]);

  useEffect(() => {
    if (tools_details_data.data?.target_pdf_path) {
      setTrgPreviewFile(tools_details_data.data?.target_pdf_path);
    }

    if (tools_details_data.data?.source_pdf_path) {
      setSrcPreviewFile(tools_details_data.data?.source_pdf_path);
    }

    // if (
    //   tools_details_data.data?.source_pdf_path ||
    //   output_status.data.src_preveiw_file_link
    // ) {
    //   setSrcPreviewFile(
    //     tools_details_data.data?.source_pdf_path ||
    //       output_status.data.src_preveiw_file_link,
    //   );
    // }
    if (tools_details_data.data?.output_download) {
      setDownloadLinks(tools_details_data.data?.output_download);
    }
    // if (output_status.data.download_links?.err) {
    //   setErrorTrgMsg(output_status.data.download_links?.err);
    // }
    // if (!output_status.data.trg_preveiw?.status) {
    //   setErrorTrgMsg(output_status.data.trg_preveiw.err);
    // }
    // if (!output_status.data.src_preveiw?.status) {
    //   setErrorSrcMsg(output_status.data.src_preveiw.err);
    // }
    // if (output_status.data.trg_preveiw_file_link?.err) {
    //   setErrorTrgMsg(output_status.data.trg_preveiw_file_link.err);
    // }
    // if (output_status.data.src_preveiw_file_link?.err) {
    //   setErrorSrcMsg(output_status.data.src_preveiw_file_link.err);
    // }
  }, [tools_details_data.data]);

  useEffect(() => {
    if (output_status.data.trg_preview?.status) {
      setTrgPreviewFile(output_status.data.trg_preview.link);
    }
    if (output_status.data.src_preview?.status) {
      setSrcPreviewFile(output_status.data.src_preview.link);
    }
    if (
      output_status.data.download_links &&
      !output_status.data.download_links.err
    ) {
      setDownloadLinks(output_status.data.download_links);
    }
    // for error
    if (output_status.data.download_links?.err) {
      setErrorTrgMsg(output_status.data.download_links?.err);
    }
    if (!output_status.data.trg_preview?.status) {
      setErrorTrgMsg(output_status.data.trg_preview.err);
    }
    // if (!output_status.data.src_preview?.status) {
    //   // setErrorSrcMsg(output_status.data.src_preview.err);
    // }
    if (output_status?.data?.message_type === "file_size_limit_exceed")
      setFileSizeLimitExceeded(true);
    if (output_status?.data?.is_partial) setPartialOutput(true);
    else setPartialOutput(false);
  }, [output_status.data]);

  useEffect(() => {
    // beginning recursive api calls
    if (tools_details_data.loading) return;
    if (
      !tools_details_data.data?.target_pdf_path ||
      !tools_details_data.data?.source_pdf_path ||
      !tools_details_data.data?.output_download
    ) {
      dispatch(resetOutputStatus());
      dispatch(
        getToolsFileOutputStatus({
          tool_type: active_tool_type,
          file_id: asgn_id,
        }),
      );
      setErrorTrgMsg(null);
      setErrorSrcMsg(null);
      if (!tools_details_data.data?.target_pdf_path) {
        setTrgPreviewFile(null);
      }
      if (!tools_details_data.data?.source_pdf_path) {
        setSrcPreviewFile(null);
      }
      if (!tools_details_data.data?.output_download) {
        setDownloadLinks(null);
      }
    }
  }, [
    tools_details_data.data?.target_pdf_path,
    tools_details_data.data?.source_pdf_path,
    tools_details_data.data?.output_download,
    tools_details_data.loading,
    asgn_id,
  ]);

  // for loader msg in source preview
  useEffect(() => {
    if (tools_details_data.loading) {
      setErrorSrcMsg("Loading preview!");
    } else if (!trgPdfloaded && tools_details_data.data?.source_pdf_path) {
      setErrorSrcMsg("Downloading Source preview...");
    }
    if (output_status.data.status === TOOLS_PROGRESS.IN_PROGRESS) {
      setErrorSrcMsg("Generating output. Please wait!");
    } else if (output_status.data.status === TOOLS_PROGRESS.FAILED) {
      if (
        !output_status.data.src_preveiw?.link &&
        !tools_details_data.data?.source_pdf_path
      ) {
        setErrorSrcMsg("Output generation failed. Retrying!");
      } else {
        setErrorSrcMsg("Downloading Source preview...");
      }
    } else if (output_status.data.status === TOOLS_PROGRESS.SUCCESS) {
      if (output_status.data.src_preview.err) {
        setErrorSrcMsg("Preview can't be generated!");
      } else if (output_status.data.src_preveiw?.link && !srcPdfloaded) {
        setErrorSrcMsg("Downloading Source preview now...");
      } else {
        setErrorSrcMsg("Loading preview!");
      }
    }
  }, [tools_details_data, output_status]);

  useEffect(() => {
    // resetting all values in case of asgn change
    setSrcPdfLoaded(false);
    setTrgPdfLoaded(false);
    setDownloadLinks(null);
    setErrorTrgMsg(null);
    setErrorSrcMsg(null);
    setSrcPreviewFile(null);
    setTrgPreviewFile(null);
    // setShowSrcMore(false);
    setShowTrgMore(false);
    // setPartialOutput(false);
  }, [asgn_id]);

  // load full pdfs
  useEffect(() => {
    if (preview_full_data.src_pdf.link) {
      setSrcPreviewFile(preview_full_data.src_pdf.link);
      setSrcPdfLoaded(false); // so as to re connect source target scroll
    }
  }, [preview_full_data.src_pdf.link]);

  useEffect(() => {
    if (preview_full_data.trg_pdf.link) {
      setTrgPreviewFile(preview_full_data.trg_pdf.link);
      setTrgPdfLoaded(false); // so as to re connect source target scroll
    }
  }, [preview_full_data.trg_pdf.link]);

  // for scroll in preveiew to be sync
  // // src
  // useEffect(() => {
  //   if (!srcPdfloaded) return;

  //   let source_pdf_preview = document
  //     .querySelector(".file_source_preview_container")
  //     .querySelector(".file_preview")
  //     .querySelector(".rpv-core__inner-pages");

  //   let src_file_preview = document
  //     .querySelector(".file_source_preview_container")
  //     .querySelector(".file_preview");

  //   // incase the pdf becomes very small
  //   if (
  //     src_file_preview.scrollHeight >= source_pdf_preview.scrollHeight - 200 &&
  //     !preview_full_data.src_pdf.link
  //   ) {
  //     setShowSrcMore(true);
  //     return;
  //   }
  //   const onSrcScroll = () => {
  //     const isReachBottom =
  //       src_file_preview.scrollHeight <= source_pdf_preview.scrollTop;
  //     const isReachUp =
  //       src_file_preview.scrollHeight - 100 >= source_pdf_preview.scrollTop;
  //     if (isReachBottom) setShowSrcMore(true);
  //     if (isReachUp) setShowSrcMore(false);
  //   };

  //   source_pdf_preview?.addEventListener("scroll", onSrcScroll);

  //   return () => {
  //     source_pdf_preview?.removeEventListener("scroll", onSrcScroll);
  //   };
  // }, [srcPdfloaded]);

  // trg
  useEffect(() => {
    if (!trgPdfloaded) return;
    let target_pdf_preview = document
      .querySelector(".file_target_preview_container")
      .querySelector(".file_preview")
      .querySelector(".rpv-core__inner-pages");

    let trg_file_preview = document
      .querySelector(".file_target_preview_container")
      .querySelector(".file_preview");

    // incase the pdf becomes very small
    if (
      trg_file_preview.scrollHeight >= target_pdf_preview.scrollHeight - 200 &&
      !preview_full_data.trg_pdf.link
    ) {
      setShowTrgMore(true);
      return;
    }

    const onTrgScroll = () => {
      const isReachBottom =
        trg_file_preview.scrollHeight <= target_pdf_preview.scrollTop;
      const isReachUp =
        trg_file_preview.scrollHeight - 100 >= target_pdf_preview.scrollTop;
      if (isReachBottom) setShowTrgMore(true);
      if (isReachUp) setShowTrgMore(false);
    };
    target_pdf_preview?.addEventListener("scroll", onTrgScroll);
    return () => {
      target_pdf_preview?.removeEventListener("scroll", onTrgScroll);
    };
  }, [trgPdfloaded]);

  return (
    <section className="files_preview_section">
      <div className="file_preview_container file_source_preview_container">
        <div className="file_preview_head">
          <div className="file_preview_heading">Source File</div>
          {downloadLinks &&
            tools_details_data?.data?.file_stage ===
              FILE_ENCRYPTION_STATES.DOWNLOAD && (
              <div className="source_download_btn">
                <DownloadBtn file_links={downloadLinks} />
              </div>
            )}
        </div>
        <div className="file_preview">
          {!srcPdfloaded && errorSrcMsg && (
            <div className="file_preview_downloading">{errorSrcMsg}</div>
          )}
          {srcPreviewFile && (
            <>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
                <Viewer
                  renderPage={renderPage}
                  fileUrl={srcPreviewFile}
                  onDocumentLoad={() => {
                    setSrcPdfLoaded(true);
                  }}
                />
              </Worker>
              {/* {!preview_full_data.src_pdf.link && showSrcMore && (
                <ShowMoreBtn src={true} />
              )} */}
            </>
          )}
        </div>
        {/* {errorSrcMsg && (
          <span className="file_preveiw_error">
            <ImWarning />
            {errorSrcMsg}
          </span>
        )}
        {!errorSrcMsg && (
          <div className="file_preview">
            {!srcPdfloaded && srcPreviewFile && (
              <div className="file_preview_downloading">
                Downloading Source preview...
              </div>
            )}
            {srcPreviewFile ? (
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.9.179/build/pdf.worker.min.js">
                <Viewer
                  // fileUrl={
                  //   process.env.PUBLIC_URL +
                  //   tools_details_data.data.source_pdf_path
                  // }
                  renderPage={renderPage}
                  fileUrl={srcPreviewFile}
                  onDocumentLoad={() => {
                    setSrcPdfLoaded(true);
                  }}
                />
              </Worker>
            ) : (
              <span className="preview-loader-msg">
                {tools_details_data.loading
                  ? "Loading preview..."
                  : "No source preview found!"}
              </span>
            )}
          </div>
        )} */}
      </div>
      <div className="file_preview_container file_target_preview_container">
        <div className="file_preview_head">
          <div className="file_preview_heading">Output</div>
          {downloadLinks &&
            !output_status.data.download_links?.err &&
            tools_details_data?.data?.file_stage ===
              FILE_ENCRYPTION_STATES.DOWNLOAD && (
              <div className="target_download_btn">
                <DownloadBtn file_links={downloadLinks} />
              </div>
            )}
          {output_status.data?.status === TOOLS_PROGRESS.IN_PROGRESS &&
            tools_details_data?.data?.file_stage ===
              FILE_ENCRYPTION_STATES.DOWNLOAD && (
              <span className="file_preview_percent">
                {output_status.data?.progress === "0"
                  ? "Processing file..."
                  : `${output_status.data?.progress}%`}
              </span>
            )}
        </div>
        {tools_details_data?.data?.file_stage &&
          (tools_details_data?.data?.file_stage ===
            FILE_ENCRYPTION_STATES.ENCRYPTED ||
            tools_details_data?.data?.file_stage ===
              FILE_ENCRYPTION_STATES.DECRYPTED) &&
          !errorTrgMsg && (
            <div className="file_preveiw_not_download">
              <span className="encrypt_message">
                Decrypt file to view and download the output
              </span>
            </div>
          )}
        {errorTrgMsg && (
          <div className="file_preveiw_error">
            <span>
              <ImWarning />
            </span>
            <span>
              {fileSizeLimitExceeded ? (
                <FileSizeLimitExceededError is_partial={false} />
              ) : (
                errorTrgMsg
              )}
            </span>
          </div>
        )}
        {/* if translation output is partially generated but preview generation failed */}
        {partialOutput && errorTrgMsg && (
          <div className="file_preveiw_error">
            <span>
              <ImWarning />
            </span>
            <span>
              <FileSizeLimitExceededError is_partial={true} />
            </span>
          </div>
        )}
        {!errorTrgMsg && (
          <OutputFilePreview
            trgPdfloaded={trgPdfloaded}
            setTrgPdfLoaded={setTrgPdfLoaded}
            previewFile={trgPreviewFile}
            showTrgMore={showTrgMore}
            isPartial={partialOutput}
          />
        )}
      </div>
    </section>
  );
};

export default FilesPreview;
