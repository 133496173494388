import { React, useContext, useRef } from "react";
import "./HomeFileSearch.scss";
import { DashboardSearchContext } from "../../..";
import { useNavigate } from "react-router-dom";
const HomeFileSearch = () => {
  const { setSearched, setSearchNavigate } = useContext(DashboardSearchContext);
  const searchRef = useRef(null);
  const navigate = useNavigate();

  const handleSearch = (e) => {
    const searchValue = searchRef.current.value;
    setSearched(searchValue);
    setSearchNavigate(true);
    const toggle = document.querySelector("#client-file-sidebar");
    // .classList.toggle("client-sidebar-show");
    console.log(toggle);
    navigate("/assignments");
  };

  return (
    <div className="home_file_search_main">
      <div className="home_file_search_heading">
        <span className="home_file_search_text">Find Assignment</span>
      </div>
      <div className="home_file_content">
        <span className="home_file_content_text">
          Enter the Delivery ID you have received via Email to fetch your files
          and decrypt them for download
        </span>
      </div>
      <form className="home_search_form" onSubmit={handleSearch}>
        <input
          className="home_search_dashboard_input"
          type="text"
          ref={searchRef}
          placeholder="Enter Delivery ID or File name here"
        />
        <button className="home_search_btns" type="submit">
          Search
        </button>
      </form>
    </div>
  );
};

export default HomeFileSearch;
