import React, { useEffect, useRef } from "react";
import "./CustomFileInput.scss";
import fileCollectiveSizeChecker from "./fileCollectiveSizeChecker";
import fileSizeChecker from "./fileSizeChecker";
import { FaUpload } from "react-icons/fa";
import Dropzone from "react-dropzone";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
const ReferenceFileInpput = ({
  tagId,
  handleChange,
  styleClass,
  placeholder,
  clear = false,
  maxFileSize,
  maxFileSizeErrFn,
  maxCollectiveFileSize,
  maxCollectiveFileSizeFnErr,
  innerRef,
  disabled,
  // handleFileUpload,
  file,
  setFile,
  setFilePaths,
}) => {
  const hiddennFileInput = useRef(innerRef || null);
  const { reference_loader } = useSelector((state) => state.utilsData);

  const handleClick = (e) => {
    // e.stopPropagation();
    if (!reference_loader) {
      hiddennFileInput.current.value = null; // to reset the uploaded files
      setFilePaths([]);
      setFile(null);
    }

    if (reference_loader) {
      toast.error("Previous File(s) are being uploaded please wait", {
        id: "resetError",
      });
    }

    // hiddennFileInput.current.click();
  };
  const handleicon = () => {
    hiddennFileInput.current.value = null; // to reset the uploaded files
    setFilePaths([]);
    hiddennFileInput.current.click();
    if (!reference_loader) {
      setFile(null);
    }
  };

  const checkOnDropFileSize = (acceptedFiles) => {
    let size = 0;
    for (let i = 0; i < acceptedFiles.length; i++) {
      size += acceptedFiles[i];
    }

    // console.log(size);
    size = size / 1000000;
    return size > 5120 ? false : true;
  };

  const handleDrop = (acceptedFiles) => {
    setFilePaths([]);
    if (acceptedFiles) {
      const sizeArr = acceptedFiles.map((elem) => {
        return elem.size;
      });
      const check = checkOnDropFileSize(sizeArr);
      if (check) {
        setFile(acceptedFiles);
        handleChange(acceptedFiles);
      } else {
        toast.error(
          "References File collective size should be less than 5 Gb",
          {
            id: "ref-drop",
          },
        );
      }
    }
  };
  const fileLimiter = (fileName) => {
    const maxLength = 16;

    if (fileName.length > maxLength) {
      const extensionIndex = fileName.lastIndexOf(".");
      const name = fileName.substring(0, extensionIndex);
      const extension = fileName.substring(extensionIndex + 1);
      const limitedName = name.substring(0, maxLength - (extension.length + 4));
      const limitedData = limitedName + "...." + extension;
      return limitedData;
    } else {
      return fileName;
    }
  };

  const handleFileChange = (e) => {
    let clearValdation = true;
    if (maxFileSize) {
      if (
        !fileSizeChecker({
          fileArr: Array.from(e.target?.files),
          maxSizeInMB: maxFileSize,
        })
      ) {
        clearValdation = false;
        maxFileSizeErrFn();
      }
    }
    if (maxCollectiveFileSize) {
      if (
        !fileCollectiveSizeChecker({
          fileArr: Array.from(e.target?.files),
          maxCollectiveSizeInMB: maxCollectiveFileSize,
        })
      ) {
        clearValdation = false;
        maxCollectiveFileSizeFnErr();
      }
    }
    if (!clearValdation) return;
    handleChange(Array.from(e.target?.files));
    setFile(Array.from(e.target?.files));
  };

  useEffect(() => {
    hiddennFileInput.current.value = null;
    setFilePaths([]);
    setFile(null);
  }, [clear]);

  return (
    <Dropzone onDrop={handleDrop} disabled={reference_loader}>
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          className={`custom_media_input_wrapper ${styleClass}`}
          onClick={handleClick}
        >
          <input
            disabled={reference_loader}
            multiple={true}
            {...getInputProps()}
          />

          <label
            className="custom_media_input_label"
            htmlFor="custom_media_input_input_file"
          >
            <div className="files_input_name_list">
              {file && file.length > 0
                ? file.slice(0, 2).map((fil, i) => {
                    const comma =
                      i < file.length - 1 && file.length !== 1 ? ", " : "";
                    return (
                      <div key={i}>
                        {fileLimiter(fil.name)}
                        {comma}
                      </div>
                    );
                  })
                : placeholder}

              {file &&
                file.length > 2 &&
                `+ ${file.length - 2} more file${file.length > 3 ? "s" : ""}`}
            </div>
          </label>
          <input
            disabled={reference_loader}
            style={{ display: "none" }}
            type="file"
            id="custom_media_input_input_file"
            data-tag-id={tagId}
            name="custom_media_input_input_file"
            ref={hiddennFileInput}
            placeholder={placeholder}
            multiple
            accept=".docx,.pptx,.xlsx,.pdf,.jpg,.jpeg,.mp4,.srt,.png,.ai,.eps,.psd,.zip,.csv,.tmx,.mxliff,.xliff,.mqxlz"
            onChange={handleFileChange}
          />
          <span className="custom_media_input_input_btn" onClick={handleicon}>
            <FaUpload
              color="var(--white)"
              size="20px"
              className="input_logo_upload"
            />
          </span>
        </div>
      )}
    </Dropzone>
  );
};

export default ReferenceFileInpput;
