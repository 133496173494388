import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAuthData } from "../../helpers/request";
import { initialState } from "./common";

export const fetchAccessLogData = createAsyncThunk(
  "accessLog/fetchAccessLogData",
  async (id) => {
    const response = await getAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/share/log/${id}/`,
    );
    return response.shared_log;
  },
);

const userShareDataSlice = createSlice({
  name: "accessLog",
  initialState,
  reducers: {
    setAccessLogData: (state, { payload }) => {
      state.accessLogData = payload;
    },
    setSharedLogData: (state, { payload }) => {
      state.sharedLogData = payload;
    },
    setSharedEmail: (state, { payload }) => {
      state.shared_email = payload;
    },
    setSharedRes: (state, { payload }) => {
      state.shared_res = payload;
    },
    setFrom: (state, { payload }) => {
      state.from = payload;
    },
    setShareModalType: (state, { payload }) => {
      state.share_modal_type = payload;
    },
    resetSharedEmail: (state) => {
      state.shared_email = [];
    },
    resetSharedRes: (state) => {
      state.shared_res = {};
    },
    resetFrom: (state) => {
      state.resetFrom = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccessLogData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAccessLogData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.sharedData = action.payload;
      })
      .addCase(fetchAccessLogData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});
export const {
  setAccessLogData,
  setSharedLogData,
  setSharedEmail,
  resetSharedEmail,
  setSharedRes,
  resetSharedRes,
  setFrom,
  resetFrom,
  setShareModalType,
} = userShareDataSlice.actions;
export default userShareDataSlice.reducer;
