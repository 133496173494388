import React from "react";

const FileProcessing = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      style={{
        shapeRendering: "auto",
        display: "block",
        background: "transparent",
      }}
    >
      <g>
        <g transform="rotate(0 50 50)">
          <rect
            fill="#ffffff"
            height="12"
            width="12"
            ry="6"
            rx="6"
            y="22"
            x="44"
          >
            <animate
              repeatCount="indefinite"
              begin="-0.875s"
              dur="1s"
              keyTimes="0;1"
              values="1;0"
              attributeName="opacity"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(45 50 50)">
          <rect
            fill="#ffffff"
            height="12"
            width="12"
            ry="6"
            rx="6"
            y="22"
            x="44"
          >
            <animate
              repeatCount="indefinite"
              begin="-0.75s"
              dur="1s"
              keyTimes="0;1"
              values="1;0"
              attributeName="opacity"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(90 50 50)">
          <rect
            fill="#ffffff"
            height="12"
            width="12"
            ry="6"
            rx="6"
            y="22"
            x="44"
          >
            <animate
              repeatCount="indefinite"
              begin="-0.625s"
              dur="1s"
              keyTimes="0;1"
              values="1;0"
              attributeName="opacity"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(135 50 50)">
          <rect
            fill="#ffffff"
            height="12"
            width="12"
            ry="6"
            rx="6"
            y="22"
            x="44"
          >
            <animate
              repeatCount="indefinite"
              begin="-0.5s"
              dur="1s"
              keyTimes="0;1"
              values="1;0"
              attributeName="opacity"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(180 50 50)">
          <rect
            fill="#ffffff"
            height="12"
            width="12"
            ry="6"
            rx="6"
            y="22"
            x="44"
          >
            <animate
              repeatCount="indefinite"
              begin="-0.375s"
              dur="1s"
              keyTimes="0;1"
              values="1;0"
              attributeName="opacity"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(225 50 50)">
          <rect
            fill="#ffffff"
            height="12"
            width="12"
            ry="6"
            rx="6"
            y="22"
            x="44"
          >
            <animate
              repeatCount="indefinite"
              begin="-0.25s"
              dur="1s"
              keyTimes="0;1"
              values="1;0"
              attributeName="opacity"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(270 50 50)">
          <rect
            fill="#ffffff"
            height="12"
            width="12"
            ry="6"
            rx="6"
            y="22"
            x="44"
          >
            <animate
              repeatCount="indefinite"
              begin="-0.125s"
              dur="1s"
              keyTimes="0;1"
              values="1;0"
              attributeName="opacity"
            ></animate>
          </rect>
        </g>
        <g transform="rotate(315 50 50)">
          <rect
            fill="#ffffff"
            height="12"
            width="12"
            ry="6"
            rx="6"
            y="22"
            x="44"
          >
            <animate
              repeatCount="indefinite"
              begin="0s"
              dur="1s"
              keyTimes="0;1"
              values="1;0"
              attributeName="opacity"
            ></animate>
          </rect>
        </g>
        <g></g>
      </g>
    </svg>
  );
};

export default FileProcessing;
