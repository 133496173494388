import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CHAT_ERROR_MESSAGE_TYPE,
  CHAT_MESSAGE_TYPE,
  CHAT_NOTIFICATION_TYPE,
  CHAT_TYPE,
  INITIAL_ARABIC_MSG_TEXT,
  INITIAL_MSG_TEXT,
} from "../../../../../store/consultgptDataSlice/common";
import "./ToolsConsultGptChat.scss";
import {
  addChatErrorMessage,
  addMessage,
  addNotificationMessage,
  addNotificationsToFlow,
  handleChatDisbale,
  notificationSocketConnection,
  setInitialChatFlow,
  socketConnection,
  updateChatCurrentFlow,
  updateChatQueryCount,
  updateInitialFileStatus,
  updateInitialSetupFlag,
  updateLastestMessageId,
  updateMessageChildren,
} from "../../../../../store/consultgptDataSlice/consultgptDataSlice";
import UserMessage from "./UserMessage";
import BotMessage from "./BotMessage";
import { updateFileProcessingState } from "../../../../../store/multiPartUploadDataSlice/multiPartUploadDataSlice";
import { updateSidebarQueryCount } from "../../../../../store/toolsDataSlice/toolsDataSlice";
import fileNameLimiter from "../../../../../utils/fileNameLimiter";
import ErrorMessage from "./ErrorMessage/ErrorMessage";
import { UPLOAD_FILES_PROGRESS_STATUS } from "../../../../../store/multiPartUploadDataSlice/common";
import { v4 as uuidv4 } from "uuid";
import { handleChatSocketErrorMessage } from "../../../../../utils/handleChatSocketErrorMessage";

const NotificationMessage = ({ content, notNotification }) => {
  return (
    <div className="tools_consult_notification_msg_wrapper" key={content?.uuid}>
      <div className="tools_consult_notification_msg_container">
        {notNotification
          ? content && <span>{content}</span>
          : content?.content && (
              <span>{fileNameLimiter(content?.content)}</span>
            )}
      </div>
    </div>
  );
};

const ToolsConsultGptChat = () => {
  const {
    socket,
    chat_data,
    notificationSocket,
    latest_message_id,
    MessagefeedbackForm,
  } = useSelector((state) => state.consultGptData);
  const { filesObj } = useSelector((state) => state.multiPartUploadData);
  const dispatch = useDispatch();
  const initialMessageAdded = useRef(false);
  const messageEndRef = useRef(null);
  const [initialCheckDone, setInitialCheckDone] = useState(false);
  const [notificationContent, setNotificationContent] = useState(null);

  useEffect(() => {
    if (
      chat_data?.messages &&
      Object.keys(chat_data.messages).length > 0 &&
      latest_message_id &&
      !chat_data.is_initial_flow_set
    ) {
      dispatch(setInitialChatFlow());
      dispatch(addNotificationsToFlow());
      dispatch(updateInitialSetupFlag(true)); // Setting a flag to true after initial setup
    }
  }, [chat_data?.messages, latest_message_id]);

  useEffect(() => {
    if (chat_data.chat_type === CHAT_TYPE.FILE_UPLOAD && !socket) {
      dispatch(handleChatDisbale(true));
      const files = Object.values(filesObj);
      const fileObj = files.find(
        (file) =>
          file.fileProgress.type === UPLOAD_FILES_PROGRESS_STATUS.PROCESSING,
      );

      if (fileObj) {
        dispatch(socketConnection({ chat_id: chat_data.root_node_id }));
      }
    }
  }, [filesObj, chat_data.chat_type, socket]);

  useEffect(() => {
    if (socket) {
      socket.on("message", (data) => {
        const messageKey = Object.keys(data)[0];
        const queryCount = data?.count;

        const messageData = data[messageKey];

        const isError = data?.type === CHAT_MESSAGE_TYPE.ERROR;
        if (isError) {
          console.log(data);
          if (data?.error_type === CHAT_ERROR_MESSAGE_TYPE.AI_PROCESSING_FAIL) {
            dispatch(addChatErrorMessage(data));
          } else {
            handleChatSocketErrorMessage({
              data,
              dispatch,
              msgData: chat_data.messages,
              latestMsgId: chat_data.latest_message_id,
              socket,
            });
          }
        } else {
          const receivedChatMessage = {
            uuid: messageData?.uuid,
            content: messageData?.content,
            output_references: messageData?.output_references,
            parent_message: messageData?.parent_message,
            child_message: [],
            type: messageData?.type,
            timestamp: messageData?.timestamp,
            feedback: messageData?.feedback,
          };
          dispatch(addMessage(receivedChatMessage));
          dispatch(updateChatCurrentFlow([messageData.uuid]));
          dispatch(updateChatQueryCount(queryCount));
          dispatch(
            updateMessageChildren({
              parentId: messageData?.parent_message,
              newChildId: messageData?.uuid,
            }),
          );
          dispatch(
            updateSidebarQueryCount({
              type_count: queryCount,
              file_name: data?.file_name,
              id: chat_data.root_node_id,
            }),
          );
          dispatch(updateLastestMessageId(messageData.uuid));
        }
      });

      return () => {
        socket.off("message");
      };
    }
  }, [socket]);

  useEffect(() => {
    if (!notificationSocket.socket) return;

    const handleNotification = (data) => {
      const { type, data: notificationData } = data;
      const { uuid, content, file_id, is_processed, timestamp } =
        notificationData || {};
      const createNotificationMessage = (extraFields = {}) => ({
        uuid,
        content,
        file_id,
        timestamp,
        ...extraFields,
      });

      if (type === CHAT_NOTIFICATION_TYPE.FILE_PROCESSED) {
        // to create the notification message with extra fields specific to FILE_PROCESSED
        const receivedNotificationMessage = createNotificationMessage({
          is_processed,
          type: notificationData.type,
        });

        if (notificationData?.is_processed) {
          dispatch(addNotificationMessage(receivedNotificationMessage));
          dispatch(updateChatCurrentFlow([uuid]));
        } else {
          const processingFailedNotification = {
            uuid: uuidv4(),
            content: `${notificationData?.file_name} processing failed`,
            type: CHAT_MESSAGE_TYPE.NOTIFICATION,
            is_processed: false,
            file_id: notificationData?.file_id,
            timestamp: Date.now(),
          };
          dispatch(addNotificationMessage(processingFailedNotification));
          dispatch(updateChatCurrentFlow([processingFailedNotification?.uuid]));
        }
        dispatch(updateFileProcessingState({ file_id, status: is_processed }));
      } else if (type === CHAT_NOTIFICATION_TYPE.FILE_INCLUDED) {
        // to create the notification message with extra fields specific to FILE_INCLUDED
        const receivedNotificationMessage = createNotificationMessage({
          type: notificationData.type,
        });

        dispatch(addNotificationMessage(receivedNotificationMessage));
        dispatch(updateChatCurrentFlow([uuid]));
      }
    };
    notificationSocket.socket.on("notification", handleNotification);
    return () => {
      notificationSocket.socket.off("notification", handleNotification);
    };
  }, [notificationSocket.socket]);

  const generateInitialGreetingMessage = (content, translate) => ({
    uuid: chat_data.root_node_id,
    parent_message: null,
    child_message: [],
    type: CHAT_MESSAGE_TYPE.BOT,
    content: content,
    translated_content: translate,
    timestamp: new Date().toISOString(),
  });

  useEffect(() => {
    const hasNotificationMessage = chat_data.currentChatFlow.some(
      (messageId) =>
        chat_data.messages[messageId]?.type === CHAT_MESSAGE_TYPE.NOTIFICATION,
    );
    const anyFileProcessed = Object.values(filesObj).some(
      (file) =>
        file.fileProgress.type === UPLOAD_FILES_PROGRESS_STATUS.COMPLETED,
    );
    let initialGreetingsMessage;
    if (
      chat_data.currentChatFlow.length &&
      !initialMessageAdded.current &&
      chat_data.chat_type === CHAT_TYPE.FILE_UPLOAD &&
      hasNotificationMessage &&
      anyFileProcessed
    ) {
      initialGreetingsMessage = generateInitialGreetingMessage(
        INITIAL_MSG_TEXT.FILE_UPLOAD,
        INITIAL_ARABIC_MSG_TEXT.FILE_UPLOAD,
      );
      if (initialGreetingsMessage) {
        dispatch(addMessage(initialGreetingsMessage));
        dispatch(updateLastestMessageId(initialGreetingsMessage?.uuid));
        dispatch(updateChatCurrentFlow([chat_data.root_node_id]));
        initialMessageAdded.current = true;
        dispatch(addNotificationsToFlow());
      }
    } else if (
      chat_data.currentChatFlow.length === 0 &&
      !initialMessageAdded.current &&
      chat_data.chat_type === CHAT_TYPE.TALK_TO_CONSULT
    ) {
      initialGreetingsMessage = generateInitialGreetingMessage(
        INITIAL_MSG_TEXT.TALK_TO_CONSULT,
        INITIAL_ARABIC_MSG_TEXT.TALK_TO_CONSULT,
      );
      if (initialGreetingsMessage) {
        dispatch(addMessage(initialGreetingsMessage));
        dispatch(updateLastestMessageId(initialGreetingsMessage?.uuid));
        dispatch(updateChatCurrentFlow([chat_data.root_node_id]));
        initialMessageAdded.current = true;
      }
    }
  }, [chat_data.messages, chat_data.currentChatFlow, dispatch, filesObj]);

  const scrollMessageToTheBottom = () => {
    messageEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useLayoutEffect(() => {
    const timeout = setTimeout(scrollMessageToTheBottom, 0);
    return () => clearTimeout(timeout);
  }, [
    chat_data.currentChatFlow,
    chat_data.errorMessageData,
    notificationContent,
  ]);

  //added this for scrolling the msg into the view whose feedback is being sent at the moment
  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      // Scroll to the element with the matching ID
      const messageElement = document.getElementById(
        MessagefeedbackForm.feedbackData.activeMessageId,
      );
      messageElement?.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 0);

    return () => clearTimeout(timeout);
  }, [
    MessagefeedbackForm.feedbackData.activeMessageId,
    MessagefeedbackForm.feedbackData.feedbackCategory,
  ]);

  useEffect(() => {
    if (chat_data.root_node_id !== null && !notificationSocket.socket) {
      dispatch(
        notificationSocketConnection({ chat_id: chat_data.root_node_id }),
      );
    }
  }, [chat_data.root_node_id, notificationSocket.socket]);

  useEffect(() => {
    if (
      chat_data.chat_type === CHAT_TYPE.FILE_UPLOAD &&
      !chat_data.initial_file_status_check
    ) {
      const hasNotificationMessage = chat_data.currentChatFlow.some(
        (messageId) =>
          chat_data.messages[messageId]?.type ===
          CHAT_MESSAGE_TYPE.NOTIFICATION,
      );
      const hasBotMessage = chat_data.currentChatFlow.some(
        (messageId) =>
          chat_data.messages[messageId]?.type === CHAT_MESSAGE_TYPE.BOT,
      );
      // Disable chat if no file is processed
      if (!hasNotificationMessage && !hasBotMessage) {
        dispatch(handleChatDisbale(true));
      } else {
        dispatch(handleChatDisbale(false));
      }
      dispatch(updateInitialFileStatus(true));
      setInitialCheckDone(true);
    }
  }, [chat_data.chat_type, chat_data.currentChatFlow, initialCheckDone]);

  const getNotificationContent = () => {
    if (!chat_data.currentChatFlow || !filesObj) {
      return null;
    }

    const noNotificationMessage =
      chat_data.currentChatFlow?.every(
        (messageId) =>
          chat_data.messages[messageId]?.type !==
          CHAT_MESSAGE_TYPE.NOTIFICATION,
      ) ?? true; // Default to true if currentChatFlow is undefined

    const fileCount = Object.keys(filesObj).length;
    const anyFileProcessed = Object.values(filesObj).some(
      (file) =>
        file.fileProgress.type === UPLOAD_FILES_PROGRESS_STATUS.COMPLETED,
    );
    const allFilesFailed = Object.values(filesObj).every(
      (file) => file.fileProgress.type === UPLOAD_FILES_PROGRESS_STATUS.FAILED,
    );

    if (
      chat_data.chat_type !== CHAT_TYPE.FILE_UPLOAD ||
      !noNotificationMessage
    ) {
      return null;
    }

    if (fileCount > 1) {
      if (allFilesFailed) {
        return "Failed to process files, try again!";
      }
      if (!anyFileProcessed) {
        return "Please wait while files are processing!";
      }
    } else if (fileCount === 1) {
      if (allFilesFailed) {
        return "Failed to process file, try again!";
      }
      if (!anyFileProcessed) {
        return "Please wait while the file is processing!";
      }
    }

    return null;
  };

  useEffect(() => {
    setNotificationContent(getNotificationContent());
  }, [chat_data.currentChatFlow, filesObj]);

  return (
    <div className="tools_consult_messages">
      <div className="tools_consult_messages_wrapper">
        {chat_data.currentChatFlow &&
          chat_data.currentChatFlow.map((id) => {
            const message = chat_data.messages[id];
            if (!message) {
              return null;
            }
            switch (message?.type) {
              case CHAT_MESSAGE_TYPE.BOT:
                return <BotMessage content={message} key={id} />;
              case CHAT_MESSAGE_TYPE.USER:
                return <UserMessage id={id} content={message} key={id} />;
              case CHAT_MESSAGE_TYPE.NOTIFICATION:
                return (
                  <NotificationMessage
                    content={message}
                    key={id}
                    notNotification={false}
                  />
                );
              default:
                return <React.Fragment key={id}></React.Fragment>;
            }
          })}
        {notificationContent && (
          <NotificationMessage
            content={notificationContent}
            notNotification={true}
            key="notification"
          />
        )}
        {chat_data.errorMessageData &&
          chat_data.errorMessageData.length > 0 && (
            <div className="tools_consult_errors_wrapper">
              {chat_data.errorMessageData.map((message, index) => (
                <ErrorMessage content={message} key={`error-${index}`} />
              ))}
            </div>
          )}
        <div ref={messageEndRef}></div>
      </div>
    </div>
  );
};

export default ToolsConsultGptChat;
