import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import initialState, {
  CHAT_ERROR_MESSAGE_TYPE,
  CHAT_MESSAGE_TYPE,
  FILE_UPLOAD_DASHBOARD,
  MESSAGE_FEEDBACK_TYPE,
} from "./common";
import {
  deleteAuthData,
  getAuthData,
  patchAuthData,
  postAuthData,
} from "../../helpers/request";
import toast from "react-hot-toast";
import { io } from "socket.io-client";
import { makeSecureDecrypt } from "../../helpers/security";
import {
  deleteFileOnCancelUpload,
  resetFilesObj,
  updateFileConfidential,
  updateFileStatus,
  updatePreviousFiles,
  updateProcessingFileAfterInclude,
} from "../multiPartUploadDataSlice/multiPartUploadDataSlice";
import { updateChatLabel } from "../toolsDataSlice/toolsDataSlice";
import { UPLOAD_FILES_PROGRESS_STATUS } from "../multiPartUploadDataSlice/common";

export const getConsultChatData = createAsyncThunk(
  "consult-gpt-data/getConsultChatData",
  async ({ chat_id }, thunkAPI) => {
    thunkAPI.dispatch(resetChatData());
    thunkAPI.dispatch(resetFilesObj());
    let url = `${process.env.REACT_APP_CONSULT_URL}/api-client/tools/v1/chat-data/${chat_id}/`;
    try {
      const res = await getAuthData(url);
      if (res.success) {
        thunkAPI.dispatch(updatePreviousFiles(res?.data?.files_data));
        return res?.data;
      } else {
        return thunkAPI.rejectWithValue(
          res?.message || "Error while loading data!",
        );
      }
    } catch (err) {
      throw thunkAPI.rejectWithValue("Something went wrong!");
    }
  },
);

export const startNewConsultChat = createAsyncThunk(
  "chat/startNewConsultChat",
  async ({ chat_id }, thunkAPI) => {
    const data = {
      root_node_id: chat_id,
      timestamp: Date.now(),
    };
    let url = `${process.env.REACT_APP_CONSULT_URL}/api-client/tools/v1/chat/start/`;
    try {
      const res = await postAuthData(url, data);
      if (res.success) {
        thunkAPI.dispatch(updateChatLabel(res?.data));
        return res;
      } else {
        return thunkAPI.rejectWithValue(
          res?.message || "Error while loading data!",
        );
      }
    } catch (err) {
      throw thunkAPI.rejectWithValue("Something went wrong!");
    }
  },
);

export const socketConnection = createAsyncThunk(
  "chat/socketConnection",
  async ({ chat_id }, thunkAPI) => {
    let connectionErrorOccurred = false;

    try {
      const token = JSON.parse(
        makeSecureDecrypt(sessionStorage.getItem("token")),
      );
      let url = `${process.env.REACT_APP_CONSULT_URL}/api-client/tools/chat?token=${token}&chat_id=${chat_id}`;
      const socket = io(url, {
        transports: ["websocket", "polling", "flashsocket"],
      });

      // Handle connection errors
      socket.on("connect_error", (error) => {
        connectionErrorOccurred = true;
        toast.error("Unable to connect to chat, try again later!", {
          id: "socket-connection-error",
        });
        thunkAPI.dispatch(handleChatDisbale(true));
        socket.disconnect();
      });

      socket.on("connect_timeout", () => {
        connectionErrorOccurred = true;
        toast.error("Unable to connect to chat, try again later!", {
          id: "socket-connection-timeout",
        });
        thunkAPI.dispatch(handleChatDisbale(true));
        socket.disconnect();
      });

      // Handle successful connection
      socket.on("connect", () => {
        if (connectionErrorOccurred) {
          toast.success("Connected to chat");
          connectionErrorOccurred = false; // Resetting the flag
          thunkAPI.dispatch(handleChatDisbale(false));
        }
      });

      return socket;
    } catch (error) {
      console.log(error);
      toast.error("Unable to connect to chat");
      throw error;
    }
  },
);

export const notificationSocketConnection = createAsyncThunk(
  "chat/notificationSocketConnection",
  async ({ chat_id }) => {
    try {
      const token = JSON.parse(
        makeSecureDecrypt(sessionStorage.getItem("token")),
      );
      let url = `${process.env.REACT_APP_CONSULT_URL}/api-client/tools/notification?token=${token}&chat_id=${chat_id}`;
      const socket = io(url, {
        transports: ["websocket", "polling", "flashsocket"],
      });
      return socket;
    } catch (error) {
      console.log(error);
      toast.error("Unable to connect to chat");
      throw error;
    }
  },
);

export const getFileDownloadLink = createAsyncThunk(
  "download_link/getFileDownloadLink",
  async ({ file_id }, thunkAPI) => {
    const data = {
      file_id: file_id,
    };
    let url = `${process.env.REACT_APP_CONSULT_URL}/api-client/tools/v1/file/media/`;
    try {
      const res = await postAuthData(url, data);
      if (res.success) {
        thunkAPI.dispatch(updateFilePreviewUrl(res?.data));
        thunkAPI.dispatch(EnableFilePreview(true));
        return res;
      } else {
        toast.error("Can't show preview, try again!");
        return thunkAPI.rejectWithValue(
          res?.message || "Error while loading data!",
        );
      }
    } catch (err) {
      throw thunkAPI.rejectWithValue("Something went wrong!");
    }
  },
);

export const updateFileDownloadLink = createAsyncThunk(
  "download_link/getFileDownloadLink",
  async ({ file_id }, thunkAPI) => {
    const data = {
      file_id: file_id,
    };
    let url = `${process.env.REACT_APP_CONSULT_URL}/api-client/tools/v1/file/media/`;
    try {
      const res = await postAuthData(url, data);
      if (res.success) {
        const downloadReferenceFile = (link) => {
          let a = document.createElement("a");
          a.setAttribute("href", link);
          a.setAttribute("target", "_blank");
          a.click();
          a.remove();
        };
        downloadReferenceFile(res?.data?.s3_link);
        return res;
      } else {
        toast.error("Can't Download file, try again!");
        return thunkAPI.rejectWithValue(
          res?.message || "Error while loading data!",
        );
      }
    } catch (err) {
      return thunkAPI.rejectWithValue("Something went wrong!");
    }
  },
);

export const retryFileProcessing = createAsyncThunk(
  "file-processing/retryFileProcessing",
  async ({ file_id }, thunkAPI) => {
    const data = {
      file: file_id,
      root_node_id: thunkAPI.getState().consultGptData.chat_data.root_node_id,
    };
    try {
      const url = `${process.env.REACT_APP_CONSULT_URL}/api-client/tools/v1/file/process/`;
      const res = await postAuthData(url, data);
      if (res.success) {
        toast.success(res?.message);
        thunkAPI.dispatch(
          updateFileStatus({
            file_id: file_id,
            status: UPLOAD_FILES_PROGRESS_STATUS.PROCESSING,
          }),
        );
        return res;
      } else {
        return thunkAPI.rejectWithValue(
          res?.message || "Error while loading data!",
        );
      }
    } catch (err) {
      return thunkAPI.rejectWithValue("Something went wrong!");
    }
  },
);

export const translateTextMessage = createAsyncThunk(
  "translate/translateTextMessage",
  async ({ chat_id, message_id }, thunkAPI) => {
    const data = {
      chat_id: chat_id,
      message_id: message_id,
    };
    let url = `${process.env.REACT_APP_CONSULT_URL}/api-client/tools/v1/chat/translate/`;
    try {
      const res = await postAuthData(url, data);
      if (res.success) {
        return res;
      } else {
        return thunkAPI.rejectWithValue(
          res?.message || "Error while loading data!",
        );
      }
    } catch (err) {
      throw thunkAPI.rejectWithValue("Something went wrong!");
    }
  },
);

export const sendConsultResponseFeedback = createAsyncThunk(
  "feedback/sendConsultResponseFeedback",
  async ({ chat_id, message_id, feedback, id }, thunkAPI) => {
    const data = {
      chat_id: chat_id,
      message_id: message_id,
      feedback: feedback,
    };
    let url = `${process.env.REACT_APP_CONSULT_URL}/api-client/tools/v1/chat/feedback/`;
    try {
      const res = await postAuthData(url, data);
      if (res.success) {
        toast.success("Feedback sent successfully!", {
          id: "feedback-submit",
        });
        thunkAPI.dispatch(
          updateMessageFeedback({ message_id: message_id, feedback: feedback }),
        );
        thunkAPI.dispatch(updateFeedbackId(res?.data?.feedback_id));
        if (feedback === MESSAGE_FEEDBACK_TYPE.NEGATIVE) {
          thunkAPI.dispatch(setNegativeResponseId(id));
          thunkAPI.dispatch(updateFeedbackFormMessagId(id));
          thunkAPI.dispatch(setFeedbackFormActive(true));
        }
        return res;
      } else {
        return thunkAPI.rejectWithValue(
          res?.message || "Error while loading data!",
        );
      }
    } catch (err) {
      toast.error("Something went wrong!", { id: "failed-feedback" });
      throw thunkAPI.rejectWithValue("Something went wrong!");
    }
  },
);
export const sendConsultResponseFeedbackFormDetails = createAsyncThunk(
  "feedback/sendConsultResponseFeedbackFormDetails",
  async (_, thunkAPI) => {
    const data = {
      chat_id: thunkAPI.getState().consultGptData.chat_data.root_node_id,
      message_id:
        thunkAPI.getState().consultGptData.MessagefeedbackForm.feedbackData
          .activeMessageId,
      feedback: "negative",
      feedback_id:
        thunkAPI.getState().consultGptData.MessagefeedbackForm.feedbackData
          .feedbackId,
      feedback_detail_text:
        thunkAPI.getState().consultGptData.MessagefeedbackForm.feedbackData
          .InputText,
      category:
        thunkAPI.getState().consultGptData.MessagefeedbackForm.feedbackData
          .feedbackCategory,
    };
    console.log(data, "data");
    let url = `${process.env.REACT_APP_CONSULT_URL}/api-client/tools/v1/chat/feedback/`;
    try {
      console.log(1);
      const res = await postAuthData(url, data);
      if (res?.success) {
        toast.success("Feedback sent successfully!", {
          id: "feedback-details-submit",
        });
        return res;
      } else {
        return thunkAPI.rejectWithValue(
          res?.message || "Error while loading data!",
        );
      }
    } catch (err) {
      throw thunkAPI.rejectWithValue("Something went wrong!");
    }
  },
);

export const fileConfidentialStatusUpdate = createAsyncThunk(
  "confidential-status/fileConfidentialUpdate",
  async ({ file_id, status }, thunkAPI) => {
    const data = {
      is_confidential: !status,
    };
    let url = `${process.env.REACT_APP_CONSULT_URL}/api-client/tools/v1/file/media/${file_id}/`;
    try {
      const res = await patchAuthData(url, data);
      if (res.success) {
        toast.success(res?.message);
        thunkAPI.dispatch(
          updateFileConfidential({ file_id: file_id, status: status }),
        );
        return res;
      } else {
        return thunkAPI.rejectWithValue(
          res?.message || "Error while loading data!",
        );
      }
    } catch (err) {
      toast.error("Something went wrong!", {
        id: "confidential-update-error",
      });
      throw thunkAPI.rejectWithValue("Something went wrong!");
    }
  },
);

export const failedProcessingFileDelete = createAsyncThunk(
  "file-delete/failedProcessingFileDelete",
  async ({ file_id }, thunkAPI) => {
    let url = `${process.env.REACT_APP_CONSULT_URL}/api-client/tools/v1/file/media/${file_id}/`;
    try {
      const res = await deleteAuthData(url);
      if (res.success) {
        toast.success(res?.message);
        thunkAPI.dispatch(deleteFileOnCancelUpload({ file_id: file_id }));
        return res;
      } else {
        return thunkAPI.rejectWithValue(
          res?.message || "Error while loading data!",
        );
      }
    } catch (err) {
      throw thunkAPI.rejectWithValue("Something went wrong!");
    }
  },
);

export const getIncludeFileData = createAsyncThunk(
  "include-file-data/getIncludeFileData",
  async ({ chat_id }, thunkAPI) => {
    let url = `${process.env.REACT_APP_CONSULT_URL}/api-client/tools/v1/file/include/${chat_id}/`;
    try {
      const res = await getAuthData(url);
      if (res.success) {
        return res?.data;
      } else {
        return thunkAPI.rejectWithValue(
          res?.message || "Error while loading data!",
        );
      }
    } catch (err) {
      throw thunkAPI.rejectWithValue("Something went wrong!");
    }
  },
);

export const sendFileIncludeData = createAsyncThunk(
  "send-include-file/sendFileIncludeData",
  async (_, thunkAPI) => {
    console.log("first");
    const data = {
      root_node_id: thunkAPI.getState().consultGptData.chat_data.root_node_id,
      files: thunkAPI.getState().consultGptData.included_file_data.IncludedFile,
      chat_type: thunkAPI.getState().consultGptData.chat_data.chat_type,
    };

    let url = `${process.env.REACT_APP_CONSULT_URL}/api-client/tools/v1/file/include/`;
    try {
      const res = await postAuthData(url, data);
      if (res.success) {
        thunkAPI.dispatch(
          updateProcessingFileAfterInclude({
            selected_id:
              thunkAPI.getState().consultGptData.included_file_data
                .IncludedFile,
            include_data:
              thunkAPI.getState().consultGptData.included_file_data.data,
          }),
          thunkAPI.dispatch(
            setActiveUploadDashboard(FILE_UPLOAD_DASHBOARD.UPLOAD),
          ),
        );
      } else {
        return thunkAPI.rejectWithValue(
          res?.message || "Error while loading data!",
        );
      }
    } catch (err) {
      throw thunkAPI.rejectWithValue("Something went wrong!");
    }
  },
);

const consultgptDataSlice = createSlice({
  name: "consult-gpt-data",
  initialState,
  reducers: {
    setActiveFilePane: (state, { payload }) => {
      state.active_file_tab = payload;
    },

    setActiveUploadDashboard: (state, { payload }) => {
      state.active_file_upload_dashboard = payload;
    },

    addMessage: (state, { payload }) => {
      const {
        uuid,
        content,
        translated_content,
        output_references,
        parent_message,
        child_message,
        timestamp,
        type,
        feedback,
      } = payload;
      if (!state.chat_data.messages) {
        state.chat_data.messages = {};
      }
      state.chat_data.messages[uuid] = {
        uuid,
        content,
        parent_message,
        child_message: Array.isArray(child_message) ? child_message : [],
        translated_content: translated_content,
        output_references: output_references,
        type,
        timestamp,
        feedback,
      };
      if (type === CHAT_MESSAGE_TYPE.BOT) {
        state.chat_data.is_chat_input_disabled = false;
      }
    },

    updateChatMessageOnError: (state, action) => {
      const { oldId, newId, updatedMessage } = action.payload;

      if (state.chat_data.messages[oldId]) {
        // Removing old message uuid
        const { [oldId]: removed, ...remainingMessages } =
          state.chat_data.messages;

        // Add new obj identifier with updated data
        state.chat_data.messages = {
          ...remainingMessages,
          [newId]: {
            ...state.chat_data.messages[oldId],
            ...updatedMessage,
            uuid: newId,
          },
        };
        // Updating child_message references in parent msg
        Object.keys(state.chat_data.messages).forEach((key) => {
          const message = state.chat_data.messages[key];
          if (message.child_message) {
            message.child_message = message.child_message.map((id) =>
              id === oldId ? newId : id,
            );
          }
        });
        state.chat_data.currentChatFlow = state.chat_data.currentChatFlow.map(
          (uuid) => (uuid === oldId ? newId : uuid),
        );
        // Updating latest message id
        state.latest_message_id = newId;

        // in case if id not found
      } else {
        console.warn(`Message with id ${oldId} not found`);
      }
    },

    removeLatestMessage: (state) => {
      const latestMessageId = state.latest_message_id;
      // Checking if the latest message exists
      if (state.chat_data.messages[latestMessageId]) {
        // Removing the latest from message onj
        const { [latestMessageId]: removedMessage, ...remainingMessages } =
          state.chat_data.messages;

        // Getting the parent ID of the latest message
        const parentId = removedMessage.parent_message;

        // Updating the parent message's child_message array
        if (parentId && state.chat_data.messages[parentId]) {
          state.chat_data.messages[parentId].child_message =
            state.chat_data.messages[parentId].child_message.filter(
              (childId) => childId !== latestMessageId,
            );
        }
        // Updating the state with the remaining messages
        state.chat_data.messages = remainingMessages;

        // removing the message id from the chat flow
        state.chat_data.currentChatFlow =
          state.chat_data.currentChatFlow.filter(
            (messageId) => messageId !== latestMessageId,
          );
        toast.error("Query cannot exceed 2000 characters");
        state.chat_data.is_chat_input_disabled = false;
      } else {
        console.warn(`Message with id ${latestMessageId} not found`);
      }
    },

    addChatErrorMessage: (state, { payload }) => {
      state.chat_data.errorMessageData = [
        ...state.chat_data.errorMessageData,
        payload,
      ];
    },

    resetChatErrorMessage: (state) => {
      state.chat_data.errorMessageData =
        initialState.chat_data.errorMessageData;
    },

    handleChatDisbale: (state, { payload }) => {
      state.chat_data.is_chat_input_disabled = payload;
    },

    addNotificationMessage: (state, { payload }) => {
      const { uuid, content, file_id, is_processed, type, timestamp } = payload;
      if (!state.chat_data.messages) {
        state.chat_data.messages = {};
      }
      state.chat_data.messages[uuid] = {
        uuid,
        content,
        file_id,
        is_processed,
        type,
        timestamp,
      };
    },
    updateChatCurrentFlow: (state, { payload }) => {
      // console.log(payload, "payload");
      if (payload) {
        const newFlow = payload.filter(
          (id) => !state?.chat_data?.currentChatFlow.includes(id),
        );
        state.chat_data.currentChatFlow = [
          ...state.chat_data.currentChatFlow,
          ...newFlow,
        ];
      }
    },

    updateChatFlowOnThreadSwitch: (state, { payload }) => {
      state.chat_data.currentChatFlow = payload;
    },

    setInitialChatFlow: (state) => {
      if (
        !state.chat_data ||
        !Array.isArray(state.chat_data.currentChatFlow) ||
        typeof state.chat_data.messages !== "object" ||
        !state.latest_message_id
      ) {
        console.error("Can't retireve previous chat");
        return;
      }

      const latestMessageId = state.latest_message_id;
      if (!latestMessageId) {
        console.error("Can't retireve previous chat");
        return;
      }

      const latestMessage = state.chat_data.messages[latestMessageId];
      const isUserMessage = latestMessage.type === CHAT_MESSAGE_TYPE.USER;

      const updatedFlow = [];
      let currentId = latestMessageId;

      while (currentId) {
        const currentMessage = state.chat_data.messages[currentId];
        updatedFlow.push(currentId);
        currentId = currentMessage ? currentMessage.parent_message : null;
      }
      // reverse the msg id order to start from the root message down to the last message
      state.chat_data.currentChatFlow = updatedFlow.reverse();
      if (isUserMessage) {
        const latestMessageWithError = {
          ...latestMessage,
          type: "ERROR",
          error_type: CHAT_ERROR_MESSAGE_TYPE.AI_PROCESSING_FAIL,
        };

        state.chat_data.errorMessageData = [
          ...state.chat_data.errorMessageData,
          latestMessageWithError,
        ];
        state.is_chat_input_disabled = true;
      }
    },

    updateLastestMessageId: (state, { payload }) => {
      state.latest_message_id = payload;
    },
    addNotificationsToFlow: (state) => {
      if (
        !state.chat_data ||
        !state.chat_data.currentChatFlow ||
        !state.chat_data.messages
      ) {
        return;
      }

      let updatedFlow = [...state.chat_data.currentChatFlow];
      const messages = state.chat_data.messages;

      Object.keys(messages).forEach((messageId) => {
        const message = messages[messageId];

        if (message?.type === CHAT_MESSAGE_TYPE.NOTIFICATION) {
          let insertIndex = updatedFlow.length;

          // checking if the message ID is already in the flow
          if (!updatedFlow.includes(messageId)) {
            for (let i = 0; i < updatedFlow.length; i++) {
              const messageIdInFlow = updatedFlow[i];
              const messageInFlow = messages[messageIdInFlow];

              if (message?.timestamp < messageInFlow?.timestamp) {
                insertIndex = i;
                break;
              }
            }

            updatedFlow.splice(insertIndex, 0, messageId);
          }
        }
      });

      state.chat_data.currentChatFlow = updatedFlow;
    },

    updateChatFlowOnEdit: (state, { payload }) => {
      state.chat_data.currentChatFlow = initialState.chat_data.currentChatFlow;
      state.chat_data.currentChatFlow = payload;
    },

    updateMessageChildren: (state, { payload }) => {
      const { parentId, newChildId } = payload;
      const parentMessage = state.chat_data.messages[parentId];
      if (parentMessage) {
        parentMessage.child_message = Array.from(
          new Set([...parentMessage.child_message, newChildId]),
        );
      }
    },

    updateFilePreviewUrl: (state, { payload }) => {
      state.file_preview.file_preview_url = payload.s3_link;
    },

    EnableFilePreview: (state, { payload }) => {
      state.file_preview.is_file_preview_enabled = payload;
    },

    resetChatData: (state) => {
      state.chat_data = initialState.chat_data;
      state.userMessageEdit = initialState.userMessageEdit;
      state.active_file_upload_dashboard =
        initialState.active_file_upload_dashboard;
      state.chatCreation = initialState.chatCreation;
      state.socket = initialState.socket;
      state.active_file_tab = initialState.active_file_tab;
      state.consult_file_pane_sidebar = initialState.consult_file_pane_sidebar;
      state.included_file_data = initialState.included_file_data;
      state.file_preview = initialState.file_preview;
      state.notificationSocket = initialState.notificationSocket;
      state.latest_message_id = initialState.latest_message_id;
    },
    setConsultChatType: (state, { payload }) => {
      state.chat_data.chat_type = payload;
    },

    updateRootChatId: (state, { payload }) => {
      state.chat_data.root_node_id = payload;
    },

    setInitialMessageAdded: (state, { payload }) => {
      state.hasInitialMessage = payload;
    },
    setSocket: (state, { payload }) => {
      state.socket = payload;
    },

    createNewChat: (state, { payload }) => {
      state.chatCreation.isNewChat = payload;
    },
    setEditMessageId: (state, { payload }) => {
      state.userMessageEdit.editMessageId = payload;
    },
    setEditMessageParentId: (state, { payload }) => {
      state.userMessageEdit.editMessageParentId = payload;
    },
    setIsMessageEditMode: (state, { payload }) => {
      state.userMessageEdit.isMessageEditMode = payload;
    },
    resetMessageEditMode: (state, { payload }) => {
      state.userMessageEdit = initialState.userMessageEdit;
    },
    setNegativeResponseId: (state, { payload }) => {
      console.log(payload, "payload");
      state.userMessageEdit.negativeResponseMessageId = payload;
    },
    setEditMessageText: (state, { payload }) => {
      state.userMessageEdit.editMessageText = payload;
    },
    toggleShowConsultFilePane: (state, { payload }) => {
      state.consult_file_pane_sidebar = payload;
    },
    updateIncludeFile: (state, { payload }) => {
      const { file_id } = payload;

      if (!state.included_file_data.IncludedFile.includes(file_id)) {
        state.included_file_data.IncludedFile = [
          ...state.included_file_data.IncludedFile,
          file_id,
        ];
      } else {
        state.included_file_data.IncludedFile =
          state.included_file_data.IncludedFile.filter((id) => id !== file_id);
      }
    },
    setInitialMessageSend: (state, { payload }) => {
      state.chatCreation.initialMessageSend = payload;
    },

    updateChatQueryCount: (state, { payload }) => {
      state.chat_data.query_count = payload;
    },

    updatePreviewReferenceData: (state, { payload }) => {
      const {
        reference_id,
        reference_text,
        reference_page,
        message_id,
        reference_index,
        reference_file_name,
      } = payload;
      const referenceTextArray = reference_text
        .split("\n")
        .filter((text) => text.trim() !== "");
      state.file_preview.reference_file_id = reference_id;
      if (referenceTextArray.length > 0) {
        state.file_preview.preview_file_id = state.file_preview.reference_text =
          referenceTextArray;
      }
      state.file_preview.reference_page = reference_page;
      state.file_preview.reference_msg_id = message_id;
      state.file_preview.reference_index = reference_index;
      state.file_preview.reference_file_name = reference_file_name;
    },
    resetIncludeData: (state) => {
      state.included_file_data.IncludedFile =
        initialState.included_file_data.IncludedFile;
    },
    updateInitialSetupFlag: (state, { payload }) => {
      state.chat_data.is_initial_flow_set = payload;
    },
    updateInitialFileStatus: (state, { payload }) => {
      state.chat_data.initial_file_status_check = payload;
    },

    updateMessageFeedback: (state, { payload }) => {
      const { message_id, feedback } = payload;
      const message = state.chat_data.messages[message_id];

      if (!message) {
        console.error(`Message with ID ${message_id} not found`);
        return state;
      }
      return {
        ...state,
        chat_data: {
          ...state.chat_data,
          messages: {
            ...state.chat_data.messages,
            [message_id]: {
              ...message,
              feedback,
            },
          },
        },
      };
    },
    resetChatReferenceData: (state) => {
      state.file_preview = initialState.file_preview;
    },
    setEditMessageDetails: (state, action) => {
      const {
        isMessageEditMode,
        editMessageId,
        editMessageParentId,
        editMessageText,
      } = action.payload;
      state.userMessageEdit.isMessageEditMode = isMessageEditMode;
      state.userMessageEdit.editMessageId = editMessageId;
      state.userMessageEdit.editMessageParentId = editMessageParentId;
      state.userMessageEdit.editMessageText = editMessageText;
    },

    // ********************FEEDBACK FORM REDUCER  ********************
    updateFeedbackFormMessagId: (state, { payload }) => {
      state.MessagefeedbackForm.feedbackData.activeMessageId = payload;
    },
    setFeedbackFormActive: (state, { payload }) => {
      state.MessagefeedbackForm.feedbackData.isFeedbackFormActive = payload;
    },

    updateFeedbackFormInputText: (state, { payload }) => {
      state.MessagefeedbackForm.feedbackData.InputText = payload;
    },
    updateFeedbackType: (state, { payload }) => {
      state.MessagefeedbackForm.feedbackData.feedbackCategory = payload;
    },
    resetFeedbackFormData: (state) => {
      state.MessagefeedbackForm.feedbackData =
        initialState.MessagefeedbackForm.feedbackData;
    },
    updateFeedbackId: (state, { payload }) => {
      state.MessagefeedbackForm.feedbackData.feedbackId = payload;
    },
  },

  extraReducers: (builder) => {
    // Chat Details
    builder.addCase(getConsultChatData.pending, (state) => {
      state.chat_data.loading = true;
    });
    builder.addCase(getConsultChatData.fulfilled, (state, { payload }) => {
      state.chat_data.messages = payload?.message_data;
      state.chat_data.root_node_id = payload?.chat_data.id;
      state.chat_data.query_count = payload?.chat_data.count;
      state.chat_data.chat_type = payload?.chat_data.type_count;
      state.latest_message_id = payload?.latest_message;
      if (payload?.chat_data.id && !state.chat_data.is_initial_flow_set) {
        state.chat_data.currentChatFlow = [payload.chat_data.id];
      }
    });
    builder.addCase(getConsultChatData.rejected, (state, { payload }) => {
      state.chat_data.loading = false;
      state.chat_data.error = payload;
      toast.error(payload, {
        id: "chat-assignment-error",
      });
    });
    //////Chat Socket//////
    builder.addCase(startNewConsultChat.rejected, (state, { payload }) => {
      state.chatCreation.loading = false;
      state.chatCreation.error = payload;
    });
    //socket connection
    builder.addCase(socketConnection.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(socketConnection.rejected, (state, action) => {
      state.loading = false;
      state.socket.error = false;
    });

    builder.addCase(
      socketConnection.fulfilled,
      (state, { payload }, thunkAPI) => {
        state.loading = false;
        state.socket = payload;
      },
    );

    //// Chat notification socket///////
    builder.addCase(notificationSocketConnection.pending, (state, action) => {
      state.notificationSocket.loading = true;
    });

    builder.addCase(notificationSocketConnection.rejected, (state, action) => {
      state.notificationSocket.error = true;
      state.notificationSocket.loading = false;
    });

    builder.addCase(
      notificationSocketConnection.fulfilled,
      (state, { payload }) => {
        state.notificationSocket.loading = false;
        state.notificationSocket.socket = payload;
        state.notificationSocket.error = false;
      },
    );

    //translation text//

    builder.addCase(translateTextMessage.pending, (state) => {
      state.textTranslation.loading = true;
    });
    builder.addCase(translateTextMessage.fulfilled, (state, { payload }) => {
      state.textTranslation.loading = false;
      if (payload && payload.data) {
        const translateData = payload.data;
        Object.keys(translateData).forEach((key) => {
          const { uuid, translated_content } = translateData[key];
          if (state.chat_data.messages[uuid]) {
            state.chat_data.messages[uuid].translated_content =
              translated_content;
          }
        });
      }
    });
    builder.addCase(translateTextMessage.rejected, (state, { payload }) => {
      state.textTranslation.loading = true;
      state.textTranslation.error = payload;
    });

    ///include file data///
    builder.addCase(getIncludeFileData.pending, (state) => {
      state.included_file_data.loading = true;
    });
    builder.addCase(getIncludeFileData.fulfilled, (state, { payload }) => {
      state.included_file_data.loading = false;
      const transformedData = {};
      payload.forEach((item) => {
        const fileIdentifier = Object.keys(item)[0];
        transformedData[fileIdentifier] = item[fileIdentifier];
      });
      // Update state with transformed data
      state.included_file_data.data = transformedData;
    });
    builder.addCase(getIncludeFileData.rejected, (state, { payload }) => {
      state.included_file_data.loading = false;
      state.included_file_data.error = payload;
    });

    /////////////
    builder.addCase(sendFileIncludeData.pending, (state) => {
      state.included_file_data.include_send_file.loading = true;
    });
    builder.addCase(sendFileIncludeData.fulfilled, (state, { payload }) => {
      state.included_file_data.include_send_file.loading = false;
    });
    builder.addCase(sendFileIncludeData.rejected, (state, { payload }) => {
      state.included_file_data.include_send_file.loading = false;
    });

    //feedback
    builder.addCase(sendConsultResponseFeedback.pending, (state) => {
      state.feedback.loading = true;
    });
    builder.addCase(sendConsultResponseFeedback.fulfilled, (state) => {
      state.feedback.loading = false;
      state.feedback.success = true;
      state.feedback = initialState.feedback;
    });
    builder.addCase(sendConsultResponseFeedback.rejected, (state) => {
      state.feedback.loading = false;
      state.feedback.error = false;
    });
    //feedback form details
    builder.addCase(sendConsultResponseFeedbackFormDetails.pending, (state) => {
      state.MessagefeedbackForm.loading = true;
    });
    builder.addCase(
      sendConsultResponseFeedbackFormDetails.fulfilled,
      (state) => {
        state.MessagefeedbackForm.loading = false;
        state.MessagefeedbackForm.success = true;
        state.MessagefeedbackForm = initialState.MessagefeedbackForm;
      },
    );
    builder.addCase(
      sendConsultResponseFeedbackFormDetails.rejected,
      (state) => {
        state.MessagefeedbackForm.loading = false;
        state.MessagefeedbackForm.error = false;
      },
    );
  },
});

export const {
  setActiveFilePane,
  setActiveUploadDashboard,
  addMessage,
  updateChatMessageOnError,
  removeLatestMessage,
  addChatErrorMessage,
  resetChatErrorMessage,
  addNotificationMessage,
  updateMessageChildren,
  updateChatCurrentFlow,
  setInitialChatFlow,
  updateLastestMessageId,
  resetChatData,
  setConsultChatType,
  updateRootChatId,
  setInitialMessageAdded,
  updateChatFlowOnEdit,
  setSocket,
  createNewChat,
  updateChatFlowOnThreadSwitch,
  setEditMessageId,
  setEditMessageParentId,
  setIsMessageEditMode,
  resetMessageEditMode,
  setNegativeResponseId,
  setEditMessageText,
  toggleShowConsultFilePane,
  updateFilePreviewUrl,
  updateIncludeFile,
  setInitialMessageSend,
  updateChatQueryCount,
  addNotificationsToFlow,
  EnableFilePreview,
  updatePreviewReferenceData,
  handleChatDisbale,
  resetIncludeData,
  updateInitialSetupFlag,
  updateMessageFeedback,
  resetChatReferenceData,
  setEditMessageDetails,
  updateInitialFileStatus,
  //feeback form reducers
  updateFeedbackFormMessagId,
  setFeedbackFormActive,
  updateFeedbackFormInputText,
  updateFeedbackType,
  resetFeedbackFormData,
  updateFeedbackId,
} = consultgptDataSlice.actions;

export default consultgptDataSlice.reducer;
