import React from "react";
import styles from "./TestimonialAutoScroll.module.scss";
import { ClientTestimonialsData } from "../TestimonialData";
import TestimonialCard from "../TestimonialCard/TestimonialCard";
import HomepageQuoteIconOne from "../../../../public_icons/Homepage/Icons/QuoteIcon";

const SliderTrack = ({ ClientTestimonialsData, speed }) => {
  return (
    <section
      style={{ "--speed": `${speed}ms` }}
      className={`${styles.testimonial_scroll_animation_container} ${styles.testimonial_scroll_animation}`}
    >
      {ClientTestimonialsData.map((details, i) => {
        return <TestimonialCard details={details} key={i} />;
      })}
    </section>
  );
};

const TestimonialAutoScroll = () => {
  let speed = 20000;
  return (
    <div className={styles.client_testimonial_section_outermost_section}>
      <div className={styles.client_testimonial_section_overlay}>
        <div className={styles.client_testimonial_content_wrapper}>
          <div className={styles.header_wrapper}>
            <h4 className={styles.heading_text}>
              What our clients say about us
            </h4>
            <div className={styles.quote_icon_wrapper}>
              <HomepageQuoteIconOne />
            </div>
          </div>

          <div className={styles.testimonial_slider_scroll_track}>
            <div className={styles.testimonial_slider_scroll_wrapper}>
              <SliderTrack
                ClientTestimonialsData={ClientTestimonialsData}
                speed={speed}
              />
              <SliderTrack
                ClientTestimonialsData={ClientTestimonialsData}
                speed={speed}
              />
              <SliderTrack
                ClientTestimonialsData={ClientTestimonialsData}
                speed={speed}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialAutoScroll;
