import style from "./Navbar.module.scss";
import { useEffect, useRef, useState } from "react";
import { IoIosSearch } from "react-icons/io";

import { Link, useNavigate } from "react-router-dom";
import { getData } from "../../helpers/request";
import { MdOutlineMenu, MdClose } from "react-icons/md";

import SearchDropdownFileIcon from "../HomePage/SearchDropdownSupport/SearchDropdownFileIcon";
import { PlaceholderInputData } from "./NavbarData";
import { useDispatch, useSelector } from "react-redux";
import { setKeyword } from "../../store/loginHomeDataSlice/loginHomeDataSlice";
import ServiceDropLists from "../ServicesDropdownSupport/ServiceDropLists";

const Navbar = () => {
  const router = useNavigate();
  const dispatch = useDispatch();
  const { pageNav, navShow } = useSelector((state) => state.loginHomeData);
  const [searchInputData, setSearchInputData] = useState(null);
  const [inputFieldisActive, setInputFieldIsActive] = useState(false);
  const [searchSuggestionsData, setSearchSuggestionsData] = useState(null);

  const searchKeywordRef = useRef(null);
  const searchThrotling = useRef(false);
  const capabilityDropdownRef = useRef(null);
  const capabilityIconRef = useRef(null);
  const mobilecapabilityuIconRef = useRef(null);
  // eslint-disable-next-line no-unused-vars
  const [isHoverable, setIsHoverable] = useState(false);
  const searchDropDownRef = useRef();
  const [randomData, setRandomData] = useState([]);
  const [navVisible, setNavVisible] = useState(false);
  const [isCapabilitydropdownActive, setIsCapabilitydropdownActive] =
    useState(false);
  const [navMenubar, setNavMenubar] = useState(false);

  useEffect(() => {
    const shuffleArray = (array) => {
      let currentIndex = array.length,
        temporaryValue,
        randomIndex;
      while (0 !== currentIndex) {
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
      }

      return array;
    };
    const shuffledData = shuffleArray([...PlaceholderInputData])?.slice(0, 10);
    shuffledData.push(shuffledData[0]);
    setRandomData(shuffledData);
  }, []);

  const SearchListItems = ({ title, slug, articleFormat }) => {
    return (
      <Link to={`${process.env.REACT_APP_WEBSITE_URL}/${slug}`} key={slug}>
        <div className={style.search_suggestionItems_wrapper}>
          <div className={style.search_suggestion_item_nav}>
            <div className={style.Navbar_searchSuggestion_Headingwrapper}>
              <SearchDropdownFileIcon articleFormat={articleFormat} />
              <h5 className={style.Navbar_searchSuggestion_TextHeadings}>
                {title.length > 75 ? title.substring(0, 75) + "..." : title}
              </h5>
            </div>
          </div>
        </div>
      </Link>
    );
  };

  const onInputFormHandler = (e) => {
    e.preventDefault();
    const isSearchPage = router.pathname === "/search";
    const loc = JSON.parse(localStorage.getItem("loc"));
    if (searchInputData?.trim().length === 0) {
      setSearchSuggestionsData(null);
      return;
    }
    if (isSearchPage) {
      dispatch(setKeyword(searchInputData));

      searchThrotling.current = true;
      const ThrotlingId = setTimeout(() => {
        searchThrotling.current = false;
        getData(
          `${
            process.env.REACT_APP_WEBSITE_URL
          }/api/web/v1/search-article/?search=${searchInputData?.replace(
            /[&?=]/g,
            " ",
          )}&country=${loc?.country ? loc.country : ""}&city=${
            loc?.city ? loc.city : ""
          }`,
        )
          .then((res) => {
            if (res.success === true) {
              window.location = `${
                process.env.REACT_APP_WEBSITE_URL
              }/search?q=${searchInputData?.replace(" ", "+")}`;

              setSearchInputData("");
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }, 500);
      return () => clearTimeout(ThrotlingId);
    } else {
      dispatch(setKeyword(searchInputData));
      window.location = `${
        process.env.REACT_APP_WEBSITE_URL
      }/search?q=${searchInputData?.replace(" ", "+")}`;
      setSearchInputData("");
    }
  };

  const onSearchBarExtendHandler = (e) => {
    if (inputFieldisActive) {
      onInputFormHandler(e);
    } else {
      setInputFieldIsActive((prev) => !prev);
      setSearchInputData("");
    }
  };

  // will change it later
  // useEffect(() => {
  //   const url = new URL(window.location.href);
  //   const path = url.pathname;
  //   const parts = path.split("/");
  //   const variable = parts[1];
  //   if (variable === "join-us") {
  //     setScrollLength(2000);
  //   } else {
  //     setScrollLength(500);
  //   }
  // }, []);

  // const navbarScrollTop = () => {
  //   const windowScrollTop = window.scrollY;
  //   if (windowScrollTop > 500) {
  //     setNavShow(true);
  //   } else {
  //     setNavShow(false);
  //     setSearchInputData("");
  //     setInputFieldIsActive(false);
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", navbarScrollTop);
  //   return () => window.removeEventListener("scroll", navbarScrollTop);
  // }, [navShow]);

  useEffect(() => {
    const loc = JSON.parse(localStorage.getItem("loc"));
    if (!searchInputData) {
      return;
    }
    if (searchInputData.trim().length === 0) {
      setSearchSuggestionsData(null);
      return;
    }
    searchThrotling.current = true;
    const searchThrotlingId = setTimeout(() => {
      searchThrotling.current = false;
      getData(
        `${
          process.env.REACT_APP_WEBSITE_URL
        }/api/web/v1/search-article/?search=${searchInputData?.replace(
          /[&?=]/g,
          " ",
        )}&country=${loc?.country ? loc.country : ""}&city=${
          loc?.city ? loc.city : ""
        }`,
      )
        .then((res) => {
          if (res?.success === true) {
            setSearchSuggestionsData(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }, 500);

    return () => clearTimeout(searchThrotlingId);
  }, [searchInputData]);

  const onGridMenuHandler = () => {
    setIsCapabilitydropdownActive((prev) => !prev);
  };
  const onMobileGridMenuHandler = () => {
    setIsCapabilitydropdownActive((prev) => !prev);
    setNavMenubar(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        capabilityDropdownRef.current &&
        !capabilityDropdownRef.current.contains(event.target) &&
        capabilityIconRef.current &&
        !capabilityIconRef.current.contains(event.target) &&
        mobilecapabilityuIconRef.current &&
        !mobilecapabilityuIconRef.current.contains(event.target)
      ) {
        setIsCapabilitydropdownActive(false);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [capabilityDropdownRef, capabilityIconRef]);

  const isHoverSupported = () => {
    // Check if the browser supports the 'hover' media feature
    const hoverMediaQuery = window.matchMedia("(hover: hover)");
    // Check if the browser supports the 'any-hover' media feature
    const anyHoverMediaQuery = window.matchMedia("(any-hover: hover)");
    // Return true if either 'hover' or 'any-hover' media feature is supported
    return hoverMediaQuery.matches || anyHoverMediaQuery.matches;
  };

  useEffect(() => {
    // Check if the device supports hover interactions
    const hoverSupported = isHoverSupported();
    // If hover is supported, set the hoverState value to true
    if (hoverSupported === true) {
      setIsHoverable(true);
    } else {
      setIsHoverable(false);
    }
  }, []);
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        searchKeywordRef.current &&
        !searchKeywordRef.current.contains(event.target) &&
        (!searchDropDownRef.current ||
          !searchDropDownRef.current.contains(event.target))
      ) {
        setSearchInputData("");
        setInputFieldIsActive(false);
      }
    };
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [searchKeywordRef]);

  useEffect(() => {
    const handleScroll = () => {
      if (!pageNav && window.scrollY < 500) {
        setNavVisible(false);
        setSearchInputData("");
        setInputFieldIsActive(false);
        setNavMenubar(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [pageNav]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 500 || navShow) {
        setNavVisible(true);
      } else {
        setNavVisible(false);
        setSearchInputData("");
        setInputFieldIsActive(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [navShow]);

  const onNavMenubarHandler = () => {
    setNavMenubar((prev) => !prev);
  };

  return (
    <nav
      className={`${style.navbar_Container} ${
        navVisible ? style.show_nav : style.hidden_nav
      }`}
    >
      {/* <Link
        className={style.navbar_logoContainer}
        to={`${process.env.REACT_APP_WEBSITE_URL}/`}
      >
        <img
          src={process.env.PUBLIC_URL + "/logo.png"}
          alt="EZ LOGO"
          className={style.navbar_logoSection}
          // width={60}
          // height={60}
        />
      </Link> */}
      <div className={style.navbar_logoContainer}>
        <img
          src={process.env.PUBLIC_URL + "/logo.png"}
          alt="EZ LOGO"
          onClick={() =>
            (window.location.href = `${process.env.REACT_APP_WEBSITE_URL}/`)
          }
          className={style.navbar_logoSection}
          // width={60}
          // height={60}
        />
      </div>

      <div className={style.navbar_InputFieldDropDown_Container}>
        <form
          className={`${
            inputFieldisActive
              ? style.navbar_inputFieldWrapper
              : style.navbar_inputFieldMobileWrapper
          } ${searchInputData?.length > 0 && style.navbar_activeInputFields}`}
          ref={searchDropDownRef}
          onSubmit={onInputFormHandler}
        >
          <input
            type="text"
            placeholder={`${inputFieldisActive ? "Search" : ""}`}
            className={` ${
              inputFieldisActive
                ? style.inputFieldMobile_Container
                : style.inputField_Container
            } ${searchInputData?.length > 0 && style.navbar_SearchinputField}`}
            value={searchInputData || ""}
            ref={searchKeywordRef}
            onChange={(e) => setSearchInputData(e.target.value)}
          />
          <IoIosSearch
            className={`${style.inputfield_searchIcon} ${
              searchInputData?.length > 0 && style.nav_iconsearchactive
            } `}
            onClick={onSearchBarExtendHandler}
          />
          {!searchInputData && (
            <div className={style.navbar_Scrolling_Wrapper}>
              <span>Search for </span>
              <div className={style.ScrollPlaceholder_Container}>
                <ul>
                  {randomData.map((item, key) => {
                    return <li key={key}>{`"${item}"`}</li>;
                  })}
                </ul>
              </div>
            </div>
          )}

          {searchInputData && (
            <div
              className={`${
                searchSuggestionsData?.length
                  ? style.navbarDropdown_searchArticleList
                  : style.navbarDropdown_empty_searchArticleList
              }`}
            >
              <div className={style.navbarDropdown_SearchArticleBox}>
                <div
                  className={`${
                    searchSuggestionsData?.length >= 1
                      ? style.navbar_allSearchArticlesContainer
                      : style.navbar_empty_allSearchArticlesContainer
                  }`}
                >
                  {searchInputData &&
                    searchSuggestionsData?.slice(0, 6).map((item, index) => {
                      return (
                        <SearchListItems
                          key={index}
                          title={item.title}
                          img_src={item.image}
                          slug={item.slug}
                          articleFormat={item.article_format}
                        />
                      );
                    })}
                </div>
                {searchSuggestionsData?.length >= 1 && (
                  <Link
                    to={`${process.env.REACT_APP_WEBSITE_URL}/search?q=${searchInputData}`}
                  >
                    <h2
                      className={style.navbar_SearchMoreArticles}
                      onClick={onInputFormHandler}
                    >
                      See more results
                    </h2>
                  </Link>
                )}
                {searchSuggestionsData?.length <= 0 && (
                  <Link
                    to={`${process.env.REACT_APP_WEBSITE_URL}/search?q=${searchInputData}`}
                  >
                    <h2
                      className={style.navbar_empty_SearchMoreArticles}
                      onClick={onInputFormHandler}
                    >
                      See more results
                    </h2>
                  </Link>
                )}
              </div>
            </div>
          )}
        </form>
      </div>
      <div
        className={`${style.nav_menubaricons_container}`}
        onClick={onNavMenubarHandler}
      >
        <span className={style.nav_menusicons_wrapper}>
          {navMenubar ? <MdClose /> : <MdOutlineMenu />}
        </span>
      </div>
      <div className={style.navbar_allIcons_Container}>
        <div
          className={`${style.navbar_secureTranserIcon_Container}`}
          onClick={onGridMenuHandler}
        >
          <span ref={capabilityIconRef}>
            {isCapabilitydropdownActive ? (
              <img
                src="/resources/LoginIcons/70+Serviceslogo.svg"
                className={style.navmenu_icons70fontsize}
                alt="service logo"
              />
            ) : (
              <img
                src="/resources/LoginIcons/70+Servicesbg.svg"
                className={style.navmenu_icons70fontsize}
                alt="bg icons"
              />
            )}
          </span>
        </div>
        <div className={`${style.navbar_secureTranserIcon_Container}`}>
          <a href={`${process.env.REACT_APP_AI_TOOLS}`} aria-label="AI-TOOLS">
            <img
              src="/resources/LoginIcons/ai_light.svg"
              className={style.navmenu_iconsfontsize}
              alt="ai light"
            />
          </a>
        </div>
        <div
          className={`${style.navbar_secureTranserIcon_Container}`}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <img
            src="/resources/LoginIcons/io_light.svg"
            className={style.navmenu_iconsfontsize}
            alt="io light"
          />
        </div>
      </div>
      <div
        className={`${style.navmenubar_dropdown_Container} ${
          navMenubar ? "" : style.navmenubar_dropdown_hide_Container
        }`}
      >
        <div className={`${style.nav_mobiledeviceIcons_Container}`}>
          <div
            className={`${style.navmenu_allIcons_wrapper} ${
              navMenubar ? "visible" : "invisible"
            }`}
            onClick={onMobileGridMenuHandler}
          >
            <div
              className={`${style.navbar_secureTranserIcon_Container}`}
              ref={mobilecapabilityuIconRef}
            >
              <img
                src="/resources/LoginIcons/70+Servicesbg.svg"
                className={style.navmenu_icons70fontsize}
                alt="70 service"
              />
            </div>
            <span>
              <img
                src="/resources/LoginIcons/Icon-awesome-angle-down.svg"
                alt="angle down"
              />
            </span>
          </div>
          <a
            href={`${process.env.REACT_APP_AI_TOOLS}`}
            className={`${style.navmenu_allIcons_wrapper}  ${
              navMenubar ? "visible" : "invisible"
            }`}
            // onClick={() => {
            //   window.scrollTo({ top: 0, behavior: "smooth" });
            // }}
          >
            <div className={`${style.navbar_secureTranserIcon_Container}`}>
              <img
                src="/resources/LoginIcons/ai_light.svg"
                className={style.navmenu_iconsfontsize}
                alt="ai icon"
              />
            </div>
            <span onClick={() => setNavMenubar(false)}>
              {/* <a aria-label="AI Tools"> */}
              <img
                src="/resources/LoginIcons/Icon-awesome-angle-down.svg"
                alt="angle down"
              />
              {/* </a> */}
            </span>
          </a>
          {/* </Link> */}
          {/* <Link href={`${process.env.NEXT_PUBLIC_TOOLS_URL}`}> */}
          <div
            className={`${style.navmenu_allIcons_wrapper}  ${
              navMenubar ? "visible" : "invisible"
            }`}
            // onClick={() =>
            //   handleRedirectionClick(
            //     `${process.env.NEXT_PUBLIC_TOOLS_FRONTEND}`,
            //   )
            // }
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <div className={`${style.navbar_secureTranserIcon_Container}`}>
              <img
                src="/resources/LoginIcons/io_light.svg"
                className={style.navmenu_iconsfontsize}
                alt="ai icon"
              />
            </div>
            <span onClick={() => setNavMenubar(false)}>
              <a aria-label="Secure Transfer">
                <img
                  src="/resources/LoginIcons/Icon-awesome-angle-down.svg"
                  alt="ai tool"
                />
              </a>
            </span>
          </div>
          {/* </Link> */}
        </div>
      </div>
      <>
        <ServiceDropLists
          setShowIconList={setIsCapabilitydropdownActive}
          showIconList={isCapabilitydropdownActive}
          refElement={capabilityDropdownRef}
        />
      </>
    </nav>
  );
};

export default Navbar;
