import React, { useContext, useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import {
  ApiContext,
  getAccessLogData,
  HomeRecentAsgnContext,
} from "../../../index.js";
import {
  ClientDashboardFileContext,
  CLIENT_MODAL_TYPE,
  ModalDispatchContext,
  ClientFilesArrayContext,
} from "../../ClientComponents";
import { getAuthData, postAuthData } from "../../../helpers/request";

import "./EncryptedAssignmentModal.css";

const EncryptedAssignmentModal = () => {
  const modal_dispatch = useContext(ModalDispatchContext);
  const [resend, setResend] = useState(true);
  const [resendTime, setResendTime] = useState(59);
  const API_URL = useContext(ApiContext);
  const { clientDisplayedFile, setClientDisplayedFile } = useContext(
    ClientDashboardFileContext,
  );
  const { setUserId } = useContext(HomeRecentAsgnContext);
  const { setClientFilesArray } = useContext(ClientFilesArrayContext);

  const updateSidebarFiles = () => {
    // bcz need to update the files list as well.
    getAuthData(
      `${API_URL}/api-client/client/v1/all-assignments/?id=${clientDisplayedFile?.id}`,
    )
      .then((res) => {
        if (res.success === true) {
          setClientFilesArray(res.data);
          // setNotFoundError(false);
        } else {
          // setNotFoundError(true);
        }
      })
      .catch((err) => {
        // console.error(err);
        // setNotFoundError(true);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    modal_dispatch({ modal_show_type: CLIENT_MODAL_TYPE.NONE });
    const data = { delivery_id: clientDisplayedFile.batch_id };
    // API CODE BELOW
    postAuthData(
      `${API_URL}/api-client/client/v1/decrypt-key/`,
      getAccessLogData(data),
    )
      .then((res) => {
        if (res.success === true) {
          updateSidebarFiles();
          getAuthData(
            `${API_URL}/api-client/client/v1/all-assignments/${clientDisplayedFile?.id}/`,
          )
            .then((res) => {
              if (res.success === true) {
                setClientDisplayedFile(res.last_assignment);
                setUserId(res.last_assignment);
              }
            })
            .catch((err) => {
              // console.error(err);
            });
        }
      })
      .catch((err) => {
        // console.error(err);
      });
  };

  useEffect(() => {
    setResend(false);
    const timer = setInterval(() => {
      setResendTime((prev) => {
        if (prev > 0) {
          return prev - 1;
        } else {
          setResend(true);
          clearInterval(timer);
          return 59;
        }
      });
    }, 1000);
  }, []);

  const resendVerification = (e) => {
    e.preventDefault();
    setResend(false);
    const data = { delivery_id: clientDisplayedFile.batch_id };
    // API CODE BELOW
    postAuthData(
      `${API_URL}/api-client/client/v1/resend-delivery/`,
      getAccessLogData(data),
    )
      .then((res) => {
        if (res.success === true) {
          getAuthData(
            `${API_URL}/api-client/client/v1/all-assignments/${clientDisplayedFile?.id}/`,
          )
            .then((res) => {
              if (res.success === true) {
                setClientDisplayedFile(res.last_assignment);
                setUserId(res.last_assignment);
              }
            })
            .catch((err) => {
              // console.error(err);
            });
        }
      })
      .catch((err) => {
        // console.error(err);
      });
  };

  return (
    <div className="client-modal-container client-encrypted-modal-container">
      <div className="client-modal-head">
        <div className="client-modal-container-head">Assignment Encrypted!</div>
        <button
          className="client-modal-container-close-btn"
          onClick={handleSubmit}
        >
          <FaTimes />
        </button>
      </div>
      <p>{"The file you're trying to access was delivered 24hrs ago."}</p>
      <p>
        To decrypt the file for download, please use the Secure Key sent via
        email.
      </p>
      <div className="client-encrypted-btn-container">
        {!resend && (
          <div className="client-encrypted-modal-timer-btn" disabled>
            Resend Key ({resendTime} sec)
          </div>
        )}
        {resend && (
          <button
            className="client-modal-btn client-encrypted-modal-resend-btn"
            onClick={resendVerification}
          >
            Resend Key
          </button>
        )}
        <button
          className="client-modal-btn client-encrypted-modal-okay-btn"
          onClick={handleSubmit}
        >
          Okay
        </button>
      </div>
    </div>
  );
};

export { EncryptedAssignmentModal };
