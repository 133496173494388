import React from "react";
import "./LoaderModal.scss";
import LoaderModalComp from "../../../ToolsComponents/FileUpload/UploadSourceFile/LoaderModalComp";

const LoaderModal = () => {
  return (
    <div className="tool_loader_container">
      <LoaderModalComp />
      <div className="assignment_loader">
        <span className="assignment_loader_text">Getting your file ready</span>
        <span className="dots">
          <span className="dot">.</span>
          <span className="dot">.</span>
          <span className="dot">.</span>
        </span>
      </div>
    </div>
  );
};

export default LoaderModal;
