import React from "react";

import { Popover } from "react-tiny-popover";
import { useDispatch } from "react-redux";

import { updateFileDownloadLink } from "../../../../../../store/consultgptDataSlice/consultgptDataSlice";
import { LANGUAGE_PAIR } from "../../../../../../constants/LANGUAGE_PAIR";
import {
  resetConsultNewReqForm,
  setConsultNewReqForm,
  setModal,
} from "../../../../../../store/utilsDataSlice/utilsDataSlice";
import TOOLS_MODALS from "../../../../../ToolsConstants/TOOLS_MODALS";
import { postAuthData } from "../../../../../../helpers/request";
import toast from "react-hot-toast";
import "./DownloadPopover.scss";

const DownloadPopover = ({
  index,
  file_id,
  activeDropdownIndex,
  setActiveDropdownIndex,
}) => {
  const dispatch = useDispatch();
  // const isVerySmallScreen = window?.matchMedia("(max-width: 350px)").matches;

  const fileDropdownStyles = {
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
    backgroundColor: "var(--white)",
    padding: "0.5rem",
    // height: 5.3rem;
    // width: isVerySmallScreen ? "8.5rem" : "9.5rem",
    borderRadius: "4px",
    color: "var(--dark-blue)",
    listStyle: "none",
    // fontSize: isVerySmallScreen ? "var(--rem-sm-font)" : "var(--rem-base-font)",
    // maxHeight: "5.3rem",
    cursor: "pointer",
    marginRight: "1rem",
  };

  const calculateTransformPosition = (position) => {
    // console.log(position.position);
    switch (position?.position) {
      case "right":
        return { top: 20, left: 13 };
      case "left":
        return { top: 20, left: -8 };
      case "top":
        return { top: -10 };
      case "bottom":
        return { top: 10 };
    }
  };

  const handleUpdateDownloadLink = async (e) => {
    e.stopPropagation();
    dispatch(updateFileDownloadLink({ file_id }));
    setActiveDropdownIndex(null);
  };

  const handleOpenRequestModal = () => {
    setActiveDropdownIndex(false);
    fetchConsultDataAndOpenModal(file_id, dispatch);
  };

  //will remove this function in phase 2 development, needed to add it for first version release
  const fetchConsultDataAndOpenModal = async (file_id, dispatch) => {
    try {
      const res = await postAuthData(
        `${process.env.REACT_APP_CONSULT_URL}/api-client/tools/v1/file/detail/`,
        { file_id },
      );

      if (res?.success) {
        const source_language = res?.data?.source_language;
        let languagePair = "";

        switch (source_language) {
          case "english":
            languagePair = LANGUAGE_PAIR.ENGLISH;
            break;
          case "arabic":
            languagePair = LANGUAGE_PAIR.ARABIC;
            break;
          default:
            languagePair = LANGUAGE_PAIR.NONE;
        }

        const consult_instruction = `I want to get this file translated with EZ Assured Language Pair: ${languagePair}`;

        const new_req_data = {
          consult_s3_file_path: res?.data?.file_path,
          consult_file_name: res?.data?.file_name,
          consult_instruction,
        };

        dispatch(setConsultNewReqForm(new_req_data));
        dispatch(setModal(TOOLS_MODALS.NEW_REQ));
      } else {
        toast.error(res?.message || "Error fetching data");
        console.error("Error Fetching Data");
        dispatch(resetConsultNewReqForm());
        dispatch(setModal(TOOLS_MODALS.NEW_REQ));
      }
    } catch (err) {
      console.error(err?.message || "something went wrong!!");
      toast.error("Something went wrong!!");
      dispatch(resetConsultNewReqForm());
      dispatch(setModal(TOOLS_MODALS.NEW_REQ));
    }
  };

  return (
    <Popover
      isOpen={index === activeDropdownIndex}
      containerStyle={{ zIndex: 1 }}
      content={
        <div
          style={fileDropdownStyles}
          className="tools_consult_reference_dropdown_wrapper"
        >
          <li onClick={(e) => handleUpdateDownloadLink(e)}>
            Download Original File
          </li>
          <li onClick={(e) => handleOpenRequestModal(e)}>
            Translate file withEZ Assured
          </li>
        </div>
      }
      positions={["right", "top", "left", "bottom"]}
      onClickOutside={() => setActiveDropdownIndex(null)}
      transform={(position) => calculateTransformPosition(position)}
      transformMode="relative"
    >
      <div className="file_dropdown_option_wrapper">
        <img
          src={
            process.env.PUBLIC_URL +
            "/resources/consult_file_status_icon/download_options.svg"
          }
          alt="icon"
          onClick={(e) => {
            e.stopPropagation();
            setActiveDropdownIndex(
              index === activeDropdownIndex ? null : index,
            );
          }}
        />
      </div>
    </Popover>
  );
};

export default DownloadPopover;
