import React, { useState, useContext } from "react";
import {
  ClientArchiveFileContext,
  // ClientArchiveFilesArrayContext,
  // ClientDashboardFileContext,
  // ModalDispatchContext,
  // CLIENT_MODAL_TYPE,
  // NotFoundErrorContext,
  // ClientArchiveSharingLogContext,
  // ClientArchiveAccessLogContext,
} from "../../ClientComponents";
import HistoryTab from "../components/Tabs/HistoryTab/HistoryTab.js";
import DeliveryTab from "../components/Tabs/DeliveryTab/DeliveryTab.js";
import TopDashboard from "../components/TopDashboard/TopDashboard.js";
import LogsTab from "../components/Tabs/LogsTab/LogsTab.js";

// All contexts
export const SecureKeyContext = React.createContext(); // To store the form information from Deliver File Modal
export const DeliverNewVersionInfoContext = React.createContext(); // To store the form information from New Version Deliver File Modal
export const AutoEncryptTimerContext = React.createContext();

const card_states = {
  SHARE: "share",
  DELIVERY: "delivery",
  HISTORY: "history",
};

const ArchiveDashboard = () => {
  const { clientArchiveFile } = useContext(ClientArchiveFileContext);
  const [showCard, setShowCard] = useState(card_states.DELIVERY);

  return (
    <div className="client-dashboard">
      <TopDashboard
        file={clientArchiveFile}
        showCard={showCard}
        setShowCard={setShowCard}
      />
      <div className="bottom-client-dashboard">
        {showCard === card_states.DELIVERY && (
          <DeliveryTab file={clientArchiveFile} />
        )}
        {showCard === card_states.HISTORY && (
          <HistoryTab file={clientArchiveFile} />
        )}
        {showCard === card_states.SHARE && <LogsTab file={clientArchiveFile} />}
      </div>
    </div>
  );
};

const ClientArchiveDashboard = () => {
  const { clientArchiveFile } = useContext(ClientArchiveFileContext);

  return (
    <>
      {!clientArchiveFile?.id ? <ClientEmptyArchive /> : <ArchiveDashboard />}
    </>
  );
};

const ClientEmptyArchive = () => {
  return (
    <section className="client-empty-dashboard">
      <span className="client-empty-dashbaord-text"></span>
    </section>
  );
};

export { ClientArchiveDashboard, ClientEmptyArchive };
