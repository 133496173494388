import React, { useContext, useState, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import {
  CLIENT_MODAL_TYPE,
  ModalDispatchContext,
} from "../../ClientComponents";

// *******CSS******** //
import "../RevokeRequestErrorModal/RevokeRequestErrorModal.css";

const RevokeRequestErrorModal = () => {
  const modal_dispatch = useContext(ModalDispatchContext);
  const [disableShareBtn, setDisableShareBtn] = useState(false); // eslint-disable-line no-unused-vars

  const shareModalRef = useRef(null);
  const shareSubmitBtn = useRef(null);

  const submitBtnSuccess = () => {
    const shareBtn = shareSubmitBtn.current;
    shareBtn.style.background = "var(--light-orange,#e98742)";
    shareBtn.innerText = "OK";
    setTimeout(() => {
      shareBtn.style.background = "var(--light-blue,#3cc3f2)";
      shareBtn.innerText = "OK";
      closeRevokeRequestErrorModal();
    }, 1000);
  };

  const closeRevokeRequestErrorModal = (e) => {
    e?.preventDefault();
    modal_dispatch({ modal_show_type: CLIENT_MODAL_TYPE.NONE });
  };

  return (
    <div
      className="client-modal-container client-revoke-request-error-modal-container"
      ref={shareModalRef}
    >
      <div className="client-modal-head">
        <div
          className="client-modal-container-head"
          id="client-revoke-reuqest-error-heading"
        >
          Revoke Access
        </div>
        <button
          className="client-modal-container-close-btn"
          id="client-revoke-request-error-heading-close-btn"
          onClick={closeRevokeRequestErrorModal}
        >
          <FaTimes />
        </button>
      </div>
      <p>You cannot revoke your own access</p>
      <div className="client-revoke-request-error-btn-container">
        <button
          className="client-btns"
          id="client-revoke-request-error-modal-cancel-btn"
          onClick={closeRevokeRequestErrorModal}
        >
          Cancel
        </button>
        <button
          className="client-btns"
          ref={shareSubmitBtn}
          id="client-revoke-request-error-modal-okay-btn"
          disabled={disableShareBtn}
          onClick={submitBtnSuccess}
        >
          OK
        </button>
      </div>
    </div>
  );
};

export { RevokeRequestErrorModal };
