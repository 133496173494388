import React from "react";

const FooterGradient = () => {
  return (
    <svg
      width="1225"
      height="628"
      viewBox="0 0 1225 628"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.26" filter="url(#filter0_f_97_37)">
        <path
          d="M988.302 326.924C1212.98 410.979 1315.01 771.945 1315.01 771.945C1315.01 771.945 1727.03 231.474 1423.51 64.8353C1119.99 -101.804 100.951 105.388 100.951 105.388C100.951 105.388 763.624 242.869 988.302 326.924Z"
          fill="url(#paint0_linear_97_37)"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_97_37"
          x="0.951172"
          y="-100"
          width="1634.12"
          height="971.945"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="50"
            result="effect1_foregroundBlur_97_37"
          />
        </filter>
        <linearGradient
          id="paint0_linear_97_37"
          x1="1056.07"
          y1="524.151"
          x2="1105.78"
          y2="-137.127"
          gradientUnits="userSpaceOnUse"
        >
          <stop />
          <stop offset="0.266" stopColor="#4C4C4C" />
          <stop offset="0.858" stopColor="#A4A4A4" />
          <stop offset="1" stopColor="#FDFDFD" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default FooterGradient;
