export const assignment_services = {
  "EZ Works": {
    details: "Video",
    sub_service: null,
    code: "EZW",
  },
  "Language Translation": {
    details:
      "Our team of translators is spread across the globe, making us the forerunner in the field of translation. Our experts are not just linguists, but also well versed in local context and global matters to make sure that translations are contextually accurate. EZ is known for delivering consistent high quality translations for all the major business languages.",
    sub_service: {
      "EZ Lite":
        "Translation is a multistage process that requires a team with various skills to work on the document. <b>EZ Lite is recommended for Internal use documents.</b> The document goes through the minimum stages listed below to ensure they document is comrehensible and easy to understand:<ul><li>Pre-processing (format conversion : pdf/image -> editable)</li><li>Translation</li><li>Flipping & Alignment</li><li>Quality Assurance</li></ul>",
      "EZ Assured":
        "EZ Assured is <b>Client Ready Output</b> recommended for Client documents, Project reports, Client presentations, Project deliverables, Proposal submissions, etc. This quality is achieved through multiple stages listed below:<ul><li>Pre-processing (pdf conversion, basic flipping, etc.)</li><li>Translation</li><li>Editing</li><li>Visual Formatting</li><li>Proofreading</li><li>Quality Assurance</li></ul>",
      Proofreading:
        "A second set of eyes is helpful in identifying mistakes, incomplete messaging or confusing phrasing. When the second set of eyes is that of an EZ Language expert, you can rest assured the output will be top-notch. Proofreading also requires a few stages ensred by us:<ul><li>Editing</li><li>Proofreading</li><li>Visual Formatting</li><li>Quality Assurance</li></ul>",
      Localization:
        "This is the highest level of service recommended only for Report Publications, Marketing collaterals, Websites and other high visibility content. The content is understood by a subject matter expert and re-written in the target language",
      Typing:
        "We have people who speak every major business language. They dont just type out information provided by you, but can also recreate well formatted versions of your scanned documents. Clients also use this service to gather information from the internet or reports into an editable and searchable database.",
      Transcription:
        "Transcriptions Services include the conversion of audio/speech to written format. This is useful for creating interview logs, note taking etc.",
      "Voice Over":
        "In all major business languages with multiple accents. Our Video production teams can further align open source video files to match the timeline of voiceover down to the millisecond!",
      Subtitling:
        "Subtitling is a two stage process, first the audio is converted to text (transcription) and time stamped to the video. Second is the technical process of embedding the text into the video which may be done as a separate file (usually .srt format), of directly onto the video.</br> We can also combine our localization expertise and run a 3 stage process to <b>produce subtitles in languages different from the original audio language.</b> This requires the language exert to understand that context and phrase sentences in a way that makes sense with the timing of video content",
      "Virtual Interpreter":
        "EZ has 1000s of Native language experts on standby. We can have someone join your call at an hour's notice",
      "In-person Interpreters":
        "EZ language experts in all major cities in the world. On 1 days notice, we can have someone join your meeting and interpret live so there is no gap in communication",
      "Event Interpretation":
        "Events for multilingual participants are complex. On top of language expertise in various languages, it requires experience in manging events, equipment such as interpreter booths, multi-channel headsets, and other audio equipment. This also requires planning, setup and coordination with event venue.</br>EZ has pulled off such events at even a weeks notice, but 1 month lead time is advised",
    },
    code: "TR",
  },
  "Translation Services": {
    // old
    details:
      "Our team of translators is spread across the globe, making us the forerunner in the field of translation. Our experts are not just linguists, but also well versed in local context and global matters to make sure that translations are contextually accurate. EZ is known for delivering consistent high quality translations for all the major business languages.",
    sub_service: {
      "EZ Lite":
        "Translation is a multistage process that requires a team with various skills to work on the document. <b>EZ Lite is recommended for Internal use documents.</b> The document goes through the minimum stages listed below to ensure they document is comrehensible and easy to understand:<ul><li>Pre-processing (format conversion : pdf/image -> editable)</li><li>Translation</li><li>Flipping & Alignment</li><li>Quality Assurance</li></ul>",
      "EZ Assured":
        "EZ Assured is <b>Client Ready Output</b> recommended for Client documents, Project reports, Client presentations, Project deliverables, Proposal submissions, etc. This quality is achieved through multiple stages listed below:<ul><li>Pre-processing (pdf conversion, basic flipping, etc.)</li><li>Translation</li><li>Editing</li><li>Visual Formatting</li><li>Proofreading</li><li>Quality Assurance</li></ul>",
      Proofreading:
        "A second set of eyes is helpful in identifying mistakes, incomplete messaging or confusing phrasing. When the second set of eyes is that of an EZ Language expert, you can rest assured the output will be top-notch. Proofreading also requires a few stages ensred by us:<ul><li>Editing</li><li>Proofreading</li><li>Visual Formatting</li><li>Quality Assurance</li></ul>",
      Localization:
        "This is the highest level of service recommended only for Report Publications, Marketing collaterals, Websites and other high visibility content. The content is understood by a subject matter expert and re-written in the target language",
      Typing:
        "We have people who speak every major business language. They dont just type out information provided by you, but can also recreate well formatted versions of your scanned documents. Clients also use this service to gather information from the internet or reports into an editable and searchable database.",
      Transcription:
        "Transcriptions Services include the conversion of audio/speech to written format. This is useful for creating interview logs, note taking etc.",
      "Voice Over":
        "In all major business languages with multiple accents. Our Video production teams can further align open source video files to match the timeline of voiceover down to the millisecond!",
      Subtitling:
        "Subtitling is a two stage process, first the audio is converted to text (transcription) and time stamped to the video. Second is the technical process of embedding the text into the video which may be done as a separate file (usually .srt format), of directly onto the video.</br> We can also combine our localization expertise and run a 3 stage process to <b>produce subtitles in languages different from the original audio language.</b> This requires the language exert to understand that context and phrase sentences in a way that makes sense with the timing of video content",
      "Virtual Interpreter":
        "EZ has 1000s of Native language experts on standby. We can have someone join your call at an hour's notice",
      "In-person Interpreters":
        "EZ language experts in all major cities in the world. On 1 days notice, we can have someone join your meeting and interpret live so there is no gap in communication",
      "Event Interpretation":
        "Events for multilingual participants are complex. On top of language expertise in various languages, it requires experience in manging events, equipment such as interpreter booths, multi-channel headsets, and other audio equipment. This also requires planning, setup and coordination with event venue.</br>EZ has pulled off such events at even a weeks notice, but 1 month lead time is advised",
    },
    code: "TR",
  },
  "Presentation Design": {
    details:
      "Corporate presentations often require infographics and appropriate designing for the information to be comprehensive and yet precise. Our expert designers make sure that your presentation contains all the critical content while keeping your viewers'/audiences' interest intact throughout.",
    sub_service: {
      "Flipping and Formatting":
        "Flipping layout of Arabic-English slides from left-to-right, and vice versa",
      // old
      "Flipping & Formatting":
        "Flipping layout of Arabic-English slides from left-to-right, and vice versa",
      "Basic Design":
        "Creating basic layouts, alignment and consistency check, Cleanup, Scrubbing, etc.",
      "High-End Design":
        "Advanced Visual Enhancement, Template creation, Content restructuring, Iconography, with options",
      "Animated Presentation":
        "Animating Basic elements like text and images, applying slide transitions, and 2D animation effects",
      "Interactive Presentation":
        "Programing elements to be clickable and creating dynamic presentation flow",
      // old
      "Interactive Presentations":
        "Programing elements to be clickable and creating dynamic presentation flow",
    },
    code: "PD",
  },
  "Graphic Design": {
    details:
      "Our research has shown us that people respond the most to visual stimuli. With a greater number of people accessing the using social media, there is a greater need and urgency to create fresh and original content. Our graphic designers cater to this need and urgency most competently.",
    sub_service: {
      Iconography:
        "Graphic Designers with significant business expertise will find creative ways to display information provided by you. Be it for Articles, Reports, Presentations, Workshops, Website pages or any other medium you wish to publish on",
      Infographics:
        "Graphic Designers with significant business expertise will find creative ways to display information provided by you. Be it for Articles, Reports, Presentations, Workshops, Website pages or any other medium you wish to publish on",
      "Creatives & Collateral":
        "Junior Graphic Designer to work on these requests. Use Case: Training material, Social media posts, Content for Internal Distribution, etc.",
      "Design Art":
        "Highly experienced Senior Graphic designer to work on these requests. Use Case: Publications, Marketing Collateral, Website Visual Content, Report Design, Magazines, etc.",
      "UI/UX Design":
        "Highly experienced Senior Graphic designer will work with you to create Intuitive UI/ UX using advanced design tools such as Adobe XD, Figma, etc.",
    },
    code: "GD",
  },
  "Audio-Video Production": {
    details:
      "An inflation in content consumerism has led to an unexpected increase in the number of video content creators. We create impactful, engaging audio-visual content for our clients, enabling them to connect with their viewership. We also provide exclusive audio content for our clients, apart from the audio-visual content.",
    sub_service: {
      "Powerpoint Animation Video":
        "High-ended powerpoint presentation can be animated as per time requirement, and converted to video with background soundtrack",
      "Stock Footage Editing":
        "Editors clean-up and stitch provided video footage into a storyline as per client guidance; multiple output formats will be provided ",
      "Images & Text Animation":
        "EZ creative team will portray client storyline using images and text, highlighting the key messages through visuals, text and appropriate voiceover in all major languages",
      "2D Animation":
        "EZ Graphic artists will create custom visuals as per client requirement, enhance the message using text, animation, sound, and add voice-overs, making for engaging content in all major business languages",
      "Voice Over": "In all major business languages with multiple accents",
    },
    code: "AV",
  },
  "Audio Video Production": {
    // old
    details:
      "An inflation in content consumerism has led to an unexpected increase in the number of video content creators. We create impactful, engaging audio-visual content for our clients, enabling them to connect with their viewership. We also provide exclusive audio content for our clients, apart from the audio-visual content.",
    sub_service: {
      "Powerpoint Animation Video":
        "High-ended powerpoint presentation can be animated as per time requirement, and converted to video with background soundtrack",
      "Stock Footage Editing":
        "Editors clean-up and stitch provided video footage into a storyline as per client guidance; multiple output formats will be provided ",
      "Images & Text Animation":
        "EZ creative team will portray client storyline using images and text, highlighting the key messages through visuals, text and appropriate voiceover in all major languages",
      "2D Animation":
        "EZ Graphic artists will create custom visuals as per client requirement, enhance the message using text, animation, sound, and add voice-overs, making for engaging content in all major business languages",
      "Voice Over": "In all major business languages with multiple accents",
    },
    code: "AV",
  },
  "Audio-Visual Production": {
    // old
    details:
      "An inflation in content consumerism has led to an unexpected increase in the number of video content creators. We create impactful, engaging audio-visual content for our clients, enabling them to connect with their viewership. We also provide exclusive audio content for our clients, apart from the audio-visual content.",
    sub_service: {
      "Powerpoint Animation Video":
        "High-ended powerpoint presentation can be animated as per time requirement, and converted to video with background soundtrack",
      "Stock Footage Editing":
        "Editors clean-up and stitch provided video footage into a storyline as per client guidance; multiple output formats will be provided ",
      "Images & Text Animation":
        "EZ creative team will portray client storyline using images and text, highlighting the key messages through visuals, text and appropriate voiceover in all major languages",
      "2D Animation":
        "EZ Graphic artists will create custom visuals as per client requirement, enhance the message using text, animation, sound, and add voice-overs, making for engaging content in all major business languages",
      "Voice Over": "In all major business languages with multiple accents",
    },
    code: "AV",
  },
  "Research & Analytics": {
    details:
      "EZ Works team comprises ex-consultants, a fleet of expert analysts, experienced in both primary and secondary research. We give topical expertise in local as well as global context. Having picked experts in their respective regions, our Research and Analytics team has its foot firm on the ground, while understanding global markets.",
    sub_service: {
      "Smart Secondary Research":
        "<b>Rapid Research:</b> EZ Analysts will scrape through web for you and extract all available data for a selected topic on demand. </br><b>Sentiment Analysis:</b> Proprietary EZ AI tools can quickly put together a report that helps you understand public sentiment for a selected topic.",
      "Quantitative Research":
        "<b>Questionnaires Design:</b> Experienced EZ Market researchers with local context work with the client to craft surveys that distil key insights</br><b> Web Survey:</b> EZ Operations team works Round the clock to gather data from geographies aroud the world</br><b> On-site survey:</b> EZ Research team has the ability to mobilize feet-on-the-ground across all major cities and gather data from respondents. Our tools assimilate and organize grass-root data into customized dashboards to meet client needs</br><b>Phone/one to one Interview:</b> EZ Researchers across geographies can interact with Subject Matter experts in their local language with local context, with authority on the topics in question",
      "Analysis & Reporting":
        "EZ Analysts bring together various EZ services to compile the data gatherd through Secondary and Quantitative research into insightful reports, with Advanced Data Visualization, High-end Formatting, and quality assurance that allows you to take our reports directly to your clients.",
    },
    code: "RA",
  },
  "Data Processing": {
    details:
      "In the data driven world, collection, cleaning, harmonization, reporting, analysing and creating data dashboards are tasks that are integral yet quite intensive in terms of the effort they require. We at EZ Works are providing quick and efficient services for all your processing needs.",
    sub_service: {
      Digitization:
        "Proprietary Optical Character Reader (OCR) for arabic, developed by EZ AI team, is able to extract important information from scanned or physical copies of documents. Use cases include digitization of old legal documents, medical records, Financial documents, etc.",
      Dashboarding:
        "EZ technology team can quickly put together Web & Mobile-based dashboards, to gather and display data from large number of sources into user-friendly and Intuitive Dashboards. We have developed dashboards for clients to gather and display client feedback, Employee perfromance evaluations, etc.",
      "Data Visualisation":
        "EZ Data scientists are capable of handling large volumes of data, Cleaning and Harmonisation it, and generating reports through advanced visualisation techniques using tools such as Power BI, Tableau, etc. ",
    },
    code: "DP",
  },
  UNKNOWN: {
    details: null,
    sub_service: null,
    code: "UNK",
  },
};
