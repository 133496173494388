import React, { useState } from "react";
// import Service from "./Service/Service";
import styles from "./ServiceDropDown.module.scss";
import { data } from "./data";
// import { RxCross2 } from "react-icons/rx";
import Service from "./Service/Service";
const ServiceDropDown = ({ setShowIconList, showIconList }) => {
  const [selectedService, setSelectedService] = useState(null); // eslint-disable-line
  // const [panelClicked, setPanelClicked] = useState(false);
  // const [touchDevice, setTouchDevice] = useState(false);
  // const serviceDropdownRef = useRef(null);

  // const touchDeviceDectection = () => {
  //   return (
  //     "ontouchstart" in window ||
  //     navigator.maxTouchPoints > 0 ||
  //     navigator.msMaxTouchPoints > 0
  //   );
  // };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (
  //       serviceDropdownRef.current &&
  //       !serviceDropdownRef.current.contains(event.target)
  //     ) {
  //       setShowIconList(false);
  //     }
  //   };
  //   window.addEventListener("click", handleClickOutside);

  //   return () => {
  //     window.removeEventListener("click", handleClickOutside);
  //   };
  // }, [setShowIconList]);

  // const onDropDownunFoldHandler = () => {
  //   setShowIconList(false);
  // };

  return (
    <div
      className={`${styles.service_dropdown} ${
        showIconList && styles.service_dropdown_specialborder
      }`}
    >
      <div className={styles.service_dropdown_inner_wrapper}>
        {selectedService === null &&
          data?.map((headService, index) => {
            return (
              <Service
                key={index}
                headService={headService}
                index={index}
                displayHeading={true}
                setShowIconList={setShowIconList}
              />
            );
          })}
        {/* if there is shortlisting then one services will be displayed according to selectedService state */}
        {/* {selectedService !== null && (
          <div className={styles.service_dropdown_toggle}>
            <h1>Hello</h1>
            <Service
              headService={data[selectedService]}
              index={data.length - 1} //passing this to remove right border from service which is in shortlisting
              displayHeading={false}
              setShowIconList={setShowIconList}
            />
          </div>
        )} */}
        {/* <RightPanel
        selectedService={selectedService}
        setSelectedService={setSelectedService}
        setShowIconList={setShowIconList}
        panelClicked={panelClicked}
        setPanelClicked={setPanelClicked}
      /> */}
      </div>
    </div>
  );
};

export default ServiceDropDown;
