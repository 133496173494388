import React from "react";
import "./LoginModule.scss";
// import "bootstrap/dist/css/bootstrap.min.css";
// import HeroBanner from "./HomePage/HeroBanner/HeroBanner";
import CapabilitiesCentre from "./HomePage/CapabilitiesCentre/CapabilitiesCentre";
import ServiceSection from "./HomePage/ServiceSection/ServiceSection";
import USPModels from "./HomePage/USPModels/USPModels";
import CommercialModals from "./HomePage/CommercialModals/CommercialModals";
import ClientTestimonials from "./HomePage/ClientTestimonials/ClientTestimonials";
import FeaturedArticles from "./HomePage/FeaturedArticles/FeaturedArticles";
import Footers from "./Footers/Footers";
import Navbar from "./Navbar/Navbar";

const LoginModule = () => {
  return (
    <main className="home_page_outer_container">
      <Navbar />
      {/* <HeroBanner /> */}
      <CapabilitiesCentre />
      <ServiceSection />
      <USPModels />
      <CommercialModals />
      <ClientTestimonials />
      <FeaturedArticles />
      <Footers />
    </main>
  );
};

export default LoginModule;
