import React from "react";
import { BiLoaderAlt } from "react-icons/bi";
import "./ConsultLoader.scss";

const ConsultLoader = () => {
  return (
    <div className="tools_consult_loader">
      <BiLoaderAlt className="tools_consult_spin_loader" />
    </div>
  );
};

export default ConsultLoader;
