import Dropzone from "react-dropzone";
import "./ToolsConsultGptFileUpload.scss";
import toast from "react-hot-toast";
import { useEffect, useRef, useState } from "react";
import {
  fetchFileChunkUrls,
  updateFilesObj,
} from "../../../../../../store/multiPartUploadDataSlice/multiPartUploadDataSlice";
import {
  MAX_FILE_COUNT,
  MAX_FILE_SIZE,
  UPLOAD_FILES_PROGRESS_STATUS,
  filesChunksGenerator,
} from "../../../../../../store/multiPartUploadDataSlice/common";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewChat,
  setSocket,
  socketConnection,
} from "../../../../../../store/consultgptDataSlice/consultgptDataSlice";
import {
  CHAT_TYPE,
  FILE_UPLOAD_DASHBOARD,
} from "../../../../../../store/consultgptDataSlice/common";

const ToolsConsultGptFileUpload = ({
  file_text,
  support_text,
  file_size_text,
  supported_languages,
}) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const { filesObj } = useSelector((state) => state.multiPartUploadData);
  const { chat_data, socket, active_file_upload_dashboard } = useSelector(
    (state) => state.consultGptData,
  );

  const [uploadState, setUploadState] = useState({
    started: false,
    completed: false,
  });

  const checkFileCount = (existingFilesObj, newFiles) => {
    const existingFilesCount = Object.keys(existingFilesObj).length;
    const newFilesCount = newFiles.length;
    const totalFilesCount = existingFilesCount + newFilesCount;
    if (totalFilesCount > MAX_FILE_COUNT) {
      toast.error(
        `Can't upload more than ${MAX_FILE_COUNT} files in a single chat`,
        {
          id: "file-count-error",
        },
      );
      return false;
    }
    return true;
  };

  const validateFiles = (files) => {
    const oversizedFiles = files.filter((file) => file.size > MAX_FILE_SIZE);
    if (oversizedFiles.length > 0) {
      toast.error("File size exceeds the maximum allowed size of 200 MB", {
        id: "file-size-error",
      });
    }
    return files.filter((file) => file.size <= MAX_FILE_SIZE);
  };

  const generateAcceptedFormats = (tool_type) => {
    switch (tool_type) {
      case "consult":
        return {
          "application/pdf": [".pdf"],
        };
      default:
        return {};
    }
  };

  const acceptedFormats = generateAcceptedFormats("consult");

  const handleDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      if (
        rejectedFiles.some(
          (file) => file.errors?.[0]?.code === "file-invalid-type",
        )
      ) {
        toast.error("Unsupported file type", { id: "unsupported-tool-file" });
      } else {
        toast.error("Error uploading file", { id: "upload-error" });
      }
      return;
    }

    if (!checkFileCount(filesObj, acceptedFiles)) {
      return;
    }

    const validFiles = validateFiles(acceptedFiles);
    if (validFiles.length === 0) return;

    setFiles([...files, ...validFiles]);
    const filesData = filesChunksGenerator(validFiles);
    dispatch(updateFilesObj(filesData));
    setFiles([]);
    setUploadState({ started: true, completed: false });

    // Clear the file input to allow the same file to be uploaded again
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.target.files);

    // Filter and validate files
    if (!checkFileCount(filesObj, newFiles)) {
      return;
    }
    const validFiles = [];
    newFiles.forEach((file) => {
      if (file.type !== "application/pdf") {
        toast.error(`File "${file.name}" type is unsupported`, {
          id: `unsupported-file-${file.name}`,
        });
      } else {
        validFiles.push(file);
      }
    });

    if (validFiles.length === 0) return;

    const furtherValidatedFiles = validateFiles(validFiles);
    if (furtherValidatedFiles.length === 0) return;

    setFiles([...files, ...furtherValidatedFiles]);

    startChunkUpload(furtherValidatedFiles);
    setFiles([]);

    // Clear the file input to allow the same file to be uploaded again
    e.target.value = "";
  };

  const startChunkUpload = (newFiles) => {
    const filesObject = filesChunksGenerator(newFiles, filesObj);
    setFiles([]);
    dispatch(updateFilesObj(filesObject));
    setUploadState({ started: true, completed: false });
  };

  const onFileUploadHandler = async (event) => {
    event.stopPropagation();
    fileInputRef.current.click();
  };

  const startFileUpload = () => {
    toast.success("File upload in progress. Do not leave this page", {
      id: "consult-upload-started-tool",
    });
    Object.values(filesObj).forEach((fileObj) => {
      if (fileObj.fileProgress.type === UPLOAD_FILES_PROGRESS_STATUS.IN_QUEUE) {
        dispatch(
          fetchFileChunkUrls({ fileObj, root_node_id: chat_data.root_node_id }),
        );
      }
    });
    setUploadState({ started: false, completed: true });
  };

  useEffect(() => {
    const files = Object.values(filesObj);
    const isAnyFileProcessing = files.some(
      (file) =>
        file.fileProgress.type === UPLOAD_FILES_PROGRESS_STATUS.PROCESSING,
    );

    if (
      !socket &&
      isAnyFileProcessing &&
      chat_data.chat_type === CHAT_TYPE.TALK_TO_CONSULT
    ) {
      dispatch(socketConnection({ chat_id: chat_data.root_node_id })).then(
        (response) => {
          const socketConnect = response.payload;
          dispatch(setSocket(socketConnect));
          dispatch(createNewChat(false));
        },
      );
    }
  }, [filesObj, chat_data.chat_type]);

  useEffect(() => {
    if (uploadState.started && !uploadState.completed) {
      startFileUpload();
    }
  }, [uploadState]);

  useEffect(() => {
    if (uploadState.completed) {
      setUploadState({ started: false, completed: false }); // Reset state to allow re-triggering
    }
  }, [uploadState.completed]);

  return (
    <Dropzone
      onDrop={handleDrop}
      accept={acceptedFormats}
      disabled={active_file_upload_dashboard === FILE_UPLOAD_DASHBOARD.INCLUDE}
    >
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          className="tools_consult_file_upload_middle_container"
        >
          <input
            {...getInputProps()}
            multiple={true}
            disabled={
              active_file_upload_dashboard === FILE_UPLOAD_DASHBOARD.INCLUDE
            }
          />
          <div className="tools_consult_file_upload_main">
            {/* <div className="tools_consult_heading_text">
              <span className="tools_consult_span_texts">File Name.pdf</span>
            </div> */}
            <div
              className="tools_consult_upload_file_btn"
              onClick={onFileUploadHandler}
            >
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleFileChange}
                multiple
                accept=".pdf"
                disabled={
                  active_file_upload_dashboard === FILE_UPLOAD_DASHBOARD.INCLUDE
                }
              />
              <img
                className="cloud_upload_img tools_consult_upload_img"
                src={process.env.PUBLIC_URL + "/cloud_upload.svg"}
                alt="upload"
              />
              <span>{file_text}</span>
              <div className="tools_consult_uplaodfile_wrapper">
                <div className="tools_consult_flip_or_section">
                  <span className="tools_consult_or_section_underline"></span>
                  <span className="or_text">OR</span>
                  <span className="tools_consult_or_section_underline"></span>
                </div>
                <div className="flip_upload_btn">
                  <div
                    className={`tool_media_input_wrapper ${
                      active_file_upload_dashboard ===
                        FILE_UPLOAD_DASHBOARD.INCLUDE && "cursor_disallowed"
                    }`}
                  >
                    <input
                      style={{ display: "none" }}
                      type="file"
                      id="tool_media_input_input_file"
                      name="tool_media_input_input_file"
                      multiple
                      disabled={
                        active_file_upload_dashboard ===
                        FILE_UPLOAD_DASHBOARD.INCLUDE
                      }
                    />
                    <span className="tool_media_input_input_btn">
                      Upload File(s)
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tools_consult_file_cretia">
            <span>{support_text}</span>
            <span>{file_size_text}</span>
            <span>{supported_languages}</span>
          </div>
        </div>
      )}
    </Dropzone>
  );
};

export default ToolsConsultGptFileUpload;
