import React from "react";
import { FiLoader } from "react-icons/fi";
import "./ClientError.css";

function ComingSoonError() {
  return (
    <section className="client-maintenance-error-section">
      <div className="client-maintenance-error-msg">
        <span className="client-maintenance-error-msg-big">
          Innovation in Progress
        </span>
        <span className="client-maintenance-error-msg-small">
          Please wait while we bring you the goodies!
        </span>
      </div>
      <img
        className="client-maintenance-error-img"
        src={
          process.env.PUBLIC_URL + "/resources/client-errors/maintenance.png"
        }
        alt="Coming Soon"
      />
    </section>
  );
}

function NotFoundError() {
  return (
    <section className="client-maintenance-error-section">
      <div className="client-maintenance-error-msg">
        <span className="client-maintenance-error-msg-big">
          Looks like something went wrong, Try Again!
        </span>
      </div>
      <img
        className="client-maintenance-error-img"
        src={process.env.PUBLIC_URL + "/resources/client-errors/not-found.png"}
        alt="Not Found"
      />
    </section>
  );
}

function UnknownLinkError() {
  return (
    <section className="client-maintenance-error-section">
      <div className="client-maintenance-error-msg">
        <span className="client-maintenance-error-msg-big">
          {"This is not the link you're looking for"}
        </span>
      </div>
      <img
        className="client-maintenance-error-img"
        src={process.env.PUBLIC_URL + "/resources/client-errors/not-found.png"}
        alt="Not Found"
      />
    </section>
  );
}

function LoaderModal({ errMsg }) {
  return (
    <section className="client-loader-modal">
      <article className="client-loader">
        <div className="spin-loader">
          <FiLoader />
        </div>
        <span>{errMsg}</span>
      </article>
    </section>
  );
}

export { ComingSoonError, NotFoundError, UnknownLinkError, LoaderModal };
