import React from "react";

const HalfFilled = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.407"
      height="30"
      viewBox="0 0 31.407 30"
    >
      <path
        id="Icon_awesome-star-half-alt"
        data-name="Icon awesome-star-half-alt"
        d="M29.8,10.049,21.221,8.8,17.389,1.044a1.882,1.882,0,0,0-3.37,0L10.185,8.8,1.608,10.048a1.875,1.875,0,0,0-1.039,3.2l6.2,6.035L5.3,27.806A1.88,1.88,0,0,0,7.157,30a1.852,1.852,0,0,0,.871-.22L15.7,25.756l7.672,4.025A1.875,1.875,0,0,0,26.1,27.807l-1.467-8.525,6.206-6.034A1.875,1.875,0,0,0,29.8,10.049Zm-7.133,7.219L21.6,18.3l.251,1.458L23,26.406l-5.984-3.14L15.7,22.578l0-18.585,2.99,6.052.655,1.326,1.465.213,6.693.974Z"
        transform="translate(0)"
        fill="#EA7B2C"
      />
    </svg>
  );
};

export default HalfFilled;
