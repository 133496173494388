import React from "react";

const VGNonHoveredTick = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="31"
      viewBox="0 0 31 31"
    >
      <path
        id="Icon_material-check-circle_tick"
        data-name="Icon material-check-circle"
        d="M18,3A15,15,0,1,0,33,18,15.005,15.005,0,0,0,18,3ZM15,25.5,7.5,18l2.115-2.115L15,21.255,26.385,9.87,28.5,12Z"
        transform="translate(-2.5 -2.5)"
        fill="#fff"
        stroke="#fff"
        strokeWidth="1"
      />
    </svg>
  );
};

export default VGNonHoveredTick;
