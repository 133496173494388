import { createSlice } from "@reduxjs/toolkit";
import initialState from "./common";

const loginHomeDataSlice = createSlice({
  name: "login-home-data",
  initialState,
  reducers: {
    setKeyword: (state, { payload }) => {
      state.keyword = payload;
    },
    setModalShow: (state, { payload }) => {
      state.modalShow = payload;
    },
    setNavShow: (state, { payload }) => {
      state.navShow = payload;
    },
    setPageNav: (state, { payload }) => {
      state.pageNav = payload;
    },
    setScrollShow: (state, { payload }) => {
      state.scrollShow = payload;
    },
    setModalTitle: (state, { payload }) => {
      state.modalTitle = payload;
    },
    setIsDeviceHoverable: (state, { payload }) => {
      state.isDeviceHoverable = payload;
    },
    setSerivceActiveIndex: (state, { payload }) => {
      state.serivceActiveIndex = payload;
    },
    setDeltaXPosition: (state, { payload }) => {
      state.deltaXPosition = payload;
    },
    setServiceName: (state, { payload }) => {
      state.serviceName = payload;
    },
    setBtnString: (state, { payload }) => {
      state.btnString = payload;
    },
  },
  extraReducers: () => {
    //   builder.addCase(getChartData.pending, (state) => {
    //     state.loading = true;
    //   });
    //   builder.addCase(getChartData.fulfilled, (state, { payload }) => {
    //     state.loading = false;
    //     state.chart_data[payload.data_type].data = payload.data;
    //   });
    //   builder.addCase(getChartData.rejected, (state, { payload }) => {
    //     state.loading = false;
    //     state.error = payload;
    //   });
  },
});

export const {
  setKeyword,
  setModalShow,
  setNavShow,
  setPageNav,
  setScrollShow,
  setModalTitle,
  setIsDeviceHoverable,
  setSerivceActiveIndex,
  setDeltaXPosition,
  setServiceName,
  setBtnString,
} = loginHomeDataSlice.actions;

export default loginHomeDataSlice.reducer;
