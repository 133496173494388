import React from "react";
import styles from "./ServiceNameCard.module.scss";
import { useSelector } from "react-redux";

const ServiceNameCard = ({ category, desc, id }) => {
  const { serivceActiveIndex } = useSelector((state) => state.loginHomeData);

  return (
    <div className={styles.category_text_wrapper} key={category}>
      <p className={styles.category_text}>{category}</p>
      {id - 1 === serivceActiveIndex && (
        <p className={styles.category_desc_text}>{desc}</p>
      )}
    </div>
  );
};

export default ServiceNameCard;
