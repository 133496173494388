import React, { useEffect, useState, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import {
  resetModal,
  setModal,
} from "../../../../store/utilsDataSlice/utilsDataSlice";
import TOOLS_MODALS from "../../../ToolsConstants/TOOLS_MODALS";
import { useDispatch, useSelector } from "react-redux";
import { getAuthData, postAuthData } from "../../../../helpers/request";
import { useParams } from "react-router-dom";

// import { SHARE_RESPONSE_STATUS } from "../../../../store/shareData/common";
import "./ToolsShareWarningModal.scss";
import toast from "react-hot-toast";
import { getAccessLogData } from "../../../..";
import {
  setAccessLogData,
  setSharedLogData,
} from "../../../../store/userSharedata/userShareDataSlice";
import { SHARE_RESPONSE_STATUS } from "../../../../utils/ShareResponseStatus";

const ToolsShareWarningModal = () => {
  const dispatch = useDispatch();
  const shareSubmitBtn = useRef(null);
  const { asgn_id } = useParams();
  // states used
  const [disableShareBtn, setDisableShareBtn] = useState(false); // eslint-disable-line no-unused-vars
  const [warningData, setWarningData] = useState();
  const [sharedWithinOrg, setWithinOrg] = useState([]);
  const [sharedOutsideOrg, setOutsideOrg] = useState([]);
  const [shareErrNameArr, setShareErrNameArr] = useState([]); // eslint-disable-line no-unused-vars
  const [shareError, setShareError] = useState(null); // eslint-disable-line no-unused-vars
  const [error, setError] = useState(null);
  const maxSharingNames = 3; // eslint-disable-line no-unused-vars
  const { tools_details_data } = useSelector((state) => state.toolsData);
  const { shared_res, from } = useSelector((state) => state.accessLog);
  // setting a limit for names
  // if (sharedWithinOrg.length>maxSharingNames){setShareError(`More than ${maxSharingNames} names shared.`);return}
  // if (sharedOutsideOrg.length>maxSharingNames){setShareError(`More than ${maxSharingNames} names shared.`);return}

  const openShareWarningModal = (e) => {
    e?.preventDefault();
    dispatch(setModal(TOOLS_MODALS.SHARE_WARNING));
  };

  const closeSharingWarningModal = (e) => {
    e?.preventDefault();
    dispatch(resetModal());
  };

  // instantly remove Within_org email and change state
  const removeInsideSharedEmail = (e, idx) => {
    setWithinOrg([
      ...sharedWithinOrg.slice(0, idx),
      ...sharedWithinOrg.slice(idx + 1, sharedWithinOrg.length),
    ]);
  };

  // instantly remove Outside_org email and change state
  const removeOutsideSharedEmail = (e, idx) => {
    setOutsideOrg([
      ...sharedOutsideOrg.slice(0, idx),
      ...sharedOutsideOrg.slice(idx + 1, sharedOutsideOrg.length),
    ]);
  };

  const submitBtnSuccess = () => {
    const shareBtn = shareSubmitBtn.current;
    shareBtn.style.background = "var(--light-orange,#e98742)";
    shareBtn.innerText = "Shared!";
    setTimeout(() => {
      shareBtn.style.background = "var(--light-blue,#3cc3f2)";
      shareBtn.innerText = "Share";
      closeSharingWarningModal();
    }, 1000);
  };

  const fetch_access_log = () => {
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/access/log/${asgn_id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          dispatch(setAccessLogData(res?.data));
        } else {
          toast.error(res?.message || "Error Fetching Data");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetch_shared_log = () => {
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/share/log/${asgn_id}/`,
    )
      .then((res) => {
        if (res?.success === true) {
          dispatch(setSharedLogData(res));
        } else {
          toast.error(res?.message || "Error Fetching Data");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  // data validation
  const validation = (data) => {
    let valid = false;
    if (from === "logs" && !data?.email && data?.email === undefined) {
      setError("No emails present to share the file. Please try again");
      setTimeout(() => {
        setError(null);
        closeSharingWarningModal();
      }, 2000);
      valid = true;
    } else if (from === "share" && data?.emails?.length < 1) {
      setError("No emails present to share the file. Please try again");
      setTimeout(() => {
        setError(null);
        closeSharingWarningModal();
      }, 2000);
      valid = true;
    }
    return valid;
  };

  // sending warning data
  const postWarningData = () => {
    let insideEmails = sharedWithinOrg;
    let outsideEmails = sharedOutsideOrg;
    let sharedEmailList = insideEmails.concat(outsideEmails);

    let newSharedEmail = sharedEmailList.values(); // access the value of array when original_requester accepts the request
    let sharedEmail = newSharedEmail.next().value; // value of the original_requester

    let data_url =
      from === "logs"
        ? `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/share/accept/`
        : `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/share/log/`;
    const getPostData = () => {
      let data = {
        assignment_id: tools_details_data?.data?.tools_assignment_id,
        // client: payload?.share_res?.client,
        accept_warning: true, //
        email: sharedEmailList,
      };

      let accept_request_data = {
        assignment_id: tools_details_data?.data?.tools_assignment_id,
        // client: payload?.share_res?.client,
        accept_warning: true, //
        email: sharedEmail,
      };

      if (from === "logs") {
        return accept_request_data;
      } else {
        return data;
      }
    };

    const validateData = validation(getPostData());
    if (validateData) {
      return;
    }

    // if (devEnv) {
    //   setDisableShareBtn(true);
    //   setOutsideOrg(sharedOutsideOrg);
    //   setWithinOrg(sharedWithinOrg);
    //   setWarningData(warningData);
    //   submitBtnSuccess();
    // } else {
    postAuthData(data_url, getAccessLogData(getPostData()))
      .then((res) => {
        if (res?.status === SHARE_RESPONSE_STATUS.SUCCESS) {
          submitBtnSuccess();
          setDisableShareBtn(false);
          // closeSharingWarningModal();
          fetch_shared_log();
          fetch_access_log();
        } else if (res?.status === SHARE_RESPONSE_STATUS.WARNING) {
          openShareWarningModal();
        } else {
          setShareError(res?.message);
          setDisableShareBtn(false);
          if (res?.emails.length > 0) {
            setShareErrNameArr([...res.emails]);
          }
        }
      })
      .catch((err) => {
        setDisableShareBtn(false);
        console.error(err);
      });
  };

  useEffect(() => {
    // if (devEnv) {
    //   setWarningData(warning_data);
    //   setWithinOrg(warning_data.emails.within_org);
    //   setOutsideOrg(warning_data.emails.outside_org);
    // }

    if (!from) return;
    setWarningData(shared_res);
    setWithinOrg(warningData?.emails?.within_org);
    setOutsideOrg(warningData?.emails?.outside_org);
  }, [from, shared_res, warningData]);

  return (
    <section className="tools_share_warning_modal">
      <div className="tool-client-modal-container tool-client-share-warning-modal-container">
        <div className="client-modal-head">
          <div
            className="client-modal-container-head"
            id="tool-client-share-warning-heading"
          >
            Warning!
          </div>
          <button
            className="tool-client-modal-container-close-btn"
            id="tool-client-share-warning-heading-close-btn"
            onClick={closeSharingWarningModal}
          >
            <FaTimes />
          </button>
        </div>

        <div className="tool-client-share-warning-modal-text-container">
          <div className="client-share-warning-para">
            <span className="client-share-warning-modal-para-span tool-client-share-warning-modal-para-span-orange">
              {"You're sharing the file outside the client entity."}
            </span>
            <span className="client-share-warning-modal-para-span tool-client-share-warning-modal-para-span-orange">
              This might result in a breach of contract and Information
              Security.
            </span>
          </div>
          <div className="client-share-warning-modal-para">
            <p className="client-share-warning-modal-para-span">
              Please ensure that all the people listed below are authorised by{" "}
              <span> {warningData?.client}</span> to receive these files.
              <strong> Remove the people not authorised to access.</strong>
            </p>
          </div>
        </div>

        <div className="tool-client-share-warning-names-container">
          {sharedWithinOrg && sharedWithinOrg.length > 0 && (
            <div className="tool-client-share-warning-name-org-heading">
              Within Organisation
            </div>
          )}

          <div className="tool-client-share-warning-names-list">
            {sharedWithinOrg?.map((item, idx) => (
              <div key={idx} className="tool-client-share-warning-name-item">
                <span className="client-share-warning-name">{item}</span>
                <button
                  className="tool-client-share-warning-remove-btn"
                  onClick={(e) => {
                    removeInsideSharedEmail(e, idx);
                  }}
                >
                  <FaTimes />
                </button>
              </div>
            ))}
          </div>

          {sharedOutsideOrg && sharedOutsideOrg.length > 0 && (
            <div className="client-share-warning-name-org-heading">
              Outside Organisation
            </div>
          )}

          <div className="tool-client-share-warning-names-list">
            {sharedOutsideOrg?.map((item, idx) => {
              return (
                <div key={idx} className="tool-client-share-warning-name-item">
                  <span className="client-share-warning-name">{item}</span>
                  <button
                    className="tool-client-share-warning-remove-btn"
                    onClick={(e) => {
                      removeOutsideSharedEmail(e, idx);
                    }}
                  >
                    {" "}
                    <FaTimes />{" "}
                  </button>
                </div>
              );
            })}
          </div>
          {error && (
            <div className="tool-client-share-warning-modal-err-msg">
              {error}
            </div>
          )}
        </div>

        <div className="tool-client-share-warning-btn-container">
          <button
            className="client-btns"
            id="tool-client-share-warning-modal-cancel-btn"
            onClick={closeSharingWarningModal}
          >
            {" "}
            Cancel{" "}
          </button>
          <button
            className="client-btns"
            id="client-share-warning-modal-okay-btn"
            ref={shareSubmitBtn}
            onClick={() => postWarningData()}
          >
            {" "}
            Share{" "}
          </button>
        </div>
      </div>
    </section>
  );
};

export default ToolsShareWarningModal;
