import React, { useState } from "react";
import FileUpload from "./FileUpload";
import flipUploadData from "./fileUploadData";
import { useSelector } from "react-redux";
import "./FileUpload.scss";

const FileUploadMain = () => {
  const { active_tool_type } = useSelector((state) => state.utilsData);
  const content = flipUploadData[active_tool_type];

  const [tooltipVisible, setTooltipVisible] = useState(false);

  const isTouchDevice = () => {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  };

  const handleMouseEnter = () => {
    if (!isTouchDevice()) {
      setTooltipVisible(true);
    }
  };

  const handleToggletootltip = () => {
    if (isTouchDevice()) {
      setTooltipVisible((prev) => !prev);
    }
  };
  return (
    <>
      {active_tool_type && (
        <div className="flip_upload_container_box">
          <FileUpload
            key={content.id}
            active_tool_type={active_tool_type}
            heading_text={content.heading_text}
            heading_text_2={content.heading_text_2}
            file_text={content.file_text}
            support_text={content.support_text}
            file_size_text={content.file_size_text}
            supported_languages={content.supported_languages}
            file_upload_note={content?.file_upload_note}
          />
          {active_tool_type === "flip" && (
            <div
              className="tooltip_container"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={() => setTooltipVisible(false)}
              onClick={handleToggletootltip}
            >
              <img src="/info.svg" alt="i button" />
              {tooltipVisible && (
                <div className="file_info_tooltip">
                  <ol className="file_info_tooltip_ordered_container">
                    {content.hover_data.map((item, index) =>
                      index === 0 ? (
                        <p
                          key={index}
                          className="file_info_tooltip_non_ordered_item"
                        >
                          {item}
                        </p>
                      ) : (
                        <li key={index} className="file_info_tooltip_list">
                          {item}
                        </li>
                      ),
                    )}
                  </ol>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default FileUploadMain;
