import React, { useState, useRef } from "react";
import { FaTimes } from "react-icons/fa";

import { useDispatch } from "react-redux";
import { resetModal } from "../../../../store/utilsDataSlice/utilsDataSlice";
import "./ToolsRevokeRequestErrorModal.scss";
const ToolsRevokeRequestErrorModal = () => {
  const [disableShareBtn, setDisableShareBtn] = useState(false); // eslint-disable-line no-unused-vars
  const dispatch = useDispatch();
  const shareModalRef = useRef(null);
  const shareSubmitBtn = useRef(null);

  const submitBtnSuccess = () => {
    const shareBtn = shareSubmitBtn.current;
    shareBtn.style.background = "var(--light-orange,#e98742)";
    shareBtn.innerText = "OK";
    setTimeout(() => {
      shareBtn.style.background = "var(--light-blue,#3cc3f2)";
      shareBtn.innerText = "OK";
      closeRevokeRequestErrorModal();
    }, 1000);
  };

  const closeRevokeRequestErrorModal = (e) => {
    e?.preventDefault();
    dispatch(resetModal());
  };

  return (
    <section className="revoke-request-error">
      <div
        className="tool-client-modal-container tool-client-revoke-request-error-modal-container"
        ref={shareModalRef}
      >
        <div className="client-modal-head">
          <div
            className="client-modal-container-head"
            id="tool-client-revoke-reuqest-error-heading"
          >
            Revoke Access
          </div>
          <button
            className="tool-client-modal-container-close-btn"
            id="tool-client-revoke-request-error-heading-close-btn"
            onClick={closeRevokeRequestErrorModal}
          >
            <FaTimes />
          </button>
        </div>
        <p>You cannot revoke your own access</p>
        <div className="tool-client-revoke-request-error-btn-container">
          <button
            className="client-btns"
            id="tool-client-revoke-request-error-modal-cancel-btn"
            onClick={closeRevokeRequestErrorModal}
          >
            Cancel
          </button>
          <button
            className="client-btns"
            ref={shareSubmitBtn}
            id="client-revoke-request-error-modal-okay-btn"
            disabled={disableShareBtn}
            onClick={submitBtnSuccess}
          >
            OK
          </button>
        </div>
      </div>
    </section>
  );
};

export default ToolsRevokeRequestErrorModal;
