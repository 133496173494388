import { useNavigate } from "react-router-dom";
import {
  FeedbackRatingEmoji,
  FileType,
  // FileType,
  date_time_convertor,
  // lazy_load_image,
} from "../../..";
import "./HomeSingleAsgns.scss";
import { assignment_services } from "../../../req_jsons/assignment_services";
// import { assignment_services } from "../../../req_jsons/assignment_services";
const HomeSingleAssgn = ({ data }) => {
  const { id, file_names, unit_details, received_date, feedback } = data;
  const navigate = useNavigate();
  const handleSelectAssgn = () => {
    navigate(`/assignments/${id}`);
  };

  return (
    <div
      onClick={handleSelectAssgn}
      className="files_pane_item files_pane_item-show"
    >
      <div className="file_service_tag">
        <FileType
          filetype={data?.services?.map(
            (service) => assignment_services[service]?.code,
          )}
          // active={data.active}
        />
        {/* <img
          className="lazy-img"
          onLoad={lazy_load_image}
          src={process.env.PUBLIC_URL + "/logo.png"}
          alt="EZ"
        /> */}
      </div>
      <div className="file_feedback">
        <FeedbackRatingEmoji feedback={feedback} />
      </div>
      <div className="file_name">
        <span>
          {`${
            file_names[0].length > 50
              ? file_names[0]?.substr(0, 40) + "..."
              : file_names[0]
          } ${
            file_names?.length > 1 ? "+ " + String(file_names?.length - 1) : ""
          }`}
        </span>
      </div>
      <div className="file_count">
        {unit_details.length < 2 ? (
          unit_details?.map((detail, idx) => {
            return (
              <span key={idx} className="client-sidebar-file-subservice-detail">
                {`${detail?.unit_count} ${detail?.unit_type}`}
                {unit_details.length > 1 && ","}
              </span>
            );
          })
        ) : (
          <>
            {unit_details.slice(0, 2)?.map((detail, idx) => {
              return (
                <span
                  key={idx}
                  className="client-sidebar-file-subservice-detail"
                >
                  {`${detail?.unit_count} ${detail?.unit_type}`},
                </span>
              );
            })}
            <span>...</span>
          </>
        )}
      </div>
      <div className="file_date">
        {" "}
        {date_time_convertor(received_date).split(",").slice(0, 2).join(",")}
      </div>
    </div>
  );
};

export default HomeSingleAssgn;
