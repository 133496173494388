import React from "react";
import "./CreatableSelectInputComp.scss";
import CreatableSelect from "react-select/creatable";

import {
  customSelectStyles,
  customSelectTheme,
} from "../CustomSelectInputComp/CustomSelectStyles";
import toast from "react-hot-toast";
const components = { DropdownIndicator: null };

const CreatableSelectInputComp = ({
  label,
  selectedValue,
  options,
  handleInputChange,
  placeholder,
  defaultValue,
  multi,
  deleteRemovesValue = true,
  backspaceRemovesValue = true,
  nonRemovableValue,
}) => {
  return (
    <div className="creatable-select-from-group">
      {label && <div className="creatable-select-from-label">{label}</div>}
      <CreatableSelect
        onChange={(option) => {
          let check = false;
          option.forEach((elem) => {
            //users email will always be there
            if (elem.label === nonRemovableValue.label) {
              check = true;
            }
          });
          const newOption = [nonRemovableValue, ...option];
          if (!check) {
            toast.error("You cannot remove your own email address.", {
              id: "email-error",
            });

            handleInputChange(newOption);
          } else {
            handleInputChange(option);
          }
        }}
        noOptionsMessage={() => "Enter Email."}
        placeholder={placeholder || label || ""}
        isMulti={multi}
        isSearchable
        defaultValue={defaultValue}
        value={selectedValue}
        options={options}
        className="creatable-select-from-group-select basic-multi-select"
        classNamePrefix="select"
        theme={customSelectTheme}
        styles={customSelectStyles}
        menuPosition={"fixed"}
        backspaceRemovesValue={backspaceRemovesValue}
        deleteRemovesValue={deleteRemovesValue}
        components={components}
      />
    </div>
  );
};

export default CreatableSelectInputComp;
