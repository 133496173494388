import style from "./Footers.module.scss";
import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import {
  setModalShow,
  setModalTitle,
  setServiceName,
} from "../../store/loginHomeDataSlice/loginHomeDataSlice";
import FooterGradient from "../../public_icons/HomePage/Icons/FooterGradient";
import { Link } from "react-router-dom";
// import { GetAQuoteModal } from "../HomePage/GetAQuoteModal/GetAQuoteModal";
import { MODAL_TYPES } from "../../store/loginHomeDataSlice/common";

const Footers = () => {
  const dispatch = useDispatch();
  // const { modalTitle, modalShow } = useSelector((state) => state.loginHomeData);
  // const [showBannerQuoteModal, setShowBannerQuoteModal] = useState(false);

  function handleSendBrief(servicename) {
    dispatch(setServiceName(servicename));
    dispatch(setModalTitle("Send a brief"));
    dispatch(setModalShow(MODAL_TYPES.GET_A_QUOTE));
    // setModalShow(MODAL_TYPES.GET_A_QUOTE);
  }
  return (
    <div className={style.footer_mainContainer}>
      <div className={style.footer_primaryContainer}>
        <div className={style.top_gradient}>
          <FooterGradient />
        </div>
        <div className={style.bottom_gradient}>
          <FooterGradient />
        </div>
        <div className={style.footer_leftPrimary_container}>
          <div className={style.footer_logotext_mainContainer}>
            <div className={style.footer_logoContainer}>
              <img
                src={process.env.PUBLIC_URL + "/logo.png"}
                alt="EZ Footer Logo"
                width={100}
                height={100}
              />
            </div>
            <h4 className={style.footer_logoinsideTextCont}>
              An Extended Team for Business Professionals.
              <br />
              EZ Offers Round the Clock, Pay as you go services ranging from
              Graphics, Video, Language, Content, Research, Data, to Technology,
              through innovation and the use of AI to modernize workflows and
              processes.
            </h4>
          </div>
          <div className={style.footer_textallIconsContainer}>
            <div className={style.footer_contactus_Container}>
              <Link
                to={`${process.env.REACT_APP_WEBSITE_URL}/contact-us`}
                className={style.skyblue_color}
              >
                Contact us
              </Link>
            </div>
            <div className={style.footer_allSocialIcons}>
              <h3 className={style.footer_followusText}>Follow us</h3>
              <div className={style.footer_container_SocialBlock}>
                <Link
                  href="https://www.facebook.com/EZWorksglobal"
                  className={style.footer_Container_Indiv_Icons}
                  aria-label="Facebook"
                >
                  <FaFacebookF />
                </Link>
                <Link
                  href="https://www.linkedin.com/company/ez-works"
                  className={style.footer_Container_Indiv_Icons}
                  aria-label="Linkdin"
                >
                  <FaLinkedinIn />
                </Link>
                <Link
                  href="https://twitter.com/EZ_Official_"
                  className={style.footer_Container_Indiv_Icons}
                  aria-label="Twitter"
                >
                  <FaXTwitter />
                </Link>
                <Link
                  href="https://www.instagram.com/ez_works_/"
                  className={style.footer_Container_Indiv_Icons}
                  aria-label="Instagram"
                >
                  <FaInstagram />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className={style.rightPrimary_container}>
          <div className={style.footer_primaryTopLinksContainer}>
            <div className={style.footer_linksupperparentContainer}>
              <div className={style.footer_linksupperindivparentContainer}>
                <h2 className={style.footer_links_text}>PEOPLE</h2>
                <h2 className={style.footer_links_text}>
                  <Link
                    to={`${process.env.REACT_APP_WEBSITE_URL}/join-us`}
                    className={style.skyblue_color}
                    aria-label="Join EZ"
                  >
                    Join EZ
                  </Link>
                </h2>
                {/* <h2>
                  <Link to={`${process.env.REACT_APP_WEBSITE_URL}team-ez`}>
                    <a className={style.skyblue_color} aria-label="Team EZ">
                      Team EZ
                    </a>
                  </Link>
                </h2> */}
                <h2
                  onClick={() => handleSendBrief("EZ Life")}
                  className={`${style.skyblue_color} ${style.footer_links_text}`}
                >
                  EZ Life
                </h2>
              </div>
              <div className={style.footer_linksupperindivparentContainer}>
                <h2 className={style.footer_links_text}>INNOVATION</h2>
                <h2 className={style.footer_links_text}>
                  <Link
                    to={`${process.env.REACT_APP_WEBSITE_URL}/tech-at-ez`}
                    className={style.skyblue_color}
                    aria-label="Tech at EZ"
                  >
                    Tech at EZ
                  </Link>
                </h2>
                <h2 className={style.footer_links_text}>
                  <Link
                    to={`${process.env.REACT_APP_WEBSITE_URL}/technology/ez-flip`}
                    className={style.skyblue_color}
                    aria-label="EZ Flip"
                  >
                    EZ Flip
                  </Link>
                </h2>
              </div>
              <div className={style.footer_linksupperindivparentContainer}>
                <h2 className={style.footer_links_text}>
                  INFORMATION SECURITY
                </h2>
                <h2 className={style.footer_links_text}>
                  <Link
                    to={`${process.env.REACT_APP_WEBSITE_URL}/ez/iso-27001-2022-certified-company`}
                    className={style.skyblue_color}
                    aria-label="ISO 27001:2022"
                  >
                    ISO 27001:2022
                  </Link>
                </h2>
                <h2
                  onClick={() => handleSendBrief("ISO 9001:2015")}
                  className={style.skyblue_color}
                >
                  ISO 9001:2015
                </h2>
                <h2 className={style.footer_links_text}>
                  <Link
                    to={`${process.env.REACT_APP_WEBSITE_URL}/ez/general-data-protection-regulation`}
                    className={style.skyblue_color}
                    aria-label="GDPR"
                  >
                    GDPR
                  </Link>
                </h2>
              </div>
            </div>
          </div>
          <div className={style.footer_primaryLinksContainer}>
            <div className={style.footer_linksDownHeadingparentContainer}>
              <h2 className={style.footer_links_text}>SERVICES</h2>
            </div>
            <div className={style.footer_linksDownparentContainer}>
              <div className={style.footer_linksupperindivparentContainer}>
                <h2 className={style.footer_links_text}>
                  <Link
                    to={`${process.env.REACT_APP_WEBSITE_URL}/presentation-design-services`}
                    className={style.skyblue_color}
                    aria-label="Presentation Design"
                  >
                    Presentation Design
                  </Link>
                </h2>
                <h2 className={style.footer_links_text}>
                  <Link
                    to={`${process.env.REACT_APP_WEBSITE_URL}/visual-graphics-services`}
                    className={style.skyblue_color}
                    aria-label="Visual Graphics"
                  >
                    Visual Graphics
                  </Link>
                </h2>
                <h2 className={style.footer_links_text}>
                  <Link
                    to={`${process.env.REACT_APP_WEBSITE_URL}/quick-turnaround-videos`}
                    className={style.skyblue_color}
                    aria-label="Animation Videos"
                  >
                    Animation Videos
                  </Link>
                </h2>
                <h2
                  onClick={() => handleSendBrief("Film Production")}
                  className={style.skyblue_color}
                >
                  Film Production
                </h2>
                <h2 className={style.footer_links_text}>
                  <Link
                    to={`${process.env.REACT_APP_WEBSITE_URL}/language-services`}
                    className={style.skyblue_color}
                    aria-label="Language Services"
                  >
                    Language Services
                  </Link>
                </h2>
              </div>
              <div className={style.footer_linksupperindivparentContainer}>
                <h2
                  onClick={() => handleSendBrief("Content Creation")}
                  className={style.skyblue_color}
                >
                  Content Creation
                </h2>
                <h2
                  onClick={() => handleSendBrief("Digital Marketing")}
                  className={style.skyblue_color}
                >
                  Digital Marketing
                </h2>
                <h2 className={style.footer_links_text}>
                  <Link
                    to={`${process.env.REACT_APP_WEBSITE_URL}/research-and-analytics`}
                    className={style.skyblue_color}
                    aria-label="Research & Analytics"
                  >
                    Research & Analytics
                  </Link>
                </h2>
                <h2
                  onClick={() => handleSendBrief("Data Processing")}
                  className={style.skyblue_color}
                >
                  Data Processing
                </h2>
                <h2
                  onClick={() => handleSendBrief("IT Support Desk")}
                  className={style.skyblue_color}
                >
                  IT Support Desk
                </h2>
              </div>
              <div className={style.footer_linksupperindivparentContainer}>
                <h2
                  onClick={() => handleSendBrief("Tech Development")}
                  className={style.skyblue_color}
                >
                  Tech Development
                </h2>
                <h2
                  onClick={() => handleSendBrief("Applied AI")}
                  className={style.skyblue_color}
                >
                  Applied AI
                </h2>
                <h2
                  onClick={() => handleSendBrief("Human Resources")}
                  className={style.skyblue_color}
                >
                  Human Resources
                </h2>
                <h2
                  onClick={() => handleSendBrief("Operations Support")}
                  className={style.skyblue_color}
                >
                  Operations Support
                </h2>
                <h2
                  onClick={() => handleSendBrief("Service Procurement")}
                  className={style.skyblue_color}
                >
                  Service Procurement
                </h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={style.footer_secondaryContainer}>
        <div className={style.footer_HeadingSecondaryContainer}>
          <h2 className={style.footer_links_text}>© 2024 ArabEasy LLC</h2>
        </div>
        <div className={style.footer_termsPolicyContainer}>
          <h2 className={style.footer_termsemptylinkContainer}>&#8203;</h2>
          <h2 className={style.footer_termspolicylinkContainer}>
            <Link
              to={`${process.env.REACT_APP_WEBSITE_URL}/terms-of-use`}
              className={style.footer_LinkTagsText}
              aria-label="Terms of Use"
            >
              Terms of Use
            </Link>
          </h2>
          <h2 className={style.footer_termspolicylinkContainer}>
            <Link
              to={`${process.env.REACT_APP_WEBSITE_URL}/privacy-policy`}
              className={style.footer_LinkTagsText}
              aria-label=" Privacy Policy"
            >
              Privacy Policy
            </Link>
          </h2>
        </div>
      </div>
      {/* <GetAQuoteModal
        title={modalTitle}
        show={modalShow === MODAL_TYPES.GET_A_QUOTE}
        onHide={() => dispatch(setModalShow(MODAL_TYPES.NULL))}
      /> */}
    </div>
  );
};

export default Footers;
