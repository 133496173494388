import React from "react";
import "./ToolsTabNav.scss";
import ToolsTabHomeNav from "../../ToolsComponents/ToolsTabHomeNav/ToolsTabHomeNav";

const ToolsTabNav = () => {
  return (
    <section>
      <ToolsTabHomeNav />
    </section>
  );
};

export default ToolsTabNav;
