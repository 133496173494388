import React from "react";

const Gradient = () => {
  return (
    <svg
      width="1674"
      height="986"
      viewBox="0 0 1674 986"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5" filter="url(#filter0_f_61_6)">
        <path
          d="M1234.04 313.12C1508.52 411.881 1633.16 836 1633.16 836C1633.16 836 2136.51 200.971 1765.72 5.17773C1394.93 -190.616 150 52.8306 150 52.8306C150 52.8306 959.557 214.365 1234.04 313.12Z"
          fill="url(#paint0_linear_61_6)"
          fillOpacity="0.8"
        />
      </g>
      <defs>
        <filter
          id="filter0_f_61_6"
          x="0"
          y="-221"
          width="2052"
          height="1207"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="75"
            result="effect1_foregroundBlur_61_6"
          />
        </filter>
        <linearGradient
          id="paint0_linear_61_6"
          x1="1316.83"
          y1="544.853"
          x2="1373.03"
          y2="-232.445"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#112949" />
          <stop offset="0.266" stopColor="#183F74" />
          <stop offset="0.858" stopColor="#3CC3F2" />
          <stop offset="1" stopColor="#FDFDFD" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Gradient;
