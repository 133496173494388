import React, { useEffect, useState } from "react";
import "./ToolsModule.scss";
import TOOLS_PAGES from "./ToolsConstants/TOOLS_PAGE";
import ToolsHome from "./ToolsPages/ToolsHome/ToolsHome";
import ToolsFileDashboard from "./ToolsPages/ToolsFileDashboard/ToolsFileDashboard";
import ToolsFileUpload from "./ToolsPages/ToolsFileUpload/ToolsFileUpload";
import ToolsModals from "./ToolsPages/ToolsModals/ToolsModals";
import ToolsTabNav from "./ToolsPages/ToolsTabNav/ToolsTabNav";
// import TOOLS_MODALS from "./ToolsConstants/TOOLS_MODALS";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveToolType,
  setModal,
  setToolsPage,
} from "../store/utilsDataSlice/utilsDataSlice";
import { makeSecureDecrypt } from "../helpers/security";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getProfileData } from "../store/profileDataSlice/profileDataSlice";
import {
  getToolsFileList,
  setSearchText,
} from "../store/toolsDataSlice/toolsDataSlice";
import redirectionEnum from "../utils/redirectionEnum";
// import { devEnvNew } from "..";
import { postAuthData } from "../helpers/request";
import toast from "react-hot-toast";
import TOOLS_MODALS from "./ToolsConstants/TOOLS_MODALS";
// import getDeviceResponsiveness, {
//   DEVICE_RESPONSIVENESS,
// } from "../utils/getDeviceResponsiveness";

const ToolsModulePageComp = ({ page, tool_type }) => {
  switch (page) {
    case TOOLS_PAGES.HOME:
      return <ToolsHome />;
    case TOOLS_PAGES.TAB_HOME:
      return <ToolsTabNav tool_type={tool_type} />;
    case TOOLS_PAGES.TAB_FILE_DASHBOARD:
      return <ToolsFileDashboard tool_type={tool_type} />;
    case TOOLS_PAGES.TAB_FILE_UPLOAD:
      return <ToolsFileUpload tool_type={tool_type} />;
    // case TOOLS_PAGES.TAB_NEW_REQUEST:
    //   return (
    //     <ToolsModals modal_type={TOOLS_MODALS.NEW_REQ} tool_type={tool_type} />
    //   );
    default:
      return <ToolsHome />;
  }
};

const ToolsModule = ({ page, tool_type }) => {
  const { modal_type } = useSelector((state) => state.utilsData);
  const [tokenValidate, setTokenValidate] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const checkToken = () => {
    if (
      sessionStorage.getItem("token") &&
      makeSecureDecrypt(sessionStorage.getItem("token"))
    ) {
      setTokenValidate(true);
      return true;
    } else if (page) {
      if (!sessionStorage.getItem("redirect")) {
        sessionStorage.setItem("redirect", window.location.pathname);
      }
      navigate("/");
      setTokenValidate(false);
      return false;
    } else {
      navigate("/");
      setTokenValidate(false);
      return false;
    }
  };

  const handleResetSearchedFiles = () => {
    // if (getDeviceResponsiveness() === DEVICE_RESPONSIVENESS.MOBILE) return;
    dispatch(
      getToolsFileList({
        tool_type: tool_type,
        search_text: null,
      }),
    );
    dispatch(setSearchText(null)); // to clear the search in files pane
  };

  useEffect(() => {
    dispatch(setActiveToolType(tool_type));
    // dispatch(getProfileData());
  }, [tool_type]);

  useEffect(() => {
    let validate = checkToken(); // in order to avoid multiple api calls upon no validation
    if (!validate) return;
    dispatch(setToolsPage(page));
    handleResetSearchedFiles();
  }, [page]);

  const handleAiTools = () => {
    //function for redirecting user to specific assignment when he redirects from ai.ez.works
    const assignmentId = searchParams.get("assignment_id");
    if (assignmentId) {
      localStorage.setItem("assignment_token", assignmentId);
    }
    const getAssignmentIdFromLocal = localStorage.getItem("assignment_token");
    const assignmentBody = {
      assignment_key: getAssignmentIdFromLocal,
    };

    const removeAssignmentIdFromUrl = () => {
      //to delete the wrong assignment_id from params and stop api call on reload
      const params = new URLSearchParams(window.location.search);
      params.delete("assignment_id");
      navigate(`${window.location.pathname}?${params.toString()}`, {
        replace: true,
      });
    };
    if (getAssignmentIdFromLocal) {
      dispatch(setModal(TOOLS_MODALS.LOADER));
      postAuthData(
        `${process.env.REACT_APP_API_URL}/api-client/client/v1/tools-assignment/`,
        assignmentBody,
      )
        .then((res) => {
          if (res?.success) {
            if (res?.is_assignment_added) {
              const redirectionUrl = `/tools/${
                redirectionEnum[res?.is_assignment_added?.tool_name]
              }/${res?.is_assignment_added?.id}`;
              localStorage.removeItem("assignment_token");
              toast.success("Assignment added Successfully", {
                id: "assgn-added",
              });
              navigate(redirectionUrl);
            } else {
              toast.error("Assignment was not found", { id: "assgn-not" });
              console.error("Assignment was not found");
              localStorage.removeItem("assignment_token");
              removeAssignmentIdFromUrl(); // deleting assignment from url when is_assignment_added is not true
            }
          } else {
            toast.error("Assignment was not found", { id: "assgn-not" });
            console.error("Error Fetching Data");
            localStorage.removeItem("assignment_token");
            removeAssignmentIdFromUrl(); //only deleting assignment from url when assignment key is invalid
          }
        })
        .catch((err) => {
          console.error(err);
          toast.error("Something went Wrong!!!", { id: "asgn-err" });
          localStorage.removeItem("assignment_token");
        })
        .finally(() => {
          dispatch(setModal(TOOLS_MODALS.NONE));
        });
    }
  };

  useEffect(() => {
    handleAiTools();
  }, []);
  useEffect(() => {
    // chekcing token validation
    let validate = checkToken();
    if (!validate) return;
    // adding token to session storage when a component page is loaded and local storage already has the tokken saved for the client
    localStorage.getItem("token") &&
      makeSecureDecrypt(localStorage.getItem("token")) &&
      sessionStorage.setItem("token", localStorage.getItem("token"));
    // getting the client profile
    dispatch(getProfileData());
  }, []);

  return (
    <section className="tools_module">
      {tokenValidate && modal_type && (
        <ToolsModals modal_type={modal_type} tool_type={tool_type} />
      )}
      {tokenValidate && (
        <ToolsModulePageComp page={page} tool_type={tool_type} />
      )}
    </section>
  );
};

export default ToolsModule;
