import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import initialState, {
  UPLOAD_FILES_PROGRESS_STATUS,
  formatDuration,
} from "./common";
import toast from "react-hot-toast";
import axios from "axios";
import { postAuthData } from "../../helpers/request";
import { updateChatLabel } from "../toolsDataSlice/toolsDataSlice";

const reAttachFile = ({ original_files_obj, updated_json_file_obj }) => {
  Object.keys(original_files_obj).forEach((file_id) => {
    updated_json_file_obj[file_id].file = original_files_obj[file_id].file;
  });
  return updated_json_file_obj;
};

const updateChunkDataInFileObj = ({
  file_obj_state,
  chunk_id,
  file_id,
  chunk_update,
}) => {
  let updatedFileObjState = JSON.parse(JSON.stringify(file_obj_state));
  const chunk_idx = Object.keys(updatedFileObjState[file_id].chunks).indexOf(
    chunk_id,
  );
  // updating chunks progress
  updatedFileObjState[file_id].chunks[chunk_id] = {
    ...updatedFileObjState[file_id].chunks[chunk_id],
    ...chunk_update,
  };
  // updating file progress based on chunk upload
  updatedFileObjState[file_id].fileProgress = {
    ...updatedFileObjState[file_id].fileProgress,
    cur_uploading_chunk: chunk_idx + 1,
    total_uploaded_chunk: Object.values(
      updatedFileObjState[file_id].chunks,
    ).filter(
      (chunk) => chunk.chunkProgress === UPLOAD_FILES_PROGRESS_STATUS.SUCCESS,
    ).length,
  };
  // attaching file bytes into state again
  updatedFileObjState = reAttachFile({
    original_files_obj: file_obj_state,
    updated_json_file_obj: updatedFileObjState,
  });
  return updatedFileObjState;
};

const updateFileObj = ({
  file_obj_state,
  file_id,
  file_data_update,
  file_s3_path,
  fileUploadId,
}) => {
  let updatedFileObjState = JSON.parse(JSON.stringify(file_obj_state));

  // Create an updated file object
  const updatedFile = {
    ...updatedFileObjState[file_id],
    ...(file_s3_path !== undefined &&
      file_s3_path !== null && { file_s3_path }),
    ...(fileUploadId !== undefined &&
      fileUploadId !== null && { fileUploadId }),
    ...file_data_update,
  };
  // Update the state with the new file object
  updatedFileObjState[file_id] = updatedFile;
  // Reattach file object state if needed
  updatedFileObjState = reAttachFile({
    original_files_obj: file_obj_state,
    updated_json_file_obj: updatedFileObjState,
  });

  return updatedFileObjState;
};

// const updateFileObj = ({
//   file_obj_state,
//   file_id,
//   file_data_update,
//   file_s3_path,
// }) => {
//   let updatedFileObjState = JSON.parse(JSON.stringify(file_obj_state));

//   const existingFile = updatedFileObjState[file_id];

//   updatedFileObjState[file_id] = {
//     ...existingFile,
//     ...(file_s3_path ? { file_s3_path } : {}), // Only update if file_s3_path is not null
//     ...(file_data_update.fileUploadId
//       ? { fileUploadId: file_data_update.fileUploadId }
//       : {}), // Only update if fileUploadId is not null
//     ...file_data_update,
//   };

//   console.log(file_s3_path, "file_s3_path");

//   console.log(updatedFileObjState[file_id], "specific file data");

//   updatedFileObjState = reAttachFile({
//     original_files_obj: file_obj_state,
//     updated_json_file_obj: updatedFileObjState,
//   });

//   return updatedFileObjState;
// };

// ###### UPLOADING CHUNKS RECURRING FUNCTION ###############

export const fetchFileChunkUrls = createAsyncThunk(
  "multipartupload-data/fetchFileChunkUrls",
  async ({ fileObj }, thunkAPI) => {
    const file_data = {
      root_node_id: thunkAPI.getState().consultGptData.chat_data.root_node_id,
      tool_service: "consult",
      file_name: fileObj.fileName,
      file_size: fileObj.fileSize,
      chunk_size: parseInt(process.env.REACT_APP_CONSULT_MEDIA_CHUNK_SIZE),
    };

    const res = await postAuthData(
      `${process.env.REACT_APP_CONSULT_URL}/api-client/tools/v1/file/upload/get/`,
      file_data,
    );

    if (!res.success || !res.part_urls) {
      console.error("Error fetching chunk URLs:", res);
      thunkAPI.dispatch(
        updateFileStatus({
          file_id: file_data?.fileIdentifier,
          status: UPLOAD_FILES_PROGRESS_STATUS.UPLOAD_FAILED,
        }),
      );
      throw new Error("Failed to fetch chunk URLs");
    }

    let file_chunks_data = JSON.parse(JSON.stringify(fileObj.chunks));
    res.part_urls?.forEach(
      (url, i) =>
        (file_chunks_data[
          `${Object.values(file_chunks_data)[i].chunkIdentifier.chunkId}`
        ].chunkUploadLink = url),
    );

    const file_obj_state = thunkAPI.getState().multiPartUploadData.filesObj;
    // s3_file_path = res?.s3_file_path;
    const updatedFileObjState = updateFileObj({
      file_obj_state: file_obj_state,
      file_id: fileObj.fileIdentifier,
      file_s3_path: res?.s3_file_path,
      fileUploadId: res.upload_id,
      file_data_update: {
        chunks: file_chunks_data,
        fileProgress: {
          cur_uploading_chunk: 0,
          total_uploaded_chunk: 0,
          type: UPLOAD_FILES_PROGRESS_STATUS.UPLOADING,
        },
      },
    });
    thunkAPI.dispatch(updateFilesObj(updatedFileObjState));
    thunkAPI.dispatch(
      uploadChunksFn({
        file_data: updatedFileObjState[fileObj?.fileIdentifier],
      }),
    );
    return {
      file_id: fileObj?.fileIdentifier,
      chunk_urls: res?.urls,
      uploadId: res?.upload_id,
    };
  },
);

export const uploadChunksFn = createAsyncThunk(
  "multipartupload-data/uploadChunksFn",
  async ({ file_data, chunk_num_to_upload = 0 }, thunkAPI) => {
    const uploadChunk = async (chunk_data) => {
      const sendAxios = axios.create();
      let fileChunkSlice = file_data.file.slice(
        chunk_data.chunkSliceInfo.initialPoint,
        chunk_data.chunkSliceInfo.finalPoint,
        file_data.file.type,
      );
      return sendAxios.put(chunk_data.chunkUploadLink, fileChunkSlice, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
    };

    const checkAllChunkProgress = ({ chunks }) => {
      let checked_all = Object.values(chunks).some((chunk_data) => {
        return (
          chunk_data.chunkProgress === UPLOAD_FILES_PROGRESS_STATUS.IN_QUEUE ||
          chunk_data.chunkProgress === UPLOAD_FILES_PROGRESS_STATUS.UPLOADING
        );
      });
      return checked_all;
    };

    let chunk_keys = Object.keys(file_data.chunks);
    let attempts_count = 0;

    const recurringUploadFn = async (chunk_num) => {
      const latestFileData =
        thunkAPI.getState().multiPartUploadData.filesObj[
          file_data.fileIdentifier
        ];
      if (latestFileData?.is_upload_cancelled) {
        thunkAPI.dispatch(
          deleteFileOnCancelUpload({ file_id: file_data.fileIdentifier }),
        );
        return;
      }
      const chunk_data = file_data.chunks[chunk_keys[chunk_num]];
      const startTime = Date.now();
      // we need chunk_num and not the chunk id bcz it will do recurring based on the next key in the arr.
      if (attempts_count > 3) {
        console.error(`Failed to upload chunk after 3 attempts:`, chunk_data);
        let updatedFileObjState = updateChunkDataInFileObj({
          file_obj_state: thunkAPI.getState().multiPartUploadData.filesObj,
          file_id: chunk_data.chunkIdentifier.fileId,
          chunk_id: chunk_data.chunkIdentifier.chunkId,
          chunk_update: {
            chunkProgress: UPLOAD_FILES_PROGRESS_STATUS.FAILED,
          },
        });

        updatedFileObjState = updateFileObj({
          file_obj_state: updatedFileObjState,
          file_id: file_data.fileIdentifier,
          file_data_update: {
            fileProgress: {
              cur_uploading_chunk: null,
              total_uploaded_chunk: null,
              type: UPLOAD_FILES_PROGRESS_STATUS.FAILED,
            },
          },
        });
        thunkAPI.dispatch(updateFilesObj(updatedFileObjState));
        // throw new Error("Failed to upload chunk");
      }
      try {
        const res = await uploadChunk(chunk_data);
        //END
        const endTime = Date.now();
        const duration = endTime - startTime;
        const remainingTime = formatDuration(
          duration,
          chunk_num,
          file_data?.totalChunks,
        );
        thunkAPI.dispatch(
          updateChunkUploadDuration({
            file_id: file_data?.fileIdentifier,
            remainingTime: remainingTime,
          }),
        );
        let partData = {
          ETag: JSON.parse(res.headers.etag),
          PartNumber: chunk_num + 1,
        };
        attempts_count = 0;
        let updatedFileObjState = updateChunkDataInFileObj({
          file_obj_state: thunkAPI.getState().multiPartUploadData.filesObj,
          file_id: chunk_data.chunkIdentifier.fileId,
          chunk_id: chunk_data.chunkIdentifier.chunkId,
          chunk_update: {
            chunkUploadData: partData,
            chunkProgress: UPLOAD_FILES_PROGRESS_STATUS.SUCCESS,
          },
        });
        thunkAPI.dispatch(updateFilesObj(updatedFileObjState));
        const check_chunks_all_success = checkAllChunkProgress({
          chunks: updatedFileObjState[chunk_data.chunkIdentifier.fileId].chunks,
        });
        const updatedFileData =
          thunkAPI.getState().multiPartUploadData.filesObj[
            file_data.fileIdentifier
          ];
        if (!updatedFileData?.is_upload_cancelled) {
          if (check_chunks_all_success) {
            recurringUploadFn(chunk_num + 1);
          } else {
            thunkAPI.dispatch(
              sendFileCombination({
                file_obj_data: updatedFileObjState,
                file_id: file_data.fileIdentifier,
              }),
            );
            return updatedFileObjState;
          }
        } else {
          thunkAPI.dispatch(
            deleteFileOnCancelUpload({ file_id: file_data.fileIdentifier }),
          );
        }
      } catch (error) {
        console.error(`Error uploading chunk:`, error, chunk_data);
        attempts_count = attempts_count + 1;
        // const retryFileData =
        //   thunkAPI.getState().multiPartUploadData.filesObj[
        //     file_data.fileIdentifier
        //   ];
        // if (retryFileData?.is_upload_cancelled) {
        // recurringUploadFn(chunk_num); // Retry uploading the same chunk
        // }
        thunkAPI.dispatch(
          updateFileStatus({
            file_id: file_data?.fileIdentifier,
            status: UPLOAD_FILES_PROGRESS_STATUS.UPLOAD_FAILED,
          }),
        );
      }
    };
    recurringUploadFn(chunk_num_to_upload);
  },
);

// const nextUploadFile = (file_obj_state) => {
//   const next_file = Object.values(file_obj_state).find(
//     (file_obj) =>
//       file_obj.fileProgress.type !== UPLOAD_FILES_PROGRESS_STATUS.SUCCESS &&
//       file_obj.fileProgress.type !== UPLOAD_FILES_PROGRESS_STATUS.UPLOADING,
//   );
//   return next_file;
// };

export const sendFileCombination = createAsyncThunk(
  "multipartupload-data/sendFileCombination",
  async ({ file_obj_data, file_id }, thunkAPI) => {
    const file_data = file_obj_data[file_id];
    const checkAllFileProgress = (file_obj_state) => {
      // if there is still some queued file pending to be uploaeded then it will return true
      let checked_all = Object.values(file_obj_state).some((file_data) => {
        return (
          file_data.fileProgress.type ===
            UPLOAD_FILES_PROGRESS_STATUS.IN_QUEUE ||
          file_data.fileProgress.type === UPLOAD_FILES_PROGRESS_STATUS.UPLOADING
        );
      });
      return checked_all;
    };
    const combination_url = `${process.env.REACT_APP_CONSULT_URL}/api-client/tools/v1/file/upload/complete/`;

    const combined_chunk_data = {
      s3_file_path:
        thunkAPI.getState().multiPartUploadData.filesObj[
          file_data?.fileIdentifier
        ].file_s3_path,
      parts: Object.values(file_data.chunks).map(
        (chunk) => chunk.chunkUploadData,
      ),
      root_node_id: thunkAPI.getState().consultGptData.chat_data.root_node_id,
      upload_id: file_data.fileUploadId,
      chat_type: thunkAPI.getState().consultGptData.chat_data.chat_type,
      is_confidential: file_data?.is_confidential,
    };

    postAuthData(combination_url, combined_chunk_data).then(async (res) => {
      if (res.success) {
        if (res?.assignment_data) {
          thunkAPI.dispatch(updateChatLabel(res?.assignment_data));
        }
        const oldFileId = file_data?.fileIdentifier;
        const key = Object.keys(res.data)[0];
        const newFileId = res.data[key]?.fileIdentifier;
        const newFileProgressType = res.data[key]?.fileProgress?.type;
        if (checkAllFileProgress(file_obj_data)) {
          const updatedFileObjState = updateFileObj({
            file_obj_state: file_obj_data,
            file_id: file_data.fileIdentifier,
            file_data_update: {
              fileProgress: {
                cur_uploading_chunk: 0,
                total_uploaded_chunk: Object.values(file_data.chunks).length,
                type: UPLOAD_FILES_PROGRESS_STATUS.SUCCESS,
              },
            },
          });
          await thunkAPI.dispatch(updateFilesObj(updatedFileObjState));

          thunkAPI.dispatch(
            updateFileIdentifier({
              oldFileId,
              newFileId,
              newFileProgressType,
            }),
          );
          // const next_file_to_upload = nextUploadFile(updatedFileObjState);
          // if (next_file_to_upload) {
          //   thunkAPI.dispatch(
          //     fetchFileChunkUrls({ fileObj: next_file_to_upload }),
          //   );
          // }
          toast.success("File Upload successful!");
          return updatedFileObjState;
        }
      } else {
        thunkAPI.dispatch(
          updateFileStatus({
            file_id: file_data?.fileIdentifier,
            status: UPLOAD_FILES_PROGRESS_STATUS.UPLOAD_FAILED,
          }),
        );
      }
    });
  },
);

const multiPartUploadDataSlice = createSlice({
  name: "multipartupload-data",
  initialState,
  reducers: {
    updateFilesObj: (state, { payload }) => {
      state.filesObj = { ...state.filesObj, ...payload };
    },
    updateChunkUploadDuration: (state, { payload }) => {
      const { file_id, remainingTime } = payload;
      if (file_id && state.filesObj[file_id]) {
        state.filesObj[file_id].remainingUploadTime = remainingTime;
      }
    },

    //to update the previous file in files obj
    updatePreviousFiles: (state, { payload }) => {
      payload.forEach((fileData) => {
        const fileId = Object.keys(fileData)[0];
        state.filesObj[fileId] = fileData[fileId];
        state.trackingFiles[fileId] = fileId;
      });
    },

    //to update the file ids as per the database file_id
    updateFileIdentifier: (state, { payload }) => {
      const { oldFileId, newFileId, newFileProgressType } = payload;
      const updatedFilesObj = state.filesObj;

      // Updating the file identifier throughout the file object
      state.trackingFiles[oldFileId] = newFileId;

      if (
        Object.keys(state.trackingFiles).length !==
        Object.keys(updatedFilesObj).length
      )
        return;

      Object.entries(state.trackingFiles).forEach(([oldFileId, newFileId]) => {
        if (newFileId !== oldFileId) {
          updatedFilesObj[newFileId] = updatedFilesObj[oldFileId];
          delete updatedFilesObj[oldFileId];
          updatedFilesObj[newFileId].fileIdentifier = newFileId;

          Object.entries(updatedFilesObj[newFileId].chunks).forEach(
            ([key, value]) => {
              value.chunkIdentifier.fileId = newFileId;
            },
          );

          if (updatedFilesObj[newFileId]) {
            updatedFilesObj[newFileId].fileProgress.type = newFileProgressType;
          }
        }
      });

      // Reset trackingFiles in the state
      state.trackingFiles = {};

      Object.keys(updatedFilesObj).forEach((key) => {
        state.trackingFiles[key] = key;
      });

      // Updating the filesobj state
      state.filesObj = updatedFilesObj;
    },

    cancelFileUpload: (state, { payload }) => {
      const { file_id } = payload;
      if (state.filesObj[file_id]) {
        state.filesObj[file_id].is_upload_cancelled = true;
      }
    },

    deleteFileOnCancelUpload: (state, { payload }) => {
      const { file_id } = payload;

      // Delete from filesObj if it exists
      if (state.filesObj[file_id]) {
        delete state.filesObj[file_id];
      }

      // Delete from trackingFiles if it exists
      if (state.trackingFiles[file_id]) {
        delete state.trackingFiles[file_id];
      }
    },

    updateProcessingFileAfterInclude: (state, { payload }) => {
      const { include_data, selected_id } = payload;
      let filesToAdd = {};
      // Extracting IDs from payload and ensuring that it's an array
      if (selected_id && Array.isArray(selected_id)) {
        // Loop through each ID in the payload
        selected_id.forEach((id) => {
          // Checking if the ID exists in included_file_data
          if (include_data[id]) {
            // Adding the file data to filesToAdd object with ID as key
            filesToAdd[id] = include_data[id];
          }
        });
      }
      state.filesObj = { ...filesToAdd, ...state.filesObj };
    },

    resetFilesObj: (state) => {
      state.filesObj = initialState.filesObj;
      state.trackingFiles = initialState.trackingFiles;
    },
    updateFileConfidential: (state, { payload }) => {
      const { file_id, status } = payload;
      if (state.filesObj[file_id]) {
        // Toggle the is_confidential key
        state.filesObj[file_id].is_confidential = !status;
      }
    },
    updateFileProcessingState: (state, { payload }) => {
      const { file_id, status } = payload;

      // Find the specific file object
      if (state.filesObj[file_id]) {
        if (status) {
          state.filesObj[file_id].fileProgress.type =
            UPLOAD_FILES_PROGRESS_STATUS.COMPLETED;
        } else if (!status) {
          state.filesObj[file_id].fileProgress.type =
            UPLOAD_FILES_PROGRESS_STATUS.FAILED;
        }
      } else {
        console.error(`File with ID ${file_id} not found.`);
      }
    },

    updateFileStatus: (state, { payload }) => {
      const { file_id, status } = payload;

      // Find the specific file object
      if (state.filesObj[file_id]) {
        if (status) {
          state.filesObj[file_id].fileProgress.type = status;
        }
      } else {
        console.error(`File with ID ${file_id} not found.`);
      }
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchFileChunkUrls.pending, (state) => {
      state.chunkUrlLoader = true;
    });
    builder.addCase(fetchFileChunkUrls.fulfilled, (state, { payload }) => {
      state.chunkUrlLoader = false;
    });
    builder.addCase(fetchFileChunkUrls.rejected, (state) => {
      // toast.error("Failed to load urls!!");
      state.chunkUrlLoader = false;
    });
  },
});

export const {
  updateFilesObj,
  updateChunkUploadDuration,
  cancelFileUpload,
  updateFileIdentifier,
  updatePreviousFiles,
  updateProcessingFileAfterInclude,
  resetFilesObj,
  updateFileConfidential,
  updateFileProcessingState,
  updateFileStatus,
  deleteFileOnCancelUpload,
} = multiPartUploadDataSlice.actions;

export default multiPartUploadDataSlice.reducer;
