import React from "react";
import "./ConsultResponseEditModal.scss";
import { useDispatch, useSelector } from "react-redux";
import TOOLS_MODALS from "../../../ToolsConstants/TOOLS_MODALS";
import { setModal } from "../../../../store/utilsDataSlice/utilsDataSlice";
import { CHAT_MESSAGE_TYPE } from "../../../../store/consultgptDataSlice/common";
import {
  handleChatDisbale,
  setEditMessageDetails,
  setEditMessageId,
  setEditMessageParentId,
  setIsMessageEditMode,
  setNegativeResponseId,
} from "../../../../store/consultgptDataSlice/consultgptDataSlice";

const ConsultResponseEditModal = () => {
  const dispatch = useDispatch();
  const { userMessageEdit, chat_data } = useSelector(
    (state) => state.consultGptData,
  );

  const closeModal = () => {
    dispatch(setModal(TOOLS_MODALS.NONE));
    dispatch(setIsMessageEditMode(false));
    dispatch(setEditMessageId(null));
    dispatch(setEditMessageParentId(null));
    dispatch(setNegativeResponseId(null));
    dispatch(handleChatDisbale(false));
  };

  const getPreviousUserMessageId = () => {
    const negativeMessageIndex = chat_data.currentChatFlow.indexOf(
      userMessageEdit?.negativeResponseMessageId,
    );
    if (negativeMessageIndex > 0) {
      for (let i = negativeMessageIndex - 1; i >= 0; i--) {
        const messageId = chat_data.currentChatFlow[i];
        const message = chat_data.messages[messageId];
        if (message && message.type === CHAT_MESSAGE_TYPE.USER) {
          return {
            message_id: messageId,
            parent_id: message?.parent_message,
            message_text: message?.content,
          };
        }
      }
    }
    return null;
  };

  const handleStartMessageEditMode = () => {
    dispatch(setModal(TOOLS_MODALS.NONE));
    const editMessagedetails = getPreviousUserMessageId();
    dispatch(handleChatDisbale(true));
    dispatch(
      setEditMessageDetails({
        isMessageEditMode: true,
        editMessageId: editMessagedetails?.message_id,
        editMessageParentId: editMessagedetails?.parent_id,
        editMessageText: editMessagedetails?.message_text,
      }),
    );
  };

  return (
    <section className="chat_query_limit_warning_modal">
      <div className="chat_query_limit_container chat_query_limit_warning-container">
        <div className="ez_consult_modal_container">
          <div className="ez_consult_modal_wrapper">
            <div className="ez_consult_modal_section">
              <div className="ez_consult_modal_heading_text">
                <h3 className="ez_consult_modal_main_text">
                  Not happy with the response?
                </h3>
                <p className="ez_consult_modal_para_text">
                  You can rewrite your last prompt to get a better response from
                  EZ consult.ai
                </p>
              </div>
              <div className="ez_consult_modal_button_section">
                <button
                  className="ez_consult_modal_left_button"
                  onClick={handleStartMessageEditMode}
                >
                  Edit Last Prompt
                </button>
                <button
                  className="ez_consult_modal_right_button"
                  onClick={closeModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ConsultResponseEditModal;
