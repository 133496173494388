import React, { useEffect } from "react";

const PdfCustomPageLayer = ({ renderPageProps, execFn }) => {
  useEffect(() => {
    execFn && execFn();
    // Mark the page rendered completely when the canvas layer is rendered completely
    // So the next page will be rendered
    if (renderPageProps.canvasLayerRendered) {
      renderPageProps.markRendered(renderPageProps.pageIndex);
    }
  }, [renderPageProps.canvasLayerRendered]);

  return <>{renderPageProps.canvasLayer.children}</>;
};

export default PdfCustomPageLayer;
