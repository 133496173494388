import React, { useEffect, useRef, useState } from "react";
import "./ToolsShareCard.scss";
// import "../WSAccessLogCard/WSAccessLogCard.scss";
import {
  FaSearch,
  FaSortAmountDown,
  // FaSortAmountDown,
  FaSortAmountDownAlt,
} from "react-icons/fa";
// import WSUserData from "./WSUserData";
import ToolsUserData from "./ToolsUserData";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuthData } from "../../../../helpers/request";
import { setAccessLogData } from "../../../../store/userSharedata/userShareDataSlice";
import { ERROR_REGEX, ToolTipError } from "../../../..";

export const access_log_sort_order = {
  ASCENDING: "ascending",
  DESCENDING: "descending",
};

const ToolsShareCard = () => {
  const [accessCardMsg, setAccessCardMsg] = useState(null);
  const dispatch = useDispatch();
  const [sort, setSort] = useState(access_log_sort_order.ASCENDING);
  const access_log_search_word = useRef(null);
  const { asgn_id } = useParams();
  const { accessLogData } = useSelector((state) => state.accessLog);
  useEffect(() => {
    if (accessLogData && accessLogData?.length === 0) {
      setAccessCardMsg("No logs found!");
    } else {
      setAccessCardMsg(null);
    }
  }, [accessLogData]);

  const fetch_access_log = () => {
    setAccessCardMsg("Fetching...");
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/access/log/${asgn_id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          dispatch(setAccessLogData(res?.data));
        } else {
          setAccessCardMsg("Can't fetch logs.");
        }
      })
      .catch(() => {
        setAccessCardMsg("Can't fetch logs.");
      });
  };

  const fetch_sorted_access_log = (sort) => {
    setAccessCardMsg("Fetching...");
    // if (devEnv) {
    //   setAccessCardMsg("Turn off dev environment to fetch real data.");
    //   return;
    // }
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/access/log/${asgn_id}/?sort=${sort}&search=${access_log_search_word?.current?.value}`,
    )
      .then((res) => {
        if (res.success === true) {
          setAccessCardMsg(null);
          dispatch(setAccessLogData(res?.data));
        } else {
          setAccessCardMsg("Can't fetch logs.");
        }
      })
      .catch(() => {
        setAccessCardMsg("Can't fetch logs.");
      });
  };

  useEffect(() => {
    access_log_search_word.current.value = "";
    asgn_id && fetch_access_log();
  }, [asgn_id]);

  // useEffect(() => {
  //   if (route === OPS_ROUTES.ARCHIVE) {
  //     setDisplayedFile(opsArchiveDisplayedFile);
  //   }
  // }, [liveAssignmentData.file_details, opsArchiveDisplayedFile]);

  useEffect(() => {
    setAccessCardMsg("Fetching...");
  }, [asgn_id]);

  const handleSort = (e) => {
    e.preventDefault();
    setSort((prev) => {
      if (prev === access_log_sort_order.ASCENDING) {
        fetch_sorted_access_log(access_log_sort_order.DESCENDING);
        return access_log_sort_order.DESCENDING;
      } else if (prev === access_log_sort_order.DESCENDING) {
        fetch_sorted_access_log(access_log_sort_order.ASCENDING);
        return access_log_sort_order.ASCENDING;
      }
    });
  };

  const handleSearch = (e) => {
    e.preventDefault();
    fetch_sorted_access_log(access_log_sort_order.ASCENDING);
  };
  return (
    <div className="client-dashboard-card glass-container-active client-log-container">
      <div className="client-dashboard-card-head client-log-card-head">
        <div className="client-access-head">
          <span>Access Logs</span>
          {sort === access_log_sort_order.ASCENDING && (
            <button
              className="client-sort-btn client-access-log-sort-btn"
              onClick={handleSort}
            >
              <FaSortAmountDownAlt />
            </button>
          )}
          {sort === access_log_sort_order.DESCENDING && (
            <button
              className="client-sort-btn client-access-log-sort-btn"
              onClick={handleSort}
            >
              <FaSortAmountDown />
            </button>
          )}
        </div>
        <div className="client-access-log-search-container">
          <form
            className="client-access-log-search-form"
            onSubmit={handleSearch}
          >
            <input
              type="text"
              className="client-dashboard-input client-access-log-search-input"
              ref={access_log_search_word}
              onChange={(e) => {
                ToolTipError(e, [ERROR_REGEX.SYMBOLS_ERROR]);
              }}
              name="client-log-search"
              id="client-log-search"
              placeholder="Search..."
            />
            <button type="submit" className="client-access-log-search-btn">
              <FaSearch />
            </button>
          </form>
        </div>
      </div>
      <div className="client-log-items-container">
        {accessCardMsg ? (
          <div className="client-log-msg">{accessCardMsg}</div>
        ) : (
          accessLogData?.map((access_log, i) => {
            return <ToolsUserData key={i} access_log={access_log} />;
          })
        )}
      </div>
    </div>
  );
};

export default ToolsShareCard;
