import React, { useContext, useEffect, useState, useRef } from "react";
import { FaDownload, FaExternalLinkAlt } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";
import {
  date_time_convertor,
  DownloadFiles,
  // emojis,
  // emoticons,
  FeedbackRatingEmoji,
  DELIVERY_USER_TYPE,
} from "../../../../..";
import {
  ClientArchiveFileContext,
  ClientDashboardFileContext,
  ClientRouteContext,
  CLIENT_ROUTES,
  EncryptedContext,
  FILE_ENCRYPTION_STATES,
  NotFoundErrorContext,
} from "../../../../ClientComponents";
import { file_links_obj } from "../DeliveryTab/DeliveryTab";

import "./HistoryTab.css";

const TargetDownloadBtn = ({ ext, link, name }) => {
  const { setNotFoundError } = useContext(NotFoundErrorContext);

  const DownloadTarget = (e) => {
    const download_file_name =
      name.split(".").slice(0, -1).join(".") + "." + ext; // making the file name with the extention provided
    // DownloadFiles([{name:download_file_name,link:link}])
    try {
      DownloadFiles([{ name: download_file_name, link: link }]);
    } catch (err) {
      setNotFoundError(true);
    }
  };

  return (
    <span className="file-info-file-download-btn" onClick={DownloadTarget}>
      <span className="file-info-file-download-btn-ext-text">
        {ext.toUpperCase()}
      </span>
      <span>
        <FaDownload />
      </span>
    </span>
  );
};

const DownloadAllBtn = ({ version }) => {
  const { encryptedState } = useContext(EncryptedContext);
  const route = useContext(ClientRouteContext);
  const { setNotFoundError } = useContext(NotFoundErrorContext);

  const handleDownloadTargetAll = (e) => {
    e.preventDefault();
    // let allFileLinks = version?.files?.map((file)=>{
    //     return Object.entries(file.links).map((link)=>{
    //         const filename = file.name.split('.').slice(0,-1).join('.')+'.'+link[0]
    //         return {link:link[1],name:filename}
    //     })
    // }).flat()

    let allFileLinks = version?.files
      ?.map((file) => file_links_obj(file).file_links)
      .flat();

    let zip_link_arr = version?.zip_link &&
      version?.zip_link.name &&
      version?.zip_link.link && [
        { name: version?.zip_link?.name, link: version?.zip_link?.link },
      ];

    try {
      if (zip_link_arr) {
        DownloadFiles(zip_link_arr, true);
      } else {
        DownloadFiles(allFileLinks, true);
      }
    } catch (err) {
      setNotFoundError(true);
    }
  };

  return (
    <>
      {route === CLIENT_ROUTES.ASSIGNMENTS &&
        encryptedState ===
          FILE_ENCRYPTION_STATES.DOWNLOAD /* Checks if the route and encrytedState are present or not */ &&
        (version?.files?.every((file) => {
          return file?.links && Object.entries(file?.links)?.length > 0;
        }) /* Checks if all links are present only then Downlaod all will appear */ ? (
          <span
            className="file-info-file-download-btn"
            onClick={handleDownloadTargetAll}
          >
            <span>Download All</span>
            <span>
              <FaDownload />
            </span>
          </span>
        ) : (
          <span className="file-info-file-download-err-text">
            <span>File unavailable, Please contact EZ Support</span>
          </span>
        ))}

      {route !== CLIENT_ROUTES.ASSIGNMENTS &&
        encryptedState !== FILE_ENCRYPTION_STATES.DOWNLOAD && (
          <span className="file-info-file-download-err-text"></span>
        )}
    </>
  );
};

const FileDownloadExtensions = ({ file }) => {
  return (
    <div className="file-info-file-download">
      {file?.links &&
        Object.entries(file.links)?.map(([ext, link], idx) => {
          return ext !== "external_link" && ext !== "external_text" ? (
            <TargetDownloadBtn
              key={idx}
              ext={ext}
              link={link}
              name={file.name}
            />
          ) : (
            <></>
          );
        })}
    </div>
  );
};

const FileExternalLink = ({ file }) => {
  return (
    <a
      className="ext-btn-box-link"
      target="_blank"
      href={file?.links?.external_link}
      rel="noreferrer"
    >
      <span className="file-info-file-download-btn">
        <span>External Link</span>
        <span>
          <FaExternalLinkAlt />
        </span>
      </span>
    </a>
  );
};

const FileExternalText = ({ file, link_obj }) => {
  const copyTextRef = useRef(null);
  const copyBtnRef = useRef(null);

  const copyValue = (e) => {
    const value = file?.links?.external_text;

    if (!navigator.clipboard) {
      return;
    }
    if (value) {
      navigator.clipboard.writeText(value);
      copyTextRef.current.innerText = "Copied!";
      copyBtnRef.current.classList.add("file-info-file-ext-text-btn-active");
      // alert('Copied to clipboard')

      setTimeout(() => {
        copyTextRef.current.innerText = "Copy ID";
        copyBtnRef.current.classList.remove(
          "file-info-file-ext-text-btn-active",
        );
      }, 1000);
    } else {
      alert("Failed to copy!");
    }
  };

  return (
    <button
      ref={copyBtnRef}
      className="ext-link-btns file-info-file-download-btn"
      onClick={copyValue}
    >
      <span ref={copyTextRef} className="file-info-file-download-btn-ext-text">
        Copy ID
      </span>
      <span>
        <MdContentCopy />
      </span>
    </button>
  );
};

const DownloadBtnGroup = ({ file }) => {
  return (
    <>
      <div className="file-info-file-download-btn-group">
        {file?.links?.external_link && <FileExternalLink file={file} />}
        {file?.links?.external_text && <FileExternalText file={file} />}
        <FileDownloadExtensions file={file} />
      </div>
    </>
  );
};

const VersionCard = ({ version, active, idx }) => {
  // const { setNotFoundError } = useContext(NotFoundErrorContext);
  // const { encryptedState } = useContext(EncryptedContext);
  const route = useContext(ClientRouteContext);
  const { clientDisplayedFile } = useContext(ClientDashboardFileContext);

  return (
    <div className="version-card glass-container-active" data-idx={idx}>
      <div className={`version-card-head ${!active && "glass-container"}`}>
        <div className="version-card-info">
          <span className="version-card-info-value version-card-info-version-head">
            Version {version.version}
          </span>
        </div>
        <div className="version-card-info">
          <span className="version-card-info-value">
            {version.files.length} file(s)
          </span>
        </div>
        <div className="version-card-info delivery-dates">
          <span className="version-card-info-label">Committed </span>
          <span className="version-card-info-value">
            {date_time_convertor(version.committed_delivery)}
          </span>
        </div>
        <div className="version-card-info delivery-dates">
          <span className="version-card-info-label">Delivered </span>
          <span className="version-card-info-value">
            {date_time_convertor(version.actual_delivery)}
          </span>
        </div>
        <div className="version-card-info">
          <FeedbackRatingEmoji feedback={version?.overall_feedback} />
        </div>
      </div>

      <div
        className={`version-card-content ${
          active && "version-card-content-show"
        }`}
      >
        <div className="version-card-info delivery-dates">
          <span className="version-card-info-label">Committed </span>
          <span className="version-card-info-value">
            {date_time_convertor(version.committed_delivery)}
          </span>
        </div>
        <div className="version-card-info delivery-dates">
          <span className="version-card-info-label">Delivered </span>
          <span className="version-card-info-value">
            {date_time_convertor(version.actual_delivery)}
          </span>
        </div>
        <div className="version-card-info">
          <span className="version-card-info-label">Delivery Notes</span>
          <span className="version-card-info-value">
            {version?.delivery_notes?.map((source, idx) => {
              return (
                <div className="version-card-delivery-notes" key={idx}>
                  {source?.names?.map((name, idx) => (
                    <span
                      key={idx}
                      className="version-card-delivery-notes-file-name"
                    >
                      @{name}
                    </span>
                  ))}
                  <span className="version-card-delivery-note">
                    {source.notes}
                  </span>
                </div>
              );
            })}
          </span>
        </div>
        <div className="version-card-info">
          <div className="version-card-info-head">
            <span className="version-card-info-label">File(s)</span>
            {<DownloadAllBtn version={version} />}
          </div>
          <span className="version-card-info-value">
            {version?.files?.map((file, i) => {
              return (
                <div key={i} className="version-card-info-value-file">
                  <span>{file.name}</span>
                  {route === CLIENT_ROUTES.ASSIGNMENTS &&
                    clientDisplayedFile.file_stage ==
                      FILE_ENCRYPTION_STATES.DOWNLOAD &&
                    (clientDisplayedFile?.delivery_user_type ===
                      DELIVERY_USER_TYPE.NONE ||
                      clientDisplayedFile?.delivery_user_type ===
                        DELIVERY_USER_TYPE.DEFAULT) && (
                      <FileDownloadExtensions file={file} />
                    )}

                  {route === CLIENT_ROUTES.ASSIGNMENTS &&
                    clientDisplayedFile.file_stage ==
                      FILE_ENCRYPTION_STATES.DOWNLOAD &&
                    clientDisplayedFile?.delivery_user_type ===
                      DELIVERY_USER_TYPE.EXTERNAL && (
                      <DownloadBtnGroup file={file} />
                    )}
                </div>
              );
            })}
          </span>
        </div>
      </div>
    </div>
  );
};

const SourceFileCard = ({ active, displayedFile }) => {
  const { clientDisplayedFile } = useContext(ClientDashboardFileContext);
  // const { clientArchiveFile } = useContext(ClientArchiveFileContext);
  const { setNotFoundError } = useContext(NotFoundErrorContext);

  const handleDownloadSourceAll = (e) => {
    e.preventDefault();
    let allFileLinks = clientDisplayedFile?.source_files
      ?.map((file) => {
        return Object.entries(file.links).map((link) => {
          const filename =
            file.name.split(".").slice(0, -1).join(".") + "." + link[0];
          return { link: link[1], name: filename };
        });
      })
      .flat();
    try {
      DownloadFiles(allFileLinks);
    } catch (err) {
      setNotFoundError(true);
    }
  };

  return (
    <div className="version-card glass-container-active" data-idx={0}>
      <div className={`version-card-head ${!active && "glass-container"}`}>
        <div className="version-card-info">
          <span className="version-card-info-value version-card-info-version-head">
            Input Files{" "}
          </span>
        </div>
        <div className="version-card-info">
          <span className="version-card-info-value">
            {displayedFile?.source_files?.length} file(s)
          </span>
        </div>
        <div className="version-card-info"></div>
      </div>

      <div
        className={`version-card-content ${
          active && "version-card-content-show"
        }`}
      >
        <div className="version-card-info">
          <div className="version-card-info-head">
            <span className="version-card-info-label">File(s)</span>
            {displayedFile?.source_files?.every((file) => file?.links) ? (
              <div
                className="file-info-file-download-btn"
                onClick={(e) => {
                  handleDownloadSourceAll(e);
                }}
              >
                <span>Download All</span>
                <FaDownload />
              </div>
            ) : (
              <div className="file-info-file-download-err-text">
                <span>File unavailable, Please contact EZ Support</span>
              </div>
            )}
          </div>
          <span className="version-card-info-value">
            {displayedFile?.source_files?.map((file, i) => {
              return <span key={i}>{file.name}</span>;
            })}
          </span>
        </div>
      </div>
    </div>
  );
};

const HistoryTab = ({ file }) => {
  const [activeCard, setActiveCard] = useState(0); // eslint-disable-line no-unused-vars

  const [displayedFile, setDisplayedFile] = useState(file);
  const route = useContext(ClientRouteContext);
  const { clientDisplayedFile } = useContext(ClientDashboardFileContext);
  const { clientArchiveFile } = useContext(ClientArchiveFileContext);

  useEffect(() => {
    if (route === CLIENT_ROUTES.ASSIGNMENTS) {
      setDisplayedFile(clientDisplayedFile);
    } else if (route === CLIENT_ROUTES.ARCHIVE) {
      setDisplayedFile(clientArchiveFile);
    }
  }, [clientDisplayedFile, clientArchiveFile]);

  // const toggleActiveCard = (e) => {
  //   if (e?.target?.closest(".file-info-file-download-btn")) return; // if download button is clicked then no card closes
  //   const activeVersionCard = e?.target?.closest(".version-card")?.dataset.idx; // this value is the index to activate that card
  //   if (
  //     (parseInt(activeVersionCard) || parseInt(activeVersionCard) == 0) &&
  //     parseInt(activeVersionCard) !== activeCard
  //   ) {
  //     // if the selected card and the active card id is same then the card will close (remove the last conditional in order to make one card open all the time)
  //     setActiveCard(parseInt(activeVersionCard));
  //   } else {
  //     setActiveCard(null); // closes any selected card if we click on the white space
  //   }
  // };

  const activateCard = (e) => {
    if (e?.target?.closest(".file-info-file-download-btn")) return;
    const history_item = e?.target?.closest(".version-card");
    if (history_item) {
      const history_item_head =
        history_item.querySelector(".version-card-head");
      const history_item_content = history_item.querySelector(
        ".version-card-content",
      );
      history_item_head.classList.toggle("glass-container");
      history_item_content.classList.toggle("version-card-content-show");
    }
  };

  return (
    <section className="history-tab-section">
      <main className="history-tabs-container" onClick={activateCard}>
        {displayedFile?.target_versions?.map((version, i) => (
          <VersionCard
            key={version.version}
            version={version}
            active={activeCard == i}
            idx={i}
          />
        ))}
        <SourceFileCard active={true} displayedFile={displayedFile} />
      </main>
    </section>
  );
};

export default HistoryTab;
