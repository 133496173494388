import "./ToolsConsultGptChatDashboard.scss";
import { IoMdArrowBack, IoMdMenu } from "react-icons/io";
import ConsultChatForm from "./ConsultChatForm/ConsultChatForm";
import ToolsConsultGptChat from "./ToolsConsultGptChat/ToolsConsultGptChat";
import { toggleShowConsultFilePane } from "../../../../store/consultgptDataSlice/consultgptDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toggleShowToolsSidebar } from "../../../../store/utilsDataSlice/utilsDataSlice";

const ToolsConsultGptChatDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onSideMenubarHandler = () => {
    dispatch(toggleShowConsultFilePane(true));
  };
  const { chatCreation } = useSelector((state) => state.consultGptData);

  const handleBack = () => {
    if (chatCreation?.isNewChat) {
      navigate(`/tools/consult-upload`);
    } else {
      navigate(`/tools/consult-home`);
    }
    dispatch(toggleShowToolsSidebar(true));
  };

  return (
    <div className="tools_consult_chat_dashboard_wrapper">
      <div className="tools_consult_back_icon_btn_wrapper">
        <button className="tools_consult_back_icon_btn" onClick={handleBack}>
          <IoMdArrowBack />
        </button>
      </div>
      <div
        className="tools_consult_menu_btn_wrapper"
        onClick={onSideMenubarHandler}
      >
        <button className="tools_consult_back_icon_btn">
          <IoMdMenu />
        </button>
      </div>
      <div className="tools_consult_chat_wrapper">
        <div className="tools_consult_chat_message_wrapper">
          <ToolsConsultGptChat />
        </div>
        <ConsultChatForm />
      </div>
    </div>
  );
};

export default ToolsConsultGptChatDashboard;
