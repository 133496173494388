import React from "react";
import "./CustomInputs.scss";
import { FaCheck } from "react-icons/fa";

export const CustomCheckbox = ({
  disabled,
  label,
  styleClassObj = { label: "", input: "" },
  onClickHandler,
  Checked,
}) => {
  // const [isChecked, setIsChecked] = useState(false);
  return (
    <div className="custom_checkbox_wrapper">
      <label
        className={`custom_checkbox_label ${styleClassObj?.label} ${
          disabled ? "disabled_checkbox" : ""
        }`}
      >
        <input
          type="checkbox"
          className={`custom_checkbox_input ${styleClassObj?.input}`}
          checked={Checked}
          onChange={onClickHandler}
          disabled={disabled}
          // ref={inputRef}
        />
        <div
          className={`custom_checkbox_box ${
            disabled ? "disabled_checkbox" : ""
          }`}
        >
          {Checked && (
            <FaCheck
              size={12}
              className={`custom_checkbox_svg ${styleClassObj.checkBox} ${
                disabled ? "disabled_checkbox" : ""
              }`}
            />
          )}
        </div>
        {label && <span className="custom_checkbox_label_text">{label}</span>}
      </label>
    </div>
  );
};

export default CustomCheckbox;
