import React, { useRef, useState } from "react";
import { showHelpSidebar } from "./ClientHelp";
import { FaAngleLeft, FaAngleRight, FaSearch } from "react-icons/fa";
// import { faq_data } from "../../req_jsons/faqs";
import { createMarkup } from "../../index";
import { faq_data } from "../../req_jsons/faq_data";

const FaqItem = ({ data }) => {
  const toggleContent = (e) => {
    const contentEl = e.target
      .closest(".client-faq-item")
      .querySelector(".client-faq-item-content");
    const arrowEl = e.target
      .closest(".client-faq-item")
      .querySelector(".client-faq-item-head-arrow");
    arrowEl.classList.toggle("client-faq-item-head-arrow-rotate");
    contentEl.classList.toggle("client-faq-item-content-show");
  };
  return (
    <div className="client-faq-item">
      <div
        className="client-faq-item-head glass-container-active"
        onClick={toggleContent}
      >
        <div
          className="client-faq-item-question"
          dangerouslySetInnerHTML={createMarkup(data.ques)}
        />
        {/* {data.ques} */}
        <FaAngleRight className="client-faq-item-head-arrow" />
      </div>

      <div className="client-faq-item-content glass-container">
        {/* {data.answer} */}
        <div
          className="client-faq-item-question"
          dangerouslySetInnerHTML={createMarkup(data.answer)}
        />
      </div>
    </div>
  );
};

const FaqsPage = () => {
  const searchRef = useRef(null);
  const [faqsData, setFaqsData] = useState(faq_data);

  const handleFaqSearch = (searchTerm) => {
    //removing multiple spacing
    searchTerm = searchTerm
      .trim()
      .replace(/\s{2,}/g, " ")
      .toLowerCase();

    if (searchTerm === "") {
      setFaqsData(faq_data);
      return;
    }

    const filteredData = faq_data
      .map((category) => {
        const filteredFaqs = category.faqs.filter((faq) => {
          const quesText = faq.ques.toLowerCase();
          const answerText = faq.answer.toLowerCase();
          return (
            quesText.includes(searchTerm) || answerText.includes(searchTerm)
          );
        });

        return {
          ...category,
          faqs: filteredFaqs,
        };
      })
      .filter((category) => category.faqs.length > 0); // Removing categories with no matching FAQs
    setFaqsData(filteredData);
  };

  const handleInputChange = (event) => {
    const searchTerm = event.target.value;
    handleFaqSearch(searchTerm);
  };

  return (
    <section className="client-help-section">
      <div className="client-help-head client-help-head-mobile">
        <button
          className="client-dashboard-back-btn"
          onClick={(e) => {
            showHelpSidebar(e);
          }}
        >
          <FaAngleLeft />
        </button>
        <span>FAQs</span>
      </div>
      <div className="client-faqs-search-wrapper">
        <div className="client-faqs-search-form">
          <input
            className="client-faqs-search-input"
            ref={searchRef}
            placeholder="Search"
            onChange={handleInputChange}
          />
          <button className="client-faqs-search-btn">
            <FaSearch />
          </button>
        </div>
      </div>
      <div className="client-help-head">FAQs</div>

      {/* <main className="client-helps-container">
        {faq_data.map((data, i) => (
          <FaqItem key={i} data={data} />
        ))}
      </main> */}
      {faqsData.length === 0 ? (
        <div className="client-faqs-no-results">No results found!</div>
      ) : (
        faqsData.map((category) => (
          <div key={category.id} className="client-faqs-wrapper">
            <div className="client-helps-category-text">
              {category.category}
            </div>
            <main className="client-helps-container">
              {category.faqs.map((faq, index) => (
                <FaqItem key={index} data={faq} />
              ))}
            </main>
          </div>
        ))
      )}
    </section>
  );
};

export { FaqsPage };
