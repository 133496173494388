import React from "react";
import "./ToolsConsultFileUploadDashBoard.scss";
import { useDispatch, useSelector } from "react-redux";
import { lazy_load_image } from "../../../../../../..";
import {
  UPLOAD_FILES_PROGRESS_STATUS,
  formatTime,
} from "../../../../../../../store/multiPartUploadDataSlice/common";
import CustomCheckbox from "../../../../../../../utils/Components/CustomInputs";
import { LuDot } from "react-icons/lu";
import {
  deleteFileOnCancelUpload,
  fetchFileChunkUrls,
} from "../../../../../../../store/multiPartUploadDataSlice/multiPartUploadDataSlice";
import fileNameLimiter from "../../../../../../../utils/fileNameLimiter";
import {
  failedProcessingFileDelete,
  fileConfidentialStatusUpdate,
  retryFileProcessing,
} from "../../../../../../../store/consultgptDataSlice/consultgptDataSlice";
import FileProcessing from "./FileStatusIcons/FileProcessing";
import FileDelete from "./FileStatusIcons/FileDelete";
import FileRetry from "./FileStatusIcons/FileRetry";
import FileComplete from "./FileStatusIcons/FileComplete";

const ProgressBar = ({ uploadedChunks, totalChunks }) => {
  const uploadPercentage = ((uploadedChunks / totalChunks) * 100).toFixed(0);
  return (
    <div className="tools_consult_File_upload_progress_bar_container">
      <span className="tools_consult_File_upload_progress_upload_percentage">
        {uploadPercentage}%
      </span>
      <div className="tools_consult_File_upload_progress_bar">
        <div
          className="tools_consult_File_upload_progress_bar_progress"
          style={{ width: `${uploadPercentage}%` }}
        ></div>
      </div>
    </div>
  );
};

const FileStatusButton = ({
  SvgComponent,
  fileIdentifier,
  progress,
  onClickHandler,
}) => {
  return (
    <button
      style={{ border: "none", background: "none" }}
      className={`file_upload_type_icon ${
        progress === UPLOAD_FILES_PROGRESS_STATUS.FAILED ||
        progress === UPLOAD_FILES_PROGRESS_STATUS.UPLOAD_FAILED ||
        progress === UPLOAD_FILES_PROGRESS_STATUS.UPLOADING
          ? "file_status_icon"
          : ""
      } ${
        progress === UPLOAD_FILES_PROGRESS_STATUS.PROCESSING &&
        "tools_consult_file_upload_processing_status_icon"
      }`}
      onClick={
        onClickHandler ? () => onClickHandler({ fileIdentifier }) : undefined
      }
      disabled={!onClickHandler}
    >
      <SvgComponent />
    </button>
  );
};

const FileUploadProgressStatusIconWrapper = ({ fileData, filesObj }) => {
  const type = fileData?.fileProgress?.type;
  const fileIdentifier = fileData?.fileIdentifier;
  const fileObj = filesObj[fileIdentifier];
  const dispatch = useDispatch();

  const handleCancelUploadingFile = ({ fileIdentifier }) => {
    dispatch(deleteFileOnCancelUpload({ file_id: fileIdentifier }));
  };

  const handleDeleteFailedProcessingFile = ({ fileIdentifier }) => {
    dispatch(failedProcessingFileDelete({ file_id: fileIdentifier }));
  };
  const handleDeleteFileOnUploadFailed = ({ fileIdentifier }) => {
    dispatch(deleteFileOnCancelUpload({ file_id: fileIdentifier }));
  };

  const handleReProcessFile = ({ fileIdentifier }) => {
    dispatch(retryFileProcessing({ file_id: fileIdentifier }));
  };

  const handleReUploadFile = () => {
    dispatch(
      fetchFileChunkUrls({
        fileObj,
      }),
    );
  };

  return (
    <div className="file_upload_type_icon_wrapper">
      {type === UPLOAD_FILES_PROGRESS_STATUS.SUCCESS && (
        <FileStatusButton
          type="file_processing"
          SvgComponent={FileProcessing}
          fileIdentifier={fileIdentifier}
          progress={type}
        />
      )}
      {type === UPLOAD_FILES_PROGRESS_STATUS.PROCESSING && (
        <FileStatusButton
          type="file_processing"
          SvgComponent={FileProcessing}
          fileIdentifier={fileIdentifier}
          progress={type}
        />
      )}
      {type === UPLOAD_FILES_PROGRESS_STATUS.UPLOADING && (
        <FileStatusButton
          type="file_delete"
          SvgComponent={FileDelete}
          fileIdentifier={fileIdentifier}
          progress={type}
          onClickHandler={handleCancelUploadingFile}
        />
      )}
      {type === UPLOAD_FILES_PROGRESS_STATUS.FAILED && (
        <>
          <FileStatusButton
            type="file_retry"
            SvgComponent={FileRetry}
            fileIdentifier={fileIdentifier}
            progress={type}
            onClickHandler={handleReProcessFile}
          />
          <FileStatusButton
            type="file_delete"
            SvgComponent={FileDelete}
            fileIdentifier={fileIdentifier}
            progress={type}
            onClickHandler={handleDeleteFailedProcessingFile}
          />
        </>
      )}
      {type === UPLOAD_FILES_PROGRESS_STATUS.COMPLETED && (
        <FileStatusButton
          type="file_complete"
          SvgComponent={FileComplete}
          fileIdentifier={fileIdentifier}
          progress={type}
        />
      )}
      {type === UPLOAD_FILES_PROGRESS_STATUS.UPLOAD_FAILED && (
        <>
          <FileStatusButton
            type="file_retry"
            SvgComponent={FileRetry}
            fileIdentifier={fileIdentifier}
            progress={type}
            onClickHandler={handleReUploadFile}
          />
          <FileStatusButton
            type="file_delete"
            SvgComponent={FileDelete}
            fileIdentifier={fileIdentifier}
            progress={type}
            onClickHandler={handleDeleteFileOnUploadFailed}
          />
        </>
      )}
    </div>
  );
};

const FileUploadDurationTracking = ({ fileData }) => {
  if (fileData?.fileProgress?.type !== UPLOAD_FILES_PROGRESS_STATUS.UPLOADING)
    return null;

  const convertToMB = (sizeInBytes) => {
    return (sizeInBytes / (1000 * 1000)).toFixed(2) + " MB";
  };

  const displayFileSize = (fileSize) => {
    return convertToMB(fileSize);
  };
  return (
    <div className="tools_consult_active_upload_file_info">
      <span>{displayFileSize(fileData?.fileSize)}</span>
      {fileData?.remainingUploadTime && (
        <>
          <LuDot />
          <span>
            {formatTime(fileData.remainingUploadTime.days ?? 0, "day")}{" "}
            {formatTime(fileData.remainingUploadTime.hours ?? 0, "hour")}{" "}
            {formatTime(fileData.remainingUploadTime.minutes ?? 0, "minute")}{" "}
            {formatTime(fileData.remainingUploadTime.seconds ?? 0, "second")}{" "}
            left
          </span>
        </>
      )}
    </div>
  );
};

const ToolsConsultFileUploadDashBoard = () => {
  const { filesObj } = useSelector((state) => state.multiPartUploadData);
  const dispatch = useDispatch();

  // for showing file preview of the user uploaded file
  // const EnableFilePreview = (file_id) => {
  //   dispatch(getFileDownloadLink({ file_id: file_id }));
  // };

  const handleToggleToggleFileConfidential = (
    e,
    file_id,
    confidential,
    progress,
  ) => {
    e.stopPropagation();
    if (progress === UPLOAD_FILES_PROGRESS_STATUS.PROCESSING) {
      dispatch(
        fileConfidentialStatusUpdate({
          file_id: file_id,
          status: confidential,
        }),
      );
    }
  };

  return (
    <div className="tools_consult_File_upload_dashboard_wrapper">
      {Object.entries(filesObj).length === 0 ? (
        <div className="no_data_found">No upload data found!</div>
      ) : (
        Object.entries(filesObj).map(([fileId, fileData]) => {
          return (
            <div key={fileId} className="tools_consult_active_upload_wrapper">
              <div>
                <img
                  className="consult_include_file_icon"
                  onLoad={lazy_load_image}
                  alt="pdf"
                  src={process.env.PUBLIC_URL + "/pdf_file.svg"}
                />
              </div>
              <div className="tools_consult_active_upload_file_details_wrapper">
                <div className="tools_consult_active_upload_file_header">
                  {fileData?.fileName && (
                    <span
                      className="tools_consult_active_upload_file_name"
                      title={fileData?.fileName}
                    >
                      {fileNameLimiter(fileData?.fileName)}
                    </span>
                  )}
                  <FileUploadProgressStatusIconWrapper
                    fileData={fileData}
                    filesObj={filesObj}
                  />
                </div>
                {fileData?.fileProgress?.type ===
                  UPLOAD_FILES_PROGRESS_STATUS.UPLOADING && (
                  <FileUploadDurationTracking fileData={fileData} />
                )}
                <div className="tools_consult_active_upload_file_confidential_wrapper">
                  {fileData?.fileProgress?.type ===
                  UPLOAD_FILES_PROGRESS_STATUS.FAILED ? (
                    <div className="tools_consult_active_upload_failed_processing">
                      File failed to process
                    </div>
                  ) : fileData?.fileProgress?.type ===
                    UPLOAD_FILES_PROGRESS_STATUS.UPLOAD_FAILED ? (
                    <div className="tools_consult_active_upload_failed_processing">
                      File failed to upload
                    </div>
                  ) : (
                    <>
                      <CustomCheckbox
                        disabled={
                          fileData?.fileProgress?.type ===
                          UPLOAD_FILES_PROGRESS_STATUS.COMPLETED
                        }
                        Checked={fileData.is_confidential}
                        onClickHandler={(e) =>
                          handleToggleToggleFileConfidential(
                            e,
                            fileData.fileIdentifier,
                            fileData.is_confidential,
                            fileData.fileProgress?.type,
                          )
                        }
                      />
                      <span
                        className={`${
                          fileData?.fileProgress?.type ===
                          UPLOAD_FILES_PROGRESS_STATUS.COMPLETED
                            ? "confidential_disbaled"
                            : "tools_consult_file_upload_checkbox_label"
                        }`}
                      >
                        Mark as confidential
                      </span>
                    </>
                  )}
                </div>
                {fileData?.fileProgress?.type ===
                  UPLOAD_FILES_PROGRESS_STATUS.UPLOADING && (
                  <ProgressBar
                    uploadedChunks={
                      fileData?.fileProgress?.total_uploaded_chunk
                    }
                    totalChunks={fileData.totalChunks}
                  />
                )}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};

export default ToolsConsultFileUploadDashBoard;
