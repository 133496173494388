import React from "react";
import "./ToolsSidebarTabNav.scss";
// import { FaRegArrowAltCircleRight } from "react-icons/fa";
import { BsArrowRightCircle } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { toggleShowToolsSidebar } from "../../../store/utilsDataSlice/utilsDataSlice";
import { useDispatch, useSelector } from "react-redux";

const ToolsSidebarCard = ({
  name,
  src,
  alt,
  content_question,
  content_answer,
  tool_type,
}) => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const { tools_show_mob_sidebar } = useSelector((state) => state.utilsData);

  const handleClick = () => {
    dispatch(toggleShowToolsSidebar(false));
    history(`/tools/${tool_type}-upload`);
  };

  return (
    <section
      onClick={handleClick}
      className={`st_tools_card ${
        tools_show_mob_sidebar && "st_tools_card_left"
      }`}
    >
      <div className="st_tool_name_main">
        <div className="st_tool_name">
          <div className="st_tool_logo">
            <img className="st_tool_new_img" src={src} alt={alt} />
          </div>
          {/* <div className="st_tool_name">{name}</div> */}
        </div>
        <div className="st_tool_arrow">
          {/* <FaRegArrowAltCircleRight size="35" color="var(--white)" /> */}
          <BsArrowRightCircle
            className="st_arrow_back_btn"
            color="var(--white)"
            size={30}
          />
          {/* <img
            className="ai_tools_icon_img"
            src={process.env.PUBLIC_URL + "/arrow_circle_right.svg"}
            alt="arrow_right"
          /> */}
        </div>
      </div>
      <div
        className={`st_tool_content_main ${
          tools_show_mob_sidebar && "st_tools_left_content"
        }`}
      >
        <div className="st_tool_content">
          <span>{content_question}</span>
          <span>{content_answer}</span>
        </div>
      </div>
    </section>
  );
};

export default ToolsSidebarCard;
