import React from "react";
import "./ToolsConsultGptFileSection.scss";
import ToolsConsultGptFileUpload from "./ToolsConsultGptFileUpload/ToolsConsultGptFileUpload";
import ToolsConsultGptFileProcessing from "./ToolsConsultGptFileProcessing/ToolsConsultGptFileProcessing";
import { FILE_UPLOAD_DASHBOARD } from "../../../../../store/consultgptDataSlice/common";
import { useSelector } from "react-redux";

const ToolsConsultGptFileSection = () => {
  const { active_file_upload_dashboard } = useSelector(
    (state) => state.consultGptData,
  );
  return (
    <div className="tools_consult_file_upload_wrapper">
      <div
        className={`${
          active_file_upload_dashboard === FILE_UPLOAD_DASHBOARD.INCLUDE &&
          "disable_upload_filepane_section"
        } tools_consult_flip_upload_container_box`}
      >
        <ToolsConsultGptFileUpload
          file_text="Drop a PDF here"
          support_text="Supported File Formats: pdf"
          file_size_text="Max file size: 200MB"
          supported_languages="Supported Languages: Arabic,English"
        />
      </div>
      <ToolsConsultGptFileProcessing />
    </div>
  );
};

export default ToolsConsultGptFileSection;
