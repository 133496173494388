import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  // createContext,
} from "react";
import {
  ApiContext,
  ToolTipError,
  ERROR_REGEX,
  date_time_convertor,
  DashboardSearchContext,
  FileType,
  DevelopmentContext,
  FeedbackRatingEmoji,
  // FileIcon,
  DELIVERY_USER_TYPE,
} from "../../index.js";
import {
  ClientFilesArrayContext,
  ClientDashboardFileContext,
  NewFileContext,
  NotFoundErrorContext,
  ClientArchiveCountContext,
  FILE_ENCRYPTION_STATES,
} from "../ClientComponents";

import { useNavigate, Link, useParams } from "react-router-dom";
import { getAuthData } from "../../helpers/request";
import { MdAdd } from "react-icons/md";
import {
  FaSearch,
  FaSortAmountDownAlt,
  FaSortAmountDown,
  FaAngleDown,
  FaLock,
  FaUnlock,
  // FaKey,
  FaExternalLinkAlt,
  FaMinusCircle,
} from "react-icons/fa";
import {
  closeFilesSidebar,
  openArchiveSidebar,
} from "../Nav/ClientSidenav/ClientSidenav.js";

// importing CSS
import "./Sidebar.css";

// importing Dummy Data
import dummy_all_assignemnts from "../../archive/test_data/dummy_data-v1/dummy_all_assignments.json";
// import dummy_all_external_assignments from "../../archive/test_data/dummy_external_assignments/dummy_all_external_assignments.json";
import { assignment_services } from "../../req_jsons/assignment_services.js";

// const DashboardSearchContext = React.createContext(); // needed to pass the search string in archive search

const DashbaordSidebarTextContext = React.createContext();

export const sort_orders = {
  ASCENDING: "ascending",
  DESCENDING: "descending",
};

const FileHistoryHead = () => {
  const { devEnv } = useContext(DevelopmentContext);
  const [sort, setSort] = useState(sort_orders.ASCENDING);
  const search_word = useRef(null);
  const searchBtn = useRef(null);
  const API_URL = useContext(ApiContext);
  const { setClientFilesArray } = useContext(ClientFilesArrayContext);
  const { setClientArchiveCount } = useContext(ClientArchiveCountContext);
  const { setSearched, setSearchNavigate, searched, searchNavigate } =
    useContext(DashboardSearchContext);
  const { setDashboardSidebarText } = useContext(DashbaordSidebarTextContext);

  const handleSort = (e) => {
    e.preventDefault();
    setSort((prev) => {
      if (prev === sort_orders.ASCENDING) {
        if (devEnv) {
          setClientFilesArray(dummy_all_assignemnts.data); // Setting Dummy Data

          // setClientFilesArray(dummy_all_external_assignments.data) // Setting External assignments Dummy Data
        } else {
          // getAuthData(`${API_URL}/api-client/client/v1/all-assignments/?search=${search_word.current.value}&sort=${sort_orders.DESCENDING}`)
          getAuthData(
            `${API_URL}/api-client/client/v1/all-assignments/?sort=${sort_orders.DESCENDING}`,
          )
            .then((res) => {
              if (res.success === true) {
                setClientArchiveCount(res.archive_count);
              }
            })
            .catch((err) => {
              // console.error(err)
            });
        }
        return sort_orders.DESCENDING;
      } else if (prev === sort_orders.DESCENDING) {
        if (devEnv) {
          setClientFilesArray(dummy_all_assignemnts.data); // Setting Dummy Data
          // setClientFilesArray(dummy_all_external_assignments.data) // Setting External assignments Dummy Data
        } else {
          // getAuthData(`${API_URL}/api-client/client/v1/all-assignments/?search=${search_word.current.value}&sort=${sort_orders.ASCENDING}`)
          getAuthData(
            `${API_URL}/api-client/client/v1/all-assignments/?sort=${sort_orders.ASCENDING}`,
          )
            .then((res) => {
              if (res.success === true) {
                setClientFilesArray(res.data);
                setClientArchiveCount(res.archive_count);
              }
            })
            .catch((err) => {
              // console.error(err)
            });
        }
        return sort_orders.ASCENDING;
      }
    });
    // getAuthData(`${API_URL}/api-client/client/v1/all-assignments/?sort=${sort}`)
    //     .then((res) => {
    //         if (res.success === true) {
    //             setClientFilesArray(res.data);
    //         }
    //     })
    //     .catch(err => {
    //         // console.error(err)
    //     })
  };
  useEffect(() => {
    if (searchNavigate) {
      const timeout = setTimeout(() => {
        searchBtn.current.click();
        setSearchNavigate(false);
      }, 1250); //adding into event queue so search happens after all assignments are fetched
      return () => {
        clearTimeout(timeout);
      };
    }
  }, []);
  const handleSearch = (e) => {
    e.preventDefault();
    if (devEnv) {
      setClientFilesArray(dummy_all_assignemnts.data); // Setting Dummy Data
      // setClientFilesArray(dummy_all_external_assignments.data) // Setting External assignments Dummy Data

      return;
    }

    setDashboardSidebarText("Searching...");
    // getAuthData(`${API_URL}/api-client/client/v1/all-assignments/?search=${search_word.current.value}&sort=${sort}`)
    getAuthData(
      `${API_URL}/api-client/client/v1/all-assignments/?search=${search_word.current.value}`,
    )
      .then((res) => {
        if (res.success === true) {
          setSearched(search_word.current.value);
          setClientFilesArray(res.data);
          setClientArchiveCount(res.archive_count);
          res.data.length < 1 && setDashboardSidebarText("No assignments yet");
        } else {
          setClientFilesArray(null);
          setClientArchiveCount(null);
          res.data.length < 1 && setDashboardSidebarText("No assignments yet");
        }
      })
      .catch((err) => {
        // console.error(err)
      });
  };

  return (
    <div className="client-file-history-head">
      <div className="client-file-history-head-top">
        <div className="client-sidebar-head">
          <span className="client-sidebar-head-text">Assignments</span>
          {sort === sort_orders.ASCENDING && (
            <button className="client-sort-btn" onClick={handleSort}>
              <FaSortAmountDownAlt />
            </button>
          )}
          {sort === sort_orders.DESCENDING && (
            <button className="client-sort-btn" onClick={handleSort}>
              <FaSortAmountDown />
            </button>
          )}
        </div>
        <Link className="client-btns client-new-file-btn" to="/decrypt">
          <MdAdd className="client-add-btn" />
        </Link>
      </div>
      <form className="client-search-sort-form" onSubmit={handleSearch}>
        <input
          type="text"
          className="client-dashboard-input client-search-input"
          ref={search_word}
          value={searched}
          onChange={(e) => {
            ToolTipError(e, [ERROR_REGEX.SYMBOLS_ERROR]);
            setSearched(e.target.value);
          }}
          name="client-search"
          id="client-search"
          placeholder="Search..."
        />
        <button type="submit" ref={searchBtn} className="client-search-btn">
          <FaSearch />
        </button>
      </form>
    </div>
  );
};

const displayNewFile = () => {
  document
    .querySelector(".client-sidebar-file-new")
    ?.classList.add("client-sidebar-file-show");
  document
    .querySelector(".client-sidebar-file-new")
    ?.classList.remove("client-sidebar-file-new");
};

const FileDefaultUserIcon = ({ file }) => {
  return (
    <>
      {file?.file_stage === FILE_ENCRYPTION_STATES.ENCRYPTED && (
        <div className="client-sidebar-file-lock client-sidebar-file-lock-encrypted">
          <FaLock />
        </div>
      )}
      {file?.file_stage === FILE_ENCRYPTION_STATES.DOWNLOAD && (
        <div className="client-sidebar-file-lock client-sidebar-file-lock-download">
          <FaUnlock />
        </div>
      )}
      {file?.file_stage === FILE_ENCRYPTION_STATES.DECRYPTED && (
        <div className="client-sidebar-file-lock client-sidebar-file-lock-decrypted">
          <FaUnlock />
        </div>
      )}
      {file?.file_stage === FILE_ENCRYPTION_STATES.REVOKED && (
        <div className="client-sidebar-file-lock client-sidebar-file-lock-revoked">
          <FaMinusCircle />
        </div>
      )}
    </>
  );
};

const FileEncryptedUserIcon = ({ file }) => {
  return (
    <>
      {file?.file_stage === FILE_ENCRYPTION_STATES.REVOKED && (
        <div className="client-sidebar-file-lock client-sidebar-file-lock-revoked">
          <FaMinusCircle />
        </div>
      )}
      {file?.file_stage !== FILE_ENCRYPTION_STATES.REVOKED && (
        <div className="client-sidebar-file-lock client-sidebar-file-lock-decrypted">
          <FaExternalLinkAlt />
        </div>
      )}
    </>
  );
};

const File = ({ file, active, hidden, new_file }) => {
  const { devEnv } = useContext(DevelopmentContext);
  const history = useNavigate();
  const API_URL = useContext(ApiContext);
  // const { setClientDisplayedFile } = useContext(ClientDashboardFileContext);
  const { setNewFile } = useContext(NewFileContext);

  const showFileDetails = (e) => {
    if (devEnv) {
      history(`/assignments/${file.id}`); // Setting Dummy Data
      return;
    }

    // API call to set the displayed assignemnt on dashboard
    getAuthData(`${API_URL}/api-client/client/v1/all-assignments/${file?.id}/`)
      .then((res) => {
        if (res.success === true) {
          // setClientDisplayedFile(res.last_assignment);
          history(`/assignments/${file.id}`);
        } else {
          alert("Can't load the requested file. Please Try again!");
        }
      })
      .catch((err) => {
        // console.error(err)
      });
  };

  // ======= this use effect calls for the animation after the element is already laoded in the dom
  useEffect(() => {
    if (new_file) {
      setTimeout(() => {
        displayNewFile();
        setNewFile(null);
      }, 500);
    }
  });

  // const getServiceCodes = () => {
  //   console.log(
  //     file.services.map((service) => assignment_services[service].code),
  //   );
  // };

  return (
    <div
      className={`client-sidebar-file glass-container glass-container-hover
            ${active && "client-sidebar-file-active"} ${
        !hidden && !new_file && "client-sidebar-file-show"
      }
            ${new_file && "client-sidebar-file-new"} ${
        file?.file_stage !== "download" && "client-sidebar-file-locked"
      }`}
      onClick={showFileDetails}
    >
      <div className="client-file-stages">
        <FileType
          filetype={file.services.map(
            (service) => assignment_services[service].code,
          )}
          active={active}
        />
      </div>

      {/* <div className="client-sidebar-file-info client-sidebar-file-icon"><FileIcon filename={file?.file_name}/></div> */}
      <div className="client-sidebar-file-feedback">
        <FeedbackRatingEmoji feedback={file?.feedback} />
      </div>
      <div className="client-sidebar-file-info client-sidebar-file-filename">
        {(file?.delivery_user_type === DELIVERY_USER_TYPE.NONE ||
          file?.delivery_user_type === DELIVERY_USER_TYPE.DEFAULT) && (
          <FileDefaultUserIcon file={file} />
        )}
        {file?.delivery_user_type === DELIVERY_USER_TYPE.EXTERNAL && (
          <FileEncryptedUserIcon file={file} />
        )}
        <span>
          {`${
            file?.file_names[0].length > 50
              ? file?.file_names[0]?.substr(0, 40) + "..."
              : file?.file_names[0]
          } ${
            file?.file_names?.length > 1
              ? "+ " + String(file?.file_names?.length - 1)
              : ""
          }`}
        </span>
      </div>
      <div className="client-sidebar-file-info client-sidebar-file-subservice-details">
        {file?.unit_details.length < 2 ? (
          file?.unit_details?.map((detail, idx) => {
            return (
              <span key={idx} className="client-sidebar-file-subservice-detail">
                {`${detail?.unit_count} ${detail?.unit_type}`}
                {file.unit_details.length > 1 && ","}
              </span>
            );
          })
        ) : (
          <>
            {file?.unit_details.slice(0, 2)?.map((detail, idx) => {
              return (
                <span
                  key={idx}
                  className="client-sidebar-file-subservice-detail"
                >
                  {`${detail?.unit_count} ${detail?.unit_type}`},
                </span>
              );
            })}
            <span>...</span>
          </>
        )}
      </div>
      <div className="client-sidebar-file-info client-sidebar-file-date">
        {date_time_convertor(file?.received_date)
          .split(",")
          .slice(0, 2)
          .join(",")}
      </div>
    </div>
  );
};

const FileHistory = () => {
  const { clientFilesArray } = useContext(ClientFilesArrayContext);
  const { clientDisplayedFile } = useContext(ClientDashboardFileContext);
  const [hiddenItems, setHiddenItems] = useState([]);
  const { newFile } = useContext(NewFileContext);
  const { asgn_id } = useParams();
  const { clientArchiveCount } = useContext(ClientArchiveCountContext);
  const history = useNavigate();
  const { searched } = useContext(DashboardSearchContext);
  const { dashboardSidebarText } = useContext(DashbaordSidebarTextContext);

  const toggleMonth = (e, item) => {
    e.preventDefault();
    const monthEl = e.target.closest(".client-month-container");

    if (
      monthEl
        .querySelector(".client-month-arrow")
        .classList.contains("client-month-arrow-active")
    ) {
      // Removing item
      setHiddenItems((prev) => {
        const index = prev.indexOf(item);
        if (index === -1) {
          return prev;
        }
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      });
      monthEl
        .querySelector(".client-month-arrow")
        .classList.remove("client-month-arrow-active");
      monthEl.querySelectorAll(".client-sidebar-file").forEach((file) => {
        file.classList.remove("client-sidebar-file-hide");
      });
    } else {
      // adding item
      setHiddenItems((prev) => {
        return [...prev, item];
      });
      monthEl
        .querySelector(".client-month-arrow")
        .classList.add("client-month-arrow-active");
      monthEl.querySelectorAll(".client-sidebar-file").forEach((file) => {
        file.classList.add("client-sidebar-file-hide");
      });
    }

    // monthEl.classList.toggle("client-month-container-hidden")    // simple Hidding without transition
  };

  const closeResponsiveSidebar = (e) => {
    e.preventDefault();
    if (!e.target.closest(".client-sidebar-file")) return;
    document
      .querySelector("#client-file-sidebar")
      ?.classList.remove("client-sidebar-show");
    document
      .querySelector(".client-account-sidebar")
      ?.classList.remove("client-sidebar-show");
  };

  // const toggleArchiveSidebarItem = (e) => {
  //   const monthEl = e.target.closest(".client-month-container");
  //   monthEl
  //     .querySelector(".client-sidebar-archive-file")
  //     .classList.toggle("client-sidebar-archive-file-show");
  //   if (
  //     monthEl
  //       .querySelector(".client-month-arrow")
  //       .classList.contains("client-month-arrow-active")
  //   ) {
  //     monthEl
  //       .querySelector(".client-month-arrow")
  //       .classList.remove("client-month-arrow-active");
  //     monthEl.querySelectorAll(".client-sidebar-file").forEach((file) => {
  //       file.classList.remove("client-sidebar-file-hide");
  //     });
  //   } else {
  //     monthEl
  //       .querySelector(".client-month-arrow")
  //       .classList.add("client-month-arrow-active");
  //     monthEl.querySelectorAll(".client-sidebar-file").forEach((file) => {
  //       file.classList.add("client-sidebar-file-hide");
  //     });
  //   }
  // };

  return (
    <div
      className="client-files-history"
      onClick={(e) => {
        closeResponsiveSidebar(e);
      }}
    >
      {clientFilesArray?.length > 0 ? (
        clientFilesArray?.map((item) => {
          return (
            <div key={item?.title} className="client-month-container">
              <div
                className="client-sidebar-month-head"
                onClick={(e) => toggleMonth(e, item)}
              >
                <span className="client-month">{item.title}</span>
                <FaAngleDown className="client-month-arrow" />
              </div>
              <div className="client-file-list">
                {item?.info?.map((file, idx) => {
                  if (file.id === newFile?.id) {
                    return (
                      <File
                        key={file?.id}
                        file={file}
                        active={false}
                        new_file={true}
                        hidden={hiddenItems.includes(item)}
                      />
                    );
                  } else if (
                    parseInt(asgn_id) === file.id ||
                    clientDisplayedFile?.id === file.id
                  ) {
                    return (
                      <File
                        key={file?.id}
                        file={file}
                        active={true}
                        hidden={hiddenItems.includes(item)}
                      />
                    );
                  } else {
                    return (
                      <File
                        key={file?.id}
                        file={file}
                        active={false}
                        hidden={hiddenItems.includes(item)}
                      />
                    );
                  }
                })}
              </div>
            </div>
          );
        })
      ) : (
        <span className="client-file-history-empty">
          {dashboardSidebarText}
        </span>
      )}
      {searched && (
        <div
          className="client-month-container"
          onClick={() => {
            searched
              ? history("/archive?search=" + searched)
              : history("/archive");
            closeFilesSidebar();
            openArchiveSidebar();
          }}
        >
          <div className="client-month-container">
            <div className="client-sidebar-month-head">
              <span
                className="client-month"
                id="client-dashboard-archive-month"
              >
                See Archived ({clientArchiveCount})
              </span>
            </div>
            {/* <div className="client-sidebar-month-head" onClick={toggleArchiveSidebarItem}>
                        <span className="client-month" id="client-dashboard-archive-month">Archived ({clientArchiveCount})</span>
                        <FaAngleDown className="client-month-arrow"/>
                    </div>
                    <div className="client-file-list">
                        <div
                            className={`client-sidebar-file glass-container glass-container-hover client-sidebar-archive-file client-sidebar-archive-file-show`}
                        >
                            <span>{clientArchiveCount}</span>
                        </div>
                    </div> */}
          </div>
        </div>
      )}
    </div>
  );
};

const ClientDashboardSidebar = () => {
  const { devEnv } = useContext(DevelopmentContext);

  const API_URL = useContext(ApiContext);
  const { setClientFilesArray } = useContext(ClientFilesArrayContext);
  const { setNotFoundError } = useContext(NotFoundErrorContext);
  const { clientDisplayedFile } = useContext(ClientDashboardFileContext);
  const { setClientArchiveCount } = useContext(ClientArchiveCountContext);
  // const [searched, setSearched] = useState("");
  const [dashboardSidebarText, setDashboardSidebarText] = useState(null);

  useEffect(() => {
    if (devEnv) {
      setClientFilesArray(dummy_all_assignemnts.data); // Setting Dummy Data
      // setClientFilesArray(dummy_all_external_assignments.data) // Setting External assignments Dummy Data

      return;
    }

    setDashboardSidebarText("Fetching...");
    getAuthData(
      `${API_URL}/api-client/client/v1/all-assignments/?id=${clientDisplayedFile?.id}`,
    )
      .then((res) => {
        if (res.success === true) {
          setClientFilesArray(res.data);
          // setClientFilesArray(res.data);

          setClientArchiveCount(res.archive_count);
          res.data.length < 1 && setDashboardSidebarText("No assignments yet");
          // setNotFoundError(false);
        } else {
          setNotFoundError(true);
        }
      })
      .catch((err) => {
        // console.error(err)
        setNotFoundError(true);
      });
  }, []);

  return (
    <DashbaordSidebarTextContext.Provider
      value={{ dashboardSidebarText, setDashboardSidebarText }}
    >
      {/* <DashboardSearchContext.Provider value={{ searched, setSearched }}> */}
      <div
        className="client-sidebar-show client-sidebar client-sidebar-mobile"
        id="client-file-sidebar"
      >
        <FileHistoryHead />
        <FileHistory />
      </div>
      {/* </DashboardSearchContext.Provider> */}
    </DashbaordSidebarTextContext.Provider>
  );
};

export { ClientDashboardSidebar };
