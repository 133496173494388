import React, { useContext, useState, useEffect } from "react";
import {
  ClientDashboardFileContext,
  // ToolTipError,
  // ERROR_REGEX,
  ClientArchiveFileContext,
} from "../ClientComponents";
import { FaqsPage } from "./ClientFAQsPage";
// import { FaSearch } from "react-icons/fa";

import "./ClientHelp.scss";

const clientHelpRenderPageContext = React.createContext(); // this is to show profile and password pages in same url

// ====================== Toggling Sidebar =================================
export const showHelpSidebar = (e) => {
  e.preventDefault();
  document
    .querySelector(".client-help-sidebar")
    .classList.toggle("client-sidebar-show");
};

// ===================== SIDEBAR =======================
const HelpSidebar = () => {
  // const search_word_ref = useRef(null);
  const { setHelpRenderPage } = useContext(clientHelpRenderPageContext);
  const showSetting = (e) => {
    document.querySelectorAll(".client-sidebar-help-item").forEach((el) => {
      el.classList.remove("glass-container-active");
    });
    e.target
      .closest(".client-sidebar-help-item")
      .classList.add("glass-container-active");
  };

  const closeResponsiveSidebar = (e) => {
    e.preventDefault();
    if (!e.target.closest(".client-sidebar-help-item")) return;
    document
      .querySelector("#client-file-sidebar")
      ?.classList.remove("client-sidebar-show");
    document
      .querySelector(".client-help-sidebar")
      ?.classList.remove("client-sidebar-show");
  };

  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   console.log(search_word_ref);
  // };

  return (
    <div className="client-sidebar client-help-sidebar">
      <div className="client-help-sidebar-head">
        <div className="client-help-sidebar-head-top">
          <div className="client-help-sidebar-text">Help</div>
        </div>
        {/* <form className="client-search-sort-form" onSubmit={handleSearch}>
                    <input type="text" className="client-dashboard-input client-search-input" ref={search_word_ref} onChange={(e)=>{ToolTipError(e,[ERROR_REGEX.SYMBOLS_ERROR,])}} name="client-help-search" id="client-help-search" placeholder="Search..."/>
                    <button type="submit" className="client-search-btn" ><FaSearch/></button>
                </form> */}
      </div>

      <div
        className="client-help-sidebar-items"
        onClick={(e) => {
          closeResponsiveSidebar(e);
        }}
      >
        <div
          className="client-sidebar-help-item glass-container glass-container-hover glass-container-active"
          onClick={(e) => {
            showSetting(e);
            setHelpRenderPage(PAGE.FAQ);
          }}
        >
          <div className="client-sidebar-help-item-head">FAQs</div>
        </div>
        {/* <div className="client-sidebar-account-item glass-container glass-container-hover" onClick={(e)=>{showSetting(e);setHelpRenderPage(PAGE.RECENT)}}>
                    <div className="client-sidebar-account-item-head">Recent</div>
                </div> */}
      </div>
    </div>
  );
};

const HelpDashboard = () => {
  const { helpRenderPage } = useContext(clientHelpRenderPageContext);
  return (
    <div className="client-dashboard">
      {/* {(helpRenderPage===PAGE.RECENT) && <RecentPage/>} */}
      {helpRenderPage === PAGE.FAQ && <FaqsPage />}
    </div>
  );
};

const PAGE = {
  // RECENT : "recent",
  FAQ: "faq",
};

const ClientHelp = () => {
  const [helpRenderPage, setHelpRenderPage] = useState(PAGE.FAQ);
  const { setClientDisplayedFile } = useContext(ClientDashboardFileContext);
  const { setClientArchiveFile } = useContext(ClientArchiveFileContext);

  useEffect(() => {
    setClientDisplayedFile({});
    setClientArchiveFile({});
  }, []);

  return (
    <>
      <clientHelpRenderPageContext.Provider
        value={{ helpRenderPage, setHelpRenderPage }}
      >
        <HelpSidebar />
        <HelpDashboard />
      </clientHelpRenderPageContext.Provider>
    </>
  );
};

export { ClientHelp };
