const fileUploadData = {
  flip: {
    id: 0,
    tool_type: "flip",
    heading_text:
      "It takes a designer 7 minutes on average to flip a slide manually.",
    heading_text_2: "Save time & money",
    file_text: "Drop a file here",
    support_text: "Supported File Formats: pptx, docx",
    file_size_text: "Max file size: 200MB",
    hover_data: [
      "The BETA version shared with you supports the following elements in pptx and docx files",
      "Flip layout of the entire slide to mirror the original - i.e. Arabic slides that are originally Right to Left, will be reoriented to flow from Left to Right. Similarly, English slides that originally Left to Right, will be reoriented to flow from Right to Left",
      "Reverses text direction L-to-R and R-to-L. This includes bullets, numbering, indentation, etc.",
      "Intelligently flips selected elements horizontally, e.g. create mirror image of arrows, gantt chart elements etc.",
      "Retains text formatting - e.g. colors, font style, spacing, etc. The tool does not translate or change document language.",
      "Automatically flips All Powerpoint Charts - column, bar, pie, bubble, spider, area, 3D charts, etc. In the next iteration we will also be able to flip waterfall, leaf chart and histogram.",
      "Creates mirror image of Shapes - i.e. flowchart, arrow, line, spline, boxes, and others; including if they are rotated or have rotated text orientation e.g. 90 deg",
      "Retains grouping and layering - grouped elements are individually moved, flipped, and re-grouped.",
      "Flips the Slide Master - all elements such as titles, footnotes, page numbering, design, cover page, etc.",
      "Automatically flips Tables - direction flipped from L-to-R and R-to-L. Column widths, formatting, etc. retained.",
      "Does not flip Smart Art yet - it will be delivered in next iteration",
    ],
  },
  read: {
    id: 0,
    tool_type: "read",
    heading_text: "Select Language of the PDF File",
    heading_text_2: "Save time & money",
    file_text: "Drop a PDF here",
    support_text: "Supported File Formats: pdf",
    file_size_text: "Max file size: 200MB",
    supported_languages: "Supported Languages: Arabic, English",
    hover_data: [""],
    file_upload_note:
      "*Note: To process files with more than 100 pages, contact ",
  },
  translate: {
    id: 0,
    tool_type: "translate",
    heading_text: "Select Language of the PDF File",
    heading_text_2: "Save time & money",
    file_text: "Drop a file here",
    support_text: "Supported File Formats: docx, pptx, xlsx",
    file_size_text: "Max file size: 200MB",
    supported_languages: "Supported Languages: Arabic, English",
    file_upload_note:
      "*Note: To process files with more than 100,000 words, contact ",
  },
  consult: {
    id: 0,
    tool_type: "consult",
    heading_text: "Need help summarizing a PDF English or Arabic then",
    heading_text_2: "",
    file_text: "Click here to upload your PDF(s)",
    support_text: "Supported File Formats: pdf",
    file_size_text: "Max file size: 200MB",
    supported_languages: "Supported Languages: English, Arabic",
    hover_data: [
      "Disclaimer: Data Usage & Privacy",
      "Our AI chatbot is designed to provide helpful responses based on user inputs. Please be aware of the following with regard to data usage and privacy:",
      "Data Collection: The chatbot may collect and process the information you provide during your interaction. This data is used solely to enhance the chatbot's responses and improve user experience. No personally identifiable information (PII) is stored unless explicitly provided by the user.",
      "Data Usage: Information shared with the chatbot may be analyzed to offer more relevant and personalized responses. However, the chatbot does not access or retain personal data unless required for the purpose of the conversation.",
      "Privacy & Security: We take the privacy and security of your data seriously. All interactions are encrypted and follow industry-standard security protocols. We do not sell, share, or disclose your data to third parties without your consent, except as required by law",
      "Confidential Data Option: We provide an option for users to mark their data as confidential. When this option is selected, your data will not be used for processing, analysis, or to improve chatbot performance. This ensures that your information is excluded from any further use beyond the immediate conversation.",
      "User Responsibility: Avoid sharing sensitive personal information (such as passwords, credit card numbers, or health records) while interacting with the chatbot. Any such data shared inadvertently will not be retained.",
      "Data Retention: We only retain user interaction data for the time necessary to improve our services, after which it is anonymized or deleted in accordance with our data retention policies. Confidential data, when marked as such, is not retained for processing or improvement purposes.",
      "By using this chatbot, you acknowledge and agree to the terms outlined in this disclaimer.",
    ],
  },
};
export default fileUploadData;
