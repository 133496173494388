import React from "react";
import "./WSLoader.scss";

import { BiLoaderAlt } from "react-icons/bi";

const WSLoader = () => {
  return (
    <div className="stc_loader">
      <BiLoaderAlt className="spin_loader" />
    </div>
  );
};

export default WSLoader;
