import { configureStore } from "@reduxjs/toolkit";
import multiPartUploadDataSlice from "./multiPartUploadDataSlice/multiPartUploadDataSlice";
import utilsDataSlice from "./utilsDataSlice/utilsDataSlice";
import profileDataSlice from "./profileDataSlice/profileDataSlice";
import toolsDataSlice from "./toolsDataSlice/toolsDataSlice";
import shareReducer from "./userSharedata/userShareDataSlice";
import loginHomeDataSlice from "./loginHomeDataSlice/loginHomeDataSlice";
import consultgptDataSlice from "./consultgptDataSlice/consultgptDataSlice";

export const store = configureStore({
  reducer: {
    //tools
    utilsData: utilsDataSlice,
    loginHomeData: loginHomeDataSlice,
    profileData: profileDataSlice,
    toolsData: toolsDataSlice,
    consultGptData: consultgptDataSlice,
    //testing
    multiPartUploadData: multiPartUploadDataSlice, //multipart upload data
    accessLog: shareReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
