import React from "react";

const ThumbsDown = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M5 0H15V13L8 20L6.75 18.75C6.63333 18.6333 6.5375 18.475 6.4625 18.275C6.3875 18.075 6.35 17.8833 6.35 17.7V17.35L7.45 13H2C1.46667 13 1 12.8 0.6 12.4C0.2 12 0 11.5333 0 11V9C0 8.88333 0.0125 8.75833 0.0375 8.625C0.0625 8.49167 0.1 8.36667 0.15 8.25L3.15 1.2C3.3 0.866667 3.55 0.583333 3.9 0.35C4.25 0.116667 4.61667 0 5 0ZM17 13V0H21V13H17Z" />
    </svg>
  );
};

export default ThumbsDown;
