import { date_time_convertor } from "../../../..";
import "./ToolsAccessCard.scss";
const ToolsSharedUserCard = ({ user }) => {
  return (
    <div className="tool-client-share-user-card glass-container glass-container-hover">
      <div className="tool-client-shared-user-card-info client-share-user">
        <span className="tool-client-shared-user-card-info-head">User</span>
        <span className="tool-client-shared-user-card-info-value">
          {user?.shared_to_user_email || "-"}
        </span>
      </div>
      <div className="tool-client-shared-user-card-info client-share-shared-by">
        <span className="tool-client-shared-user-card-info-head">
          Shared By
        </span>
        <span className="tool-client-shared-user-card-info-value">
          {user?.shared_by || "-"}
        </span>
      </div>
      <div className="tool-client-shared-user-card-info client-share-time-accessed tool-client-shared-user-card-info-center">
        <span className="tool-client-shared-user-card-info-head">
          Times Accessed
        </span>
        <span className="tool-client-shared-user-card-info-value">
          {user?.accessed_number ?? "-"}
        </span>
      </div>
      {/* <div className="client-shared-user-card-info client-share-last-download-ed-version client-shared-user-card-info-center">
        <span className="client-shared-user-card-info-head">
          Last Downloaded Version
        </span>
        <span className="client-shared-user-card-info-value">
          {user?.last_download_version || "-"}
        </span>
      </div> */}
      <div className="tool-client-shared-user-card-info client-share-last-accessed-on tool-client-shared-user-card-info-center">
        <span className="tool-client-shared-user-card-info-head">
          Last Accessed on
        </span>
        <span className="tool-client-shared-user-card-info-value">
          {date_time_convertor(user.last_accessed_time) || "-"}
        </span>
      </div>
    </div>
  );
};

export default ToolsSharedUserCard;
