import React from "react";

const FileDelete = () => {
  return (
    <svg
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.24186 14C1.85829 14 1.53157 13.8603 1.26171 13.581C0.992 13.3014 0.857143 12.963 0.857143 12.5658V1.57075H0V0.682943H3.42857V0H8.57143V0.682943H12V1.57075H11.1429V12.5658C11.1429 12.9744 11.0107 13.3157 10.7464 13.5894C10.4821 13.8631 10.1527 14 9.75814 14H2.24186ZM10.2857 1.57075H1.71429V12.5658C1.71429 12.7251 1.76371 12.8561 1.86257 12.9586C1.96157 13.061 2.088 13.1122 2.24186 13.1122H9.75814C9.89014 13.1122 10.0111 13.0553 10.1209 12.9415C10.2308 12.8277 10.2857 12.7025 10.2857 12.5658V1.57075ZM4.12093 11.3366H4.97807V3.34635H4.12093V11.3366ZM7.02193 11.3366H7.87907V3.34635H7.02193V11.3366Z"
        fill="white"
      />
    </svg>
  );
};

export default FileDelete;
