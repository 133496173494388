import toast from "react-hot-toast";
import { v4 as uuidv4 } from "uuid";

const resendUserMessage = ({ socket, getMessageData }) => {
  if (socket) {
    const messageObj = {
      query_type: "regenerate",
      parent_node_id: getMessageData?.parent_message,
      current_node_id: getMessageData?.uuid,
      child_node_id: getMessageData?.child_message?.[0] ?? uuidv4(),
      message_content: getMessageData?.content,
      timestamp: getMessageData?.timestamp,
    };
    socket.emit("query", messageObj);
  } else {
    toast.error("Something went wrong !", {
      id: "regenrate_error",
    });
  }
};

export default resendUserMessage;
