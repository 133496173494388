import React, { useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { resetModal } from "../../../../store/utilsDataSlice/utilsDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getAuthData, postAuthData } from "../../../../helpers/request";
import {
  setAccessLogData,
  setSharedLogData,
} from "../../../../store/userSharedata/userShareDataSlice";
import { getAccessLogData } from "../../../..";
import "../ToolsShareModal/ToolsShareModal.scss";
import toast from "react-hot-toast";
function ToolsDeclineRequestModal() {
  // const [revokeData, setRevokeData] = useState();
  const [shareErrNameArr, setShareErrNameArr] = useState([]); // eslint-disable-line no-unused-vars
  const [disableShareBtn, setDisableShareBtn] = useState(false);
  const [shareError, setShareError] = useState(null); // eslint-disable-line no-unused-vars
  // const [requesterNames ,setRequesterNames] = useState();
  const dispatch = useDispatch();
  const shareModalRef = useRef(null);
  const shareSubmitBtn = useRef(null);
  const { asgn_id } = useParams();
  const { tools_details_data } = useSelector((state) => state.toolsData);
  const { shared_email } = useSelector((state) => state.accessLog);
  const closeDeclineRequestModal = (e) => {
    e?.preventDefault();
    dispatch(resetModal());
  };

  const submitBtnSuccess = () => {
    const shareBtn = shareSubmitBtn.current;
    shareBtn.style.background = "var(--light-orange,#e98742)";
    shareBtn.innerText = "Declined!";
    setTimeout(() => {
      shareBtn.style.background = "var(--light-blue,#3cc3f2)";
      shareBtn.innerText = "Decline";
      closeDeclineRequestModal();
    }, 1000);
  };

  const fetch_access_log = () => {
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/access/log/${asgn_id}/`,
    )
      .then((res) => {
        if (res?.success === true) {
          dispatch(setAccessLogData(res?.data));
        } else {
          toast.error(res?.message || "Error while Fetching Data!!");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetch_shared_log = () => {
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/share/log/${asgn_id}/`,
    )
      .then((res) => {
        if (res?.success === true) {
          dispatch(setSharedLogData(res));
        } else {
          toast.error(res?.message || "Error while Fetching Data!!");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const postDeclineRequestData = () => {
    let data = {
      assignment_id: tools_details_data?.data?.tools_assignment_id,
      email: shared_email,
    };

    // Disabling the share button to prevent multiple clicks
    setDisableShareBtn(true);

    // if (devEnv) {
    //   submitBtnSuccess();
    //   console.log(data);
    // } else {
    postAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/share/decline/`,
      getAccessLogData(data),
    )
      .then((res) => {
        if (res?.success === true) {
          submitBtnSuccess();
          setDisableShareBtn(false);
          fetch_shared_log();
          fetch_access_log();
        } else {
          setShareError(res.message);
          setDisableShareBtn(false);
          if (res?.emails.length > 0) {
            setShareErrNameArr([...res.emails]);
          }
        }
      })
      .catch((err) => {
        setDisableShareBtn(false);
        console.error(err);
      });
  };
  return (
    <section className="tools-decline-request-modal">
      <div
        className="tool-client-modal-container tool-client-shared-modal-container"
        ref={shareModalRef}
      >
        <div className="client-modal-head">
          <div
            className="client-modal-container-head"
            id="tool-client-share-heading"
          >
            Decline Share Request
          </div>
          <button
            className="tool-client-modal-container-close-btn"
            id="tool-client-share-heading-close-btn"
            onClick={closeDeclineRequestModal}
          >
            <FaTimes />
          </button>
        </div>
        <p>You are declining the share request for the below users -</p>
        <div className="tool-client-revoke-share-names-container">
          <div className="client-revoke-share-warning-name-item">
            <span className="client-revoke-share-name">{shared_email}</span>
          </div>
        </div>

        <div className="tool-client-share-btn-container">
          <button
            className="client-btns"
            id="tool-client-share-modal-cancel-btn"
            onClick={closeDeclineRequestModal}
          >
            Cancel
          </button>
          <button
            className="client-btns"
            ref={shareSubmitBtn}
            id="client-share-modal-okay-btn"
            disabled={disableShareBtn}
            onClick={postDeclineRequestData}
          >
            Decline
          </button>
        </div>
      </div>
    </section>
  );
}

export default ToolsDeclineRequestModal;
