import toast from "react-hot-toast";
import { CHAT_QUERY_LIMIT } from "../store/consultgptDataSlice/common";

export const validateChatMessage = (
  message,
  chatData,
  handleChatDisable = null,
  handleShowQueryLimitModal,
) => {
  const trimmedMessage = message.trim();
  const containsArabicText = (text) =>
    /[\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF]/u.test(text);
  const isQueryLimitReached = (queryCount, limit) => queryCount >= limit;

  if (!trimmedMessage) {
    toast.error("Can't send an empty message", { id: "empty-query" });

    handleChatDisable();
    return false;
  }

  if (trimmedMessage.length > 2000) {
    toast.error("Query cannot exceed 2000 characters", {
      id: "message-too-long",
    });

    handleChatDisable();
    return false;
  }

  if (containsArabicText(trimmedMessage)) {
    toast.error("Can't send message in Arabic");
    handleChatDisable();
    return false;
  }

  if (isQueryLimitReached(chatData.query_count, CHAT_QUERY_LIMIT)) {
    // dispatch(setModal(TOOLS_MODALS.CHAT_QUERY_LIMIT));
    handleShowQueryLimitModal();
    if (handleChatDisable) handleChatDisable(false);
    return false;
  }

  return true;
};
