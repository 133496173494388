import React, { useContext, useState, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import {
  ApiContext,
  getAccessLogData,
  DevelopmentContext,
  // DELIVERY_USER_TYPE,
  CLIENT_USER_TYPE,
} from "../../../index";
import {
  ClientAccessLogContext,
  ClientDashboardFileContext,
  ClientSharingLogContext,
  CLIENT_MODAL_TYPE,
  ModalDispatchContext,
  NotFoundErrorContext,
} from "../../ClientComponents";
import { getAuthData, postAuthData } from "../../../helpers/request";
// import { ShareWarningModal } from "../ShareWarningModal/ShareWarningModal";
// import { ShareRevokeModal } from "../ShareRevokeModal/ShareRevokeModal";

import { SHARE_MODAL_TYPE } from "../../../utils/ShareModalType";
import { SHARE_RESPONSE_STATUS } from "../../../utils/ShareResponseStatus";

// import { warning_data } from "../../../archive/test_data/dummy_data-v1/dummy_share_warning.json";

// =========== CSS ============ //
import "./SharingModal.css";
import "../ShareWarningModal/ShareWarningModal.css";

// ========== Contexts =========== //
export const renderShareModalContext = React.createContext(); // this is to show share modal and share warning in same modal
export const ShareWarningDataContext = React.createContext(); // this is to show response when status: "warning"
export const SharedInputNamesContext = React.createContext(); // this is to show input emails in cards
export const RevokeShareDataContext = React.createContext(); // this is to store revoke share request data

const SharingModal = ({ payload }) => {
  const { devEnv } = useContext(DevelopmentContext);
  const modal_dispatch = useContext(ModalDispatchContext);
  const API_URL = useContext(ApiContext);
  const { clientDisplayedFile } = useContext(ClientDashboardFileContext);
  const { setNotFoundError } = useContext(NotFoundErrorContext);
  const { setSharedLogData } = useContext(ClientSharingLogContext);
  const { setAccessLogData } = useContext(ClientAccessLogContext);

  // states used
  const [shareNameInput, setShareNameInput] = useState(null);
  const suggestionContainerRef = useRef(null);
  const shareModalRef = useRef(null);
  const sharedNameInputRef = useRef(null);
  const [shareError, setShareError] = useState(null);
  const [shareNameSuggestions, setShareNameSuggestions] = useState([]);
  const [sharedNames, setSharedNames] = useState([]);
  const [shareErrNameArr, setShareErrNameArr] = useState([]);
  const [shareWarningData, setSharewarningData] = useState(); // eslint-disable-line no-unused-vars
  // const [revokeShareData, setRevokeShareData] = useState(null);
  const [disableShareBtn, setDisableShareBtn] = useState(false);

  const maxSharingNames = 10;

  // ref used
  const shareSubmitBtn = useRef(null);

  const closeSharingModal = (e) => {
    e?.preventDefault();
    modal_dispatch({ modal_show_type: CLIENT_MODAL_TYPE.NONE });
  };

  const openShareWarningModal = (res) => {
    modal_dispatch({
      modal_show_type: CLIENT_MODAL_TYPE.SHARE_WARNING,
      payload: {
        share_modal_type: SHARE_MODAL_TYPE.WARNING,
        share_res: res,
        from: "share",
      },
    });
  };

  const openShareRevokeModal = (sharedNames) => {
    modal_dispatch({
      modal_show_type: CLIENT_MODAL_TYPE.SHARE_REVOKE,
      payload: {
        share_modal_type: SHARE_MODAL_TYPE.REVOKE,
        share_res: sharedNames,
      },
    });
  };

  const addSuggestion = (e) => {
    if (sharedNames.length === maxSharingNames) {
      setShareError(`Only ${maxSharingNames} names can be shared at a time.`);
      return;
    } // setting a limit for names
    const emailRegex = /^\w*(\.|-)?\w+@\w*(\.|-)?\w+\.\w*$/g;
    const share_form_input = sharedNameInputRef.current.value?.toLowerCase();
    const suggested_item = e?.target.innerText?.toLowerCase();
    setShareError(null); // clears the error message before submitting and checcking
    // only submit if its an email
    if (suggested_item && sharedNames.includes(suggested_item)) {
      setShareError("Email already added.");
      return;
    } else if (suggested_item && emailRegex.test(suggested_item)) {
      setSharedNames([...sharedNames, suggested_item]);
      clearShareInput();
      return;
    } else if (!suggested_item && sharedNames.includes(share_form_input)) {
      setShareError("Email already added.");
      return;
    } else if (!suggested_item && emailRegex.test(share_form_input)) {
      setSharedNames([...sharedNames, share_form_input]);
      clearShareInput();
      return;
    } else {
      setShareError("Please enter an email.");
      return;
    }
  };

  const removeName = (e, idx) => {
    // instantly change the state when remove
    setSharedNames([
      ...sharedNames.slice(0, idx),
      ...sharedNames.slice(idx + 1, sharedNames.length),
    ]);
  };

  const extractEmailArrays = (data) => {
    const new_arr = data?.map((cur) => {
      return Object.values(cur)[0];
    });
    return new_arr;
  };

  const getSuggestions = (e) => {
    if (devEnv) {
      setShareNameSuggestions(shareNameSuggestions);
    } else {
      getAuthData(
        `${process.env.REACT_APP_API_URL}/api-client/misc/v1/client-suggestion/?search=${e.target.value}&delivery_id=${clientDisplayedFile?.batch_id}`,
      )
        .then((res) => {
          // console.log(e.target.value)
          if (res.success === true) {
            setShareNameSuggestions([...extractEmailArrays(res.data)]);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  const clearShareInput = () => {
    // Clearing the input and its suggestions after submitting the name
    sharedNameInputRef.current.value = "";
    setShareNameInput(null);
  };

  const submitBtnSuccess = () => {
    const shareBtn = shareSubmitBtn.current;
    shareBtn.style.background = "var(--light-orange,#e98742)";
    shareBtn.innerText = "Shared!";
    setTimeout(() => {
      shareBtn.style.background = "var(--light-blue,#3cc3f2)";
      shareBtn.innerText = "Share";
      closeSharingModal();
    }, 1000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    addSuggestion();
  };

  const removeShareErrors = () => {
    setShareError(null);
    Array.from(document.querySelectorAll(".client-share-name-item")).map(
      (el) => {
        el.classList.remove("client-share-name-item-err");
      },
    );
  };

  const fetch_access_log = () => {
    getAuthData(
      `${API_URL}/api-client/misc/v1/access-log/${clientDisplayedFile?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setAccessLogData(res.data);
        } else {
          setNotFoundError(true);
        }
      })
      .catch((err) => {
        setNotFoundError(true);
      });
  };

  const fetch_shared_log = () => {
    getAuthData(
      `${API_URL}/api-client/misc/v1/sharedlog/${clientDisplayedFile?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setSharedLogData(res);
        } else {
          setNotFoundError(true);
        }
      })
      .catch((err) => {
        setNotFoundError(true);
      });
  };

  const submitSharedArray = (e) => {
    e.preventDefault();
    const original_req_data = {
      delivery_id: clientDisplayedFile.batch_id,
      emails: sharedNames,
      accept_warning: false, //to check for warning
    };

    const share_req_data = {
      delivery_id: clientDisplayedFile.batch_id,
      emails: sharedNames,
    };

    // checking of empty list is being sent
    if (sharedNames.length < 1) {
      setShareError("Please enter an email!");
      return;
    }

    // Disabling the share button to prevent multiple clicks
    setDisableShareBtn(true);

    // checking if more than 10 names are shared
    if (sharedNames.length > maxSharingNames) {
      setShareError(`More than ${maxSharingNames} names shared.`);
      return;
    } // setting a limit for names

    // sending data
    if (devEnv) {
      if (clientDisplayedFile.client_user_type === CLIENT_USER_TYPE.ORIGINAL) {
        openShareWarningModal();
        console.log(original_req_data);
      } else {
        openShareRevokeModal();
        console.log(share_req_data);
      }
      setDisableShareBtn(false);
    } else {
      if (clientDisplayedFile.client_user_type === CLIENT_USER_TYPE.ORIGINAL) {
        postAuthData(
          `${API_URL}/api-client/misc/v1/client-shared-file/`,
          getAccessLogData(original_req_data),
        )
          .then((res) => {
            if (res.status === SHARE_RESPONSE_STATUS.SUCCESS) {
              removeShareErrors();
              submitBtnSuccess();
              clearShareInput();
              setSharedNames([]);
              setDisableShareBtn(false);
              fetch_shared_log();
              fetch_access_log();
            } else if (res.status === SHARE_RESPONSE_STATUS.WARNING) {
              setSharewarningData(res);
              setDisableShareBtn(false);
              openShareWarningModal(res);
            } else if (res.status === SHARE_RESPONSE_STATUS.FAILED) {
              setShareError(res.message);
              setDisableShareBtn(false);
              setTimeout(() => {
                closeSharingModal();
              }, 2000);
              if (res?.emails?.length > 0) {
                setShareErrNameArr([...res.emails]);
              }
            } else {
              setShareError(res.message);
              setDisableShareBtn(false);
              setTimeout(() => {
                closeSharingModal();
              }, 2000);
              if (res?.emails?.length > 0) {
                setShareErrNameArr([...res.emails]);
              }
            }
          })
          .catch((err) => {
            setDisableShareBtn(false);
            setNotFoundError(true);
          });
      } else {
        //open revoke share modal
        openShareRevokeModal(sharedNames);
      }
    }

    // // temp data
    // const err_res = {
    //     "message": "Assignment can not be shared outside organisation",
    //     "emails": ["bhavya.pruthi@arabeasy.com","shubham.singh@ez.works"],
    //     "success": false
    // }
    // // error handling
    // setShareError(err_res.message);
    // if (err_res.emails.length>0){
    //     wrongName(err_res.emails);
    // }
  };

  return (
    <div
      className="client-modal-container client-shared-modal-container"
      ref={shareModalRef}
    >
      <div className="client-modal-head">
        <div className="client-modal-container-head" id="client-share-heading">
          Share Assignment
        </div>
        <button
          className="client-modal-container-close-btn"
          id="client-share-heading-close-btn"
          onClick={closeSharingModal}
        >
          <FaTimes />
        </button>
      </div>
      <p>
        The assignment can only be shared with people from within your
        organisation.
      </p>

      <div className="client-share-names-container">
        {sharedNames?.map((item, idx) => {
          return (
            <div
              key={idx}
              className={`client-share-name-item ${
                shareErrNameArr.includes(item) && "client-share-name-item-err"
              }`}
            >
              <span className="client-share-name">{item}</span>
              <button
                className="client-share-remove-btn"
                onClick={(e) => {
                  removeName(e, idx);
                }}
              >
                <FaTimes />
              </button>
            </div>
          );
        })}
      </div>

      <form className="client-share-modal-form" onSubmit={handleSubmit}>
        <input
          type="text"
          className="client-dashboard-input client-share-modal-input"
          ref={sharedNameInputRef}
          defaultValue={shareNameInput}
          onChange={(e) => {
            setShareError(null);
            setShareNameInput(e.target.value);
            getSuggestions(e);
          }}
          autoFocus
        />

        {shareError && (
          <div className="client-input-error client-share-error">
            {shareError}
          </div>
        )}

        {shareNameInput && (
          <div
            className="client-share-suggestions-container"
            ref={suggestionContainerRef}
          >
            <span
              className="client-share-suggestion-item"
              onClick={addSuggestion}
            >
              {shareNameInput}
            </span>
            {shareNameSuggestions.map((item, idx) => {
              return (
                <span
                  className="client-share-suggestion-item"
                  key={idx}
                  onClick={addSuggestion}
                >
                  {item}
                </span>
              );
            })}
          </div>
        )}
      </form>
      <div className="client-share-btn-container">
        <button
          className="client-btns"
          id="client-share-modal-cancel-btn"
          onClick={closeSharingModal}
        >
          Cancel
        </button>
        <button
          className="client-btns"
          ref={shareSubmitBtn}
          id="client-share-modal-okay-btn"
          disabled={disableShareBtn}
          onClick={submitSharedArray}
        >
          Share
        </button>
      </div>
    </div>
  );
};

export { SharingModal };

// const loadPoistion = () => {
//     // const inp_rect = document.querySelector(".client-share-modal-input").getBoundingClientRect();
//     // const modal_rect = document.querySelector(".client-shared-modal-container").getBoundingClientRect();
//     if (!shareNameInput) return;
//     const inp_rect = sharedNameInputRef.current.getBoundingClientRect();
//     const modal_rect = shareModalRef.current.getBoundingClientRect();
//     const suggestionContainerRect = suggestionContainerRef.current.getBoundingClientRect();
//     const inp_left = inp_rect.left;
//     const inp_bottom = inp_rect.bottom;
//     const modal_right = modal_rect.right;
//     const modal_bottom = modal_rect.bottom;
//     const modal_width = modal_rect.width;
//     const modal_height = modal_rect.height;
//     const x_perc = ((modal_width-modal_right+inp_left)/modal_width)*100;
//     const y_perc = ((modal_height-modal_bottom+inp_bottom)/modal_height)*100;
//     const suggestion_container = document.querySelector(".client-share-suggestions-container");
//     console.log("Sug",suggestionContainerRect.right,"Modal",modal_rect.right,suggestionContainerRect.right>modal_rect.right);
//     (suggestion_container) && (suggestion_container.style.transform = `translate(${x_perc}%,${y_perc}%)`);
// }

// const setInputWidth = (e) => {
//     e.target.style.width = (((shareNameInput?.length || 0) + 1) * 9) + 'px';
// }

// useEffect(() => {
//     loadPoistion()
// }, [shareNameInput])

// {/* <div
//                     className="client-dashboard-input client-share-modal-input-container"
//                     onClick={(e)=>{document.querySelector(".client-share-modal-input").focus()}}
//                 >
//                     <input
//                         type="text"
//                         className="client-share-modal-input"
//                         ref={sharedNameInputRef}
//                         defaultValue={shareNameInput}
//                         onChange={(e)=>{
//                             setShareNameInput(e.target.value);
//                             setInputWidth(e);
//                             getSuggestions(e);
//                         }}
//                         // onKeyPress={setInputWidth}
//                         autoFocus
//                     />
//                 </div> */}
