import React, { useContext } from "react";
import { FaDownload, FaTimes } from "react-icons/fa";
import {
  // ApiContext,
  DownloadFiles,
  FileIcon,
  // getAccessLogData,
} from "../../../index.js";
import {
  ClientDashboardFileContext,
  CLIENT_MODAL_TYPE,
  ModalDispatchContext,
  NotFoundErrorContext,
} from "../../ClientComponents";
// import { getAuthData, postAuthData } from "../../../helpers/request";

import "./SourceFileDownloadModal.css";

const SourceDownloadBtn = ({ ext, link, name }) => {
  const { setNotFoundError } = useContext(NotFoundErrorContext);

  const DownloadTarget = (e) => {
    const download_file_name =
      name.split(".").slice(0, -1).join(".") + "." + ext; // making the file name with the extention provided
    try {
      DownloadFiles([{ name: download_file_name, link: link }]);
    } catch (err) {
      setNotFoundError(true);
    }
  };

  return (
    <span className="client-source-file-download-btn" onClick={DownloadTarget}>
      <span>{ext.toUpperCase()}</span>
      <span>
        <FaDownload />
      </span>
    </span>
  );
};

const SourceFile = ({ file }) => {
  return (
    <div className="client-source-file">
      <div className="client-source-file-name">
        <FileIcon filename={file.name} />
        <span className="client-source-file-name-modal-text">{file.name}</span>
      </div>
      <div className="client-source-file-download">
        {file?.links &&
          Object.entries(file.links).map(([ext, link], idx) => {
            return (
              <SourceDownloadBtn
                key={idx}
                ext={ext}
                link={link}
                name={file.name}
              />
            );
          })}
      </div>
    </div>
  );
};

export const SourceFileDownloadModal = () => {
  const { clientDisplayedFile } = useContext(ClientDashboardFileContext);
  const { setNotFoundError } = useContext(NotFoundErrorContext);

  const modal_dispatch = useContext(ModalDispatchContext);

  // const donwloadFileClick = () => {
  //     try{
  //         DownloadFiles(clientDisplayedFile?.source_files);
  //     } catch (err) {
  //         setNotFoundError(true)
  //     }
  // }
  const handleDownloadSourceAll = (e) => {
    e.preventDefault();
    let allFileLinks = clientDisplayedFile?.source_files
      ?.map((file) => {
        return Object.entries(file.links).map((link) => {
          const filename =
            file.name.split(".").slice(0, -1).join(".") + "." + link[0];
          return { link: link[1], name: filename };
        });
      })
      .flat();
    try {
      DownloadFiles(allFileLinks);
    } catch (err) {
      setNotFoundError(true);
    }
  };

  return (
    <div className="client-modal-container client-source-download-file-modal">
      <div className="client-modal-head">
        <div className="client-modal-container-head client-source-download-file-modal-head">
          Source Files
        </div>
        <button
          className="client-modal-container-close-btn"
          onClick={() => {
            modal_dispatch({ modal_show_type: CLIENT_MODAL_TYPE.NONE });
          }}
        >
          <FaTimes />
        </button>
      </div>
      <section className="client-source-file-download-section">
        {clientDisplayedFile?.source_files?.every((file) => file?.links) && (
          <div
            className="client-source-file-download-btn client-source-file-download-all"
            onClick={(e) => {
              handleDownloadSourceAll(e);
            }}
          >
            <span>Download All</span>
            <FaDownload />
          </div>
        )}
        {clientDisplayedFile?.source_files.map((file, i) => {
          return <SourceFile file={file} key={i} />;
        })}
      </section>
    </div>
  );
};
