// import Dummy_file_processing from "../../ToolsModule/ToolsComponents/ToolConsulGptChatSection/ToolsConsultGptFilepane/ToolsConsultGptFileSection/ToolsConsultGptFileProcessing/Dummy_file_processing.json";

export const UPLOAD_FILES_PROGRESS_STATUS = {
  IN_QUEUE: "in-queue",
  UPLOADING: "uploading",
  FAILED: "failed",
  SUCCESS: "success",
  REJECT: "reject",
  UPLOAD_FAILED: "upload-failed",
  PROCESSING: "processing",
  COMPLETED: "completed",
};

export const chunkSizeInMB =
  process.env.REACT_APP_CONSULT_MEDIA_CHUNK_SIZE * 1024 * 1024;

export const filesChunksGenerator = (files, existingFilesObj = {}) => {
  let filesObj = { ...existingFilesObj }; // Clone existing filesObj to maintain current state
  const chunkSize =
    process.env.REACT_APP_CONSULT_MEDIA_CHUNK_SIZE * 1024 * 1024;
  const existingFileIds = Object.keys(filesObj).length;
  files.forEach((file, i) => {
    const file_id = `f_${existingFileIds + i}`; // creating unique file id based on existing files count
    const totalChunks = Math.ceil(file.size / chunkSize); // getting chunks number
    const chunkObj = {};

    // creating chunk slices
    Array.from({ length: totalChunks }).forEach((_, chunkIndex) => {
      const chunk_id = `c_${chunkIndex}`;
      const initialPointer = chunkIndex * chunkSize;
      const finalPointer = initialPointer + chunkSize;
      chunkObj[chunk_id] = {
        chunkIdentifier: { fileId: file_id, chunkId: chunk_id },
        chunkProgress: UPLOAD_FILES_PROGRESS_STATUS.IN_QUEUE,
        chunkUploadLink: null,
        chunkSliceInfo: {
          initialPoint: initialPointer,
          finalPoint: finalPointer,
        },
        chunkUploadData: { ETag: null, PartNumber: null },
      };
    });

    filesObj[file_id] = {
      file: file,
      fileIdentifier: file_id,
      fileName: file.name,
      fileSize: file.size,
      fileType: file.type,
      fileUploadId: null,
      file_s3_path: null,
      remainingUploadTime: null,
      is_confidential: false,
      is_upload_cancelled: false,
      fileProgress: {
        cur_uploading_chunk: null,
        total_uploaded_chunk: null,
        type: UPLOAD_FILES_PROGRESS_STATUS.IN_QUEUE,
      },
      totalChunks: totalChunks,
      chunks: chunkObj,
    };
  });

  return filesObj;
};

export const formatDuration = (duration, chunksCompleted, totalChunks) => {
  const millisecondsRemaining = duration * (totalChunks - chunksCompleted);
  const secondsRemaining = Math.floor(millisecondsRemaining / 1000);
  const minutesRemaining = Math.floor(secondsRemaining / 60);
  const hoursRemaining = Math.floor(minutesRemaining / 60);
  const daysRemaining = Math.floor(hoursRemaining / 24);

  return {
    days: daysRemaining,
    hours: hoursRemaining % 24,
    minutes: minutesRemaining % 60,
    seconds: secondsRemaining % 60,
  };
};

export const formatTime = (timeValue, unit) => {
  if (timeValue === null) {
    return "";
  } else if (timeValue === 0) {
    return ""; // If time value is 0, returning an empty string to skip rendering
  } else {
    return `${timeValue} ${unit}${timeValue !== 1 ? "s" : ""}`;
  }
};

export const MAX_FILE_SIZE = 200 * 1024 * 1024;
export const MAX_FILE_COUNT = 5;

const initialState = {
  filesObj: {},
  trackingFiles: {},
  chunkUrlLoader: false,
};

export default initialState;
