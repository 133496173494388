import React from "react";
import "./ToolsHome.scss";
// import HomeCharts from "../../ToolsComponents/HomeCharts/HomeCharts";
import HomeRecentAsgns from "../../ToolsComponents/HomeRecentAsgns/HomeRecentAsgns";
import HomeFileSearch from "../../ToolsComponents/HomeFIleSearch/HomeFileSearch";
import HomeCapabilityCenter from "../../ToolsComponents/HomeCapabilityCenter/HomeCapabilityCenter";
import ToolsSidebarTabNav from "../../ToolsComponents/ToolsSidebarTabNav/ToolsSidebarTabNav";
import HomePieChart from "../../ToolsComponents/HomePieChart/HomePieChart";

const ToolsHome = () => {
  return (
    <div className="tools_home_page">
      <div className="tools_home_wrapper">
        <HomePieChart />
        {/* <HomeCharts /> */}
        <HomeRecentAsgns />
        <HomeFileSearch />
      </div>
      <div className="mobile_sidebar_tools">
        <ToolsSidebarTabNav />
      </div>
      <HomeCapabilityCenter />
    </div>
  );
};

export default ToolsHome;
