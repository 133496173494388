// import { FaRegPlusSquare } from "react-icons/fa";
import { BsPlusSquare } from "react-icons/bs";
import "./HomeCapabilityCenter.scss";
import { useDispatch } from "react-redux";
import {
  setModal,
  setServiceOffering,
} from "../../../store/utilsDataSlice/utilsDataSlice";
import TOOLS_MODALS from "../../ToolsConstants/TOOLS_MODALS";
const HomeCapabilityOffering = ({ offering_name, subservices }) => {
  // const { modal_type } = useSelector((state) => state.utilsData);
  const dispatch = useDispatch();

  const handleNewRequest = () => {
    dispatch(setServiceOffering(offering_name));
    dispatch(setModal(TOOLS_MODALS.NEW_REQ));
  };
  return (
    <div className="st_single_offering_main">
      <div className="st_single_offering_icons">
        <div className="st_offering_heading_main">
          <div className="offering_heading">{offering_name}</div>
        </div>
        <div className="offering_subservices">
          {subservices.map((single, id) => {
            return (
              <div key={id} className="offering_icons">
                <img
                  className="offering_icons_img"
                  // src={
                  //   process.env.PUBLIC_URL +
                  //   `/resources/cpb_icons/${single.sub_img_src}.svg`
                  // }
                  src={`${process.env.REACT_APP_CDN_URL}/capabilities_center/iconsList/${single.sub_img_src}.svg`}
                  alt={single.title}
                />
                <div className="offering_single_icon_heading_main">
                  <span className="offering_single_icon_heading">
                    {single.title}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="offering_add_icon_main">
        <div className="offering_add_icon" onClick={handleNewRequest}>
          {/* <FaRegPlusSquare color="var(--white)" size={27} /> */}
          <BsPlusSquare color="var(--white)" size={27} />
        </div>
      </div>
    </div>
  );
};

export default HomeCapabilityOffering;
