import React from "react";
import "./ToolsConsultGptFileProcessing.scss";
import { FILE_UPLOAD_DASHBOARD } from "../../../../../../store/consultgptDataSlice/common";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewChat,
  getIncludeFileData,
  resetIncludeData,
  sendFileIncludeData,
  setActiveUploadDashboard,
  setInitialMessageSend,
  setSocket,
  socketConnection,
  updateIncludeFile,
} from "../../../../../../store/consultgptDataSlice/consultgptDataSlice";
import CustomCheckbox from "../../../../../../utils/Components/CustomInputs";
import { lazy_load_image } from "../../../../../..";
import ToolsConsultFileUploadDashBoard from "./ToolsConsultFileUploadDashBoard/ToolsConsultFileUploadDashBoard";
import ConsultLoader from "../../../ConsultLoader/ConsultLoader";
import fileNameLimiter from "../../../../../../utils/fileNameLimiter";
import toast from "react-hot-toast";
import { BiLoaderAlt } from "react-icons/bi";

const IncludedFilesSection = ({
  file_data,
  chat_data,
  socket,
  handleToggleUploadDashboard,
}) => {
  const dispatch = useDispatch();
  const handleToggleToggleFileConfidential = (file_id, confidential) => {
    dispatch(updateIncludeFile({ file_id }));
  };

  const handleApplyIncludeFile = () => {
    if (!socket) {
      dispatch(socketConnection({ chat_id: chat_data.root_node_id })).then(
        (response) => {
          const socketConnect = response.payload;
          dispatch(setSocket(socketConnect));
          dispatch(sendFileIncludeData());
          dispatch(createNewChat(false));
          dispatch(setInitialMessageSend(true));
        },
      );
    } else {
      if (file_data.IncludedFile.length > 0 && socket) {
        dispatch(sendFileIncludeData());
      } else {
        toast.error("No file selected!", { id: "no-include-file-selected" });
      }
    }
  };

  return (
    <div className="file_include_section">
      <div className="consult_include_file_wrapper_container">
        {file_data && file_data.loading ? (
          <ConsultLoader />
        ) : file_data &&
          file_data.data &&
          Object.keys(file_data.data).length > 0 ? (
          Object.keys(file_data.data).map((fileIdentifier) => {
            const fileDetails = file_data.data[fileIdentifier];
            const isIncluded = file_data.IncludedFile.includes(
              fileDetails?.fileIdentifier,
            );
            return (
              <div
                className="consult_include_file_wrapper"
                key={fileDetails.fileIdentifier}
              >
                <span className="consult_include_file_name">
                  <img
                    className="consult_include_file_icon"
                    onLoad={lazy_load_image}
                    alt="pdf"
                    src={process.env.PUBLIC_URL + "/pdf_file.svg"}
                  />
                  {fileDetails?.fileName && (
                    <span title={fileDetails.fileName}>
                      {fileNameLimiter(fileDetails.fileName)}
                    </span>
                  )}
                </span>
                <CustomCheckbox
                  Checked={isIncluded}
                  onClickHandler={() =>
                    handleToggleToggleFileConfidential(
                      fileDetails.fileIdentifier,
                      fileDetails.is_confidential,
                    )
                  }
                />
              </div>
            );
          })
        ) : (
          <div className="no_data_found">No upload data found</div>
        )}
      </div>
      <div className="consult_include_file_btn_wrapper">
        <button
          className="consult_include_file_apply_btn"
          onClick={handleApplyIncludeFile}
          disabled={
            file_data?.include_send_file?.loading ||
            file_data.IncludedFile.length === 0
          }
        >
          {file_data?.include_send_file?.loading ? <BiLoaderAlt /> : "Apply"}
        </button>
        <button
          className="consult_include_file_cancel_btn"
          onClick={() => {
            handleToggleUploadDashboard(FILE_UPLOAD_DASHBOARD.UPLOAD);
            dispatch(resetIncludeData());
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

const ToolsConsultGptFileProcessing = () => {
  const {
    active_file_upload_dashboard,
    included_file_data,
    chat_data,
    socket,
  } = useSelector((state) => state.consultGptData);
  const dispatch = useDispatch();

  const handleToggleUploadDashboard = (tab) => {
    dispatch(setActiveUploadDashboard(tab));
  };

  const handleToggleIncludeTab = () => {
    dispatch(setActiveUploadDashboard(FILE_UPLOAD_DASHBOARD.INCLUDE));
    dispatch(getIncludeFileData({ chat_id: chat_data.root_node_id }));
    dispatch(resetIncludeData());
  };

  // console.log(included_file_data, "included_file_data");

  return (
    <div className="tools_consult_file_processing_wrapper">
      <div className="tools_consult_file_processing_header">
        {FILE_UPLOAD_DASHBOARD.UPLOAD === active_file_upload_dashboard && (
          <span
            className="tools_consult_file_processing_upload"
            onClick={() =>
              handleToggleUploadDashboard(FILE_UPLOAD_DASHBOARD.UPLOAD)
            }
          >
            Uploaded Files
          </span>
        )}

        {FILE_UPLOAD_DASHBOARD.INCLUDE === active_file_upload_dashboard && (
          <span className="tools_consult_file_processing_upload">My FIles</span>
        )}
        <button
          className={`${
            active_file_upload_dashboard === FILE_UPLOAD_DASHBOARD.INCLUDE &&
            "tools_consult_file_include_active"
          } tools_consult_file_processing_include`}
          onClick={handleToggleIncludeTab}
          disabled={
            active_file_upload_dashboard === FILE_UPLOAD_DASHBOARD.INCLUDE
          }
        >
          Include
        </button>
      </div>
      <div className="file_details_section">
        {FILE_UPLOAD_DASHBOARD.UPLOAD === active_file_upload_dashboard && (
          <ToolsConsultFileUploadDashBoard />
        )}
        {FILE_UPLOAD_DASHBOARD.INCLUDE === active_file_upload_dashboard && (
          <IncludedFilesSection
            file_data={included_file_data}
            chat_data={chat_data}
            socket={socket}
            handleToggleUploadDashboard={handleToggleUploadDashboard}
          />
        )}
      </div>
    </div>
  );
};

export default ToolsConsultGptFileProcessing;
