import TOOLS_TYPE from "../ToolsModule/ToolsConstants/TOOLS_TYPE";

const getToolTypeIcon = (cur_tool_type) => {
  switch (cur_tool_type) {
    case TOOLS_TYPE.FLIP:
      return "/EZFlip_logo.svg";
    case TOOLS_TYPE.READ:
      return "/EZRead_logo.svg";
    case TOOLS_TYPE.TRANSLATE:
      return "/EZTranslate_logo.svg";
    case TOOLS_TYPE.CONSULT:
      return "/EZConsult_logo.svg";
    default:
      break;
  }
};

export default getToolTypeIcon;
