import React from "react";
import "./ToolsNewRequestModal.scss";
import NewRequest from "../../../ToolsComponents/NewRequest/NewRequest";

const ToolsNewRequestModal = () => {
  return (
    <div className="new_request_container">
      <NewRequest />
    </div>
  );
};

export default ToolsNewRequestModal;
