// import FileDetails from "../../ToolsModule/ToolsJsonData/ToolsDetails.json";
// import FileList from "../../ToolsModule/ToolsJsonData/ToolsFilesList.json";
// import FileOutput from "../../ToolsModule/ToolsJsonData/ToolsFileOutputStatus.json";
// import OutputPreview from "../../ToolsModule/ToolsJsonData/ToolsOutputPreview.json";

import TOOLS_PROGRESS from "../../ToolsModule/ToolsConstants/TOOLS_PROGRESS";

export const status_obj = {
  download_links: null,
  progress: 0,
  status: TOOLS_PROGRESS.IN_QUEUE,
  // src_preveiw_file_link: null,
  // trg_preveiw_file_link: null,
  // src_preview_status: null,
  // trg_preview_status: null,
  // src_preview_err_mg: null,
  // src_preview_err_mg: null,
  src_preview: {
    link: null,
    status: null,
    err: null,
  },
  trg_preview: {
    link: null,
    status: null,
    err: null,
  },
};

const initialState = {
  preview_full_data: {
    src_pdf: {
      full: false,
      link: null,
      loading: false,
      error: null,
    },
    trg_pdf: {
      full: false,
      link: null,
      loading: false,
      error: null,
    },
  },
  tools_list_data: {
    // data: FileList.data,
    data: null,
    search_text: null,
    error: null,
    loading: false,
  },
  tools_details_data: {
    // data: FileDetails.data,
    data: null,
    error: null,
    loading: false,
  },
  output_status: {
    // data: FileOutput.data,
    data: status_obj,
    error: null,
    loading: false,
  },
  output_details: {
    // data: FileOutput.data,
    data: null,
    error: null,
    loading: false,
  },
  output_preview: {
    // data: OutputPreview.data,
    data: null,
    error: null,
    loading: false,
  },
};

export default initialState;
