const toolInfoData = {
  flip: {
    id: 0,
    tool_type: "flip",
    name: "Flip.ai",
    main_content:
      "The visual layout of a document needs to be mirrored when translating documents from or to languages such as Arabic, Urdu, Hebrew, and Persian that are written right to left.Use EZ Flip’s intelligent algorithm to mirror your documents at the click of a button.",
    main_content_a:
      "Use EZ Flip’s intelligent algorithm to mirror your documents at the click of a button.",
    content_a: "SIMPLE... ",
    content_a_small: "Flip at the click of a button",
    content_b: "INSTANT... ",
    content_b_small: "Output in seconds for 100+ slides",
    content_c: "ACCURATE... ",
    content_c_small: "AI algorithm repositions & flips selected elements",
    video_id: "W5In21sT5js",
  },
  read: {
    id: 1,
    tool_type: "read",
    name: "Read.ai",
    main_content:
      "Enhance productivity with our professional OCR. Easily convert scanned documents and PDFs into editable text, eliminating manual data entry. Ideal for businesses and professionals seeking streamlined workflows.",
    main_content_a:
      "Use EZ Flip’s intelligent algorithm to mirror your documents at the click of a button.",
    content_a: "SCAN... ",
    content_a_small: "Quickly scan any PDF file you want to convert",
    content_b: "RECOGNIZE... ",
    content_b_small: "OCR effortlessly recognizes English or Arabic text",
    content_c: "CONVERT... ",
    content_c_small: "Download the extracted text in editable format",
    video_id: "W5In21sT5js",
  },
  translate: {
    id: 2,
    tool_type: "translate",
    name: "Translate.ai",
    main_content:
      "We have cracked the formula for high quality Machine translation using Generative AI for Arabic Business Language. Our Proprietary model performs much better than any other player in the field, even Big Tech.",
    main_content_a:
      "Use EZ Flip’s intelligent algorithm to mirror your documents at the click of a button.",
    content_a: "READ... ",
    content_a_small: "Break down text into linguistic components",
    content_b: "TRANSLATE... ",
    content_b_small: "Convert text into chosen language",
    content_c: "REPLACE... ",
    content_c_small: "Produce translated output",
    video_id: "W5In21sT5js",
  },
  consult: {
    id: 2,
    tool_type: "consult",
    name_prefix: "Welcome to",
    name: "Consult.ai",
    description_content:
      "EZ Consult.ai is Best in the world for Arabic Language! Our Data Scientists combined it with our Proprietary LLM. And with their powers combined... we got Consult.ai... Your Personal Research Assistant.",
    about_content:
      "EZ Consult.ai has read through all data relevant to Middle East. Ask your business questions and it will not just point you to the relevant document but also summarize the Arabic content for you in English!",
    btn_text: "Start Research",
  },
};
export default toolInfoData;
