const fileCollectiveSizeChecker = ({ maxCollectiveSizeInMB, fileArr }) => {
  const totalFilesSize = fileArr
    .map((file) => file.size)
    .reduce((acc, cur) => acc + cur, 0);
  if (totalFilesSize > maxCollectiveSizeInMB * 1000000) {
    return false;
  } else {
    return true;
  }
};

export default fileCollectiveSizeChecker;
