// import { FaArchive } from "react-icons/fa";
// import { FiArchive } from "react-icons/fi";
// import { MdHomeFilled } from "react-icons/md";
// import { FaClipboardCheck } from "react-icons/fa";
// import { FaMartiniGlass } from "react-icons/fa6";

// Client Sidenav Css
import "./ToolsLeftSideNav.scss";
import { Link, useNavigate } from "react-router-dom";
import { lazy_load_image } from "../../..";
import { useDispatch, useSelector } from "react-redux";
import { toggleShowToolsSidebar } from "../../../store/utilsDataSlice/utilsDataSlice";
import TOOLS_PAGES from "../../ToolsConstants/TOOLS_PAGE";
import { useLocation } from "react-router-dom";
import {
  closeArchiveSidebar,
  closeFilesSidebar,
} from "../../../client_module/Nav/ClientSidenav/ClientSidenav";
import { resetToolsDetailsData } from "../../../store/toolsDataSlice/toolsDataSlice";

const ToolsLeftSideNav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { tools_page } = useSelector((state) => state.utilsData);
  const { active_tool_type } = useSelector((state) => state.utilsData);

  const hoverHiglight = (e) => {
    const navBtn = e.target.closest(".st-client-sidenav-btn");
    if (e.type === "mouseenter") {
      navBtn.classList.add("st-client-sidenav-btn-hover");
    } else if (e.type === "mouseleave") {
      navBtn.classList.remove("st-client-sidenav-btn-hover");
    }
  };

  const toggleSidebar = () => {
    dispatch(toggleShowToolsSidebar());
  };

  const handleMoveTo = ({ nav_path }) => {
    if (location.pathname === nav_path) {
      dispatch(toggleShowToolsSidebar());
    }
    if (location.pathname !== nav_path) {
      dispatch(toggleShowToolsSidebar(false));
    }
    navigate(nav_path);
  };

  return (
    <div className="st-client-sidenav">
      <Link className="st-client-ez-logo" to="/home" onClick={toggleSidebar}>
        <img
          className="lazy-img"
          onLoad={lazy_load_image}
          src={process.env.PUBLIC_URL + "/logo.png"}
          alt="EZ"
        />
      </Link>
      {/* <Link
        className={`st-client-sidenav-btn files-btn st-client-dashboard-sidebar-show-btn ${
          tools_page === TOOLS_PAGES.HOME && "st-client-sidenav-btn-active"
        }`}
        id="st-client-dashboard-show-btn"
        to="/home"
        onMouseEnter={hoverHiglight}
        onMouseLeave={hoverHiglight}
        onClick={toggleSidebar}
      >
        <img
          src={process.env.PUBLIC_URL + "/resources/nav_icons/home.svg"}
          className="st-client-sidenav-icon"
          onLoad={lazy_load_image}
          alt="Btn"
        />
      </Link> */}
      <button
        className={`st-client-sidenav-btn files-btn st-client-dashboard-sidebar-show-btn ${
          tools_page === TOOLS_PAGES.HOME && "st-client-sidenav-btn-active"
        }`}
        onMouseEnter={hoverHiglight}
        onMouseLeave={hoverHiglight}
        onClick={() => {
          handleMoveTo({ nav_path: "/home" });
        }}
      >
        <img
          src={process.env.PUBLIC_URL + "/resources/nav_icons/home.svg"}
          className="st-client-sidenav-icon"
          onLoad={lazy_load_image}
          alt="Btn"
        />
      </button>
      <Link
        className={`st-client-sidenav-btn files-btn st-client-dashboard-sidebar-show-btn        }`}
        id="st-client-dashboard-show-btn"
        to="/assignments"
        onMouseEnter={hoverHiglight}
        onMouseLeave={hoverHiglight}
        onClick={toggleSidebar}
      >
        <img
          src={
            process.env.PUBLIC_URL +
            "/resources/nav_icons/assignment_turned_in.svg"
          }
          className="st-client-sidenav-icon"
          // onLoad={lazy_load_image}
          alt="Btn"
        />
      </Link>

      {/* <Link
        className={`st-client-sidenav-btn files-btn st-client-dashboard-sidebar-show-btn ${
          tools_page !== TOOLS_PAGES.HOME && "st-client-sidenav-btn-active"
        }`}
        id="st-client-dashboard-show-btn"
        to="/tools/flip-home"
        onMouseEnter={hoverHiglight}
        onMouseLeave={hoverHiglight}
        onClick={toggleSidebar}
      >
        <img
          src={process.env.PUBLIC_URL + "/ai_icon_sidenav.svg"}
          className="st-client-sidenav-icon "
          // onLoad={lazy_load_image}
          alt="Btn"
        />
      </Link> */}
      <button
        className={`st-client-sidenav-btn files-btn st-client-dashboard-sidebar-show-btn ${
          tools_page !== TOOLS_PAGES.HOME && "st-client-sidenav-btn-active"
        }`}
        onMouseEnter={hoverHiglight}
        onMouseLeave={hoverHiglight}
        onClick={() => {
          dispatch(resetToolsDetailsData());
          handleMoveTo({
            nav_path: `/tools/${active_tool_type || "flip"}-home`,
          });
        }}
      >
        <img
          src={process.env.PUBLIC_URL + "/ai_icon_sidenav.svg"}
          className="st-client-sidenav-icon "
          // onLoad={lazy_load_image}
          alt="Btn"
        />
      </button>

      <Link
        className={`st-client-sidenav-btn files-btn st-client-dashboard-sidebar-show-btn}`}
        id="st-client-dashboard-show-btn"
        to="/archive"
        onMouseEnter={hoverHiglight}
        onMouseLeave={hoverHiglight}
        onClick={toggleSidebar}
      >
        <img
          src={process.env.PUBLIC_URL + "/resources/nav_icons/inventory_2.svg"}
          className="st-client-sidenav-icon "
          // onLoad={lazy_load_image}
          alt="Btn"
        />
      </Link>

      <Link
        className={
          "st-client-sidenav-btn files-btn st-client-dashboard-sidebar-show-btn st-client-settings-btn"
        }
        id="st-client-dashboard-show-btn"
        to="/faq"
        onClick={() => {
          setTimeout(() => {
            closeFilesSidebar();
            closeArchiveSidebar();
          }, 100);
        }}
        onMouseEnter={hoverHiglight}
        onMouseLeave={hoverHiglight}
      >
        <img
          className="st-client-sidenav-icon-faq "
          // onLoad={lazy_load_image}
          src={process.env.PUBLIC_URL + "/resources/nav_icons/feedback.svg"}
          alt="Btn"
        />
      </Link>
    </div>
  );
};

export default ToolsLeftSideNav;
