import Modal from "react-bootstrap/Modal";
import { useRef, useEffect, useState } from "react";
import { getData, postData } from "../../../helpers/request";
import { GrClose } from "react-icons/gr";
import styles from "./GetAQuoteModals.module.scss";
import PhoneInput from "react-phone-input-2";
// import { useNavigate } from "react-router-dom";

// import { SwapContext, LanguageContext } from "../../pages/_app";
import { toast } from "react-hot-toast";
import SearchDropdown from "./SearchDropdown/SearchDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  setBtnString,
  setModalShow,
  setModalTitle,
  setScrollShow,
  setServiceName,
} from "../../../store/loginHomeDataSlice/loginHomeDataSlice";
import { MODAL_TYPES } from "../../../store/loginHomeDataSlice/common";

export function GetAQuoteModal(props) {
  const dispatch = useDispatch();
  const { scrollShow, serviceName, btnString } = useSelector(
    (state) => state.loginHomeData,
  );
  // const { modalShow, setModalShow } = useContext(modalShowContext);
  // const { scrollShow, setScrollShow } = useContext(ScrollShowContext);
  // const { modalTitle, setModalTitle } = useContext(modalTitleContext);

  // const { swap, setSwap } = useContext(SwapContext);
  // const { language, setLanguage } = useContext(LanguageContext);

  // const { serviceName, setServiceName } = useContext(serviceNameContext);
  // const { btnString, setBtnString } = useContext(serviceBtnContext);
  const name_ref = useRef(null);
  const email_ref = useRef(null);
  const promotional_mail = useRef(null);
  const message_ref = useRef(null);
  // const router = useNavigate();
  const [isModalRequestSending, SetIsModalRequestSending] = useState(false);
  const [selectedService, setSelectedServices] = useState([]);
  const [selectedSubServices, setSelecteSubServices] = useState([]);
  const [selectedServiceData, setSelecteServicesData] = useState([]);
  // const [searchDownServiceData, setSearchDownServiceData] = useState([]);
  const handleSubmit = (e) => {
    e.preventDefault();
    SetIsModalRequestSending(true);
    const form_data = {
      name: name_ref.current.value,
      email: email_ref.current.value,
      promotional_email: promotional_mail.current.checked,
      phone_code: `+${phone_ref.current.state.selectedCountry.dialCode}`,
      phone_number: phnValue.phone,
      message: message_ref.current.value,
      website: "EZ Works",
      url: window.location.href,
      service_name: serviceName,
      services_json: selectedServiceData,
      button_value: btnString,
    };

    if (
      phnValue.phone.length > 5 &&
      phone_ref.current.state.selectedCountry.dialCode
    ) {
      postData(
        `${process.env.REACT_APP_WEBSITE_URL}/api/web/v1/quote/`,
        form_data,
      )
        .then((res) => {
          if (res.success === true) {
            SetIsModalRequestSending(false);
            toast.success("Submitted Successfully");
            window.location = "https://www.ezworks.ai/thankyou";
            e.target.reset();
            setModalShow(MODAL_TYPES.NULL);
            setSelecteServicesData([]);
            setSelecteSubServices([]);
            setSelectedServices([]);
            dispatch(setModalTitle(null));
            setValue({
              phone: phone_ref.current.state.selectedCountry.dialCode,
            });
            dispatch(setServiceName(null));
            dispatch(setBtnString(null));
          } else {
            alert(res.message);
            SetIsModalRequestSending(false);
          }
        })
        .catch((err) => {
          console.error(err);
          SetIsModalRequestSending(false);
        });
    } else {
      alert("Please enter a valid phone number with country code.");
      SetIsModalRequestSending(false);
    }
  };

  // ------------------------------------------------------------------------------------------------

  const phone_ref = useRef();
  const [phnValue, setValue] = useState({ phone: "" });
  const [country, setCountry] = useState();

  useEffect(() => {
    handleNavigator();
  }, []);

  async function handleNavigator() {
    getData(`https://geolocation-db.com/json/`)
      .then((res) => {
        try {
          if (res.longitude) {
            setCountry(res.country_code.toLowerCase());
          }
        } catch (error) {
          console.log(error);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function onPhnChange(value, data, event, formattedValue) {
    if (
      formattedValue.split(" ")[1] &&
      formattedValue.split(" ")[0] !== `+${data.dialCode}`
    ) {
      setValue({ phone: `${data.dialCode}${value}` });
    } else {
      setValue({ phone: value });
    }
  }
  // ------------------------------------------------------------------------------------------------

  // This use effect handles opening of this modal on scroll
  useEffect(() => {
    const handleScroll = () => {
      let yPos = window.scrollY;
      if (yPos > 1500 && scrollShow) {
        dispatch(setModalShow(MODAL_TYPES.GET_A_QUOTE));
        dispatch(setScrollShow(false));
      }
    };
    const id = setTimeout(() => {
      handleScroll();
    }, 3000);

    return () => clearTimeout(id);
  }, []);

  const handleModalHide = () => {
    dispatch(setModalShow(MODAL_TYPES.NULL));
    dispatch(setScrollShow(false));
    setSelecteServicesData([]);
    setSelectedServices([]);
    setSelecteSubServices([]);
  };

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        className={styles.quoteModal_ParentContainers}
        onHide={handleModalHide}
      >
        <div className={styles.quoteModal_ParentMainContainerWrapper}>
          <Modal.Header
            closeButton
            className={styles.quoteModal_MainHeaderContainers}
          >
            <div className={styles.quoteModal_backgroundImgWrapper}></div>
            <Modal.Title>
              <button
                className={`${styles.quoteModal_mobileclose_btn}`}
                onClick={handleModalHide}
              >
                <GrClose />
              </button>
              <div className={styles.quoteModal_titleMainContainers}>
                <h1 className={styles.quoteModal_maintextHeading}>
                  Send us a brief
                </h1>
                <h3 className={styles.quoteModal_subtextparagraph}>
                  Our team will get in touch with you within 10 Minutes!
                </h3>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={styles.quoteModal_MainBodyContainers}>
            <button
              className={`${styles.quoteModal_desktopclose_btn}`}
              onClick={handleModalHide}
            >
              <GrClose />
            </button>
            <form onSubmit={handleSubmit} style={{ width: "100%" }}>
              <div className={styles.quoteModal_MainBodyWrapperContainer}>
                <div className={styles.quoteModal_FieldWrapper}>
                  <input
                    type="text"
                    placeholder="Name"
                    ref={name_ref}
                    className={styles.quoteModal_InputFieldContainer}
                    required
                  />
                </div>
                <div
                  className={`${styles.quoteModal_FieldWrapper} contact_modal_phone_input`}
                >
                  <PhoneInput
                    className="phn-input"
                    country={country}
                    placeholder={"Mobile Number"}
                    ref={phone_ref}
                    onChange={onPhnChange}
                    value={phnValue.phone}
                    inputProps={{
                      required: true,
                    }}
                  />
                </div>
                <div className={styles.quoteModal_FieldWrapper}>
                  <input
                    type="email"
                    placeholder="Email"
                    ref={email_ref}
                    className={styles.quoteModal_InputFieldContainer}
                    required
                  />
                </div>
                {/* <div className={styles.quoteModal_FieldWrapper}>
                <GetAQuoteSeachInput
                  searchDownServiceData={searchDownServiceData}
                  setSearchDownServiceData={setSearchDownServiceData}
                  serviceName={serviceName}
                />
              </div> */}
                <div className={styles.quoteModal_FieldWrapper}>
                  <SearchDropdown
                    selectedService={selectedService}
                    setSelectedServices={setSelectedServices}
                    selectedSubServices={selectedSubServices}
                    setSelecteSubServices={setSelecteSubServices}
                    selectedServiceData={selectedServiceData}
                    setSelecteServicesData={setSelecteServicesData}
                  />
                </div>
                <div className={styles.quoteModal_textareasFieldWrappers}>
                  <textarea
                    type="text"
                    ref={message_ref}
                    className={styles.quoteModal_textareaWrapper}
                    placeholder="Message"
                    required
                  />
                </div>
                <div className={styles.quoteModal_textareaFieldWrapper}>
                  <div className={styles.quoteModal_checkboxMainWrapper}>
                    <input
                      type="checkbox"
                      defaultChecked={true}
                      ref={promotional_mail}
                      style={{ cursor: "pointer" }}
                    />
                    <span className={styles.quoteModal_spanContainer}>
                      I would like to receive promotional emails
                    </span>
                  </div>
                  <div className={styles.quoteModal_FieldWrapper}>
                    <button
                      type="submit"
                      disabled={isModalRequestSending}
                      className={styles.quoteModal_submitBtn}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
}
