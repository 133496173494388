import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  // createContext,
} from "react";
import {
  ApiContext,
  ToolTipError,
  ERROR_REGEX,
  date_time_convertor,
  DevelopmentContext,
  FeedbackRatingEmoji,
  DELIVERY_USER_TYPE,
} from "../../index.js";
import {
  ClientArchiveFilesArrayContext,
  ClientArchiveFileContext,
  NewFileContext,
  NotFoundErrorContext,
  // ClientDashboardFileContext,
  CLIENT_ROUTES,
  ClientRouteContext,
  BrokenLinkErrorContext,
  FILE_ENCRYPTION_STATES,
} from "../ClientComponents";
import { useNavigate, Link, useParams } from "react-router-dom";
import { getAuthData } from "../../helpers/request";
// import {FileIcon} from "../Dashboard/ClientDashboard";
import { MdAdd } from "react-icons/md";
import {
  FaSearch,
  FaSortAmountDownAlt,
  FaSortAmountDown,
  FaAngleDown,
  FaLock,
  FaUnlock,
  // FaKey,
  FaExternalLinkAlt,
  FaMinusCircle,
} from "react-icons/fa";

// importing Dummy Data
import dummy_all_assignemnts from "../../archive/test_data/dummy_data-v1/dummy_all_assignments.json";
// import dummy_all_external_assignments from "../../archive/test_data/dummy_external_assignments/dummy_all_external_assignments.json";
import { assignment_services } from "../../req_jsons/assignment_services.js";
import { closeFilesSidebar } from "../Nav/ClientSidenav/ClientSidenav.js";

const sidebarTextContext = React.createContext();

const FileType = ({ filetype, active }) => {
  return (
    <>
      {filetype?.includes("TR") && (
        <div
          className={`client-file-stage ${
            active && "client-file-stage-active"
          }`}
        >
          {active ? (
            <img
              className="file-type-svg"
              src={
                process.env.PUBLIC_URL + "/resources/services_icons/blue/TR.svg"
              }
              alt="TR"
            ></img>
          ) : (
            <img
              className="file-type-svg"
              src={
                process.env.PUBLIC_URL +
                "/resources/services_icons/white/TR.svg"
              }
              alt="TR"
            ></img>
          )}
        </div>
      )}
      {filetype?.includes("DP") && (
        <div
          className={`client-file-stage ${
            active && "client-file-stage-active"
          }`}
        >
          {/* <img className="file-type-svg" src="./resources/services_icons/DP.svg" alt="DP"></img> */}
          {active ? (
            <img
              className="file-type-svg"
              src={
                process.env.PUBLIC_URL + "/resources/services_icons/blue/DP.svg"
              }
              alt="DP"
            ></img>
          ) : (
            <img
              className="file-type-svg"
              src={
                process.env.PUBLIC_URL +
                "/resources/services_icons/white/DP.svg"
              }
              alt="DP"
            ></img>
          )}
        </div>
      )}
      {filetype?.includes("PD") && (
        <div
          className={`client-file-stage ${
            active && "client-file-stage-active"
          }`}
        >
          {active ? (
            <img
              className="file-type-svg"
              src={
                process.env.PUBLIC_URL + "/resources/services_icons/blue/PD.svg"
              }
              alt="PD"
            ></img>
          ) : (
            <img
              className="file-type-svg"
              src={
                process.env.PUBLIC_URL +
                "/resources/services_icons/white/PD.svg"
              }
              alt="PD"
            ></img>
          )}
          {/* <img className="file-type-svg" src="./resources/services_icons/PD.svg" alt="PD"></img> */}
        </div>
      )}
      {filetype?.includes("GD") && (
        <div
          className={`client-file-stage ${
            active && "client-file-stage-active"
          }`}
        >
          {active ? (
            <img
              className="file-type-svg"
              src={
                process.env.PUBLIC_URL + "/resources/services_icons/blue/GD.svg"
              }
              alt="DP"
            ></img>
          ) : (
            <img
              className="file-type-svg"
              src={
                process.env.PUBLIC_URL +
                "/resources/services_icons/white/GD.svg"
              }
              alt="DP"
            ></img>
          )}
          {/* <img className="file-type-svg" src="./resources/services_icons/GD.svg" alt="GD"></img> */}
        </div>
      )}
    </>
  );

  // if (filetype?.includes("VG") && filetype?.includes("TR")){
  //     return (
  //         <>
  //             <div className={`client-file-stage ${(active) && "client-file-stage-active"}`}>TR</div>
  //             <div className={`client-file-stage ${(active) && "client-file-stage-active"}`}>VG</div>
  //         </>
  //     )
  // } else if (filetype?.includes("TD") && filetype?.includes("TR")){
  //     return (
  //         <>
  //             <div className={`client-file-stage ${(active) && "client-file-stage-active"}`}>TR</div>
  //             <div className={`client-file-stage ${(active) && "client-file-stage-active"}`}>TD</div>
  //         </>
  //     )
  // } else if (filetype?.includes("PR") && filetype?.includes("TR")){
  //     return (
  //         <>
  //             <div className={`client-file-stage ${(active) && "client-file-stage-active"}`}>TR</div>
  //             <div className={`client-file-stage ${(active) && "client-file-stage-active"}`}>PR</div>
  //         </>
  //     )
  // } else if (filetype?.includes("VG")){
  //     return (
  //         <>
  //             <div className={`client-file-stage ${(active) && "client-file-stage-active"}`}>VG</div>
  //         </>
  //     )
  // } else if (filetype?.includes("TR")){
  //     return (
  //         <>
  //             <div className={`client-file-stage ${(active) && "client-file-stage-active"}`}>TR</div>
  //         </>
  //     )
  // } else {
  //     return (<></>)
  // }
};

export const sort_orders = {
  ASCENDING: "ascending",
  DESCENDING: "descending",
};

const FileHistoryHead = () => {
  const { devEnv } = useContext(DevelopmentContext);
  const [sort, setSort] = useState(sort_orders.ASCENDING);
  // const [searched, setSearched] = useState("");
  const prev_search = window.location?.search.split("=")[1];
  const search_word = useRef(null);
  const API_URL = useContext(ApiContext);
  const { setClientArchiveFilesArray } = useContext(
    ClientArchiveFilesArrayContext,
  );
  // const { setClientArchiveFile } = useContext(ClientArchiveFileContext);
  const history = useNavigate();
  // const { setSidebarText } = useContext(sidebarTextContext);

  const handleSort = (e) => {
    e.preventDefault();
    setSort((prev) => {
      if (prev === sort_orders.ASCENDING) {
        if (devEnv) {
          setClientArchiveFilesArray(dummy_all_assignemnts.data); // Setting Dummy Data
          // setClientArchiveFilesArray(dummy_all_external_assignments.data) // Setting Dummy External data
        } else {
          // getAuthData(`${API_URL}/api-client/client/v1/all-assignments/?search=${search_word.current.value}&sort=${sort_orders.DESCENDING}`)
          getAuthData(
            `${API_URL}/api-client/archive/v1/all-assignments/?sort=${sort_orders.DESCENDING}`,
          )
            .then((res) => {
              if (res.success === true) {
                setClientArchiveFilesArray(res.data);
              }
            })
            .catch((err) => {
              // console.error(err)
            });
        }
        return sort_orders.DESCENDING;
      } else if (prev === sort_orders.DESCENDING) {
        if (devEnv) {
          setClientArchiveFilesArray(dummy_all_assignemnts.data); // Setting Dummy Data
          // setClientArchiveFilesArray(dummy_all_external_assignments.data) // Setting Dummy External data
        } else {
          // getAuthData(`${API_URL}/api-client/client/v1/all-assignments/?search=${search_word.current.value}&sort=${sort_orders.ASCENDING}`)
          getAuthData(
            `${API_URL}/api-client/archive/v1/all-assignments/?sort=${sort_orders.ASCENDING}`,
          )
            .then((res) => {
              if (res.success === true) {
                setClientArchiveFilesArray(res.data);
              }
            })
            .catch((err) => {
              // console.error(err)
            });
        }
        return sort_orders.ASCENDING;
      }
    });
    // getAuthData(`${API_URL}/api-client/client/v1/all-assignments/?sort=${sort}`)
    //     .then((res) => {
    //         if (res.success === true) {
    //             setClientArchiveFilesArray(res.data);
    //         }
    //     })
    //     .catch(err => {
    //         // console.error(err)
    //     })
  };

  const handleSearch = (e) => {
    e.preventDefault();

    if (devEnv) {
      setClientArchiveFilesArray(dummy_all_assignemnts.data); // Setting Dummy Data
      // setClientArchiveFilesArray(dummy_all_external_assignments.data) // Setting Dummy External data
      return;
    }

    history(`/archive?search=${search_word.current.value}`);
    // history.push(`/archive?search=${search_word.current.value}`) left here checking for router change path
    // getAuthData(`${API_URL}/api-client/client/v1/all-assignments/?search=${search_word.current.value}&sort=${sort}`)
    // setSidebarText("Searching...")
    // getAuthData(`${API_URL}/api-client/archive/v1/client-all-assignments/?search=${search_word.current.value}`)
    //     .then((res) => {
    //         if (res.success === true) {
    //             setSearched(search_word.current.value)
    //             setClientArchiveFilesArray(res.data);
    //             setClientArchiveFile(res.last_assignment);
    //             (res.data.lenght<1) && (setSidebarText("No assingments found"))
    //         } else {
    //             (res.data.lenght<1) && (setSidebarText("No assingments found"))
    //             setClientArchiveFilesArray(null);
    //         }
    //     })
    //     .catch(err => {
    //         // console.error(err)
    //     })
  };

  return (
    <div className="client-file-history-head">
      <div className="client-file-history-head-top">
        <div className="client-sidebar-head">
          <span className="client-sidebar-head-text">Archive</span>
          {sort === sort_orders.ASCENDING && (
            <button className="client-sort-btn" onClick={handleSort}>
              <FaSortAmountDownAlt />
            </button>
          )}
          {sort === sort_orders.DESCENDING && (
            <button className="client-sort-btn" onClick={handleSort}>
              <FaSortAmountDown />
            </button>
          )}
        </div>
        <Link
          className="client-btns client-new-file-btn"
          to="/decrypt"
          onClick={() => {
            closeFilesSidebar();
          }}
        >
          <MdAdd className="client-add-btn" />
        </Link>
      </div>
      <form className="client-search-sort-form" onSubmit={handleSearch}>
        <input
          type="text"
          className="client-dashboard-input client-search-input"
          ref={search_word}
          defaultValue={prev_search || ""}
          onChange={(e) => {
            ToolTipError(e, [ERROR_REGEX.SYMBOLS_ERROR]);
          }}
          name="client-archive-search"
          id="client-archive-search"
          placeholder="Search..."
        />
        <button type="submit" className="client-search-btn">
          <FaSearch />
        </button>
      </form>
    </div>
  );
};

const displayNewFile = () => {
  document
    .querySelector(".client-sidebar-file-new")
    ?.classList.add("client-sidebar-file-show");
  document
    .querySelector(".client-sidebar-file-new")
    ?.classList.remove("client-sidebar-file-new");
};

const closeArchiveSidebar = (e) => {
  e?.preventDefault();
  document
    .querySelector("#client-archive-sidebar")
    ?.classList.remove("client-sidebar-show");
};

const FileDefaultUserIcon = ({ file }) => {
  return (
    <>
      {file?.file_stage === FILE_ENCRYPTION_STATES.ENCRYPTED && (
        <div className="client-sidebar-file-lock client-sidebar-file-lock-encrypted">
          <FaLock />
        </div>
      )}
      {file?.file_stage === FILE_ENCRYPTION_STATES.DOWNLOAD && (
        <div className="client-sidebar-file-lock client-sidebar-file-lock-download">
          <FaUnlock />
        </div>
      )}
      {file?.file_stage === FILE_ENCRYPTION_STATES.DECRYPTED && (
        <div className="client-sidebar-file-lock client-sidebar-file-lock-decrypted">
          <FaUnlock />
        </div>
      )}
      {file?.file_stage === FILE_ENCRYPTION_STATES.REVOKED && (
        <div className="client-sidebar-file-lock client-sidebar-file-lock-revoked">
          <FaMinusCircle />
        </div>
      )}
    </>
  );
};

const FileEncryptedUserIcon = ({ file }) => {
  return (
    <>
      {file?.file_stage === FILE_ENCRYPTION_STATES.REVOKED && (
        <div className="client-sidebar-file-lock client-sidebar-file-lock-revoked">
          <FaMinusCircle />
        </div>
      )}
      {file?.file_stage !== FILE_ENCRYPTION_STATES.REVOKED && (
        <div className="client-sidebar-file-lock client-sidebar-file-lock-decrypted">
          <FaExternalLinkAlt />
        </div>
      )}
    </>
  );
};

const File = ({ file, active, hidden, new_file }) => {
  const history = useNavigate();
  // const API_URL = useContext(ApiContext);
  // const { setClientArchiveFile } = useContext(ClientArchiveFileContext);
  const { setNewFile } = useContext(NewFileContext);
  // const { setNotFoundError } = useContext(NotFoundErrorContext);

  const showFileDetails = (e) => {
    closeArchiveSidebar();
    history(`/archive/${file.id}`);
    // getAuthData(`${API_URL}/api-client/archive/v1/client-all-assignments/${file?.id}`)
    //     .then((res) => {
    //         if (res.success === true) {
    //             history.push(`/archive/${file.id}`);
    //         }
    //         else {
    //             alert('Can\'t load the requested file. Please Try again!')
    //         }
    //     })
    //     .catch(err => {
    //         setNotFoundError(true)
    //     })
  };

  // ======= this use effect calls for the animation after the element is already laoded in the dom
  useEffect(() => {
    if (new_file) {
      setTimeout(() => {
        displayNewFile();
        setNewFile(null);
      }, 500);
    }
  });

  return (
    <div
      className={`client-sidebar-file glass-container glass-container-hover
            ${active && "client-sidebar-file-active"} ${
        !hidden && !new_file && "client-sidebar-file-show"
      }
            ${new_file && "client-sidebar-file-new"} ${
        file?.file_stage !== "download" && "client-sidebar-file-locked"
      }`}
      onClick={showFileDetails}
    >
      <div className="client-file-stages">
        <FileType
          filetype={file.services.map(
            (service) => assignment_services[service].code,
          )}
          active={active}
        />
      </div>

      {/* <div className="client-sidebar-file-info client-sidebar-file-icon"><FileIcon filename={file?.file_name}/></div> */}
      <div className="client-sidebar-file-feedback">
        <FeedbackRatingEmoji feedback={file.feedback} />
      </div>
      <div className="client-sidebar-file-info client-sidebar-file-filename">
        {(file?.delivery_user_type === DELIVERY_USER_TYPE.NONE ||
          file?.delivery_user_type === DELIVERY_USER_TYPE.DEFAULT) && (
          <FileDefaultUserIcon file={file} />
        )}
        {file?.delivery_user_type === DELIVERY_USER_TYPE.EXTERNAL && (
          <FileEncryptedUserIcon file={file} />
        )}

        {/* {(file?.file_stage===FILE_ENCRYPTION_STATES.ENCRYPTED) && <div className="client-sidebar-file-lock client-sidebar-file-lock-encrypted"><FaLock/></div>}
                {(file?.file_stage===FILE_ENCRYPTION_STATES.DOWNLOAD) && <div className="client-sidebar-file-lock client-sidebar-file-lock-download"><FaUnlock/></div>}
                {(file?.file_stage===FILE_ENCRYPTION_STATES.DECRYPTED) && <div className="client-sidebar-file-lock client-sidebar-file-lock-decrypted"><FaKey/></div>} */}
        <span>
          {`${
            file?.file_names[0].length > 50
              ? file?.file_names[0]?.substr(0, 40) + "..."
              : file?.file_names[0]
          } ${
            file?.file_names?.length > 1
              ? "+ " + String(file?.file_names?.length - 1)
              : ""
          }`}
        </span>
      </div>
      <div className="client-sidebar-file-info client-sidebar-file-subservice-details">
        {file?.unit_details.length < 2 ? (
          file?.unit_details?.map((detail, idx) => {
            return (
              <span key={idx} className="client-sidebar-file-subservice-detail">
                {`${detail?.unit_count} ${detail?.unit_type}`}
                {file.unit_details.lenght > 1 && ","}
              </span>
            );
          })
        ) : (
          <>
            {file?.unit_details.slice(0, 2)?.map((detail, idx) => {
              return (
                <span
                  key={idx}
                  className="client-sidebar-file-subservice-detail"
                >
                  {`${detail?.unit_count} ${detail?.unit_type}`},
                </span>
              );
            })}
            <span>...</span>
          </>
        )}
      </div>
      <div className="client-sidebar-file-info client-sidebar-file-date">
        {date_time_convertor(file?.received_date)
          .split(",")
          .slice(0, 2)
          .join(",")}
      </div>
    </div>
  );
};

const FileHistory = () => {
  const { clientArchiveFilesArray } = useContext(
    ClientArchiveFilesArrayContext,
  );
  const { clientArchiveFile } = useContext(ClientArchiveFileContext);
  const [hiddenItems, setHiddenItems] = useState([]);
  const { newFile } = useContext(NewFileContext);
  const { asgn_id } = useParams();
  const { sidebarText } = useContext(sidebarTextContext);

  const toggleMonth = (e, item) => {
    e.preventDefault();
    const monthEl = e.target.closest(".client-month-container");

    if (
      monthEl
        .querySelector(".client-month-arrow")
        .classList.contains("client-month-arrow-active")
    ) {
      // Removing item
      setHiddenItems((prev) => {
        const index = prev.indexOf(item);
        if (index === -1) {
          return prev;
        }
        return [...prev.slice(0, index), ...prev.slice(index + 1)];
      });
      monthEl
        .querySelector(".client-month-arrow")
        .classList.remove("client-month-arrow-active");
      monthEl.querySelectorAll(".client-sidebar-file").forEach((file) => {
        file.classList.remove("client-sidebar-file-hide");
      });
    } else {
      // adding item
      setHiddenItems((prev) => {
        return [...prev, item];
      });
      monthEl
        .querySelector(".client-month-arrow")
        .classList.add("client-month-arrow-active");
      monthEl.querySelectorAll(".client-sidebar-file").forEach((file) => {
        file.classList.add("client-sidebar-file-hide");
      });
    }

    // monthEl.classList.toggle("client-month-container-hidden")    // simple Hidding without transition
  };

  const closeResponsiveSidebar = (e) => {
    e.preventDefault();
    if (!e.target.closest(".client-sidebar-file")) return;
    document
      .querySelector("#client-file-sidebar")
      ?.classList.remove("client-sidebar-show");
    document
      .querySelector(".client-account-sidebar")
      ?.classList.remove("client-sidebar-show");
  };

  return (
    <div
      className="client-files-history"
      onClick={(e) => {
        closeResponsiveSidebar(e);
      }}
    >
      {clientArchiveFilesArray?.length > 0 ? (
        clientArchiveFilesArray?.map((item) => {
          return (
            <div key={item?.title} className="client-month-container">
              <div
                className="client-sidebar-month-head"
                onClick={(e) => toggleMonth(e, item)}
              >
                <span className="client-month">{item.title}</span>
                <FaAngleDown className="client-month-arrow" />
              </div>
              <div className="client-file-list">
                {item?.info?.map((file) => {
                  if (file.id === newFile?.id) {
                    return (
                      <File
                        key={file?.id}
                        file={file}
                        active={false}
                        new_file={true}
                        hidden={hiddenItems.includes(item)}
                      />
                    );
                  } else if (
                    parseInt(asgn_id) === file.id ||
                    clientArchiveFile?.id === file.id
                  ) {
                    return (
                      <File
                        key={file?.id}
                        file={file}
                        active={true}
                        hidden={hiddenItems.includes(item)}
                      />
                    );
                  } else {
                    return (
                      <File
                        key={file?.id}
                        file={file}
                        active={false}
                        hidden={hiddenItems.includes(item)}
                      />
                    );
                  }
                })}
              </div>
            </div>
          );
        })
      ) : (
        <span className="client-file-history-empty">{sidebarText}</span>
      )}
    </div>
  );
};

const ClientArchiveSidebar = () => {
  const { devEnv } = useContext(DevelopmentContext);

  const API_URL = useContext(ApiContext);
  const { clientArchiveFilesArray, setClientArchiveFilesArray } = useContext(
    ClientArchiveFilesArrayContext,
  );
  const { setNotFoundError } = useContext(NotFoundErrorContext);
  const { setBrokenLinkError } = useContext(BrokenLinkErrorContext);
  const { clientArchiveFile, setClientArchiveFile } = useContext(
    ClientArchiveFileContext,
  );
  // const { clientDisplayedFile, setClientDisplayedFile } = useContext(
  //   ClientDashboardFileContext,
  // );
  const [sidebarText, setSidebarText] = useState(null);
  const { asgn_id } = useParams();
  const route = useContext(ClientRouteContext);
  const search = window.location?.search.split("=")[1];

  const getArchiveData = (id) => {
    // getAuthData(`${API_URL}/api-client/client/v1/all-assignments/?id=${clientArchiveFile?.id}`)
    setClientArchiveFilesArray(null);
    setSidebarText("Fetching...");
    getAuthData(
      `${API_URL}/api-client/archive/v1/all-assignments/?id=${
        id || clientArchiveFile?.id
      }`,
    )
      .then((res) => {
        if (res.success === true) {
          setClientArchiveFilesArray(res.data);
          setClientArchiveFile(res.last_assignment);
          res.data.length < 1 && setSidebarText("No assignments yet");
          // setNotFoundError(false);
        } else if (res.message === "User doesn't have permission for it.") {
          setBrokenLinkError(true);
        } else {
          setNotFoundError(true);
        }
      })
      .catch((err) => {
        // console.error(err)
        setNotFoundError(true);
      });
  };

  const getArchiveDetailData = (id) => {
    // getAuthData(`${API_URL}/api-client/client/v1/all-assignments/?id=${clientArchiveFile?.id}`)
    getAuthData(`${API_URL}/api-client/archive/v1/all-assignments/${id}/`)
      .then((res) => {
        if (res.success === true) {
          setClientArchiveFile(res.last_assignment);
          // setNotFoundError(false);
        } else {
          setNotFoundError(true);
        }
      })
      .catch((err) => {
        // console.error(err)
        setNotFoundError(true);
      });
  };

  // const getAllAssignments = (id) => {
  //     // getAuthData(`${API_URL}/api-client/client/v1/all-assignments/?id=${clientArchiveFile?.id}`)
  //     // getAuthData(`${API_URL}/api-client/client/v1/all-assignments/?id=${asgn_id}`)
  //     getAuthData(`${API_URL}/api-client/archive/v1/client-all-assignments/?id=${id}`)
  //             .then((res) => {
  //                 if (res.success === true) {
  //                     // setClientArchiveFilesArray(res.data);
  //                     setClientArchiveFile(res.last_assignment);
  //                     setNotFoundError(false);
  //                 } else {
  //                     setNotFoundError(true);
  //                 }
  //             })
  //             .catch(err => {
  //                 setNotFoundError(true);
  //             })
  // }

  const getSearchArchiveData = (search_keyword) => {
    setClientArchiveFilesArray(null);
    setSidebarText("Searching...");
    getAuthData(
      `${API_URL}/api-client/archive/v1/all-assignments/?search=${search_keyword}`,
    )
      .then((res) => {
        if (res.success === true) {
          // setSearched(search_word.current.value)
          setClientArchiveFilesArray(res.data);
          setClientArchiveFile(res.last_assignment);
          res.data.length < 1 && setSidebarText("No assignments found");
        } else {
          setClientArchiveFilesArray(null);
        }
      })
      .catch((err) => {
        // console.error(err)
      });
  };

  // useEffect(()=> {
  //     const search = window.location?.search.split("=")[1];
  //     if (search) {
  //         getSearchArchiveData(search);
  //     } else {
  //         console.log("initial load all")
  //         setClientDisplayedFile(null);       // helps to load no assignment active in sidebar in mobile layout
  //         (!asgn_id) && getArchiveData(undefined);
  //         // getArchiveData();
  //     }

  // },[])

  // useEffect(() => {
  //     const search = window.location?.search.split("=")[1];
  //     // if (!route.includes(CLIENT_ROUTES.ARCHIVE)) return
  //     (asgn_id) && getArchiveData(asgn_id);
  //     (!search) && ((!asgn_id) && getArchiveData(undefined));
  // }, [asgn_id])

  useEffect(() => {
    if (!route.includes(CLIENT_ROUTES.ARCHIVE)) return;

    if (devEnv) {
      setClientArchiveFilesArray(dummy_all_assignemnts.data); // Setting Dummy Data
      setClientArchiveFile(dummy_all_assignemnts.last_assignment);

      // setClientArchiveFilesArray(dummy_all_external_assignments.data) // Setting Dummy External data
      // setClientArchiveFile(dummy_all_external_assignments.last_assignment);
      return;
    }
    if (search) {
      getSearchArchiveData(search);
    } else if (asgn_id && clientArchiveFilesArray) {
      getArchiveDetailData(asgn_id);
    } else {
      getArchiveData(asgn_id);
    }
    // (!search) && ((!asgn_id) && getArchiveData(undefined));
    // } else if (!asgn_id && clientArchiveFilesArray){
    //     console.log("no asgn")
    //     getArchiveDetailData(clientArchiveFilesArray[0].id);
  }, [asgn_id, search]);

  // useEffect(() => {
  //     console.log(window.location.href)
  //     const search = window.location?.search.split("=")[1];
  //     setClientDisplayedFile(null);       // helps to load no assignment active in sidebar in mobile layout
  //     (!search) && ((!asgn_id) && getArchiveData(undefined));
  // },)

  // history.listen((location, action) => {
  //     console.log(`The current URL is ${location.pathname}${location.search}${location.hash}`)
  //     console.log(`The last navigation action was ${action}`)
  // })

  return (
    <sidebarTextContext.Provider value={{ sidebarText, setSidebarText }}>
      <div
        className={`client-sidebar client-sidebar-mobile ${
          route === CLIENT_ROUTES.ARCHIVE && "client-sidebar-show"
        }`}
        id="client-archive-sidebar"
      >
        <FileHistoryHead />
        <FileHistory />
      </div>
    </sidebarTextContext.Provider>
  );
};

export { ClientArchiveSidebar };
