import React from "react";
import styles from "./CapabilitiesCentre.module.scss";

const CapabilitiesCentre = () => {
  return (
    <div className={styles.capabilities_outer_container}>
      <div className={styles.capabilities_container_wrapper}>
        <div className={styles.capabilities_container_brand_title}>
          <div className={styles.brand_title_heading_wrapper}>
            <img
              src={process.env.PUBLIC_URL + "/logo.png"}
              alt="ez_logo"
              className={styles.capabilities_ez_logo_image}
            />
            <h2 className={styles.brand_title_heading}>Capability Centre</h2>
          </div>
          <p className={styles.brand_title_desc}>
            An Extended Team for Business Professionals
          </p>
        </div>
        <div className={styles.capabilities_container_brand_desc}>
          <div className={styles.desc_text_wrapper}>
            <p className={`${styles.dark_blue}  ${styles.primary_size_desc}`}>
              100+ Multinational
            </p>
            <p className={`${styles.desc_text}`}>organizations trust EZ with</p>
            <p className={`${styles.dark_blue} ${styles.desc_text}`}>
              100s of assignments each week
            </p>
          </div>
          <div className={styles.desc_text_wrapper}>
            <p>
              <span
                className={`${styles.dark_blue}  ${styles.primary_size_desc}`}
              >
                Expertise in AI
              </span>
              &nbsp;<span className={`${styles.desc_text}`}>and other</span>
            </p>
            <p className={`${styles.desc_text}`}>Advanced Technology drives</p>
            <p className={`${styles.desc_text} ${styles.dark_blue}`}>
              Efficiency and Impact for clients
            </p>
          </div>
          <div className={styles.desc_text_wrapper}>
            <p className={`${styles.dark_blue}  ${styles.primary_size_desc}`}>
              1000+ Subject Matter
            </p>
            <p>
              <span
                className={`${styles.dark_blue}  ${styles.primary_size_desc}`}
              >
                Experts
              </span>
              &nbsp;
              <span className={`${styles.desc_text}`}>
                from 5 continents bring
              </span>
            </p>
            <p>
              <span className={`${styles.desc_text}`}>knowledge across</span>
              &nbsp;
              <span className={`${styles.desc_text} ${styles.dark_blue}`}>
                Topics, Functions,
              </span>
            </p>
            <p className={`${styles.desc_text} ${styles.dark_blue}`}>
              and Industries
            </p>
          </div>
          <div className={styles.desc_text_wrapper}>
            <p className={`${styles.dark_blue}  ${styles.primary_size_desc}`}>
              Stringent
            </p>
            <p className={`${styles.dark_blue}  ${styles.primary_size_desc}`}>
              Information Security
            </p>
            <p className={`${styles.desc_text}`}>
              practices at EZ have been awarded
            </p>
            <p>
              <span className={`${styles.desc_text}`}>the</span>&nbsp;
              <span className={`${styles.desc_text} ${styles.dark_blue}`}>
                ISO 27001:2022
              </span>
              &nbsp;
              <span className={`${styles.desc_text}`}>Certification</span>
            </p>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default CapabilitiesCentre;
