export const ToolsCardData = [
  {
    id: 0,
    tool_type: "flip",
    name: "Flip.ai",
    content_question: "Mirroring Arabic :: English PPTs taking a lot of time?",
    content_answer:
      "Use EZ Flip’s intelligent algorithm to mirror your documents at the click of a button",
    src: "/EZFlip_logo.svg",
    alt: "flip logo",
  },
  {
    id: 1,
    name: "Read.ai",
    tool_type: "read",
    content_question: "Struggling to extract text from scans?",
    content_answer:
      "Upload Arabic or English PDFs securely & download editable text using EZ’s OCR (Optical Character Reader)",
    src: "/EZRead_logo.svg",
    alt: "read logo",
  },
  {
    id: 2,
    name: "Translate.ai",
    tool_type: "translate",
    content_question: "Don’t speak English & Arabic both?",
    content_answer:
      "Experience the WORLD’s BEST Arabic :: English translation with our proprietary AI engine loved by Top Linguists around the world!",
    src: "/EZTranslate_logo.svg",
    alt: "translate logo",
  },
  {
    id: 3,
    name: "Consult.ai",
    tool_type: "consult",
    content_question: "Taking too long to Find relevant information?",
    content_answer: "Let EZ’s M-E Research LLM point you in relevant sources",
    src: "/EZConsult_logo.svg",
    alt: "consult logo",
  },
];
