import React from "react";
import styles from "./../SingleServiceComponent/SingleService.module.scss";
import { useDispatch } from "react-redux";
import {
  setModalShow,
  setModalTitle,
  setServiceName,
} from "../../../store/loginHomeDataSlice/loginHomeDataSlice";
import { MODAL_TYPES } from "../../../store/loginHomeDataSlice/common";

const SubSingleService = ({
  subSinglelist,
  id,
  redirectLink,
  service,
  setShowIconList,
}) => {
  const dispatch = useDispatch();
  // const { setModalShow } = useContext(modalShowContext);
  // const { setModalTitle } = useContext(modalTitleContext);
  // const { setServiceName } = useContext(serviceNameContext);

  function handleSendBrief() {
    setShowIconList(false);
    dispatch(setServiceName(service));
    dispatch(setModalTitle("Get a Quote"));
    dispatch(setModalShow(MODAL_TYPES.GET_A_QUOTE));
  }

  return (
    <div key={id} className={styles.subservice_single_iconcontainer}>
      {redirectLink ? (
        <a
          href={`${process.env.REACT_APP_WEBSITE_URL}/${redirectLink}`}
          rel="noopener noreferrer"
        >
          <div
            onClick={() => setShowIconList(false)}
            className={styles.serviceIndivisual_IconsContainer}
          >
            <span className={styles.serviceContainer_Iconimage}>
              <img
                className={styles.img_svg}
                src={`https://dxw9jueyijhmn.cloudfront.net/ez_website/frontend_img/iconsList/${subSinglelist.src}.svg`}
              />
            </span>
            <h3 className={styles.serviceIndivisual_IconsTextContainer}>
              {subSinglelist.name}
            </h3>
          </div>
        </a>
      ) : (
        <>
          <div
            onClick={handleSendBrief}
            className={styles.serviceIndivisual_IconsContainer}
          >
            <span className={styles.serviceContainer_Iconimage}>
              <img
                className={styles.img_svg}
                src={`https://dxw9jueyijhmn.cloudfront.net/ez_website/frontend_img/iconsList/${subSinglelist.src}.svg`}
              />
            </span>
            <h3 className={styles.serviceIndivisual_IconsTextContainer}>
              {subSinglelist.name}
            </h3>
          </div>
        </>
      )}
    </div>
  );
};

export default SubSingleService;
