import React, { useState, useRef } from "react";
import { FaTimes } from "react-icons/fa";

import { getAuthData, postAuthData } from "../../../../helpers/request";
import { useDispatch, useSelector } from "react-redux";
import { resetModal } from "../../../../store/utilsDataSlice/utilsDataSlice";
import TOOLS_MODALS from "../../../ToolsConstants/TOOLS_MODALS";
import { useParams } from "react-router-dom";
import {
  setAccessLogData,
  setSharedLogData,
} from "../../../../store/userSharedata/userShareDataSlice";
import { getAccessLogData } from "../../../..";
import "../ToolsShareModal/ToolsShareModal.scss";
import toast from "react-hot-toast";
const ToolsRevokeRequestModal = () => {
  // const [revokeResponse, setRevokeResponse] = useState();
  // const [shareErrNameArr, setShareErrNameArr] = useState([]);
  const [disableShareBtn, setDisableShareBtn] = useState(false);
  const [shareError, setShareError] = useState(null); // eslint-disable-line no-unused-vars
  // const [requesterNames ,setRequesterNames] = useState();
  const { asgn_id } = useParams();
  const shareModalRef = useRef(null);
  const shareSubmitBtn = useRef(null);
  const dispatch = useDispatch();
  const { tools_details_data } = useSelector((state) => state.toolsData);
  const { shared_email } = useSelector((state) => state.accessLog);

  const closeRevokeRequestModal = (e) => {
    e?.preventDefault();
    dispatch(resetModal());
  };

  const openRevokeRequestErrorModal = (e) => {
    e?.preventDefault();
    dispatch(TOOLS_MODALS.REVOKE_REQUEST_ERROR);
  };

  const submitBtnSuccess = () => {
    const shareBtn = shareSubmitBtn.current;
    shareBtn.style.background = "var(--light-orange,#e98742)";
    shareBtn.innerText = "Revoked!";
    setTimeout(() => {
      shareBtn.style.background = "var(--light-blue,#3cc3f2)";
      shareBtn.innerText = "Revoke";
      closeRevokeRequestModal();
    }, 1000);
  };

  const fetch_access_log = () => {
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/access/log/${asgn_id}/`,
    )
      .then((res) => {
        if (res?.success === true) {
          dispatch(setAccessLogData(res?.data));
        } else {
          toast.error(res?.message || "Error while Fetching Data!!");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const fetch_shared_log = () => {
    getAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/share/log/${asgn_id}/`,
    )
      .then((res) => {
        if (res?.success === true) {
          dispatch(setSharedLogData(res));
        } else {
          toast.error(res?.message || "Error while Fetching Data!!");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const postRevokeRequestdata = () => {
    let data = {
      assignment_id: tools_details_data?.data?.tools_assignment_id,
      email: shared_email,
    };

    // Disabling the share button to prevent multiple clicks
    setDisableShareBtn(true);

    // if (devEnv) {
    //   openRevokeRequestErrorModal();
    //   // closeRevokeRequestModal();
    //   // submitBtnSuccess();
    //   console.log(data);
    // } else {
    postAuthData(
      `${process.env.REACT_APP_API_URL}/api-client/tools/v1/assignment/share/revoke/`,
      getAccessLogData(data),
    )
      .then((res) => {
        if (res?.success === true) {
          submitBtnSuccess();
          setDisableShareBtn(false);
          fetch_shared_log();
          fetch_access_log();
        } else {
          toast.error(res?.message || "error occurred");
          setShareError(res?.message);
          setDisableShareBtn(false);
          openRevokeRequestErrorModal(); // this error modal will be shown when the user will try to revoke it's own access
          // alert(res.message)
          // closeRevokeRequestModal();
        }
      })
      .catch((err) => {
        setDisableShareBtn(false);
        console.error(err);
      });
  };

  return (
    <section className="tools-revoke-request-modal">
      <div
        className="tool-client-modal-container tool-client-shared-modal-container"
        ref={shareModalRef}
      >
        <div className="client-modal-head">
          <div
            className="client-modal-container-head"
            id="tool-client-share-heading"
          >
            Revoke Access
          </div>
          <button
            className="tool-client-modal-container-close-btn"
            id="tool-client-share-heading-close-btn"
            onClick={closeRevokeRequestModal}
          >
            <FaTimes />
          </button>
        </div>
        <p>You are revoking the access for the following user</p>
        <div className="tool-client-revoke-share-names-container">
          <div className="client-revoke-share-warning-name-item">
            <span className="client-revoke-share-name">{shared_email}</span>
          </div>
        </div>

        <div className="tool-client-share-btn-container">
          <button
            className="client-btns"
            id="tool-client-share-modal-cancel-btn"
            onClick={closeRevokeRequestModal}
          >
            Cancel
          </button>
          <button
            className="client-btns"
            ref={shareSubmitBtn}
            id="client-share-modal-okay-btn"
            disabled={disableShareBtn}
            onClick={postRevokeRequestdata}
          >
            Revoke
          </button>
        </div>
      </div>
    </section>
  );
};

export default ToolsRevokeRequestModal;
