import React from "react";
import STToolsCard from "./ToolsSidebarCard";
import { ToolsCardData } from "./ToolsCardData";
import "./ToolsSidebarTabNav.scss";
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { FaSearch } from "react-icons/fa";
// import { setSearchText } from "../../../store/toolsDataSlice/toolsDataSlice";
// import TOOLS_TYPE from "../../ToolsConstants/TOOLS_TYPE";

const ToolsSidebarTabNav = () => {
  // const searchRef = useRef();
  // const dispatch = useDispatch();
  const { tools_show_mob_sidebar } = useSelector((state) => state.utilsData);
  // const navigate = useNavigate();

  // const handleSearch = (e) => {
  //   e.preventDefault();
  //   dispatch(setSearchText(searchRef.current.value));
  //   navigate(`/tools/${TOOLS_TYPE.FLIP}-home`);
  // };

  return (
    // <div className="st_tools_main">
    <div
      className={`st_tools_main ${
        tools_show_mob_sidebar && "st_tools_main_left"
      }`}
    >
      <div className="st_tools_topnav_area"></div>
      <div className="st_tools_main_heading_container">
        <span className="st_tools_heading">Tools.ai</span>
        {/* <span className="st_tools_heading_icon">
          <img
            className="ai_tools_icon_img"
            src={process.env.PUBLIC_URL + "/ai_icon_sidenav.svg"}
            alt="ai"
          />
        </span> */}
      </div>
      {/* {tools_show_mob_sidebar && (
        <form className="search_form_home_mobile" onSubmit={handleSearch}>
          <input
            className="search_form_home_mobile_input"
            ref={searchRef}
            placeholder="Search file name"
          />
          <button className="search_form_mobile_btn" type="submit">
            <FaSearch />
          </button>
        </form>
      )} */}
      <div
        className={`st_tools_card_main ${
          tools_show_mob_sidebar && "st_tools_mobile_left"
        }`}
      >
        <div className="st_tools_card_wrapper">
          {ToolsCardData.map((tool) => (
            <STToolsCard
              key={tool.id}
              name={tool.name}
              src={tool.src}
              alt={tool.alt}
              content_question={tool.content_question}
              content_answer={tool.content_answer}
              tool_type={tool.tool_type}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ToolsSidebarTabNav;
