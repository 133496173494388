import React, { useContext, useRef, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { ApiContext, HomeRecentAsgnContext } from "../../../index.js";
import {
  ClientDashboardFileContext,
  CLIENT_MODAL_TYPE,
  ModalDispatchContext,
} from "../../ClientComponents";
import { getAuthData } from "../../../helpers/request";
import { SecureKeyContext } from "../../Dashboard/ClientLiveDashboard/ClientLiveDashboard.js";

import "./DecryptFileModal.css";

const ShowError = ({ errorMsg }) => {
  return <div className="client-input-error">{errorMsg}</div>;
};
const DecryptFileModal = () => {
  const secureKeyContext = useContext(SecureKeyContext);
  const { clientDisplayedFile, setClientDisplayedFile } = useContext(
    ClientDashboardFileContext,
  );
  const { setUserId } = useContext(HomeRecentAsgnContext);
  const [fetchError, setFetchError] = useState(null);
  const API_URL = useContext(ApiContext);

  const fetchId = useRef(null);

  const modal_dispatch = useContext(ModalDispatchContext);

  const validatingFormInput = (data) => {
    let valid = false;
    if (!data.secure_key) {
      // Checking for each input
      fetchId.current.placeholder = "Secure Key empty!";
      fetchId.current.style.borderColor = "red";
    } else {
      // If input is given then the red border for invalid input is removed

      fetchId.current.style.borderColor = "var(--dark-blue,#112949)";

      getAuthData(
        `${API_URL}/api-client/client/v1/decrypt/?q=${data.secure_key}`,
      )
        .then((res) => {
          if (res.success === true) {
            getAuthData(
              `${API_URL}/api-client/client/v1/all-assignments/${clientDisplayedFile?.id}/`,
            )
              .then((res) => {
                if (res.success === true) {
                  setClientDisplayedFile(res.last_assignment);
                  setUserId(res.last_assignment);
                  valid = true;
                  modal_dispatch({ modal_show_type: CLIENT_MODAL_TYPE.NONE }); // this line will show the copy secure key modal
                  secureKeyContext.setSecureKey(data.secure_key); // saving the data of the form into the state
                }
              })
              .catch((err) => {
                // console.error(err);
              });
          }
          if (res.message === "Please enter valid secure key.") {
            fetchId.current.focus();
            fetchId.current.style.borderColor = "red";
            setFetchError(res.message);
          }
        })
        .catch((err) => {
          // console.error(err);
        });
    }

    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const fetchFormData = {
      secure_key: fetchId.current.value,
      batch_id: clientDisplayedFile.batch_id,
    };
    validatingFormInput(fetchFormData);
  };

  return (
    <div className="client-modal-container client-secure-key-modal">
      <div className="client-modal-container-head">To Access this file:</div>
      <button
        className="client-modal-container-close-btn"
        onClick={() => {
          modal_dispatch({ modal_show_type: CLIENT_MODAL_TYPE.NONE });
        }}
      >
        <FaTimes />
      </button>
      <form>
        <input
          className="client-modal-input client-secure-key-input"
          ref={fetchId}
          type="text"
          id="fetchId"
          name="fetchId"
        />

        <button
          type="submit"
          className="client-modal-submit-btn client-secure-key-submit-btn"
          onClick={handleSubmit}
        >
          Fetch & Decrypt
        </button>
        {fetchError && <ShowError errorMsg={fetchError} />}
      </form>
    </div>
  );
};

export { DecryptFileModal };
