import React, { useState } from "react";
import "./FileUpload.scss";
import UploadSourceFile from "./UploadSourceFile/UploadSourceFile";
import TOOL_ENUM_FOR_MAIL from "../../../constants/TOOL_ENUM_FOR_EMAIL.js";

const FileUpload = ({
  file_text,
  active_tool_type,
  heading_text,
  heading_text_2,
  support_text,
  file_size_text,
  supported_languages,
  file_upload_note,
}) => {
  const [activeLang, setActiveLang] = useState("english");
  const [source, setSource] = useState("english");

  const supportMailId = "support@ez.works";

  return (
    <div className="flip_upload_container">
      {active_tool_type === "read" && (
        <div className="flip_upload_heading">
          <div className="file_select_upload_heading_text">{heading_text}</div>
          <div className="file_upload_language_box">
            <span
              onClick={() => setActiveLang("english")}
              className={
                activeLang === "english" ? "active_lang_box" : "english_box"
              }
            >
              English
            </span>
            <span
              onClick={() => setActiveLang("arabic")}
              className={
                activeLang === "arabic" ? "active_lang_box" : "arabic_box"
              }
            >
              Arabic
            </span>
          </div>
        </div>
      )}

      {active_tool_type === "flip" && (
        <div className="flip_upload_heading">
          <span className="flip_upload_heading_text">{heading_text}</span>
          <span className="flip_text_2">{heading_text_2}</span>
        </div>
      )}
      {active_tool_type === "translate" && (
        <div className="flip_upload_heading">
          <div className="file_swap_languages">
            <div className="source_lang">
              <span className="english_arabic_text_lang">
                {source[0].toUpperCase() + source.slice(1)}
              </span>
              <span className="source_target_text_lang">Source</span>
            </div>
            <span
              onClick={() =>
                setSource(source === "english" ? "arabic" : "english")
              }
              className="swap_icons_logo"
            >
              <img
                src={process.env.PUBLIC_URL + "/swap_icon.svg"}
                alt="swap_icon"
              />
            </span>
            <div className="target_lang">
              <span
                onClick={() => setSource("english")}
                className="english_arabic_text_lang"
              >
                {source === "english" ? "Arabic" : "English"}
              </span>
              <span className="source_target_text_lang">Translation</span>
            </div>
          </div>
        </div>
      )}
      <UploadSourceFile
        activeLang={activeLang}
        active_tool_type={active_tool_type}
        file_text={file_text}
        source={source}
      />
      <div className="upload_support_text_main">
        <span className="upload_support_text">{support_text}</span>
        <span className="upload_file_size_text">{file_size_text}</span>
        {(active_tool_type === "read" || active_tool_type === "translate") && (
          <span className="upload_language_support">{supported_languages}</span>
        )}
        {(active_tool_type === "read" || active_tool_type === "translate") && (
          <span className="file_upload_note_text">
            {file_upload_note}
            <a
              className="file_upload_note_support_mail_id"
              // onClick={handleSupportIdClick}
              href={`mailto:${supportMailId}?subject=File%20too%20large%20for%20${TOOL_ENUM_FOR_MAIL[active_tool_type]}`}
              target="_blank"
              rel="noreferrer"
            >
              {supportMailId}
            </a>
          </span>
        )}
      </div>
    </div>
  );
};

export default FileUpload;
