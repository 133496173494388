import React, { useEffect } from "react";
import "./ToolConsulGptChatSection.scss";
import ToolsConsultGptChatDashboard from "./ToolsConsultGptChatDashboard/ToolsConsultGptChatDashboard";
import ToolsConsultGptFilepane from "./ToolsConsultGptFilepane/ToolsConsultGptFilepane";
// import { getToolsFileDetails } from "../../../store/toolsDataSlice/toolsDataSlice";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getConsultChatData,
  socketConnection,
} from "../../../store/consultgptDataSlice/consultgptDataSlice";

const ToolConsulGptChatSection = () => {
  const dispatch = useDispatch();
  const { asgn_id } = useParams();
  const { chat_data, chatCreation } = useSelector(
    (state) => state.consultGptData,
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (
      asgn_id &&
      !chatCreation?.isNewChat &&
      !chatCreation?.initialMessageSend
    ) {
      dispatch(getConsultChatData({ chat_id: asgn_id }));
    }
  }, [asgn_id, chatCreation?.isNewChat, chatCreation?.initialMessageSend]);

  useEffect(() => {
    if (
      chat_data.root_node_id &&
      !chatCreation?.isNewChat &&
      !chatCreation?.initialMessageSend
    ) {
      dispatch(socketConnection({ chat_id: chat_data.root_node_id }));
    }
  }, [
    chat_data.root_node_id,
    chatCreation?.isNewChat,
    chatCreation?.initialMessageSend,
  ]);

  useEffect(() => {
    // Redirect if specific error occurs
    if (chat_data.error === "Assignment not found !") {
      navigate("/tools/consult-upload");
    }
  }, [chat_data.error]);

  return (
    <div className="tools_consult_chat_section_wrapper">
      <ToolsConsultGptChatDashboard />
      <ToolsConsultGptFilepane />
    </div>
  );
};

export default ToolConsulGptChatSection;
