import { React, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  ApiContext,
  UserContext,
  lazy_load_image,
  DevelopmentContext,
} from "../../../index.js";
import { postAuthData } from "../../../helpers/request";
import { Link } from "react-router-dom";
import company_code_data from "../../../archive/test_data/company_code.json";
import {
  hideFilesSidebar,
  NotFoundErrorContext,
  BrokenLinkErrorContext,
} from "../../ClientComponents.js";

// Client TopNav CSS
import "./ClientTopNav.css";
import toast from "react-hot-toast";
import { setProfileData } from "../../../store/profileDataSlice/profileDataSlice.js";
import { useSelector } from "react-redux";
import { nameLimiter } from "../../../utils/nameLimiter.js";
import {
  closeArchiveSidebar,
  closeFilesSidebar,
} from "../ClientSidenav/ClientSidenav.js";

const ClientTopnav = () => {
  const { devEnv } = useContext(DevelopmentContext);
  const history = useNavigate();
  const API_URL = useContext(ApiContext);
  const { profile, setProfile } = useContext(UserContext);
  const { setNotFoundError } = useContext(NotFoundErrorContext);
  const { setBrokenLinkError } = useContext(BrokenLinkErrorContext);
  const { comp_code } = useSelector((state) => state.profileData);

  const toggleLogoutBox = (e) => {
    if (
      e.target.closest(".client-user") ||
      e.target.closest(".client-logout-box")
    ) {
      const logoutBox = document.querySelector(".client-logout-box");
      if (getComputedStyle(logoutBox).display === "none") {
        document.querySelector(".client-logout-box").style.display =
          "inline-flex";
        // document.querySelector('.client-user-name').style.color = "var(--dark-blue,#112949)"
      } else if (getComputedStyle(logoutBox).display === "flex") {
        document.querySelector(".client-logout-box").style.display = "none";
      }
    } else if (
      !e.target.closest(".client-user") &&
      !e.target.closest(".client-logout-box")
    ) {
      document.querySelector(".client-logout-box").style.display = "none";
    }
  };

  const handleLogout = () => {
    if (devEnv) {
      setProfile({}); // Setting Dummy Data
      setProfileData({});
      window.localStorage.clear(); // Setting Dummy Data
      window.sessionStorage.clear(); // Setting Dummy Data
      // history("/"); // Setting Dummy Data
      history(
        comp_code && company_code_data.includes(comp_code)
          ? `/${comp_code}`
          : "/",
      );

      return;
    }

    postAuthData(`${API_URL}/api-client/auth/v1/logout/`)
      .then((res) => {
        if (res.success === true) {
          toast.success("Logged out successfully!");
          // setProfile({});
          // setProfileData({});
          window.localStorage.clear();
          window.sessionStorage.clear();
          history(
            comp_code && company_code_data.includes(comp_code)
              ? `/${comp_code}`
              : "/",
          );
        }
      })
      .catch((err) => {
        // console.error(err);
      });
  };

  useEffect(() => {
    // comment out this code if you want to apply lazy load on the image of the profile
    // if (!profile.image) return
    Array.from(document.querySelectorAll(".client-user-img")).map((cur) => {
      lazy_load_image(cur);
    });
  }, [profile]);

  return (
    <div className="client-topnav">
      <Link
        className="client-ez-logo-topnav"
        to="/home"
        // to="/decrypt"
        onClick={() => {
          hideFilesSidebar();

          setNotFoundError(false);
          setBrokenLinkError(false);
        }}
      >
        <img
          className="lazy-img"
          onLoad={lazy_load_image}
          src={process.env.PUBLIC_URL + "/logo.png"}
          alt="EZ"
        />
      </Link>
      <div
        className="client-user glass-container-hover"
        onClick={toggleLogoutBox}
      >
        <div className="client-user-name">
          Hi {nameLimiter(profile?.first_name || "User")},
        </div>
        <img
          className="client-user-img lazy-img"
          src={
            profile?.image
              ? `${API_URL}` + profile?.image
              : `${process.env.PUBLIC_URL}/resources/nav_icons/user.svg`
          }
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = `${process.env.PUBLIC_URL}/resources/nav_icons/user.svg`;
          }}
          alt={profile.first_name}
        />
      </div>
      <div className="client-logout-box">
        <div
          className="client-logout-btns"
          id="client-user-name-btn"
          onClick={toggleLogoutBox}
        >
          <div className="client-user-name" id="client-logout-box-user-name">
            Hi {nameLimiter(profile?.first_name || "User")},
          </div>
          <img
            className="client-user-img lazy-img"
            src={
              profile?.image
                ? `${API_URL}` + profile?.image
                : `${process.env.PUBLIC_URL}/resources/nav_icons/user.svg`
            }
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = `${process.env.PUBLIC_URL}/resources/nav_icons/user.svg`;
            }}
            alt={profile.first_name}
          />
        </div>
        <button
          className="client-logout-btns"
          onClick={(e) => {
            setTimeout(() => {
              closeFilesSidebar();
              closeArchiveSidebar();
            }, 100);
            setNotFoundError(false);
            setBrokenLinkError(false);
            toggleLogoutBox(e);
            history("/account");
            hideFilesSidebar();
          }}
        >
          Account
        </button>
        <button className="client-logout-btns" onClick={handleLogout}>
          Logout
        </button>
      </div>
    </div>
  );
};

export { ClientTopnav };
