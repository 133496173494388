import { useEffect, useRef } from "react";
import "./ToolsYoutubeModal.scss";
import { useDispatch } from "react-redux";
import { RxCross2 } from "react-icons/rx";
import { resetModal } from "../../../../store/utilsDataSlice/utilsDataSlice";
const ToolsYoutubeModal = () => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(resetModal());
  };
  const videoModalRef = useRef(null);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    const handleClickOutside = (event) => {
      if (
        videoModalRef.current &&
        !videoModalRef.current.contains(event.target)
      ) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.body.style.overflow = "auto";
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="flipAi_MainContainer">
      <div className="flipAi_MainWrapper" ref={videoModalRef}>
        <span
          className="flipVideoClose_btn"
          // onClick={onClose}
          onClick={handleClose}
        >
          <RxCross2 size={28} color="var(--white)" />
        </span>
        <iframe
          className="flipAiIframeSection"
          //   src={videoId}
          src="https://www.youtube.com/embed/faSTBQWnWqg?si=yMXZ1xO8j_mNEOoQ"
          title="YouTube video player"
          //   frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          //   referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen="allowfullscreen"
        ></iframe>
      </div>
    </div>
  );
};

export default ToolsYoutubeModal;
