import { useState, useEffect } from "react";
import { config } from "@react-spring/web";
import styles from "./ServiceNameCarousel.module.scss";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";
import Carousel from "react-spring-3d-carousel";
import { useDispatch } from "react-redux";
import { setSerivceActiveIndex } from "../../../../store/loginHomeDataSlice/loginHomeDataSlice";

// const Carousel = dynamic(() => import("react-spring-3d-carousel"), {
//   ssr: false,
// });

const ServiceNameCarousel = (props) => {
  const dispatch = useDispatch();
  const table = props.cards.map((element, index) => {
    return { ...element, onClick: () => handleSlideClick(index) };
  });
  const [offsetRadius, setOffsetRadius] = useState(2);
  const [showArrows, setShowArrows] = useState(false);
  const [goToSlide, setGoToSlide] = useState(0);
  const [cards] = useState(table);

  const indicatorSelecter = (index) => {
    if (index === 0) {
      props.setIndicatorSet([13, 14, 0, 1, 2, 3]);
    } else if (index === 1) {
      props.setIndicatorSet([4, 5, 6, 7, 8, 9]);
    } else if (index === 2) {
      props.setIndicatorSet([5, 6, 7, 8, 9, 10]);
    } else if (index === 3) {
      props.setIndicatorSet([9, 10, 11, 12, 13, 14]);
    } else if (index === 4) {
      props.setIndicatorSet([12, 13, 14, 0, 1, 2]);
    }
  };

  const handleSlideClick = (index) => {
    setGoToSlide(index);
    props.setSerivceTitleCardActiveIndex(index);

    indicatorSelecter(index);
  };

  useEffect(() => {
    dispatch(setSerivceActiveIndex(props.serivceTitleCardActiveIndex));
  }, [props.serivceTitleCardActiveIndex]);

  useEffect(() => {
    setOffsetRadius(props.offset);
    setShowArrows(props.showArrows);
  }, [props.offset, props.showArrows]);

  useEffect(() => {
    let newGoToSlide;
    let newTitleCardActiveIndex;

    if (
      props.serivceCardActiveIndex >= 0 &&
      props.serivceCardActiveIndex <= 3
    ) {
      newGoToSlide = 0;
      newTitleCardActiveIndex = 0;
    } else if (
      props.serivceCardActiveIndex >= 4 &&
      props.serivceCardActiveIndex <= 6
    ) {
      newGoToSlide = 1;
      newTitleCardActiveIndex = 1;
    } else if (
      props.serivceCardActiveIndex >= 7 &&
      props.serivceCardActiveIndex <= 8
    ) {
      newGoToSlide = 2;
      newTitleCardActiveIndex = 2;
    } else if (
      props.serivceCardActiveIndex >= 9 &&
      props.serivceCardActiveIndex <= 11
    ) {
      newGoToSlide = 3;
      newTitleCardActiveIndex = 3;
    } else if (
      props.serivceCardActiveIndex >= 12 &&
      props.serivceCardActiveIndex <= 15
    ) {
      newGoToSlide = 4;
      newTitleCardActiveIndex = 4;
    }

    // Check if state needs to be updated
    if (
      newGoToSlide !== goToSlide ||
      goToSlide !== props.serivceTitleCardActiveIndex
    ) {
      // console.log("1 effect service");
      setGoToSlide(newGoToSlide);
      props.setSerivceTitleCardActiveIndex(newTitleCardActiveIndex);
    }

    if (props.serviceCardGoToSlide !== props.serivceCardActiveIndex) {
      props.setServiceCardGoToSlide(props.serivceCardActiveIndex);
    }
  }, [props.serivceCardActiveIndex]);

  useEffect(() => {
    props.setPreviousCardIndex(props.serivceCardActiveIndex);
    props.setSerivceCardActiveIndex(props.serviceCardGoToSlide);
  }, [props.serviceCardGoToSlide]);

  const handleGoToPreviousSlide = () => {
    if (goToSlide === 0) {
      setGoToSlide(4);
      indicatorSelecter(4);
      dispatch(setSerivceActiveIndex(4));
    } else {
      setGoToSlide(goToSlide - 1);
      indicatorSelecter(goToSlide - 1);
      dispatch(setSerivceActiveIndex(goToSlide - 1));
    }
  };

  const handleGoToNextSlide = () => {
    if (goToSlide === 4) {
      setGoToSlide(0);
      indicatorSelecter(0);
      dispatch(setSerivceActiveIndex(0));
    } else {
      setGoToSlide(goToSlide + 1);
      indicatorSelecter(goToSlide + 1);
      dispatch(setSerivceActiveIndex(goToSlide + 1));
    }
  };

  return (
    <div className={styles.carousel_wrapper}>
      <Carousel
        slides={cards}
        goToSlide={goToSlide}
        offsetRadius={offsetRadius}
        showNavigation={showArrows}
        animationConfig={config.stiff}
      />
      {props.showNavigation && (
        <div className={styles.carousel_btns_wrapper}>
          <button
            onClick={handleGoToPreviousSlide}
            className={styles.carousel_btn_left}
          >
            <FaAngleLeft />
          </button>
          <button
            onClick={handleGoToNextSlide}
            className={styles.carousel_btn_right}
          >
            <FaAngleRight />
          </button>
        </div>
      )}
    </div>
  );
};

export default ServiceNameCarousel;
