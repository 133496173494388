import React, { useState, useRef, useContext, useEffect } from "react";
import { FaEye } from "react-icons/fa";
import { postData } from "../../helpers/request";
import { ApiContext, ToolTipError, ERROR_REGEX } from "../../index.js";
import {
  Link,
  useNavigate,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import queryString from "query-string";
import { ForgetMailSentContext, RetryErrorContext } from "../ClientComponents";
import toast from "react-hot-toast";
import company_code_data from "../../archive/test_data/company_code.json";
//Error components
const InputError = (props) => {
  return (
    <div className="client-login-input-error email-error">{props.errorMsg}</div>
  );
};

const ClientChangePasswordForm = () => {
  const API_URL = useContext(ApiContext);
  const { search } = useLocation();
  const history = useNavigate();
  const loggedClientPassword = useRef(null);
  const loggedClientConfirmPassword = useRef(null);
  const [passwordError, setPasswordError] = useState(null);
  const [confrimPasswordError, setConfirmPasswordError] = useState(null);
  const { q } = queryString.parse(search);
  const { setRetryError } = useContext(RetryErrorContext);
  const { company_code } = useParams();
  useEffect(() => {
    if (localStorage.getItem("query")) {
      localStorage.removeItem("query");
    }
    setRetryError(false);

    if (q) {
      localStorage.setItem("query", q);
      history(window.location.href.split("?"));
    }
  }, []);

  // Checking token
  // useEffect(() => {
  //     getData(`${API_URL}/api-client/auth/v1/verify-account/?q=${q}`)
  //         .then((res) => {
  //             if (res.success === false) {
  //                 if (res.message === "Your account is already verified.") {
  //                     history.push("/forgotpassword")
  //                 } else {
  //                     alert(res.message)
  //                 }
  //             }
  //         })
  //         .catch(err => {
  //             // console.error(err);
  //         })
  // }, [])

  const accountValidation = (password, confirmPassword) => {
    // const pswdRegex = new RegExp(ERROR_REGEX.PASSWORD_ERROR);
    // const passwordValidation = pswdRegex.test(password);

    // Checking for password
    if (password === "") {
      setConfirmPasswordError(null);
      return {
        valid: false,
        error: { msg: "Enter Password", onInput: "password" },
      };
      // } else if (!passwordValidation){
      //     setConfirmPasswordError(null);
      //     return {valid:passwordValidation,error:{msg:"Check Password", onInput:'password'}}
    } else {
      setPasswordError(null);
    }

    // Checking for confirm password
    if (confirmPassword === "") {
      setPasswordError(null);
      return {
        valid: false,
        error: { msg: "Enter Password Again", onInput: "confirm-password" },
      };
    } else if (confirmPassword !== password) {
      setPasswordError(null);
      return {
        valid: false,
        error: { msg: "Passwords don't match", onInput: "confirm-password" },
      };
    } else {
      setConfirmPasswordError(null);
    }

    // Passed all test
    return { valid: true, data: { password } };
  };

  const clearInput = () => {
    // Clearing the fields
    loggedClientPassword.current.value = "";
    loggedClientConfirmPassword.current.value = "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const user = {
      password: loggedClientPassword.current.value,
      confirmPassword: loggedClientConfirmPassword.current.value,
    };
    const data = {
      password: loggedClientPassword.current.value,
      q: localStorage.getItem("query"),
    };
    const validation = accountValidation(user.password, user.confirmPassword); // validatinng the input
    if (validation.valid) {
      clearInput();
      postData(`${API_URL}/api-client/auth/v1/change-password/`, data)
        .then((res) => {
          if (res.success === true) {
            toast.success("Password has been reset successfully!");
            history("/");
          } else {
            toast.error(res?.message || "Error!");
            setPasswordError(res.message);
          }
          localStorage.removeItem("query");
        })
        .catch((err) => {
          setRetryError(true);
        });
    } else {
      validation.error.onInput === "password" &&
        setPasswordError(validation.error.msg);
      validation.error.onInput === "confirm-password" &&
        setConfirmPasswordError(validation.error.msg);
      localStorage.removeItem("query");
    }
  };

  const showPassword = (pswd_input) => {
    if (pswd_input.current.type === "password") {
      pswd_input.current.type = "text";
    } else {
      pswd_input.current.type = "password";
    }
  };

  return (
    <form className="common-form client-login-form" onSubmit={handleSubmit}>
      <div className="client-form-head">Forgot Password?</div>

      <div className="login-input client-password-input">
        <input
          className="client-input client-form-input"
          onChange={(e) => {
            ToolTipError(e, [
              ERROR_REGEX.PASSWORD_ERROR,
              ERROR_REGEX.PASSWORD_SYMBOLS_ERROR,
            ]);
          }}
          ref={loggedClientPassword}
          type="password"
          id="client-password"
          name="client-password"
          placeholder="Password"
        ></input>
        <span
          className="client-pswd-show-btn"
          onClick={(e) => {
            e.preventDefault();
            e.target
              .closest(".client-pswd-show-btn")
              .classList.toggle("client-pswd-show-btn-active");
            showPassword(loggedClientPassword);
            loggedClientPassword.current.focus();
          }}
        >
          <FaEye />
        </span>
        {passwordError && <InputError errorMsg={passwordError} />}
      </div>

      <div className="login-input client-password-input">
        <input
          className="client-input client-form-input"
          onChange={(e) => {
            ToolTipError(e, [
              ERROR_REGEX.PASSWORD_ERROR,
              ERROR_REGEX.PASSWORD_SYMBOLS_ERROR,
            ]);
          }}
          ref={loggedClientConfirmPassword}
          type="password"
          id="client-conifrm-password"
          name="client-conifrm-password"
          placeholder="Confirm Password"
        />
        <span
          className="client-pswd-show-btn"
          onClick={(e) => {
            e.preventDefault();
            e.target
              .closest(".client-pswd-show-btn")
              .classList.toggle("client-pswd-show-btn-active");
            showPassword(loggedClientConfirmPassword);
            loggedClientConfirmPassword.current.focus();
          }}
        >
          <FaEye />
        </span>
        {confrimPasswordError && <InputError errorMsg={confrimPasswordError} />}
      </div>

      <table className="client-login-bottom-form-btns-table">
        <tbody>
          <tr>
            <td>
              <Link
                className="client-login-form-btns client-anchor"
                to={
                  company_code && company_code_data.includes(company_code)
                    ? `/${company_code}`
                    : "/"
                }
              >
                Login
              </Link>
            </td>
            <td>
              <Link
                className="client-login-form-btns client-anchor"
                to={
                  company_code && company_code_data.includes(company_code)
                    ? `/${company_code}/forgotpassword`
                    : "/forgotpassword"
                }
                style={{
                  float: "right",
                  textDecoration: "none",
                  color: "white",
                }}
              >
                Resend Link
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link
                className="client-login-form-btns client-anchor"
                to={
                  company_code && company_code_data.includes(company_code)
                    ? `/${company_code}/signup`
                    : "/signup"
                }
              >
                Sign Up
              </Link>
            </td>
            <td>
              <button
                className="client-login-form-btns client-login-btn"
                type="submit"
              >
                Update Password
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  );
};

// Forgot Password Form Component

const ClientForgetPasswordForm = () => {
  const { setForgetMailSent } = useContext(ForgetMailSentContext);
  const { setRetryError } = useContext(RetryErrorContext);
  const { company_code } = useParams();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    setRetryError(false);
  }, []);

  const API_URL = useContext(ApiContext);

  // Using ref hook to capture user credentials
  const loggedClientEmail = useRef(null);
  const [emailError, setEmailError] = useState(null);

  const accountValidation = (email) => {
    const emailRegex = /\w*(\.|-)?\w+@\w*(\.|-)?\w+\.\w*/g;
    const valid = emailRegex.test(email);
    if (email === "") {
      setEmailError("Enter Email Address");
    } else if (!valid) {
      setEmailError("Invalid Email");
    }
    return valid;
  };

  const clearInput = () => {
    loggedClientEmail.current.value = "";
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = { email: loggedClientEmail.current.value.toLowerCase() };
    if (searchParams.get("assignment_id"))
      data = { ...data, assignment_key: searchParams.get("assignment_id") };

    const validation = accountValidation(data.email); // validatinng the input
    if (validation) {
      clearInput();
      postData(`${API_URL}/api-client/auth/v1/forget-password/`, data)
        .then((res) => {
          if (res.success === true) {
            // alert("Please check your email and find the link.")
            setForgetMailSent(true);
          } else {
            setEmailError(res.message);
          }
        })
        .catch((err) => {
          setRetryError(true);
        });
    }
  };

  return (
    <form className="common-form client-login-form" onSubmit={handleSubmit}>
      <div className="client-form-head">Forgot Password?</div>

      <div className="login-input">
        <input
          className="client-input client-form-input"
          ref={loggedClientEmail}
          type="text"
          id="client-email"
          name="client-email"
          placeholder="Work Email"
        />
        {emailError && <InputError errorMsg={emailError} />}
      </div>
      <div className="client-login-btns-section">
        <div className="client-login-btn-part">
          <Link
            className="client-login-form-btns client-anchor"
            // to={
            //   company_code && company_code_data.includes(company_code)
            //     ? `/${company_code}`
            //     : "/"
            // }
            to={
              searchParams.get("assignment_id")
                ? `/?assignment_id=${searchParams.get("assignment_id")}`
                : company_code && company_code_data.includes(company_code)
                ? `/${company_code}`
                : "/"
            }
          >
            Login
          </Link>
          <button
            className="client-login-form-btns client-login-btn"
            type="submit"
          >
            Send Link
          </button>
        </div>
      </div>

      {/* <table className="client-login-bottom-form-btns-table">
                <tbody>
                    <tr>
                        <td><Link className="client-login-form-btns client-anchor" to="/">Login</Link></td>
                        <td>

                        </td>
                    </tr>
                    <tr>
                        <td><Link className="client-login-form-btns client-anchor" to="/signup">Sign Up</Link></td>
                        <td><button className="client-login-form-btns client-login-btn" type='submit'>Send Link</button></td>
                    </tr>
                </tbody>
            </table> */}
    </form>
  );
};

const CheckForgetVerificationEmail = () => {
  const { setForgetMailSent } = useContext(ForgetMailSentContext);
  const { setRetryError } = useContext(RetryErrorContext);
  const { company_code } = useParams();
  useEffect(() => {
    setRetryError(false);
  }, []);

  const resendVerification = (e) => {
    e.preventDefault();
    setForgetMailSent(false);
  };

  return (
    <div className="client-verify-container">
      <div className="client-form-head">
        Check your inbox for verification email.
      </div>
      <div className="client-login-btns-section">
        <div className="client-login-btn-part">
          <span
            className="client-login-form-btns client-anchor"
            onClick={resendVerification}
          >
            Resend Verification
          </span>
          <Link
            className="client-login-form-btns client-login-btn"
            to={
              company_code && company_code_data.includes(company_code)
                ? `/${company_code}`
                : "/"
            }
          >
            Login
          </Link>
        </div>
      </div>
      {/* <table className="client-login-bottom-form-btns-table">
                <tbody>
                    <tr>
                        <td>
                            <span className="client-login-form-btns client-anchor" onClick={resendVerification}>Resend Verification</span>
                        </td>
                        <td><Link className="client-login-form-btns client-login-btn" to="/">Login</Link></td>
                    </tr>
                </tbody>
            </table> */}
    </div>
  );
};

export {
  ClientForgetPasswordForm,
  ClientChangePasswordForm,
  CheckForgetVerificationEmail,
};
