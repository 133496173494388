import TOOLS_TYPE from "../ToolsModule/ToolsConstants/TOOLS_TYPE";

const getFileUnitType = ({ cur_tool_type, file_name, plural = true }) => {
  const file_ext = file_name?.split(".")[file_name?.split(".").length - 1];
  switch (cur_tool_type) {
    case TOOLS_TYPE.FLIP:
      if (file_ext == "docx") {
        return "Page";
      } else if (file_ext == "pptx") {
        return "Slide";
      } else {
        return "Slide/Page";
      }

    case TOOLS_TYPE.READ:
      if (file_ext == "docx") {
        return "Page";
      } else if (file_ext == "pptx") {
        return "Slide";
      } else {
        return "Slide/Page";
      }

    case TOOLS_TYPE.TRANSLATE:
      if (plural) {
        return "Words";
      } else {
        return "Word";
      }

    case TOOLS_TYPE.CONSULT:
      return "Queries";

    default:
      return "Slide/Page/Word/Queries";
  }
};

export default getFileUnitType;
