import React, { useContext, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaPen, FaEye, FaAngleLeft } from "react-icons/fa";
import { getAuthData, postAuthData } from "../../helpers/request";
import axios from "axios";
import { makeSecureDecrypt } from "../../helpers/security";
import {
  ApiContext,
  UserContext,
  ToolTipCustomError,
  ToolTipError,
  ERROR_REGEX,
  checkCountryCode,
  lazy_load_image,
} from "../../index.js";
import {
  ClientArchiveFileContext,
  ClientDashboardFileContext,
} from "../ClientComponents";
import { makeSecureEncrypt } from "../../helpers/security";
import countryCodes from "../../req_jsons/country_code.json";

import "./ClientAccountSettings.css";
import toast from "react-hot-toast";
import { setProfileData } from "../../store/profileDataSlice/profileDataSlice.js";

const renderPageContext = React.createContext(); // this is to show profile and password pages in same url
const accountContext = React.createContext();

// ====================== Toggling Sidebar =================================
const showAccountSidebar = (e) => {
  e.preventDefault();
  document
    .querySelector(".client-account-sidebar")
    .classList.toggle("client-sidebar-show");
};

// ===================== SIDEBAR =======================
const AccountSidebar = () => {
  const { setRenderPage } = useContext(renderPageContext);
  const showSetting = (e) => {
    // Removing active class from all other files
    document.querySelectorAll(".client-sidebar-account-item").forEach((el) => {
      el.classList.remove("glass-container-active");
    });
    // adding active file to the clicked file
    e.target
      .closest(".client-sidebar-account-item")
      .classList.add("glass-container-active");
  };

  const closeResponsiveSidebar = (e) => {
    e.preventDefault();
    if (!e.target.closest(".client-sidebar-account-item")) return;
    document
      .querySelector("#client-file-sidebar")
      ?.classList.remove("client-sidebar-show");
    document
      .querySelector(".client-account-sidebar")
      ?.classList.remove("client-sidebar-show");
  };

  return (
    <div className="client-sidebar client-account-sidebar">
      <div className="client-profile-sidebar-head">
        <div className="client-profile-sidebar-head-top">
          <div className="client-profile-sidebar-text">Account</div>
        </div>
      </div>

      <div
        className="client-profile-sidebar-items"
        onClick={(e) => {
          closeResponsiveSidebar(e);
        }}
      >
        <div
          className="client-sidebar-account-item glass-container glass-container-hover glass-container-active"
          onClick={(e) => {
            showSetting(e);
            setRenderPage(PAGE.PROFILE);
          }}
        >
          {/* <div className="client-sidebar-account-item-logo"><FaIdCard/></div> */}
          <div className="client-sidebar-account-item-head">
            Profile Information
          </div>
        </div>
        <div
          className="client-sidebar-account-item glass-container glass-container-hover"
          onClick={(e) => {
            showSetting(e);
            setRenderPage(PAGE.PASSWORD);
          }}
        >
          {/* <div className="client-sidebar-account-item-logo"><FaKey /></div> */}
          <div className="client-sidebar-account-item-head">
            Change Password
          </div>
        </div>
      </div>
    </div>
  );
};

// =================== DASHBOARD ========================

// const country_options = [
//   {
//     country: "Country",
//     country_code: "country",
//     num_code: "Code",
//     nationality: "European",
//   },
//   {
//     country: "Europe",
//     country_code: "eur",
//     num_code: "+95",
//     nationality: "European",
//   },
//   {
//     country: "Australia",
//     country_code: "aus",
//     num_code: "+92",
//     nationality: "Australian",
//   },
//   {
//     country: "USA",
//     country_code: "usa",
//     num_code: "+93",
//     nationality: "American",
//   },
//   {
//     country: "Middle East",
//     country_code: "me",
//     num_code: "+94",
//     nationality: "Middle East",
//   },
//   {
//     country: "India",
//     country_code: "ind",
//     num_code: "+91",
//     nationality: "Indian",
//   },
// ];

// const gender_options = [{label:"Male",value:"M"}, {label:"Female",value:"F"},{label:"Other",value:"O"}, {label:"Prefer Not to say",value:"NO"}]

// const ToolTipError = (input_ref,errMsg) => {
//     const input = input_ref?.current;
//     if(input?.validity.patternMismatch){
//         input?.setCustomValidity(errMsg);
//     }
//     else {
//         input?.setCustomValidity('');
//     }
//     return true;
// }

const AccountProfileForm = () => {
  // let highlight_color;
  const history = useNavigate();
  const { profile, setProfile } = useContext(UserContext);
  const API_URL = useContext(ApiContext);
  const { setClientDisplayedFile } = useContext(ClientDashboardFileContext);
  const { setClientArchiveFile } = useContext(ClientArchiveFileContext);
  const [sbmtBtnDisable, setSbmtBtnDisable] = useState(false);

  const f_name_ref = useRef();
  const l_name_ref = useRef();
  const email_ref = useRef();
  const organisation_ref = useRef();
  // const designation_ref = useRef();
  // const cur_location_ref = useRef();
  // const nationality_ref = useRef();
  // const gender_ref = useRef();
  const phone_num_code_ref = useRef();
  const phone_num_ref = useRef();
  const whatsapp_num_code_ref = useRef();
  const whatsapp_num_ref = useRef();
  const profile_pic_ref = useRef();
  const [image, setImage] = useState(null);

  const btnChange = () => {
    const profile_submit_btn = document.querySelector(
      "#client-profile-submit-btn",
    );
    profile_submit_btn.innerText = "Saved";
    profile_submit_btn.style.background = "var(--orange,#ea7b2c)";
    profile_submit_btn.style.borderColor = "var(--orange,#ea7b2c)";
    setTimeout(() => {
      profile_submit_btn.innerText = "Save";
      profile_submit_btn.style.background = "var(--light-blue,#3cc3f2)";
      profile_submit_btn.style.borderColor = "var(--light-blue,#3cc3f2)";
    }, 1000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Disabling the submit button to prevent multiple clicks
    setSbmtBtnDisable(true);
    // setDisableShareBtn(true)
    const user = JSON.parse(makeSecureDecrypt(sessionStorage.getItem("token")));
    let formData = new FormData();
    formData.append("first_name", f_name_ref.current.value);
    formData.append("last_name", l_name_ref.current.value);
    formData.append("organization", organisation_ref.current.value);
    // formData.append('designation', designation_ref.current.value);
    // formData.append('current_location', cur_location_ref.current.value);
    // formData.append('nationality', nationality_ref.current.value);
    // formData.append('gender', gender_ref.current.value);
    formData.append("phone_number_ext", phone_num_code_ref.current.value);
    formData.append("phone_number", phone_num_ref.current.value);
    formData.append("whatsapp_number_ext", whatsapp_num_code_ref.current.value);
    formData.append("whatsapp_number", whatsapp_num_ref.current.value);
    image && formData.append("image", image);

    axios({
      method: "put",
      url: `${API_URL}/api-client/client/v1/profile/${profile?.id}/`,
      data: formData,
      headers: { Authorization: `Bearer ${user}` },
    })
      .then((res) => {
        getAuthData(`${API_URL}/api-client/client/v1/profile/`)
          .then((res) => {
            if (res.success === true) {
              btnChange();
              setSbmtBtnDisable(false);
              setProfile(res.data);
              setProfileData(res.data);
            } else {
              // toast.custom(() => (
              //   <div className="toast-error-container">
              //     <div className="toast-content">{res.message}</div>
              //   </div>
              // ));
              toast.error(res?.message || "Error!");
              history("/account");
            }
          })
          .catch((err) => {
            setSbmtBtnDisable(false);
            // toast.custom(() => (
            //   <div className="toast-error-container">
            //     <div className="toast-content">{err}</div>
            //   </div>
            // ));
            toast.error(err || "Error!");
            history("/account");
            // console.error(err);
          });
        // history.push("/assignments");
      })
      .catch((err) => {
        console.error(err.message);
        setSbmtBtnDisable(false);
        // toast.custom(() => (
        //   <div className="toast-error-container">
        //     <div className="toast-content">{err.message}</div>
        //   </div>
        // ));
        toast.error(err?.message || "Error!");
        history("/account");
      });
  };

  // Getting the account
  const fetchClient = () => {
    if (
      sessionStorage.getItem("token") &&
      makeSecureDecrypt(sessionStorage.getItem("token"))
    ) {
      getAuthData(
        `${process.env.REACT_APP_API_URL}/api-client/client/v1/profile/`,
      )
        .then((res) => {
          if (res.success === true) {
            setProfile(res.data);
            setProfileData(res.data);
            lazy_load_image(profile_pic_ref.current);
          }
        })
        .catch((err) => {
          // console.error(err);
        });
    }
  };

  useEffect(() => {}, [profile]);

  useEffect(() => {
    fetchClient();
    setClientDisplayedFile(null);
    setClientArchiveFile(null);
  }, []);

  const handleCancel = (e) => {
    e.preventDefault();
    history("/assignments");
  };

  useEffect(
    (e) => {
      if (image) {
        let reader = new FileReader();
        reader.onload = function () {
          profile_pic_ref.current.src = reader.result;
        };
        reader.readAsDataURL(image);
      }
    },
    [image],
  );

  return (
    <section className="client-profile-section">
      <div className="client-profile-head client-profile-head-mobile">
        <button
          className="client-dashboard-back-btn"
          onClick={(e) => {
            showAccountSidebar(e);
          }}
        >
          <FaAngleLeft />
        </button>
        <span>Profile Information</span>
      </div>

      <div className="client-profile-form-container glass-container-active">
        <div className="client-profile-head">Profile Information</div>

        <form className="client-profile-form" onSubmit={handleSubmit}>
          <div className="client-profile-form-part">
            <div className="client-profile-image-container">
              <img
                className="client-profile-image lazy-img"
                ref={profile_pic_ref}
                src={
                  profile?.image ? `${API_URL}` + profile?.image : "./user.png"
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "./user.png";
                }}
                alt="Profile"
              />
              <div className="client-profile-image-edit">
                <input
                  className="client-profile-image-edit-input"
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={(e) => setImage(Array.from(e.target.files)[0])}
                  id="client-profile-image-edit-input"
                />
                <label htmlFor="client-profile-image-edit-input">
                  <FaPen className="client-profile-image-edit-pen" />
                  <p className="new-client-profile-image"></p>
                </label>
              </div>
            </div>
          </div>

          <div className="client-profile-form-part client-form-fields-part">
            <div className="client-profile-field">
              <label
                className="client-profile-form-label"
                htmlFor="client-profile-form-first-name"
              >
                First Name
              </label>
              <input
                className="client-dashboard-input client-profile-form-input"
                ref={f_name_ref}
                type="text"
                id="client-profile-form-first-name"
                // pattern="[A-Za-z\d\. ]{0,}"
                onChange={(e) => {
                  ToolTipError(e, [ERROR_REGEX.SYMBOLS_ERROR]);
                }}
                defaultValue={profile?.first_name}
                placeholder="First Name"
              />
            </div>

            <div className="client-profile-field">
              <label
                className="client-profile-form-label"
                htmlFor="client-profile-form-last-name"
              >
                Last Name
              </label>
              <input
                className="client-dashboard-input client-profile-form-input"
                ref={l_name_ref}
                type="text"
                id="client-profile-form-last-name"
                onChange={(e) => {
                  ToolTipError(e, [ERROR_REGEX.SYMBOLS_ERROR]);
                }}
                name="client-profile-form-last-name"
                defaultValue={profile?.last_name}
                placeholder="Last Name"
              />
            </div>

            <div className="client-profile-field">
              <label
                className="client-profile-form-label"
                htmlFor="client-profile-form-email"
              >
                Email
              </label>
              <input
                className="client-dashboard-input client-profile-form-input"
                ref={email_ref}
                type="text"
                id="client-profile-form-email"
                onChange={(e) => {
                  ToolTipError(e, [ERROR_REGEX.SYMBOLS_ERROR]);
                }}
                name="client-profile-form-email"
                defaultValue={profile?.email}
                disabled
                placeholder="Email"
                style={{ color: "var(--dark-grey)" }}
              />
            </div>

            <div className="client-profile-field">
              <label
                className="client-profile-form-label"
                htmlFor="client-profile-form-organisation"
              >
                Organisation
              </label>
              <input
                className="client-dashboard-input client-profile-form-input"
                ref={organisation_ref}
                type="text"
                id="client-profile-form-organisation"
                onChange={(e) => {
                  ToolTipError(e, [ERROR_REGEX.SYMBOLS_ERROR]);
                }}
                name="client-profile-form-organisation"
                defaultValue={profile?.organization}
                disabled
                style={{ color: "var(--dark-grey)" }}
                placeholder="Organisation"
              />
            </div>

            <div className="client-profile-field">
              <label
                className="client-profile-form-label"
                htmlFor="client-profile-form-phone-number-type"
              >
                Phone Number
              </label>
              <div className="client-profile-number-field">
                {/* <div className="client-profile-input-select  client-profile-input-number-select">
                                    <select className="client-dashboard-input client-profile-form-input client-number-type-input" defaultValue={profile?.phone_number_ext} ref={phone_num_code_ref} id="client-profile-form-phone-number-type" name="client-profile-form-phone-number-type" >
                                        {countryCodes.map((cur,i)=>{
                                            return <option key={i} value={cur.country_code}>{cur.country_code}</option>
                                        })}
                                    </select>
                                </div> */}
                <div className="client-profile-input-select  client-profile-input-number-select">
                  <input
                    list="phone-number-codes"
                    id="phone-number-extention"
                    onChange={(e) => {
                      ToolTipError(e, [ERROR_REGEX.TEXT_ERROR]);
                      ToolTipCustomError(
                        e,
                        "Please select a value from the list!",
                        !checkCountryCode(e),
                      );
                    }}
                    name="phone-number-code-extention"
                    ref={phone_num_code_ref}
                    defaultValue={profile?.phone_number_ext}
                    className="client-dashboard-input client-profile-form-input client-number-type-input"
                  />
                  <datalist id="phone-number-codes">
                    {countryCodes.map((cur, i) => {
                      return (
                        <option key={i} value={cur.country_code}>
                          {cur.country_code}
                        </option>
                      );
                    })}
                  </datalist>
                </div>

                <input
                  className="client-dashboard-input client-profile-form-input client-profile-number-input"
                  ref={phone_num_ref}
                  type="number"
                  id="client-profile-form-phone-number"
                  name="client-profile-form-phone-number"
                  defaultValue={profile?.phone_number}
                  placeholder="Number"
                />
              </div>
            </div>

            <div className="client-profile-field">
              <label
                className="client-profile-form-label"
                htmlFor="client-profile-form-whatsapp-number-type"
              >
                WhatsApp Number
              </label>
              <div className="client-profile-number-field">
                <div className="client-profile-input-select client-profile-input-number-select">
                  {/* <select className="client-dashboard-input client-profile-form-input client-number-type-input"  defaultValue={profile?.whatsapp_number_ext} ref={whatsapp_num_code_ref} id="client-profile-form-whatsapp-number-type" name="client-profile-form-whatsapp-number-type">
                                        {countryCodes.map((cur,i)=>{
                                            return <option key={i} value={cur.country_code}>{cur.country_code}</option>
                                        })}cToolTipCustomError
                                    </select> */}
                  <input
                    list="whatsapp-number-codes"
                    id="whatsapp-number-extention"
                    onChange={(e) => {
                      ToolTipError(e, [ERROR_REGEX.TEXT_ERROR]);
                      ToolTipCustomError(
                        e,
                        "Please select value from the list!",
                        !checkCountryCode(e),
                      );
                    }}
                    name="whatsapp-number-code-extention"
                    ref={whatsapp_num_code_ref}
                    defaultValue={profile?.whatsapp_number_ext}
                    className="client-dashboard-input client-profile-form-input client-number-type-input"
                  />
                  <datalist id="whatsapp-number-codes">
                    {countryCodes.map((cur, i) => {
                      return (
                        <option key={i} value={cur.country_code}>
                          {cur.country_code}
                        </option>
                      );
                    })}
                  </datalist>
                </div>
                <input
                  className="client-dashboard-input client-profile-form-input client-profile-number-input"
                  ref={whatsapp_num_ref}
                  type="number"
                  id="client-profile-form-whatsapp-number"
                  name="client-profile-form-whatsapp-number"
                  defaultValue={profile?.whatsapp_number}
                  placeholder="Number"
                />
              </div>
            </div>
          </div>

          <div className="client-profile-form-part">
            <div className="client-profile-form-btns">
              <div
                className="client-btns client-profile-form-btn client-profile-form-clear-btn"
                onClick={handleCancel}
              >
                Cancel
              </div>
              <button
                type="submit"
                className="client-btns client-profile-form-btn client-profile-form-submit-btn"
                id="client-profile-submit-btn"
                disabled={sbmtBtnDisable}
              >
                Save
              </button>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

const AccountPasswordForm = () => {
  const API_URL = useContext(ApiContext);
  const [oldPswdError, setOldPswdError] = useState(null);
  const [newPswdError, setNewPswdError] = useState(null);
  const [confirmPswdError, setConfirmPswdError] = useState(null);

  const oldPassword = useRef(null);
  const newPassword = useRef(null);
  const confirmNewPassword = useRef(null);

  const showPassword = (pswd_input) => {
    if (pswd_input.current.type === "password") {
      pswd_input.current.type = "text";
    } else {
      pswd_input.current.type = "password";
    }
  };

  const ShowErrror = ({ errorMsg }) => {
    return <div className="client-pswd-change-error">{errorMsg}</div>;
  };

  const handleClear = (e) => {
    e && e.preventDefault();
    oldPassword.current.value = "";
    newPassword.current.value = "";
    confirmNewPassword.current.value = "";
    setOldPswdError(null);
    setNewPswdError(null);
    setConfirmPswdError(null);
  };

  const btnChange = () => {
    const profile_submit_btn = document.querySelector(
      "#client-password-submit-btn",
    );
    profile_submit_btn.innerText = "Saved";
    profile_submit_btn.style.background = "var(--orange,#ea7b2c)";
    profile_submit_btn.style.borderColor = "var(--orange,#ea7b2c)";
    setTimeout(() => {
      profile_submit_btn.innerText = "Save";
      profile_submit_btn.style.background = "var(--light-blue,#3cc3f2)";
      profile_submit_btn.style.borderColor = "var(--light-blue,#3cc3f2)";
    }, 1000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // const pswdRegex = new RegExp(ERROR_REGEX.PASSWORD_ERROR);
    if (!oldPassword.current.value) {
      setNewPswdError(null);
      setConfirmPswdError(null);
      setOldPswdError("Enter Current Password");
    }
    // else if (!pswdRegex.test(newPassword.current.value)){
    //     setOldPswdError(null);
    //     setConfirmPswdError(null);
    //     setNewPswdError("Please enter an 8 digit password that includes at least 1 uppercase, 1 lowercase, 1 number and 1 special character ( $, @, #, %, &, * ).")
    // }
    else if (newPassword.current.value !== confirmNewPassword.current.value) {
      setOldPswdError(null);
      setNewPswdError(null);
      setConfirmPswdError("Passwords don't match, Enter Again");
      confirmNewPassword.current.value = "";
    } else if (newPassword.current.value === oldPassword.current.value) {
      setOldPswdError(null);
      setConfirmPswdError(null);
      setNewPswdError("New password can't be same as old password.");
    } else {
      const data = {
        password: oldPassword.current.value,
        new_password: newPassword.current.value,
      };
      postAuthData(`${API_URL}/api-client/auth/v1/reset-password/`, data)
        .then((res) => {
          if (res.success === true) {
            handleClear();
            sessionStorage.setItem(
              "token",
              makeSecureEncrypt(JSON.stringify(res.token.access_token)),
            );
            // if local storage already has token then it will save the token otherwise it won't
            localStorage.getItem("token") &&
              makeSecureDecrypt(localStorage.getItem("token")) &&
              localStorage.setItem("token", sessionStorage.getItem("token"));
            btnChange();
            // window.sessionStorage.clear();
            // window.localStorage.clear();
            // window.location.href = '/';
          }
          if (res.success === false) {
            setConfirmPswdError(null);
            setNewPswdError(null);
            setOldPswdError(res.message);
          }
        })
        .catch((err) => {
          // console.error(err);
        });
    }
  };
  return (
    <section className="client-profile-section">
      <div className="client-profile-head client-profile-head-mobile">
        <button
          className="client-dashboard-back-btn"
          onClick={(e) => {
            showAccountSidebar(e);
          }}
        >
          <FaAngleLeft />
        </button>
        <span>Change Password</span>
      </div>

      <div className="client-profile-form-container glass-container-active">
        <div className="client-profile-head">Change Password</div>

        <form
          className="client-password-form-container"
          onSubmit={handleSubmit}
        >
          <div className="client-form-grid-item" id="client-old-password-field">
            <label className="client-profile-form-label" htmlFor="old_password">
              Old Password
            </label>
            <div className="client-old-password-input">
              <div className="client-pswd-input-contianer">
                <input
                  className="client-dashboard-input client-profile-form-input"
                  onChange={(e) => {
                    ToolTipError(e, [
                      ERROR_REGEX.PASSWORD_ERROR,
                      ERROR_REGEX.PASSWORD_SYMBOLS_ERROR,
                    ]);
                  }}
                  ref={oldPassword}
                  type="password"
                  id="old_password"
                  name="old_password"
                  placeholder="Old Password"
                />
                <span
                  className="client-pswd-change-form-input-show-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    e.target
                      .closest(".client-pswd-change-form-input-show-btn")
                      .classList.toggle(
                        "client-pswd-change-form-input-show-btn-active",
                      );
                    showPassword(oldPassword);
                    oldPassword.current.focus();
                  }}
                >
                  <FaEye />
                </span>
              </div>
              {oldPswdError && <ShowErrror errorMsg={oldPswdError} />}
            </div>
          </div>

          <div className="client-form-grid-item" id="client-new-password-field">
            <label className="client-profile-form-label" htmlFor="new_password">
              New Password
            </label>
            <div className="client-pswd-input-contianer">
              <input
                className="client-dashboard-input client-profile-form-input"
                onChange={(e) => {
                  ToolTipError(e, [
                    ERROR_REGEX.PASSWORD_ERROR,
                    ERROR_REGEX.PASSWORD_SYMBOLS_ERROR,
                  ]);
                }}
                ref={newPassword}
                type="password"
                id="new_password"
                name="new_password"
                placeholder="New Password"
              />
              <span
                className="client-pswd-change-form-input-show-btn"
                onClick={(e) => {
                  e.preventDefault();
                  e.target
                    .closest(".client-pswd-change-form-input-show-btn")
                    .classList.toggle(
                      "client-pswd-change-form-input-show-btn-active",
                    );
                  showPassword(newPassword);
                  newPassword.current.focus();
                }}
              >
                <FaEye />
              </span>
            </div>
            {newPswdError && <ShowErrror errorMsg={newPswdError} />}
          </div>

          <div
            className="client-form-grid-item"
            id="client-confirm-password-field"
          >
            <label
              className="client-profile-form-label"
              htmlFor="confirm_new_password"
            >
              Confirm New Password
            </label>
            <div className="client-pswd-input-contianer">
              <input
                className="client-dashboard-input client-profile-form-input"
                onChange={(e) => {
                  ToolTipError(e, [
                    ERROR_REGEX.PASSWORD_ERROR,
                    ERROR_REGEX.PASSWORD_SYMBOLS_ERROR,
                  ]);
                }}
                ref={confirmNewPassword}
                type="password"
                id="confirm_new_password"
                name="confirm_new_password"
                placeholder="Confirm New Password"
              />
              <span
                className="client-pswd-change-form-input-show-btn"
                onClick={(e) => {
                  e.preventDefault();
                  e.target
                    .closest(".client-pswd-change-form-input-show-btn")
                    .classList.toggle(
                      "client-pswd-change-form-input-show-btn-active",
                    );
                  showPassword(confirmNewPassword);
                  confirmNewPassword.current.focus();
                }}
              >
                <FaEye />
              </span>
            </div>
            {confirmPswdError && <ShowErrror errorMsg={confirmPswdError} />}
          </div>

          <div
            className="client-form-grid-item"
            id="client-password-btns-container"
          >
            {/* <div className="client-pswd-change-form-btns">
                            <button className="client-pswd-change-form-btn client-pswd-change-cancel-btn" onClick={handleClear}>Cancel</button>
                            <button className="client-pswd-change-form-btn client-pswd-change-update-btn" type='submit' onClick={handleSubmit}>Change Password</button>
                        </div> */}
            <div className="client-profile-form-btns">
              <div
                className="client-btns client-profile-form-btn client-profile-form-clear-btn"
                onClick={handleClear}
              >
                Cancel
              </div>
              <button
                type="submit"
                className="client-btns client-profile-form-btn client-profile-form-submit-btn"
                id="client-password-submit-btn"
              >
                Save
              </button>
            </div>
          </div>

          {/* <div className="client-form-grid-item">
                        {newPswdError && <ShowErrror errorMsg={newPswdError}/>}
                    </div>

                    <div className="client-form-grid-item">
                        {confirmPswdError && <ShowErrror errorMsg={confirmPswdError}/>}
                    </div> */}
        </form>
      </div>
    </section>
  );
};
const AccountDashboard = () => {
  const { renderPage } = useContext(renderPageContext);
  return (
    <div className="client-dashboard">
      {renderPage === PAGE.PASSWORD && <AccountPasswordForm />}
      {renderPage === PAGE.PROFILE && <AccountProfileForm />}
    </div>
  );
};

const PAGE = {
  PASSWORD: "password",
  PROFILE: "profile",
};

const ClientAccountSettings = () => {
  const [renderPage, setRenderPage] = useState(PAGE.PROFILE);
  const [account] = useState({});
  const { setClientDisplayedFile } = useContext(ClientDashboardFileContext);

  useEffect(() => {
    setClientDisplayedFile({});
  }, []);

  return (
    <>
      <accountContext.Provider value={{ account }}>
        <renderPageContext.Provider value={{ renderPage, setRenderPage }}>
          <AccountSidebar />
          <AccountDashboard />
        </renderPageContext.Provider>
      </accountContext.Provider>
    </>
  );
};

export { ClientAccountSettings };
