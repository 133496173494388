import React, { useContext } from "react";
// import { AccessLogTypeIcon } from "./AccessLogTypes";
// import { ACCESS_LOG_TYPES } from "./AccessLogTypes";
import {
  ACCESS_LOG_TYPES,
  AccessLogTypeIcon,
  DEVICE_RESPONSIVENESS,
  HoverToolTipTextContext,
  TOOLTIP_HOVER_TYPE,
  date_time_convertor,
  getDeviceResponsiveness,
  showHoverToolTip,
} from "../../../..";

import "../ToolsAccessCard/ToolsAccessCard.scss";
const ToolsUserData = ({ access_log }) => {
  const HoverText = ({ tooltip_txt, hover_str, split_txt }) => {
    const log_str = hover_str.split(split_txt)[0] + split_txt;
    const log_hover = hover_str.split(split_txt)[1];
    const { setHoverTooltipStr } = useContext(HoverToolTipTextContext);

    return (
      <span>
        {log_str + " "}
        {getDeviceResponsiveness() === DEVICE_RESPONSIVENESS.MOBILE ? (
          <span
            className="hover-tooltip"
            onMouseOver={(e) => {
              setHoverTooltipStr(tooltip_txt);
              showHoverToolTip(e, TOOLTIP_HOVER_TYPE.OPEN);
            }}
            onMouseLeave={(e) => {
              showHoverToolTip(e, TOOLTIP_HOVER_TYPE.CLOSE, null);
            }}
          >
            {log_hover}
          </span>
        ) : (
          <u
            className="hover-tooltip"
            onMouseOver={(e) => {
              setHoverTooltipStr(tooltip_txt);
              showHoverToolTip(e, TOOLTIP_HOVER_TYPE.OPEN);
            }}
            onMouseLeave={(e) => {
              showHoverToolTip(e, TOOLTIP_HOVER_TYPE.CLOSE, null);
            }}
          >
            {log_hover}
          </u>
        )}
      </span>
    );
  };

  return (
    <div className="client-log-item-container client-access-log-item-container glass-container glass-container-hover">
      <div className="client-log-item-card client-access-log-type-logo">
        <div className="client-log-item-value">
          <AccessLogTypeIcon type={access_log.type} user={"client"} />
        </div>
      </div>
      <div className="client-log-item-card client-access-log-string">
        <div className="client-log-item-value">
          {[
            ACCESS_LOG_TYPES.DOWNLOAD,
            ACCESS_LOG_TYPES.SHARED,
            ACCESS_LOG_TYPES.SHARED_OUTSIDE,
            ACCESS_LOG_TYPES.ACCEPT,
            ACCESS_LOG_TYPES.DECLINE,
            ACCESS_LOG_TYPES.REQUEST,
            ACCESS_LOG_TYPES.REVOKE,
          ].includes(access_log.type) ? (
            <>
              {access_log.type === ACCESS_LOG_TYPES.DOWNLOAD && (
                <HoverText
                  tooltip_txt={access_log.tooltip.files}
                  hover_str={access_log.access_log_string}
                  split_txt={"downloaded "}
                />
              )}
              {access_log.type === ACCESS_LOG_TYPES.SHARED && (
                <HoverText
                  tooltip_txt={access_log.tooltip.shared_users}
                  hover_str={access_log.access_log_string}
                  split_txt={"with "}
                />
              )}
              {access_log.type === ACCESS_LOG_TYPES.SHARED_OUTSIDE && (
                <HoverText
                  tooltip_txt={access_log.tooltip.shared_users}
                  hover_str={access_log.access_log_string}
                  split_txt={"with "}
                />
              )}
              {access_log.type === ACCESS_LOG_TYPES.REQUEST && (
                <HoverText
                  tooltip_txt={access_log.tooltip.shared_users}
                  hover_str={access_log.access_log_string}
                  split_txt={"with "}
                />
              )}
              {access_log.type === ACCESS_LOG_TYPES.DECLINE && (
                <HoverText
                  tooltip_txt={access_log.tooltip.shared_users}
                  hover_str={access_log.access_log_string}
                  split_txt={"for "}
                />
              )}
              {access_log.type === ACCESS_LOG_TYPES.REVOKE && (
                <HoverText
                  tooltip_txt={access_log.tooltip.shared_users}
                  hover_str={access_log.access_log_string}
                  split_txt={"for "}
                />
              )}
              {access_log.type === ACCESS_LOG_TYPES.ACCEPT && (
                <HoverText
                  tooltip_txt={access_log.tooltip.shared_users}
                  hover_str={access_log.access_log_string}
                  split_txt={"for "}
                />
              )}
            </>
          ) : (
            access_log.access_log_string
          )}
        </div>
      </div>
      <div className="client-log-item-card client-access-log-platform client-blue-access-log">
        <div className="client-log-item-value">
          {/* <HoverText tooltip_txt={access_log.tooltip.ip} hover_str={access_log.platform} split_txt={"from "}/> */}
          {access_log?.tooltip?.ip ? (
            <HoverText
              tooltip_txt={access_log.tooltip.ip}
              hover_str={access_log.platform}
              split_txt={"from "}
            />
          ) : (
            access_log.platform
          )}
        </div>
      </div>
      <div className="client-log-item-card client-access-log-date client-blue-access-log">
        <div className="client-log-item-value">
          {date_time_convertor(access_log.date)}
        </div>
      </div>
    </div>
  );
};

export default ToolsUserData;
