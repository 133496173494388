import React, { useEffect, useRef } from "react";
import "./CustomFileInput.scss";
import fileCollectiveSizeChecker from "./fileCollectiveSizeChecker";
import fileSizeChecker from "./fileSizeChecker";
import { FaUpload } from "react-icons/fa";
import Dropzone from "react-dropzone";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { fileConsultLimiter } from "../../../../utils/fileNameLimiter";

const CustomFileInput = ({
  tagId,
  handleChange,
  styleClass,
  placeholder,
  clear = false,
  maxFileSize,
  maxFileSizeErrFn,
  maxCollectiveFileSize,
  maxCollectiveFileSizeFnErr,
  innerRef,
  disabled,
  files,
  formData,
  setFiles,
  setFilePaths,
  // handleFileUpload,
}) => {
  const hiddenFileInput = useRef(innerRef || null);

  const { source_loader, isConsultMode, consult_new_req_data } = useSelector(
    (state) => state.utilsData,
  );

  const checkOnDropFileSize = (acceptedFiles) => {
    //checking for filesize
    let size = 0;
    for (let i = 0; i < acceptedFiles.length; i++) {
      size += acceptedFiles[i];
    }

    size = size / 1000000;
    return size > 5120 ? false : true;
  };

  const handleDrop = (acceptedFiles) => {
    setFilePaths([]);
    if (acceptedFiles) {
      const sizeArr = acceptedFiles.map((elem) => {
        return elem.size;
      });
      const check = checkOnDropFileSize(sizeArr);
      if (check) {
        setFiles(acceptedFiles);
        handleChange(acceptedFiles);
      } else {
        toast.error("Source Files collective size should be less than 5 Gb", {
          id: "source-drop",
        });
      }
    }
  };
  const handleClick = (e) => {
    // e.stopPropagation();

    if (isConsultMode) {
      e.preventDefault(); // Prevent click action if consult mode is on
      toast.error("Input Files Field is disabled ", {
        id: "input-file-consult",
      });
      return;
    }
    if (!source_loader) {
      hiddenFileInput.current.value = null; // to reset the uploaded files
      setFilePaths([]);
      setFiles(null);
    }

    if (source_loader) {
      toast.error("Previous File(s) are being uploaded please wait", {
        id: "resetError",
      });
    }
    // hiddenFileInput.current.click();
  };
  const handleicon = () => {
    if (isConsultMode) {
      toast.error("Input Files Field is disabled ", {
        id: "input-file-consult",
      });
      return;
    }
    hiddenFileInput.current.value = null; // to reset the uploaded files
    setFilePaths([]);
    hiddenFileInput.current.click();

    if (!source_loader) {
      setFiles(null); // Reset files if not loading
    }
  };

  const fileLimiter = (fileName) => {
    const maxLength = 16;

    if (fileName.length > maxLength) {
      const extensionIndex = fileName.lastIndexOf(".");
      const name = fileName.substring(0, extensionIndex);
      const extension = fileName.substring(extensionIndex + 1);
      const limitedName = name.substring(0, maxLength - (extension.length + 4));
      const limitedData = limitedName + "...." + extension;
      return limitedData;
    } else {
      return fileName;
    }
  };
  const handleFileChange = (e) => {
    let clearValdation = true;
    if (maxFileSize) {
      if (
        !fileSizeChecker({
          fileArr: Array.from(e.target?.files),
          maxSizeInMB: maxFileSize,
        })
      ) {
        clearValdation = false;
        maxFileSizeErrFn();
      }
    }
    if (maxCollectiveFileSize) {
      if (
        !fileCollectiveSizeChecker({
          fileArr: Array.from(e.target?.files),
          maxCollectiveSizeInMB: maxCollectiveFileSize,
        })
      ) {
        clearValdation = false;
        maxCollectiveFileSizeFnErr();
      }
    }
    if (!clearValdation) return;
    handleChange(Array.from(e.target?.files));
    setFiles(Array.from(e.target?.files));
  };

  useEffect(() => {
    hiddenFileInput.current.value = null;
    setFilePaths([]);
    if (!source_loader) {
      setFiles(null);
    }
  }, [clear]);
  return (
    <Dropzone onDrop={handleDrop} disabled={isConsultMode || source_loader}>
      {({ getRootProps, getInputProps }) => (
        <div
          {...getRootProps()}
          className={`custom_media_input_wrapper ${styleClass}`}
          onClick={handleClick}
        >
          <input
            disabled={isConsultMode || source_loader}
            multiple={true}
            {...getInputProps()}
          />

          <label
            className="custom_media_input_label"
            htmlFor="custom_media_input_input_files"
          >
            {/* {placeholder || "Add media files"} */}
            {/* {files && files.length > 0 ? `${files[0].name}` : placeholder} */}
            <div className="files_input_name_list">
              {isConsultMode ? (
                `${fileConsultLimiter(consult_new_req_data.consult_file_name)}`
              ) : (
                <>
                  {files && files.length > 0
                    ? files.slice(0, 2).map((file, i) => {
                        const comma =
                          i < files.length - 1 && files.length !== 1
                            ? ", "
                            : "";
                        return (
                          <div key={i}>
                            {fileLimiter(file.name)}
                            {comma}
                          </div>
                        );
                      })
                    : placeholder}
                  {files &&
                    files.length > 2 &&
                    `+ ${files.length - 2} more file${
                      files.length > 3 ? "s" : ""
                    }`}
                </>
              )}
            </div>
          </label>
          <input
            disabled={isConsultMode || source_loader}
            style={{ display: "none" }}
            type="file"
            id="custom_media_input_input_files"
            data-tag-id={tagId}
            name="custom_media_input_input_files"
            ref={hiddenFileInput}
            placeholder={placeholder}
            multiple
            accept=".docx,.pptx,.xlsx,.pdf,.jpg,.jpeg,.mp4,.srt,.png,.ai,.eps,.psd,.zip,.csv,.tmx,.mxliff,.xliff,.mqxlz"
            onChange={handleFileChange}
          />
          <span className="custom_media_input_input_btn" onClick={handleicon}>
            <FaUpload color="var(--white)" className="input_logo_upload" />
          </span>
        </div>
      )}
    </Dropzone>
  );
};

export default CustomFileInput;
