import React, { useEffect, useRef, useState } from "react";
import "./ToolsConsultFIleUpload.scss";
// import { flipUploadData } from "../../FileUpload/fileUploadData";
import { useDispatch, useSelector } from "react-redux";
import fileUploadData from "../../FileUpload/fileUploadData";
import Dropzone from "react-dropzone";
import toast from "react-hot-toast";
import {
  MAX_FILE_COUNT,
  MAX_FILE_SIZE,
  UPLOAD_FILES_PROGRESS_STATUS,
  filesChunksGenerator,
} from "../../../../store/multiPartUploadDataSlice/common";
import {
  fetchFileChunkUrls,
  updateFilesObj,
} from "../../../../store/multiPartUploadDataSlice/multiPartUploadDataSlice";
import { useNavigate } from "react-router-dom";
import {
  createNewChat,
  resetChatData,
  setConsultChatType,
  updateRootChatId,
} from "../../../../store/consultgptDataSlice/consultgptDataSlice";
import { CHAT_TYPE } from "../../../../store/consultgptDataSlice/common";
import { v4 as uuidv4 } from "uuid";

const ToolsConsultFIleUpload = () => {
  const { active_tool_type } = useSelector((state) => state.utilsData);
  const content = fileUploadData[active_tool_type];
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);
  const { filesObj } = useSelector((state) => state.multiPartUploadData);
  const { chat_data } = useSelector((state) => state.consultGptData);
  const [uploadState, setUploadState] = useState({
    started: false,
    completed: false,
  });
  const [isInitialized, setIsInitialized] = useState(false);
  const navigate = useNavigate();

  const checkFileCount = (currentFiles, newFiles) => {
    if (currentFiles.length + newFiles.length > MAX_FILE_COUNT) {
      toast.error("Can't upload more than 5 files", {
        id: "file-count-error",
      });
      return false;
    }
    return true;
  };

  const validateFiles = (files) => {
    const oversizedFiles = files.filter((file) => file.size > MAX_FILE_SIZE);
    if (oversizedFiles.length > 0) {
      toast.error("File size exceeds the maximum allowed size of 200 MB", {
        id: "file-size-error",
      });
    }
    return files.filter((file) => file.size <= MAX_FILE_SIZE);
  };

  // Generate accepted formats
  const generateAcceptedFormats = (tool_type) => {
    switch (tool_type) {
      case "consult":
        return { "application/pdf": [".pdf"] };
      default:
        return {};
    }
  };

  const acceptedFormats = generateAcceptedFormats("consult");

  // Handle drop function
  const handleDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles && rejectedFiles.length > 0) {
      if (
        rejectedFiles.some(
          (file) => file.errors?.[0]?.code === "file-invalid-type",
        )
      ) {
        toast.error("file type is unsupported", {
          id: "unsupported-tool-file",
        });
      } else {
        toast.error("Error uploading file", { id: "upload-error" });
      }
      return;
    }

    if (!checkFileCount(files, acceptedFiles)) {
      return;
    }

    const validFiles = validateFiles(acceptedFiles);
    if (validFiles.length === 0) return;

    setFiles([...files, ...validFiles]);
    const newFilesObj = filesChunksGenerator(validFiles);
    dispatch(updateFilesObj(newFilesObj));
    setUploadState({ started: true, completed: false });
    setIsInitialized(true);
  };

  // Handle file change
  const handleFileChange = (e) => {
    e.preventDefault();
    const newFiles = Array.from(e.target.files);

    if (!checkFileCount(files, newFiles)) {
      return;
    }
    // Filter and validate files
    const validFiles = newFiles.filter((file) => {
      if (file.type !== "application/pdf") {
        toast.error(`File type is unsupported`, {
          id: `unsupported-file-${file.name}`,
        });
        return false;
      }
      return true;
    });

    if (validFiles.length === 0) return;

    const furtherValidatedFiles = validateFiles(validFiles);
    if (furtherValidatedFiles.length === 0) return;

    setFiles([...files, ...furtherValidatedFiles]);
    startChunkUpload(furtherValidatedFiles);
  };

  // Start chunk upload
  const startChunkUpload = (newFiles) => {
    const newFilesObj = filesChunksGenerator(newFiles, filesObj);
    setFiles([]);
    dispatch(updateFilesObj(newFilesObj));
    setUploadState({ started: true, completed: false });
    setIsInitialized(true);
  };

  // Handle file upload button click
  const onFileUploadHandler = async (event) => {
    event.stopPropagation();
    fileInputRef.current.click();
  };

  // Initialize upload
  const initializeUpload = () => {
    const new_chat_id = uuidv4();
    dispatch(resetChatData());
    dispatch(createNewChat(true));
    dispatch(updateRootChatId(new_chat_id));
    dispatch(setConsultChatType(CHAT_TYPE.FILE_UPLOAD));
    navigate(`/tools/consult-file/${new_chat_id}`);
  };

  // Start file upload
  const startFileUpload = () => {
    toast.success("File upload in progress. Do not leave this page", {
      id: "consult-upload-started-tool",
    });
    Object.values(filesObj).forEach((fileObj) => {
      if (
        fileObj.fileProgress.type !== UPLOAD_FILES_PROGRESS_STATUS.SUCCESS &&
        fileObj.fileProgress.type !== UPLOAD_FILES_PROGRESS_STATUS.COMPLETED &&
        fileObj.fileProgress.type !== UPLOAD_FILES_PROGRESS_STATUS.PROCESSING &&
        fileObj.fileProgress.type !== UPLOAD_FILES_PROGRESS_STATUS.FAILED
      ) {
        dispatch(
          fetchFileChunkUrls({ fileObj, root_node_id: chat_data.root_node_id }),
        );
      }
    });
    setUploadState({ started: false, completed: true });
  };

  // UseEffect to handle file upload
  useEffect(() => {
    if (isInitialized && uploadState.started) {
      initializeUpload();
      startFileUpload();
    }
  }, [uploadState.started, isInitialized, filesObj]);

  return (
    <div className="tools_consult_file_upload_section">
      <Dropzone onDrop={handleDrop} accept={acceptedFormats}>
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="file_upload_middle_container">
            <input {...getInputProps()} multiple={true} />
            <div className="tools_consult_file_upload_main">
              <div className="tools_consult_heading_text">
                <span>{content?.heading_text}</span>
                <span>{content?.heading_text_2}</span>
              </div>
              <div
                className="tools_consult_upload_btn"
                onClick={onFileUploadHandler}
              >
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  multiple
                  accept=".pdf"
                />
                <img
                  className="cloud_upload_img"
                  src={process.env.PUBLIC_URL + "/cloud_upload.svg"}
                  alt="upload"
                />
                <span>{content?.file_text}</span>
              </div>
            </div>
            <div className="tools_consult_file_cretia">
              <span>{content?.support_text}</span>
              <span>{content?.file_size_text}</span>
              <span>{content?.supported_languages}</span>
            </div>
          </div>
        )}
      </Dropzone>
    </div>
  );
};

export default ToolsConsultFIleUpload;
