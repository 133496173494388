import React from "react";
import styles from "./TestimonialCard.module.scss";
import { AiOutlineStar } from "react-icons/ai";
import FilledStar from "../../../../public_icons/HomePage/Icons/FilledStar";
import HalfFilled from "../../../../public_icons/HomePage/Icons/HalfFilled";
import TestimonialIcon from "../../../../public_icons/Icons/TestimonialIcon";

const TestimonialCard = (details) => {
  const StartTestimonial = ({ rating }) => {
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating - filledStars >= 0.5;

    const stars = Array(5)
      .fill()
      .map((_, index) => {
        if (index < filledStars) {
          return <FilledStar key={index} className={styles.star} />;
        } else if (index === filledStars && hasHalfStar) {
          return <HalfFilled key={index} className={styles.star} />;
        } else {
          return <AiOutlineStar key={index} className="star" />;
        }
      });

    return <div className={styles.testimonial_star_wrapper}>{stars}</div>;
  };

  return (
    <div
      className={styles.testimonial_card_wrapper}
      key={details.details.message}
    >
      <div className={styles.testimonial_card_icon}>
        <TestimonialIcon />
      </div>
      <div className={styles.testimonial_card_message_wrapper}>
        <p className={styles.testimonial_card_message_text}>
          {details.details.message}
        </p>
      </div>
      <div className={styles.client_details_wrapper}>
        <div className={styles.client_country_flag}>
          <img
            src={details.details.clientCountryIcon}
            className={styles.country_flag_img}
            alt="country_flag"
          />
        </div>
        <div className={styles.clients_info}>
          <p className={styles.testimonial_client_name}>
            {details.details.clientName}
          </p>
          <p className={styles.testimonial_client_profile}>
            {details.details.clientProfile}
          </p>
        </div>
        <div className={styles.testimonial_rating_wrapper}>
          <StartTestimonial rating={details.details.rating} />
        </div>
      </div>
    </div>
  );
};

export default TestimonialCard;
