import React, { useContext, useRef, useState } from "react";
import {
  ApiContext,
  ERROR_REGEX,
  getAccessLogData,
  ToolTipError,
  HomeRecentAsgnContext,
} from "../../../..";
import { getAuthData, postAuthData } from "../../../../helpers/request";
import {
  ClientDashboardFileContext,
  ClientFilesArrayContext,
  EncryptedContext,
  FILE_ENCRYPTION_STATES,
  NotFoundErrorContext,
} from "../../../ClientComponents";
import {
  AutoEncryptTimerContext,
  SecureKeyContext,
} from "../../ClientLiveDashboard/ClientLiveDashboard";

import "./DownloadBtn.css";

export const DownloadBtn = () => {
  const secureKeyContext = useContext(SecureKeyContext);
  const fetchId = useRef(null);
  const { setClientFilesArray } = useContext(ClientFilesArrayContext);
  const { clientDisplayedFile, setClientDisplayedFile } = useContext(
    ClientDashboardFileContext,
  );
  const { setUserId } = useContext(HomeRecentAsgnContext);
  const API_URL = useContext(ApiContext);
  const { encryptedState } = useContext(EncryptedContext);
  const { autoEncryptTimer } = useContext(AutoEncryptTimerContext);
  const [fetchError, setFetchError] = useState(null);
  const { setNotFoundError } = useContext(NotFoundErrorContext);
  const [resendBtnMsg, setResendBtnMsg] = useState(null); // eslint-disable-line no-unused-vars
  const [disableBtn, setDisableBtn] = useState(false);

  const updateSidebarFiles = () => {
    // bcz need to update the files list as well.
    getAuthData(
      `${API_URL}/api-client/client/v1/all-assignments/?id=${clientDisplayedFile?.id}`,
    )
      .then((res) => {
        if (res.success === true) {
          setClientFilesArray(res.data);
          setNotFoundError(false);
        } else {
          setNotFoundError(true);
        }
      })
      .catch((err) => {
        // console.error(err);
        setNotFoundError(true);
      });
  };

  // const notifyMailSent = (msg) => {
  //   setResendBtnMsg(msg);
  //   setTimeout(() => {
  //     setResendBtnMsg(null);
  //   }, 2000);
  // };

  const resendDataFn = (btn) => {
    const data = { delivery_id: clientDisplayedFile.batch_id };
    postAuthData(
      `${API_URL}/api-client/client/v1/resend-delivery/`,
      getAccessLogData(data),
    )
      .then((res) => {
        if (res.success === true) {
          getAuthData(
            `${API_URL}/api-client/client/v1/all-assignments/${clientDisplayedFile?.id}/`,
          )
            .then((res) => {
              if (res.success === true) {
                setClientDisplayedFile(res.last_assignment);
                setUserId(res.last_assignment);
                // notifyMailSent("Mail sent!")
                btnDisableFn(btn, true, true); // switching timer on
              }
            })
            .catch((err) => {
              setNotFoundError(true);
              btnDisableFn(btn, false, false); // enabling button since there is an error
            });
        }
      })
      .catch((err) => {
        setNotFoundError(true);
        btnDisableFn(btn, false, false); // enabling button since there is an error
      });
  };

  const btnDisableFn = (btn, disable = true, timer = false) => {
    btn.disabled = disable;
    if (timer) {
      btn.innerText = "Mail Sent!";
      setTimeout(() => {
        let t = 30; // timer time
        let timer_interval = setInterval(() => {
          t--;
          if (t > 0) {
            btn.innerText = `Wait ${t}s`;
            btn.classList.add("decrypt-resend-btn-timer");
          } else {
            btn.innerText = "Resend Email";
            btn.disabled = false;
            btn.classList.remove("decrypt-resend-btn-timer");
            clearInterval(timer_interval);
          }
        }, 1000);
      }, 2000);
    }
  };

  const handleResend = (e) => {
    const resendBtn = e.target.closest("button");
    if (resendBtn.disabled) return;
    btnDisableFn(resendBtn, true); // disabling the button
    resendDataFn(resendBtn);
  };

  const handleKeySubmit = () => {
    const fetchFormData = {
      secure_key: fetchId.current.value,
      delivery_id: clientDisplayedFile.batch_id,
      // batch_id : clientDisplayedFile.batch_id
    };
    // setBtn(FILE_ENCRYPTION_STATES.DOWNLOAD)
    if (!fetchId.current.value) {
      // Checking for each input
      // fetchId.current.placeholder = "Secure Key empty!";
      setFetchError("Enter Secure Key");
      setDisableBtn(false);
      // fetchId.current.style.borderColor ="red"
    } else {
      // If input is given then the red border for invalid input is removed
      fetchId.current.style.borderColor = "var(--dark-blue,#112949)";
      postAuthData(
        `${API_URL}/api-client/client/v1/decrypt/`,
        getAccessLogData(fetchFormData),
      )
        .then((res) => {
          // if (res.message === "Client User can download file Successfully") {
          if (res.success) {
            getAuthData(
              `${API_URL}/api-client/client/v1/all-assignments/${clientDisplayedFile?.id}/`,
            )
              .then((res) => {
                if (res.success === true) {
                  setClientDisplayedFile(res.last_assignment);
                  setUserId(res.last_assignment);
                  secureKeyContext.setSecureKey(fetchFormData.secure_key); // saving the data of the form into the state
                }
                setDisableBtn(false);
              })
              .catch((err) => {
                setNotFoundError(true);
                setDisableBtn(false);
              });
            updateSidebarFiles();
          } else {
            fetchId.current.focus();
            fetchId.current.style.borderColor = "red";
            setFetchError(res.message);
            setDisableBtn(false);
          }
          // if (res.message==="Invalid Secure Key, Check Again" || res.message==="Key Expired, Check email for new key") {
          //     // fetchId.current.focus();
          //     // fetchId.current.style.borderColor ="red";
          //     // setFetchError(res.message)
          // }
        })
        .catch((err) => {
          // console.error(err);
          setDisableBtn(false);
        });
    }
  };

  const fetchAPI = (api_url, data) => {
    if (!api_url) {
      getAuthData(
        `${API_URL}/api-client/client/v1/all-assignments/${clientDisplayedFile?.id}/`,
      )
        .then((res) => {
          if (res.success === true) {
            setClientDisplayedFile(res.last_assignment);
            setUserId(res.last_assignment);
            setDisableBtn(false);
          } else {
            setDisableBtn(false);
          }
        })
        .catch((err) => {
          setNotFoundError(true);
          setDisableBtn(false);
        });
      return true;
    }
    postAuthData(api_url, data)
      .then((res) => {
        if (res.success === true) {
          getAuthData(
            `${API_URL}/api-client/client/v1/all-assignments/${clientDisplayedFile?.id}/`,
          )
            .then((res) => {
              if (res.success === true) {
                setClientDisplayedFile(res.last_assignment);
                setUserId(res.last_assignment);
                setDisableBtn(false);
              } else {
                setDisableBtn(false);
              }
            })
            .catch((err) => {
              setNotFoundError(true);
              setDisableBtn(false);
            });
        }
      })
      .catch((err) => {
        setNotFoundError(true);
      });
    updateSidebarFiles();
  };

  const onFileStateBtnClick = (e) => {
    e.preventDefault();
    setDisableBtn(true);
    const data = { delivery_id: clientDisplayedFile.batch_id };
    let api_url;
    switch (encryptedState) {
      case FILE_ENCRYPTION_STATES.ENCRYPTED:
        // setBtn(FILE_ENCRYPTION_STATES.DECRYPTED);
        api_url = `${API_URL}/api-client/client/v1/delivery/`;
        fetchAPI(api_url, getAccessLogData(data));
        break;
      case FILE_ENCRYPTION_STATES.DECRYPTED:
        handleKeySubmit();
        break;
      case FILE_ENCRYPTION_STATES.DOWNLOAD:
        // setBtn(FILE_ENCRYPTION_STATES.ENCRYPTED);
        api_url = `${API_URL}/api-client/client/v1/encrypt/`;
        fetchAPI(api_url, getAccessLogData(data));
        setDisableBtn(false);
        break;
      default:
        api_url = `${API_URL}/api-client/client/v1/delivery/`;
        fetchAPI(api_url, getAccessLogData(data));
        setDisableBtn(false);
        break;
    }
  };

  switch (encryptedState) {
    case FILE_ENCRYPTION_STATES.OLD_FILE:
    case FILE_ENCRYPTION_STATES.ENCRYPTED:
      return (
        <div className="client-dashboard-file-decrypt-btn">
          <button
            className="client-btns client-secure-key-btn"
            disabled={disableBtn}
            onClick={onFileStateBtnClick}
          >
            Decrypt
          </button>
        </div>
      );
    case FILE_ENCRYPTION_STATES.DECRYPTED:
      return (
        <div className="client-dashboard-file-decrypt-btn">
          <form
            className="client-dashboard-file-decrypt-form"
            onSubmit={onFileStateBtnClick}
          >
            <div className="decrypt-key-input-error-contianer">
              <input
                className="client-dashboard-input decrypt-key-input"
                type="text"
                onChange={(e) => {
                  ToolTipError(e, [ERROR_REGEX.SYMBOLS_ERROR]);
                }}
                ref={fetchId}
                placeholder="Enter Secure Key"
              />
            </div>
            <button
              className="client-btns client-secure-key-btn"
              disabled={disableBtn}
              type="submit"
              id="client-decrypt-key-btn"
            >
              Decrypt
            </button>
          </form>
          <div className="client-decrypt-btn-info-panel">
            {fetchError ? (
              <div className="client-decrypt-btn-info decrypt-key-error">
                {fetchError}
              </div>
            ) : (
              <div className="client-decrypt-btn-info client-decrypt-check-mail">
                Check your email for Secure Key
              </div>
            )}
            {resendBtnMsg ? (
              <div className="decrypt-resend-btn">{resendBtnMsg}</div>
            ) : (
              <button className="decrypt-resend-btn" onClick={handleResend}>
                Resend Email
              </button>
            )}
          </div>
        </div>
      );
    case FILE_ENCRYPTION_STATES.DOWNLOAD:
      return (
        <div className="client-dashboard-file-decrypt-btn">
          <div className="client-timer-container">
            {/* This below button will show the encrypt now button with the timer */}
            {/* <button className="client-btns client-secure-key-btn" id="client-secure-encrypt-btn" onClick={onFileStateBtnClick}>
                            <span id="client-timer-text">Encrypt Now</span>
                            <span id="client-timer-span" style={{width:(autoEncryptTimer/(3600*((clientDisplayedFile.duration==="hrs") ? 24: 1)))*100 +"%"}}></span>
                        </button> */}
            <span className="client-secure-timer">
              {Math.floor(
                autoEncryptTimer /
                  (clientDisplayedFile.duration === "hrs" ? 3600 : 60),
              )}{" "}
              {clientDisplayedFile.duration} remaining
            </span>
            <span
              className="client-encrypt-now-btn"
              onClick={onFileStateBtnClick}
            >
              Encrypt Now
            </span>
          </div>
        </div>
      );

    default:
      return (
        <div className="client-dashboard-file-decrypt-btn">
          <button
            className="client-btns client-secure-key-btn"
            onClick={onFileStateBtnClick}
          >
            Decrypt
          </button>
        </div>
      );
  }
};

export default DownloadBtn;
