import React from "react";
import { FaKey, FaLock, FaUnlock } from "react-icons/fa";
import { FILE_ENCRYPTION_STATES } from "../../../client_module/ClientComponents";
import ToolDownloadBtn from "./ToolDownloadBtn";
import "./FileInfoSection.scss";
import "./ToolDecryptComp.scss";
const ToolDecryptComp = ({ tools_details_data }) => {
  if (!tools_details_data) {
    return null;
  }

  return (
    <section className="tool-decrypt-comp">
      <div className="st-client-key-button-section tools-dashboard-key-section">
        {tools_details_data?.data?.file_stage &&
          tools_details_data?.data?.file_stage !==
            FILE_ENCRYPTION_STATES.REVOKED && <ToolDownloadBtn />}
      </div>

      {tools_details_data?.data?.file_stage ===
        FILE_ENCRYPTION_STATES.ENCRYPTED && (
        <div
          className=" tool-client-lock-mobile tool-client-file-lock"
          id="tools-client-file-lock-encrypted"
        >
          <FaLock />
        </div>
      )}
      {tools_details_data?.data?.file_stage ===
        FILE_ENCRYPTION_STATES.DOWNLOAD && (
        <div
          className="tool-client-lock-mobile tool-client-file-lock"
          id="tools-client-file-lock-download"
        >
          <FaUnlock />
        </div>
      )}
      {tools_details_data?.data?.file_stage ===
        FILE_ENCRYPTION_STATES.DECRYPTED && (
        <div
          className="tool-client-lock-mobile tool-client-file-lock"
          id="tools-client-file-lock-decrypted"
        >
          <FaKey />
        </div>
      )}
    </section>
  );
};

export default ToolDecryptComp;
