import React, { useState, useEffect } from "react";
import "./GeneralInputComp.scss";

const GeneralInputComp = ({
  label,
  type = "text",
  placeholder,
  value,
  handleInputChange,
  disabled = false,
  isScroll = false,
  inputValid = true,
  onClick,
  readOnly = false,
  onFocus,
}) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    // Update inputValue when value prop changes
    setInputValue(value);
  }, [value]);

  const handleChange = (e) => {
    const newValue =
      type === "number" ? Number(e.target.value) : e.target.value.trimStart();
    setInputValue(newValue);
    handleInputChange(newValue);
  };

  return (
    <div className="general-form-text-group">
      {label && (
        <div className="general-form-text-label">
          {label}{" "}
          {!inputValid && (
            <span className="general-form-text-label-validation"> * </span>
          )}
        </div>
      )}

      <input
        type={type}
        name={label}
        className="general-form-text-input"
        value={inputValue}
        onChange={handleChange}
        readOnly={readOnly}
        placeholder={placeholder || label || ""}
        disabled={disabled}
        onClick={onClick}
        onWheel={(e) => type === "number" && isScroll && e.currentTarget.blur()}
      />
    </div>
  );
};

export default GeneralInputComp;
