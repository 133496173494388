export const MODAL_TYPES = {
  GET_A_QUOTE: "getaquote",
  NULL: null,
};

const initialState = {
  keyword: null,
  modalShow: MODAL_TYPES.NULL,
  navShow: false,
  pageNav: false,
  scrollShow: false,
  modalTitle: null,
  isDeviceHoverable: false,
  serivceActiveIndex: 0,
  deltaXPosition: 0,
  serviceName: null,
};

export default initialState;
