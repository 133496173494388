import React from "react";

const FilledStar = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.344"
      height="30"
      viewBox="0 0 31.344 30"
    >
      <path
        id="Icon_awesome-star"
        data-name="Icon awesome-star"
        d="M15.992,1.042,12.167,8.8,3.607,10.047a1.876,1.876,0,0,0-1.037,3.2L8.763,19.28,7.3,27.8a1.874,1.874,0,0,0,2.718,1.974l7.657-4.025,7.657,4.025A1.875,1.875,0,0,0,28.049,27.8L26.585,19.28l6.193-6.034a1.876,1.876,0,0,0-1.037-3.2L23.181,8.8,19.355,1.042A1.876,1.876,0,0,0,15.992,1.042Z"
        transform="translate(-2.002 0.001)"
        fill="#EA7B2C"
      />
    </svg>
  );
};

export default FilledStar;
