import React from "react";

const FileRetry = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 14C5.04583 14 3.39058 13.3222 2.03425 11.9665C0.678084 10.6108 0 8.95633 0 7.003C0 5.04967 0.678084 3.39417 2.03425 2.0365C3.39058 0.678834 5.04583 0 7 0C8.18833 0 9.30117 0.282333 10.3385 0.847C11.3757 1.41183 12.2115 2.2 12.846 3.2115V0H13.846V5.23075H8.61525V4.23075H12.3152C11.7948 3.23325 11.0624 2.44542 10.1182 1.86725C9.17392 1.28908 8.1345 1 7 1C5.33333 1 3.91667 1.58333 2.75 2.75C1.58333 3.91667 1 5.33333 1 7C1 8.66667 1.58333 10.0833 2.75 11.25C3.91667 12.4167 5.33333 13 7 13C8.28333 13 9.44167 12.6333 10.475 11.9C11.5083 11.1667 12.2333 10.2 12.65 9H13.7115C13.2705 10.4975 12.4327 11.7052 11.198 12.623C9.96333 13.541 8.564 14 7 14Z"
        fill="white"
      />
    </svg>
  );
};

export default FileRetry;
