import React from "react";
import "./FilesShare.scss";
import ToolsShareCard from "./ToolsShareCard/ToolsShareCard";
import ToolsAccessCard from "./ToolsAccessCard/ToolsAccessCard";
const FilesShare = () => {
  return (
    <section className="tools_file_share_container">
      <ToolsAccessCard />
      <ToolsShareCard />
    </section>
  );
};

export default FilesShare;
