import React, { useContext, useEffect, useState, useRef } from "react";
import { FaTimes } from "react-icons/fa";
import {
  ApiContext,
  getAccessLogData,
  DevelopmentContext,
} from "../../../index";
import {
  ClientAccessLogContext,
  ClientDashboardFileContext,
  ClientSharingLogContext,
  CLIENT_MODAL_TYPE,
  ModalDispatchContext,
  NotFoundErrorContext,
} from "../../ClientComponents";
import { getAuthData, postAuthData } from "../../../helpers/request";
// import {
//   renderShareModalContext,
//   RevokeShareDataContext,
// } from "../SharingModal/SharingModal";
// import { SHARE_MODAL_TYPE } from "../../../utils/ShareModalType";

import "./ShareRevokeModal.css";

import revoke_share_data from "../../../archive/test_data/dummy_revoke_assignments/revoke_share.json";

const ShareRevokeModal = ({ payload }) => {
  const { devEnv } = useContext(DevelopmentContext);
  const API_URL = useContext(ApiContext);
  const { clientDisplayedFile } = useContext(ClientDashboardFileContext);
  const modal_dispatch = useContext(ModalDispatchContext);
  const { setNotFoundError } = useContext(NotFoundErrorContext);
  const { setSharedLogData } = useContext(ClientSharingLogContext);
  const { setAccessLogData } = useContext(ClientAccessLogContext);

  const [requesterNames, setRequesterNames] = useState();
  const [shareErrNameArr, setShareErrNameArr] = useState([]); // eslint-disable-line no-unused-vars
  const [disableShareBtn, setDisableShareBtn] = useState(false); // eslint-disable-line no-unused-vars
  const [shareError, setShareError] = useState(null);
  const [error, setError] = useState(null); // eslint-disable-line no-unused-vars
  const [sharedNamesList, setSharedNamesList] = useState([]);

  const shareModalRef = useRef(null);
  const shareSubmitBtn = useRef(null);

  const removeName = (e, idx) => {
    setSharedNamesList([
      ...sharedNamesList.slice(0, idx),
      ...sharedNamesList.slice(idx + 1, sharedNamesList.length),
    ]);
  };

  // const openSharingModal = (e) => {
  //     e?.preventDefault();
  //     setRenderShareModal(SHARE_MODAL_TYPE.NORMAL);
  // };

  const closeShareRevokeModal = (e) => {
    e?.preventDefault();
    modal_dispatch({ modal_show_type: CLIENT_MODAL_TYPE.NONE });
  };

  const submitBtnSuccess = () => {
    const shareBtn = shareSubmitBtn.current;
    shareBtn.style.background = "var(--light-orange,#e98742)";
    shareBtn.innerText = "Request sent!";
    setTimeout(() => {
      shareBtn.style.background = "var(--light-blue,#3cc3f2)";
      shareBtn.innerText = "Send Request";
      closeShareRevokeModal();
    }, 1000);
  };

  const fetch_access_log = () => {
    getAuthData(
      `${API_URL}/api-client/misc/v1/access-log/${clientDisplayedFile?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setAccessLogData(res.data);
        } else {
          setNotFoundError(true);
        }
      })
      .catch((err) => {
        setNotFoundError(true);
      });
  };

  const fetch_shared_log = () => {
    getAuthData(
      `${API_URL}/api-client/misc/v1/sharedlog/${clientDisplayedFile?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setSharedLogData(res);
        } else {
          setNotFoundError(true);
        }
      })
      .catch((err) => {
        setNotFoundError(true);
      });
  };

  const getRequesterNames = () => {
    getAuthData(
      `${API_URL}/api-client/misc/v1/share-request/${clientDisplayedFile?.id}/`,
    )
      .then((res) => {
        if (res.success === true) {
          setRequesterNames(res.data);
        } else {
          setNotFoundError(true);
        }
      })
      .catch((err) => {
        setNotFoundError(true);
        // console.log(err)
      });
  };

  // data validation
  const validation = () => {
    if (sharedNamesList.length < 1) {
      setError("No emails present to share the file. Please try again");
      setTimeout(() => {
        setError(null);
        closeShareRevokeModal();
      }, 2000);
      return true;
    } else {
      return false;
    }
  };

  const postRevokeShareData = () => {
    if (validation()) {
      return;
    }

    let shared_emails = sharedNamesList;

    let data = {
      delivery_id: clientDisplayedFile.batch_id,
      emails: shared_emails,
    };

    // Disabling the share button to prevent multiple clicks
    setDisableShareBtn(true);

    if (devEnv) {
      submitBtnSuccess();
      console.log(data);
    } else {
      postAuthData(
        `${API_URL}/api-client/misc/v1/share-request/`,
        getAccessLogData(data),
      )
        .then((res) => {
          if (res.success === true) {
            submitBtnSuccess();
            setSharedNamesList(null);
            setDisableShareBtn(false);
            fetch_shared_log();
            fetch_access_log();
          } else {
            setShareError(res.message);
            setDisableShareBtn(false);
            setTimeout(() => {
              closeShareRevokeModal();
            }, 2000);
            if (res?.emails?.length > 0) {
              setShareErrNameArr([...res.emails]);
            }
          }
        })
        .catch((err) => {
          setDisableShareBtn(false);
          setNotFoundError(true);
        });
    }
  };

  useEffect(() => {
    if (devEnv) {
      setRequesterNames(revoke_share_data);
    } else {
      getRequesterNames();
      setSharedNamesList(payload?.share_res);
    }
  }, [clientDisplayedFile, payload]);

  return (
    <div
      className="client-modal-container client-revoke-share-modal-container"
      ref={shareModalRef}
    >
      <div className="client-modal-head">
        <div
          className="client-modal-container-head"
          id="client-revoke-share-heading"
        >
          Share Assignment
        </div>
        <button
          className="client-modal-container-close-btn"
          id="client-revoke-share-heading-close-btn"
          onClick={closeShareRevokeModal}
        >
          <FaTimes />
        </button>
      </div>
      <p className="client-revoke-share-heading-para-white">
        {" "}
        You have requested to share the assignment with the below email
        addresses. Please verify and remove any incorrect or unauthorized email
        addresses.
      </p>

      <div className="client-revoke-share-names-container">
        {shareError && (
          <div className="client-input-error client-share-error">
            {shareError}
          </div>
        )}

        {!shareError && (
          <div className="client-revoke-share-names-list">
            {sharedNamesList?.map((item, idx) => {
              return (
                <div key={idx} className="client-revoke-share-name-item">
                  <span className="client-revoke-share-name">{item}</span>
                  <button
                    className="client-revoke-share-remove-btn"
                    onClick={(e) => {
                      removeName(e, idx);
                    }}
                  >
                    <FaTimes />
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <p className="client-revoke-share-heading-para">
        A share request will be sent to the below users for approval.{" "}
      </p>

      <div className="client-revoke-share-names-container">
        <div className="client-revoke-share-requester-names-list">
          {requesterNames?.requester_emails?.map((item, idx) => {
            return (
              <div
                key={idx}
                className="client-revoke-share-requester-name-item"
              >
                <span className="client-revoke-share-requester-name">
                  {item}
                </span>
              </div>
            );
          })}
        </div>
      </div>

      <div className="client-revoke-share-btn-container">
        <button
          className="client-btns"
          id="client-revoke-share-modal-cancel-btn"
          onClick={closeShareRevokeModal}
        >
          {" "}
          Cancel{" "}
        </button>
        <button
          className="client-btns"
          id="client-revoke-share-modal-okay-btn"
          ref={shareSubmitBtn}
          onClick={() => postRevokeShareData()}
        >
          {" "}
          Send Request{" "}
        </button>
      </div>
    </div>
  );
};

export { ShareRevokeModal };
