import React from "react";
import { useSelector } from "react-redux";
import toolInfoData from "../toolInfoData";
import { lazy_load_image } from "../../../..";
import "./ToolsConsultInfoBanner.scss";

const ToolsConsultInfoBanner = () => {
  const { active_tool_type } = useSelector((state) => state.utilsData);
  const content = toolInfoData?.[active_tool_type];
  return (
    <div className="consult_tool_description_banner">
      <div className="consult_tool_header_wrapper">
        <img
          onLoad={lazy_load_image}
          src={process.env.PUBLIC_URL + "/EZConsult_welcome_logo.svg"}
          alt={`${active_tool_type}.ai`}
          className="consult_tool_header_mainImage"
        />
      </div>
      <div className="consult_description_text_wrapper">
        <span className="consult_description_text">
          {content.description_content}
        </span>
      </div>
    </div>
  );
};

export default ToolsConsultInfoBanner;
