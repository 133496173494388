import React from "react";
import "./ToolInfo.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  setModal,
  toggleShowToolsSidebar,
} from "../../../store/utilsDataSlice/utilsDataSlice";
import TOOLS_MODALS from "../../ToolsConstants/TOOLS_MODALS";
import { FaAngleLeft, FaPlayCircle } from "react-icons/fa";
import { lazy_load_image } from "../../..";
import getToolTypeIcon from "../../../utils/getToolTypeIcon";

const ToolInfo = ({
  name,
  main_content,
  main_content_a,
  content_a,
  content_a_small,
  content_b,
  content_b_small,
  content_c,
  content_c_small,
  // video_id,
}) => {
  const dispatch = useDispatch();
  const handleNew = () => {
    dispatch(setModal(TOOLS_MODALS.YOUTUBE));
  };
  const { active_tool_type } = useSelector((state) => state.utilsData);

  const handleBack = () => {
    dispatch(toggleShowToolsSidebar(true));
  };

  const renderVideoSection = () => {
    if (active_tool_type && active_tool_type.toLowerCase() === "flip") {
      return (
        <div className="modal_learn_more_main" onClick={handleNew}>
          <FaPlayCircle size={22} />
          {/* <img
            src="/flip_thumbnail.jpg"
            width="72%"
            height="23%"
            className="yt_image_icon"
            onClick={handleNew}
            alt="thumbnail"
          />
          <img
            src="/newyticon.svg"
            width="60px"
            height="60px"
            className="yt_icon_center"
            alt="YouTube icon"
            onClick={handleNew}
          />*/}
          <span className="watch_video_text">Learn More</span>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="flip_upload_description_container">
      {active_tool_type && active_tool_type.toLowerCase() === "flip" && (
        <div className="mobile_learn_more" onClick={handleNew}>
          <span className="mobile_learn_text">Learn more</span>
          <span className="mobile_learn_logo">
            <img
              className="mobile_play_btn"
              src={process.env.PUBLIC_URL + "/play_circle.svg"}
              alt="play"
            />
          </span>
        </div>
      )}
      <div className="flip_upload_logo_container">
        <div className="flip_upload_back" onClick={handleBack}>
          <FaAngleLeft />
        </div>
        <div className="flip_upload_logo">
          {/* <img
            className="flip_upload_logo_img"
            src={process.env.PUBLIC_URL + "/logo.png"}
            alt="EZ"
          /> */}
          <img
            onLoad={lazy_load_image}
            src={process.env.PUBLIC_URL + getToolTypeIcon(active_tool_type)}
            alt={`${active_tool_type}.ai`}
          />
        </div>
        {/* <div className="flip_upload_name">{name}</div> */}
      </div>
      <div className="modal_content_description">
        <span>{main_content}</span>
        {/* <span>{main_content_a}</span> */}
      </div>
      <div className="modal_content_a">
        <span className="text_strong">{content_a}</span>
        {content_a_small}
      </div>
      <div className="modal_content_b">
        <span className="text_strong">{content_b}</span>
        {content_b_small}
      </div>
      <div className="modal_content_c">
        <span className="text_strong">{content_c}</span>
        {content_c_small}
      </div>
      {renderVideoSection()}
    </div>
  );
};

export default ToolInfo;
