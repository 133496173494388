import React, { useEffect, useState } from "react";
import styles from "./ServicesDropdown.module.scss";
import { serviceList } from "./ServiceDropdownData";
import SingleService from "./SingleServiceComponent/SingleService";
const ServicesDropdown = ({ setShowIconList, showIconList }) => {
  const [show, setShow] = useState({});
  const [openTitle, setOpenTitle] = useState(null);

  useEffect(() => {
    //made subsections for each state, as we would have many usestates,  so we are combining all here and then using them, key is the data eg presentationDesign
    function GetStates() {
      let initialShowState = {};
      serviceList.forEach((elem) => {
        elem.subservices.forEach((item) => {
          initialShowState = {
            ...initialShowState,
            [item.title]: false,
          };
        });
      });

      setShow(initialShowState);
    }

    GetStates();
  }, []);
  return (
    <div
      className={`${styles.services_dropdown_container} ${
        showIconList ? styles.services_dropdown_container_open : ""
      }`}
    >
      {serviceList.map((iconlist, id) => {
        return (
          <SingleService
            key={id}
            iconlist={iconlist}
            show={show}
            setShow={setShow}
            openTitle={openTitle}
            setOpenTitle={setOpenTitle}
            setShowIconList={setShowIconList}
          />
        );
      })}
    </div>
  );
};

export default ServicesDropdown;
