import React from "react";
import toolInfoData from "./toolInfoData";
import ToolInfo from "./ToolInfo";

import { useSelector } from "react-redux";
const ToolsInfoMain = () => {
  const { active_tool_type } = useSelector((state) => state.utilsData);
  const content = toolInfoData?.[active_tool_type];

  return (
    <>
      {active_tool_type && (
        <ToolInfo
          name={content.name}
          main_content={content?.main_content}
          main_content_a={content?.main_content_a}
          content_a={content?.content_a}
          content_a_small={content?.content_a_small}
          content_b={content?.content_b}
          content_b_small={content?.content_b_small}
          content_c={content?.content_c}
          content_c_small={content?.content_c_small}
          videoId={content?.video_id}
        />
      )}
    </>
  );
};

export default ToolsInfoMain;
